export const PAYMENT_CENTER_TABLE_NAME = 'kikiloginPaymentHistory';

export const acceptedPaymentMethod = [
  {
    key: 'document/ed/92/cb/a84a64cb37532e7c1fc914b31d4c8c13.png',
    url: 'https://salt.tikicdn.com/ts/document/ed/92/cb/a84a64cb37532e7c1fc914b31d4c8c13.png',
    original_name: 'Vietcombank.png',
    width: 146,
    height: 49,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/68/82/50/03808058a04773f0e8a77cf1898604c0.png',
    url: 'https://salt.tikicdn.com/ts/document/68/82/50/03808058a04773f0e8a77cf1898604c0.png',
    original_name: 'Momo.png',
    width: 146,
    height: 39,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/50/25/68/a6400bb0ffd945919b476dab6d636bd2.png',
    url: 'https://salt.tikicdn.com/ts/document/50/25/68/a6400bb0ffd945919b476dab6d636bd2.png',
    original_name: 'paypal.png',
    width: 146,
    height: 39,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/3d/d7/9c/fdb7b61890dacb3b63d2afb0cf040132.png',
    url: 'https://salt.tikicdn.com/ts/document/3d/d7/9c/fdb7b61890dacb3b63d2afb0cf040132.png',
    original_name: 'USDT.png',
    width: 112,
    height: 42,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/f9/46/88/3713f04b55d1cac7599125a9f6184b79.png',
    url: 'https://salt.tikicdn.com/ts/document/f9/46/88/3713f04b55d1cac7599125a9f6184b79.png',
    original_name: 'USD COIN.png',
    width: 146,
    height: 49,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/10/2c/bc/cee9bed44d9a00c087d2c2e23de8cd89.png',
    url: 'https://salt.tikicdn.com/ts/document/10/2c/bc/cee9bed44d9a00c087d2c2e23de8cd89.png',
    original_name: 'BITCOIN.png',
    width: 146,
    height: 46,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/90/b1/2c/7cc5c53d76f76524c130281e65593b75.png',
    url: 'https://salt.tikicdn.com/ts/document/90/b1/2c/7cc5c53d76f76524c130281e65593b75.png',
    original_name: 'ETH.png',
    width: 112,
    height: 49,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/ea/9d/a2/ddac7f0a31cdad169d0ddcf423a94d46.png',
    url: 'https://salt.tikicdn.com/ts/document/ea/9d/a2/ddac7f0a31cdad169d0ddcf423a94d46.png',
    original_name: 'PERFECT.png',
    width: 116,
    height: 49,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
  {
    key: 'document/60/d7/d6/f4e1fe6ecb8f804041232677f7e98fe7.png',
    url: 'https://salt.tikicdn.com/ts/document/60/d7/d6/f4e1fe6ecb8f804041232677f7e98fe7.png',
    original_name: 'PAYEER.png',
    width: 146,
    height: 34,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },

  {
    key: 'document/ba/fd/9a/77a6dbcd611a542bbebe4faf8499b071.png',
    url: 'https://salt.tikicdn.com/ts/document/ba/fd/9a/77a6dbcd611a542bbebe4faf8499b071.png',
    original_name: 'VISA.png',
    width: 146,
    height: 46,
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px',
  },
];

export const DEPOSIT_STEPS = {
  inputAmount: 0,
  selectPaymentMethod: 1,
};

export const orderTypes = {
  deposit: 'Deposit',
  commission: 'Commission',
  extendPackage: 'ExtendPackage',
  changePackage: 'ChangePackage',
  refund: 'Refund',
  withdrawn: 'Withdrawn',
  withdrawnAffMoney: 'Withdrawn Aff Money',
} as const;

export const couponTypes = {
  fixed: 'fixed',
  percentage: 'percentage',
  addMonth: 'add-month',
} as const;

export const defaultExtendPackageApplyCoupon = {
  Solo: {
    oneMonth: undefined,
    threeMonths: 'KIKISOLO3MONTHEP',
    sixMonths: 'KIKISOLO6MONTHEP',
    oneYear: 'KIKISOLO1YEAREP',
    twoYears: 'KIKISOLO2YEAREP',
    threeYears: 'KIKISOLO3YEAREP',
  },
  Team: {
    oneMonth: undefined,
    threeMonths: 'KIKITEAM3MONTHEP',
    sixMonths: 'KIKITEAM6MONTHEP',
    oneYear: 'KIKITEAM1YEAREP',
    twoYears: 'KIKITEAM2YEAREP',
    threeYears: 'KIKITEAM3YEAREP',
  },
  Scale: {
    oneMonth: undefined,
    threeMonths: 'KIKISCALE3MONTHEP',
    sixMonths: 'KIKISCALE6MONTHEP',
    oneYear: 'KIKISCALE1YEAREP',
    twoYears: 'KIKISCALE2YEAREP',
    threeYears: 'KIKISCALE3YEAREP',
  },
  Business: {
    oneMonth: undefined,
    threeMonths: 'KIKIBUSINESS3MONTHEP',
    sixMonths: 'KIKIBUSINESS6MONTHEP',
    oneYear: 'KIKIBUSINESS1YEAREP',
    twoYears: 'KIKIBUSINESS2YEAREP',
    threeYears: 'KIKIBUSINESS3YEAREP',
  },
};

export const defaultChangePackageApplyCoupon = {
  Solo: {
    oneMonth: undefined,
    threeMonths: 'KIKISOLO3MONTHCP',
    sixMonths: 'KIKISOLO6MONTHCP',
    oneYear: 'KIKISOLO1YEARCP',
    twoYears: 'KIKISOLO2YEARCP',
    threeYears: 'KIKISOLO3YEARCP',
  },
  Team: {
    oneMonth: undefined,
    threeMonths: 'KIKITEAM3MONTHCP',
    sixMonths: 'KIKITEAM6MONTHCP',
    oneYear: 'KIKITEAM1YEARCP',
    twoYears: 'KIKITEAM2YEARCP',
    threeYears: 'KIKITEAM3YEARCP',
  },
  Scale: {
    oneMonth: undefined,
    threeMonths: 'KIKISCALE3MONTHCP',
    sixMonths: 'KIKISCALE6MONTHCP',
    oneYear: 'KIKISCALE1YEARCP',
    twoYears: 'KIKISCALE2YEARCP',
    threeYears: 'KIKISCALE3YEARCP',
  },
  Business: {
    oneMonth: undefined,
    threeMonths: 'KIKIBUSINESS3MONTHCP',
    sixMonths: 'KIKIBUSINESS6MONTHCP',
    oneYear: 'KIKIBUSINESS1YEARCP',
    twoYears: 'KIKIBUSINESS2YEARCP',
    threeYears: 'KIKIBUSINESS3YEARCP',
  },
};

export const extendTimeOptions = {
  1: 'oneMonth',
  3: 'threeMonths',
  6: 'sixMonths',
  12: 'oneYear',
  24: 'twoYears',
  36: 'threeYears',
};

export const timeLabelToNumberMonth = {
  oneMonth: 1,
  threeMonths: 3,
  sixMonths: 6,
  oneYear: 12,
  twoYears: 24,
  threeYears: 36,
};

export const packageOptions = {
  Solo: {
    useAPIAutomate: false,
    useFarm: false,
  },
  Team: {
    useAPIAutomate: true,
    useFarm: false,
  },
  Scale: {
    useAPIAutomate: true,
    useFarm: true,
  },
  Business: {
    useAPIAutomate: true,
    useFarm: true,
  },
};
