import React, { useCallback, useEffect, useState } from 'react';
import { DEFAULT_PAGINATION_X } from 'kikifrontend/utils/pagination';
import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium';
import { convertFilterModelToAdvancedSearch } from '@kikisoftware/uikit-table-v2';
import { GridPaginationModel } from '@mui/x-data-grid';
import { PaginationProps, QueryFilterData, ResponseData } from 'kikifrontend/types/api.types';
import { UseQueryResult } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { tableFilterAtom } from 'kikifrontend/utils/recoil/atom';

const defaultOptions = {
  initialPagination: DEFAULT_PAGINATION_X,
  initialFilterItems: [],
};

export default function useTableConfigs<T>(
  queryFn: (query: QueryFilterData) => UseQueryResult<ResponseData<T[]>>,
  externalFilter: Record<string, string> = {},
  {
    initialPagination = defaultOptions.initialPagination,
    initialFilterItems = defaultOptions.initialFilterItems,
    trackQueryFilterName,
  }: {
    initialPagination?: PaginationProps;
    initialFilterItems?: GridFilterItem[];
    trackQueryFilterName?: string;
  } = defaultOptions,
) {
  const [pagination, setPagination] = useState(initialPagination);
  const [searchTerm, setSearchTerm] = useState<GridFilterModel>({
    items: initialFilterItems,
  });
  const setTableFilter = useSetRecoilState(tableFilterAtom);

  const queryResult = queryFn({
    page: pagination.page,
    limit: pagination.limit,
    ...convertFilterModelToAdvancedSearch(searchTerm),
    ...externalFilter,
  });

  useEffect(() => {
    if (trackQueryFilterName) {
      setTableFilter((prev) => ({
        ...prev,
        [trackQueryFilterName]: {
          pagination,
          searchTerm: searchTerm,
        },
      }));
    }
  }, [trackQueryFilterName, pagination, searchTerm]);

  const onPaginationModelChange = useCallback((model: GridPaginationModel) => {
    setPagination((prev) => ({ ...prev, page: model.page + 1, limit: model.pageSize }));
  }, []);

  const onFilterModelChange = useCallback((model: GridFilterModel) => {
    setSearchTerm(model);
  }, []);

  const onPageChange = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPagination((prevPagination) => ({ ...prevPagination, page: newPage + 1 }));
  }, []);

  const onRowsPerPageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prevPagination) => ({ ...prevPagination, limit: parseInt(e.target.value) }));
  }, []);

  const onChangeInputPage = useCallback((value: string) => {
    const p = value ? parseInt(value) : '';
    if (p) {
      setPagination((prevPagination) => ({ ...prevPagination, page: p }));
    }
  }, []);

  const reset = useCallback(() => {
    setPagination(initialPagination);
  }, []);

  return {
    queryResult,
    tableConfig: {
      pagination,
      onPaginationModelChange,
      searchTerm,
      onFilterModelChange,
      onPageChange,
      onRowsPerPageChange,
      onChangeInputPage,
      reset,
    },
  };
}
