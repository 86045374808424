import http from 'kikifrontend/utils/api-services/http';
import { QueryFilterData, ResponseApi, ResponseData } from 'kikifrontend/types/api.types';
import { ProfileInfo, ProfileProps } from 'kikifrontend/types/profile.types';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import { NewFingerprintProps } from 'kikifrontend/features/profiles/profileEditor/types/fingerprint.types';
import HttpUtils from 'kikifrontend/utils/api-services/httpUtils';
import { AddProfileConfigProps } from 'kikifrontend/features/profiles/profileEditor/types/addProfileConfig.types';
import {
  ProfileEditorFormValue,
  ProfileProxyConfig,
} from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import axios from 'axios';
import { antiCheckEndpoint } from 'kikifrontend/utils/configs';
import { QUERY_MAPPING_SYSTEM } from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { getFromLocalStorage } from 'kikifrontend/utils/localstorage';

const getAllProfilesV2 = async (data: QueryFilterData) => {
  return http.client
    .post<ResponseApi<ResponseData<ProfileProps[]>>>('/api/v2/profiles/filter', data)
    .then(HttpUtils.getDataFromHttpResponse);
};

const getAllTeamProfiles = async (data: QueryFilterData) => {
  return http.client
    .post<ResponseApi<ResponseData<ProfileInfo[]>>>('/api/account-profile/team-profile/filter', data)
    .then(HttpUtils.getDataFromHttpResponse);
};

const getProfileById = async (id?: string) => {
  if (!id) throw 'Missing profile id';
  return http.client
    .get<ResponseApi<ProfileProps>>(`/api/account-profile/${id}/get-detail`)
    .then(HttpUtils.getDataFromHttpResponse);
};

const getProfileDeleted = async (data: QueryFilterData) => {
  return http.client
    .post<ResponseApi<ResponseData<ProfileInfo[]>>>('/api/account-profile/trash/filter', data)
    .then((res) => res.data);
};

const deleteProfileInTrash = async ({ profileId }: { profileId: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/delete-in-trash`);
};

const restoreProfileInTrash = async ({ profileId }: { profileId: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/restore-from-trash`);
};

const createProfileConfig = async (system: string, browserType: BrowserType) => {
  return http.client
    .get<ResponseApi<NewFingerprintProps>>('/api/v2/config/create-profile', { system, browserType })
    .then(HttpUtils.getDataFromHttpResponse);
};

const createProfileConfigV2 = async (system: string, browserType: BrowserType) => {
  const webConfig = getFromLocalStorage<{ latestBrowserVersion: { gomu: string; kifox: string } }>('webConfig');

  return http.client
    .post<ResponseApi<NewFingerprintProps>>(`${antiCheckEndpoint}/api/v1/fingerprint/new-fingerprint`, {
      platform: QUERY_MAPPING_SYSTEM[system.toLowerCase() as keyof typeof QUERY_MAPPING_SYSTEM],
      browserType: browserType === 'gomubrowser' ? 'gomu' : browserType,
      browserVersion:
        browserType === 'gomubrowser'
          ? webConfig?.latestBrowserVersion?.gomu ?? '116'
          : browserType === 'kifox'
          ? webConfig?.latestBrowserVersion?.kifox
          : '122',
    })
    .then(HttpUtils.getDataFromHttpResponse);
};

const addProfileConfig = async () => {
  return http.client
    .get<ResponseApi<AddProfileConfigProps>>('/api/add-profile-config')
    .then(HttpUtils.getDataFromHttpResponse);
};

const mutateAccountProfile = async ({ profileId, config }: { profileId?: string; config: ProfileEditorFormValue }) => {
  if (profileId) {
    return http.client.post(`/api/account-profile/${profileId}/edit`, { config });
  }

  return http.client.post('/api/account-profile/create', { config });
};

const cloneCookieAccountProfile = async ({
  cookieProfile,
  config,
}: {
  cookieProfile?: string;
  config: ProfileEditorFormValue;
}) => {
  return http.client.post('/api/account-profile/clone-cookie', { config, cookieProfile });
};

const updateProfileProxy = async ({ profileId, proxy }: { profileId: string; proxy: ProfileProxyConfig }) => {
  return http.client.post(`/api/account-profile/${profileId}/update-proxy`, { proxy });
};

const startProfile = async (data: { profileId: string; customParameters: any[] }) => {
  return http.local.post('/api/browser-profile/start', data);
};

const getProfileSharedUser = async (profileId: string) => {
  return http.client
    .get<ResponseApi<{ canEdit: boolean; user: { email: string; _id: string }; createdAt: string }[]>>(
      `/api/account-profile/${profileId}/shared-user`,
    )
    .then(HttpUtils.getDataFromHttpResponse);
};

const shareProfileToUser = async ({ profileId, ...data }: { profileId: string; canEdit: boolean; email: string }) => {
  return http.client.post(`/api/account-profile/${profileId}/share`, data);
};

const unShareProfile = async ({ userId, profileId }: { userId: string; profileId: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/unshare/${userId}`);
};

const unShareProfileV2 = async ({ profileId, ...payload }: { profileId: string; email: string }) => {
  return http.client.post(`/api/account-profile/${profileId}/unshare`, payload);
};

const updateSharePermission = async ({ profileId, ...data }: { profileId: string; canEdit: boolean; user: string }) => {
  return http.client.post(`/api/account-profile/${profileId}/update-shared-permission`, data);
};

const cloneProfile = async (profile: Pick<ProfileInfo, '_id' | 'name'>) => {
  return http.client.post(`/api/account-profile/${profile._id}/clone`, {
    profileId: profile._id,
    profileName: profile.name,
  });
};

const importCookie = async (profileId: string, data: string[]) => {
  return http.client.post(`/api/v2/account-profile/${profileId}/update-cookies`, data);
};

const transferProfile = async ({ profileId, username }: { profileId: string; username: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/transfer?email=${username}`);
};

const moveProfileToTrash = async ({ profileId }: { profileId: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/delete`);
};

const updateProfile = async ({ id, data }: { id: string; data: { config: Partial<ProfileEditorFormValue> } }) => {
  return http.client.post(`/api/account-profile/${id}/edit`, data);
};

const refreshProxyIpURL = async (url: string) => {
  return axios.get(url);
};

const updateNote = async ({ id, note }: { id: string; note: string }) => {
  return http.client.post(`/api/account-profile/${id}/update-note`, { note });
};

export const profileApis = {
  getAllProfilesV2,
  getProfileById,
  getProfileDeleted,
  deleteProfileInTrash,
  restoreProfileInTrash,
  createProfileConfig,
  addProfileConfig,
  mutateAccountProfile,
  updateProfileProxy,
  startProfile,
  getProfileSharedUser,
  shareProfileToUser,
  unShareProfile,
  updateSharePermission,
  cloneProfile,
  importCookie,
  transferProfile,
  moveProfileToTrash,
  updateProfile,
  refreshProxyIpURL,
  getAllTeamProfiles,
  updateNote,
  createProfileConfigV2,
  cloneCookieAccountProfile,
  unShareProfileV2,
};
