// ----------------------------------------------------------------------

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        // root: {
        //   '& .MuiInputBase-root': {
        //     height: '36px',
        //   },
        // },
      },
    },
  };
}
