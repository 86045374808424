// ----------------------------------------------------------------------

import { Theme } from '@mui/material';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiLoadingButton-loading': {
            '&.MuiButton-containedPrimary.Mui-disabled': {
              opacity: 0.5,
              backgroundColor: theme.palette.primary.main,
              color: 'transparent',
            },
            '&.MuiButton-outlinedPrimary.Mui-disabled': {
              opacity: 0.5,
              borderColor: theme.palette.primary.main,
              color: 'transparent',
            },
          },
          '&.MuiButton-containedPrimary': {
            '&.Mui-disabled': {
              opacity: 0.5,
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
          },
          '&.MuiButton-outlinedPrimary': {
            '&.Mui-disabled': {
              opacity: 0.5,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
          },
          borderRadius: 8,
        },
        sizeSmall: {
          height: 32,
          fontSize: 12,
        },
        sizeMedium: {
          height: 40,
          fontSize: 14,
        },
        sizeLarge: {
          height: 48,
          fontSize: 16,
        },
        outlinedInherit: {
          // @ts-ignore
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
