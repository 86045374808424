/* eslint-disable no-var */
import { Helmet } from 'react-helmet-async';
import { googleAnalyticsConfig, isGAAllowance } from 'kikifrontend/utils/configs';
import React from 'react';

// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = googleAnalyticsConfig;

export default function GoogleAnalytics() {
  if (!isGAAllowance) return null;
  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script>
      {/*<script type='text/javascript'>*/}
      {/*  {`var _paq = window._paq = window._paq || [];*/}
      {/*      _paq.push(['trackPageView']);*/}
      {/*      _paq.push(['enableLinkTracking']);*/}
      {/*      (function() {*/}
      {/*      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];*/}
      {/*      g.type='text/javascript'; g.async=true; g.src='https://cdn.matomo.cloud/kikilogin.matomo.cloud/container_g1ZsAAlO.js'; s.parentNode.insertBefore(g,s);*/}
      {/*  })();`}*/}
      {/*</script>*/}
    </Helmet>
  );
}
