import { UserIpGeolocationProps, UserIpProps } from 'kikifrontend/types/user.types';
import { PROFILE_EDITOR_LOCALES } from 'kikifrontend/features/profiles/profileEditor/utils/constants';

export const getLanguageByCountryFromLocales = (country: string) =>
  PROFILE_EDITOR_LOCALES.find((c) => c.value.endsWith(country.toUpperCase()))?.value ?? '';

export const parseDataFromIpGeolocation = (rs: UserIpGeolocationProps): UserIpProps => {
  const language = getLanguageByCountryFromLocales(rs.country_code2);

  return {
    status: 'proxyActive',
    country: rs.country_name,
    countryCode: rs.country_code2,
    region: rs.state_prov,
    regionName: rs.state_prov,
    city: rs.state_prov,
    zip: '',
    lat: rs.latitude,
    lon: rs.longitude,
    timezone: rs.time_zone?.name,
    query: rs.ip,
    language,
  };
};
const regexExpIPV6 =
  /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi;

export const validateIpAddress = (ipAddress: string) => {
  const ipV4 = ipAddress.split(':')[0];
  return (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipV4,
    ) || regexExpIPV6.test(ipAddress)
  );
};
