import React from 'react';
import { Image, Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { pluginDetailQuery } from 'kikifrontend/features/plugins/queries/pluginDetail.query';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PluginUseButton from 'kikifrontend/features/plugins/components/PluginUseButton';
import { personalPluginsQuery } from 'kikifrontend/features/plugins/queries/personalPlugins.query';
import { mergePlugin } from 'kikifrontend/features/plugins/utils/mergePluginStore';
import { unEscapeHTML } from 'kikifrontend/features/externalServices/components/ExternalServiceDetailModal';
import useLocales from 'kikifrontend/hooks/useLocales';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import Button from '@mui/material/Button';
import VideoModal from 'kikifrontend/components/Modals/VideoModal';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import dayjs from 'dayjs';
function PluginDetailModal({ onClose, pluginId, dayRemaining, expiredAt }: ModalProps) {
  const { t } = useTranslation();
  const { data } = pluginDetailQuery(pluginId);
  const { data: personalPlugins } = personalPluginsQuery();
  const { getI18nText } = useLocales();
  const { openModal } = useModal();

  const plugin = mergePlugin(data, personalPlugins);

  const openVideoGuide = () => {
    if (!plugin?.tutorialVideo) return;
    openModal(VideoModal, { videoSrc: plugin.tutorialVideo, title: 'Hướng dẫn sử dụng plugin' }, undefined, {
      isSecondModal: true,
    });
  };

  return (
    <Modal onClose={onClose} hideHeader width={800} DialogContentProps={{ sx: { p: 0 } }}>
      <Box sx={{ bgcolor: 'background.onLayout2' }} className='flex gap-10 items-center px-8 py-2'>
        {plugin && <Image src={plugin.icon} alt={plugin.name} sx={{ width: 120, height: 120 }} />}
        <div className='grow'>
          <Typography variant='h6'>{plugin?.name}</Typography>
          <Typography variant='body2' color='text.subColor4'>
            {getI18nText(plugin?.shortDescription)}
          </Typography>
        </div>
        {plugin && (
          <div className='text-right'>
            <Typography variant='h6' color='text.modalTitle' whiteSpace='nowrap'>
              {plugin.price === 0
                ? 'FREE'
                : currencyFormat(`${plugin.price}`, {
                    prefix: '$',
                    suffix:
                      plugin.pricePeriod !== -1
                        ? `/${plugin.pricePeriod === 1 ? '' : plugin.pricePeriod}${t('tháng')}`
                        : '',
                    rate: USDvsVND,
                  })}
            </Typography>
            {expiredAt && (
              <Typography variant='body2' color='grey.500'>
                {t('Expire date')}: {dayjs(expiredAt).format('DD/MM/YYYY HH:mm')}
              </Typography>
            )}
          </div>
        )}
      </Box>
      <div className='p-8 flex gap-10'>
        <Box width={120} className='flex flex-col gap-4 shrink-0'>
          {plugin && <PluginUseButton plugin={plugin} dayRemaining={dayRemaining} />}
          {plugin?.tutorialVideo && (
            <Button variant='outlined' startIcon={<OndemandVideoOutlinedIcon />} onClick={openVideoGuide}>
              {t('scriptGuide')}
            </Button>
          )}
          {/*<Button variant='outlined' fullWidth disabled={isFreePlugin} startIcon={<ShoppingBasketOutlinedIcon />}>*/}
          {/*  {t('Gia hạn')}*/}
          {/*</Button>*/}
        </Box>
        <Box
          className='text-sm'
          sx={{ '& p, h1, h2, h3, h4, h5, h6, ul, li': { mt: 1 }, '& li': { ml: 3 }, '& span': { lineHeight: 1.5 } }}
          dangerouslySetInnerHTML={{
            __html: unEscapeHTML(getI18nText(plugin?.description)),
          }}
        />
      </div>
    </Modal>
  );
}

export default PluginDetailModal;
