import { useSetRecoilState } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { notifyError } from 'kikifrontend/utils/notification';
import { setSession, setSessionInfo } from 'kikifrontend/utils/sessions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authServices } from 'kikifrontend/_apis_';
import { PATH_AUTH, PATH_DASHBOARD } from 'kikifrontend/routes/path';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import useLocales from 'kikifrontend/hooks/useLocales';

export function useAuth() {
  const { t } = useTranslation();
  const setSystemConfigs = useSetRecoilState(statisticAtom);
  const navigate = useNavigate();
  const { currentLang } = useLocales();
  const location = useLocation();

  const login = async (sId: string, redirectPath?: string) => {
    try {
      const userInfo = await authServices.ssoLogin({ serviceCode: 'kikilogin', sessionId: sId });
      if (userInfo?.token) {
        const userToken = userInfo?.token;
        setSession(userToken);
        setSessionInfo(userInfo.userInfo);
        localStorage.setItem('authenticated', userToken);
        await getSystemStatistic();
        // if (res) {
        //   posthog?.identify(res.userInfo.email, { packageName: res.package.name, buyAt: res.package.buyAt });
        //   window._paq?.push(['setUserId', res.userInfo.email]);
        // }
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate(PATH_DASHBOARD.profiles.fullPath);
        }
      } else {
        if (window.self === window.top) {
          window.location.href = `${PATH_AUTH.login.fullPath}&lang=${currentLang.value}`;
        }
        notifyError('Missing token');
      }
    } catch (err) {
      if (window.self === window.top) {
        window.location.href = `${PATH_AUTH.login.fullPath}&lang=${currentLang.value}`;
      }
      if (err instanceof Error) {
        notifyError(err.message || 'Something went wrong');
      }
    }
  };

  const logout = async (exactPath = false) => {
    await authServices.logoutSSO();
    setSession(null);
    setSessionInfo(null);
    localStorage.removeItem('authenticated');
    setSystemConfigs((prevState) => ({
      ...prevState,
      isAuthenticated: false,
    }));
    if (window.self === window.top) {
      window.location.href = `${PATH_AUTH.login.fullPath}&lang=${currentLang.value}${
        exactPath ? `&path=${location.pathname}` : ''
      }`;
    }
  };

  const getSystemStatistic = async () => {
    try {
      const res = await authServices.getStatistic();
      setSessionInfo(res.userInfo);
      setSystemConfigs({
        statistic: res,
        isAuthenticated: true,
        isInitialize: true,
      });
      // window._paq?.push([
      //   'setCustomVariable',
      //   // Index, the number from 1 to 5 where this custom variable name is stored
      //   1,
      //   // Name, the name of the variable, for example: Gender, VisitorType
      //   'packageName',
      //   // Value, for example: "Male", "Female" or "new", "engaged", "customer"
      //   res.package.name,
      //   // Scope of the custom variable, "visit" means the custom variable applies to the current visit
      //   'visit',
      // ]);

      return res;
    } catch (err) {
      ErrorUtils.handleError(err, {
        isUnauthorizedErrorFn: (error) => {
          notifyError(t(error.data.reason));
          logout(true);
        },
      });
    }
  };

  return { getSystemStatistic, logout, login };
}
