import React from 'react';
import { HeaderTitleProps } from 'kikifrontend/layouts/dashboard/types';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Image } from '@kikisoftware/uikit';
import { classNames } from 'kikifrontend/utils/string';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

const HeaderLabel = ({ title, icon }: Omit<HeaderTitleProps, 'url'>) => {
  const { t } = useTranslation();
  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {icon &&
        (typeof icon === 'string' ? (
          <Image src={icon} sx={{ width: 30, height: 30 }} />
        ) : (
          <Box
            sx={{ width: 30, height: 30, bgcolor: '#ecefff' }}
            className='flex items-center justify-center rounded-full'
          >
            {icon}
          </Box>
        ))}
      <Typography variant='body1Bold' className='whitespace-nowrap overflow-hidden text-ellipsis'>
        {t(title)}
      </Typography>
    </Stack>
  );
};

function HeaderTab({ title, icon, url }: HeaderTitleProps) {
  if (url) {
    return (
      <NavLink
        to={url}
        className={({ isActive }) =>
          classNames('no-underline relative', isActive ? 'text-primary-main' : 'text-grey-500')
        }
        end
      >
        {({ isActive }) => (
          <>
            <HeaderLabel title={title} icon={icon} />
            {isActive && <div className='bg-primary-main w-full h-1 absolute -bottom-6 left-0 rounded-t-lg' />}
          </>
        )}
      </NavLink>
    );
  }
  return <HeaderLabel title={title} icon={icon} />;
}

export default HeaderTab;
