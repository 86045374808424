import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function useTextField(initialValue?: string, autoFocus?: boolean) {
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoFocus) {
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [autoFocus]);

  const [text, setText] = useState(initialValue ?? '');

  const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const onEnter = useCallback(
    (callback: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        callback();
      }
    },
    [],
  );

  const onReset = useCallback((text?: string) => {
    setText(text ?? initialValue ?? '');
  }, []);

  return [text, onTextChange, { inputRef, onEnter, onReset }] as const;
}
