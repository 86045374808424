import React, { useEffect, useRef, useState } from 'react';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Image } from '@kikisoftware/uikit';

function ImageUpload({ onUpload }: { onUpload: (path: string) => void }) {
  const ref = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState<string | undefined>();
  const [file, setFile] = useState<File | undefined>();

  useEffect(() => {
    if (!file) {
      setImg(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setImg(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;
    setFile(e.target.files[0]);
    // @ts-ignore
    if (e.target.files[0].path) {
      // @ts-ignore
      onUpload?.(e.target.files[0].path);
    }
  };

  const openBrowseFile = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <div
      className='w-[103px] h-[103px] rounded-lg border border-dashed border-primary-main flex justify-center items-center cursor-pointer hover:border-dashed hover:border-blue-700'
      onClick={openBrowseFile}
    >
      <input ref={ref} accept='image/*' type='file' onChange={handleFile} hidden />
      {img ? (
        <Image src={img} sx={{ width: 78, height: 78, borderRadius: 1 }} />
      ) : (
        <AddPhotoAlternateOutlinedIcon color='primary' />
      )}
    </div>
  );
}

export default ImageUpload;
