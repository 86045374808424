import React, { useEffect, useRef, useState } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';
import { gridColumnDefinitionsSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { ButtonIcon, MenuPopover, TableInput } from '@kikisoftware/uikit';
import { ColumnsPopoverProps, DataGridColumnDef } from '../types';

// export const sortColumns = (a: { defaultOrder: number }, b: { defaultOrder: number }) => {
//   if (a.defaultOrder > b.defaultOrder) {
//     return 1;
//   }
//   if (a.defaultOrder < b.defaultOrder) {
//     return -1;
//   }
//
//   return 0;
// };

const splitPinnedColumns = (columns: DataGridColumnDef[], pinnedColumns?: string[]): DataGridColumnDef[] => {
  if (!pinnedColumns) {
    return columns;
  }
  const pinned = pinnedColumns
    .filter((col) => col !== '__check__' && col !== '__detail_panel_toggle__')
    .map((col) => columns.find((c) => c.field === col));
  const unpinned = columns.filter((col) => !pinnedColumns.includes(col.field));
  return [...pinned, ...unpinned] as DataGridColumnDef[];
};

const ColumnsPopover = React.memo(
  ({
    // columns,
    onColumnVisibilityChange,
    columnVisibility,
    title,
    initialPinnedColumns,
    handleColumnsPinned,
  }: ColumnsPopoverProps) => {
    const apiRef = useGridApiContext();
    // const currentColumns = useMemo(() => gridColumnDefinitionsSelector(apiRef), [])
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [searchColumn, setSearchColumn] = useState('');
    const [currentPinColumn, setCurrentPinColumn] = useState(apiRef.current.getPinnedColumns());
    const [currentColumns, setCurrentColumns] = useState(gridColumnDefinitionsSelector(apiRef));

    useEffect(() => {
      const initPinnedColumns = initialPinnedColumns;
      let newPinnedColumns;
      if (initPinnedColumns && initPinnedColumns.length > 0) {
        newPinnedColumns = {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...initPinnedColumns],
          right: [],
        };
      } else {
        newPinnedColumns = {
          left: [],
          right: [],
        };
      }
      apiRef.current.setPinnedColumns(newPinnedColumns);
      setCurrentPinColumn(newPinnedColumns);
    }, [initialPinnedColumns]);

    const handleOpen = () => {
      const orderColumns = gridColumnDefinitionsSelector(apiRef);
      setCurrentColumns(orderColumns);
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const handleChangeSearchColumns = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchColumn(e.target.value);
    };

    const onPinColumn = (column: string) => () => {
      const pinnedColumns = apiRef.current.getPinnedColumns();
      let newPinnedColumns;
      if (!pinnedColumns.left || pinnedColumns.left.length === 0) {
        newPinnedColumns = {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, column],
          right: [],
        };
        apiRef.current.setPinnedColumns(newPinnedColumns);
        setCurrentPinColumn(newPinnedColumns);
      } else if (pinnedColumns.left.length === 2) {
        if (pinnedColumns.left.includes(column)) {
          newPinnedColumns = {
            left: [],
            right: pinnedColumns.right,
          };
          apiRef.current.setPinnedColumns(newPinnedColumns);
          setCurrentPinColumn(newPinnedColumns);
        } else {
          newPinnedColumns = {
            left: [...pinnedColumns.left, column],
            right: pinnedColumns.right,
          };
          apiRef.current.setPinnedColumns(newPinnedColumns);
          setCurrentPinColumn(newPinnedColumns);
        }
      } else {
        if (pinnedColumns.left.includes(column)) {
          newPinnedColumns = {
            left: pinnedColumns.left.filter((c) => c !== column),
            right: pinnedColumns.right,
          };
          apiRef.current.setPinnedColumns(newPinnedColumns);
          setCurrentPinColumn(newPinnedColumns);
        } else {
          newPinnedColumns = {
            left: [...pinnedColumns.left, column],
            right: pinnedColumns.right,
          };
          apiRef.current.setPinnedColumns(newPinnedColumns);
          setCurrentPinColumn(newPinnedColumns);
        }
      }
      const crColumns = gridColumnDefinitionsSelector(apiRef);
      setCurrentColumns(crColumns);
      handleColumnsPinned(newPinnedColumns);
      // apiRef.current.pinColumn(GRID_CHECKBOX_SELECTION_COL_DEF.field, GridPinnedPosition.left)
      // apiRef.current.pinColumn(column, GridPinnedPosition.left)
    };

    return (
      <>
        <ButtonIcon onClick={handleOpen} ref={anchorRef} title={title?.columnsAdjust} buttonType='rectangle'>
          <SettingsOutlinedIcon />
        </ButtonIcon>
        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 300, px: 2, py: 1 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{title?.columnsAdjust}</Typography>
          <Box my={1}>
            <TableInput
              value={searchColumn}
              onChange={handleChangeSearchColumns}
              placeholder={title.findColumnAdjust}
            />
          </Box>
          <FormGroup>
            {splitPinnedColumns(
              currentColumns.filter((col) => col.field !== '__check__' && col.field !== '__detail_panel_toggle__'),
              currentPinColumn.left,
            )
              .filter((col) =>
                typeof col.headerName === 'string'
                  ? col.headerName.toLowerCase().indexOf(searchColumn.toLowerCase()) !== -1
                  : true,
              )
              .map((col) => (
                <Stack
                  key={col.field}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ width: '100%' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={columnVisibility[col.field]}
                        onChange={
                          col.hideVisibleSetting || currentPinColumn.left?.includes(col.field)
                            ? undefined
                            : onColumnVisibilityChange(col.field)
                        }
                        sx={{ cursor: col.hideVisibleSetting ? 'not-allowed' : 'pointer' }}
                        size='small'
                      />
                    }
                    label={col.headerName}
                    sx={{
                      width: '100%',
                      cursor:
                        col?.hideVisibleSetting || currentPinColumn.left?.includes(col?.field)
                          ? 'not-allowed'
                          : 'pointer',
                      opacity: col.hideVisibleSetting || currentPinColumn.left?.includes(col.field) ? 0.5 : 1,
                      '&:hover': {
                        bgcolor:
                          col.hideVisibleSetting || currentPinColumn.left?.includes(col.field)
                            ? 'transparent'
                            : 'rgba(0, 0, 0, 0.08)',
                      },
                      m: 0,
                      '& .MuiCheckbox-root': {
                        padding: 0.75,
                      },
                      '& .MuiTypography-root': {
                        fontSize: 14,
                      },
                    }}
                  />

                  {currentPinColumn.left?.includes(col.field) ? (
                    <ButtonIcon
                      onClick={onPinColumn(col.field)}
                      sx={{ visibility: columnVisibility[col.field] ? 'visible' : 'hidden' }}
                      buttonType='circle'
                    >
                      <PushPinIcon sx={{ fontSize: 18, cursor: 'pointer' }} />
                    </ButtonIcon>
                  ) : (
                    <ButtonIcon
                      onClick={onPinColumn(col.field)}
                      sx={{ visibility: columnVisibility[col.field] ? 'visible' : 'hidden' }}
                      buttonType='circle'
                    >
                      <PushPinOutlinedIcon sx={{ fontSize: 18, cursor: 'pointer' }} />
                    </ButtonIcon>
                  )}
                </Stack>
              ))}
          </FormGroup>
        </MenuPopover>
      </>
    );
  },
);

export default ColumnsPopover;
