import { QueryFilterData, ResponseApi, ResponseData } from 'kikifrontend/types/api.types';
import http from 'kikifrontend/utils/api-services/http';
import { GroupProps } from 'kikifrontend/types/group.types';
import HttpUtils from 'kikifrontend/utils/api-services/httpUtils';
import { GroupColor } from 'kikifrontend/features/profiles/profileList/types/group.types';

const getAllUserGroups = async (query: QueryFilterData, signal?: AbortSignal) => {
  return http.client
    .get<ResponseApi<ResponseData<GroupProps[]>>>('/api/account-group', query, { signal })
    .then(HttpUtils.getDataFromHttpResponse);
};

const getAllUserSharedGroups = async (query: QueryFilterData, signal?: AbortSignal) => {
  return http.client
    .get<ResponseApi<ResponseData<GroupProps[]>>>('/api/account-group/shared-with-me', query, { signal })
    .then(HttpUtils.getDataFromHttpResponse);
};

const pinGroup = async (id: string) => {
  return http.client.post(`/api/account-group/${id}/pin`);
};

const mutateGroup = async ({ id, ...data }: { id?: string; name: string; color?: GroupColor }) => {
  if (id) {
    return http.client.post(`/api/account-group/${id}/edit`, { config: data });
  }
  return http.client.post<ResponseApi<GroupProps>>('/api/v2/groups', data);
};

const deleteGroup = async (id: string) => {
  return http.client.get(`/api/account-group/${id}/delete`);
};

const getDetailGroup = async (id?: string) => {
  return http.client
    .get<ResponseApi<GroupProps>>(`/api/account-group/${id}/detail`)
    .then(HttpUtils.getDataFromHttpResponse);
};

const moveProfileToOtherGroup = async ({ profileId, targetGroupId }: { profileId: string; targetGroupId: string }) => {
  return http.client.get(`/api/account-profile/${profileId}/move/${targetGroupId}`);
};

export const groupServices = {
  getAllUserGroups,
  getAllUserSharedGroups,
  pinGroup,
  mutateGroup,
  deleteGroup,
  moveProfileToOtherGroup,
  getDetailGroup,
};
