import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

function CrashFallback({ error }: { error: Error }) {
  const { t } = useTranslation();
  if (
    error.message.indexOf('Loading chunk') !== -1 ||
    error.message.indexOf('Failed to fetch dynamically imported module') !== -1
  ) {
    return null;
  }

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ width: 1, height: 'calc(100vh - 100px)' }}>
      {/* <img src={`/assets/images/oops.png`} alt={``} className={`w-1/3`} /> */}
      <Typography fontSize={42} fontWeight={700}>
        Something went wrong
      </Typography>
      <Typography variant='textBase'>
        Có vấn đề gì đó đã xảy ra. Vui lòng nhấn Refresh hoặc liên hệ với support để được hỗ trợ
      </Typography>
      <Typography variant='textBase' color='white' sx={{ pointerEvents: 'none' }}>
        {error.message}
      </Typography>
      <div className='flex flex-col gap-2 items-center'>
        <Button variant='contained' onClick={() => window.location.reload()}>
          Refresh now
        </Button>
        <Typography variant='body2'>{t('hoặc')}</Typography>
        <Button
          variant='contained'
          onClick={() => {
            window.$crisp.push(['do', 'chat:show']);
            window.$crisp?.push(['do', 'chat:open']);
          }}
        >
          Live chat
        </Button>
      </div>
      <Typography variant='textBase' color='transparent' sx={{ pointerEvents: 'none' }}>
        {error.stack}
      </Typography>
    </Stack>
  );
}

export default CrashFallback;
