import React from 'react';
import { Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { PremiumCard, TipText } from 'kikifrontend/components';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import Typography from '@mui/material/Typography';
import { ProfileProps } from 'kikifrontend/types/profile.types';
import { CreateMultiProfile } from 'kikifrontend/features/apolloScan/components/ApolloFixProfileModal';
import Box from '@mui/material/Box';

interface Props extends ModalProps {
  profile: ProfileProps;
  backUrl?: string;
}

function ProfileCloneCookieModal({ onClose, profile }: Props) {
  const { t } = useTranslation();
  const isFree = useRecoilValue(isFreePackageSelector);
  const { openModal } = useModal();

  const openGuideModal = () => {
    openModal(
      ({ onClose }) => (
        <Modal title={t('kikiAutoDocument')} onClose={onClose} width={950}>
          <Box
            component='iframe'
            src='https://tailieu.kikilogin.com/tinh-nang-co-ban/1.-profile/1.12-nhan-ban-du-lieu'
            sx={{ border: 0, width: '100%', height: 550 }}
          />
        </Modal>
      ),
      {},
      undefined,
      { isSecondModal: true },
    );
  };

  return (
    <Modal title={t('Nhân bản dữ liệu')} onClose={onClose} width={600}>
      <TipText
        text={
          <>
            {t(
              'Tính năng này sẽ tạo ra một profile mới với cấu hình mới và được import sẵn dữ liệu của profile được chọn',
            )}
            .{' '}
            <Typography variant='body2Bold' color='primary.main' className='cursor-pointer' onClick={openGuideModal}>
              {t('Tìm hiểu thêm')}
            </Typography>
          </>
        }
      />
      {isFree ? (
        <div className='my-2'>
          <PremiumCard />
        </div>
      ) : (
        <div className='mt-4'>
          <CreateMultiProfile cloneProfile={profile} />
        </div>
      )}
    </Modal>
  );
}

export default ProfileCloneCookieModal;
