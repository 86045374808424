import React from 'react';
import { Modal, useModal } from '@kikisoftware/uikit';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import ChangePackageListPackagesModal from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageListPackagesModal';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { isOpenWithApp } from 'kikifrontend/utils/table';
import FreePackageMaxProfileModal from 'kikifrontend/components/Modals/FreePackageMaxProfileModal';
import { useAuth } from 'kikifrontend/hooks/useAuth';
// import BlockPolicyModal from 'kikifrontend/components/Modals/BlockPolicyModal';

function BlockedModal() {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const isFree = useRecoilValue(isFreePackageSelector);
  const { statistic } = useRecoilValue(statisticAtom);
  const { logout } = useAuth();

  const openChangePackageModal = () => {
    closeModal({ closeAll: true });
    openModal(ChangePackageListPackagesModal);
  };

  if (statistic && isFree && !isOpenWithApp) {
    return <></>;
    return (
      <Modal hideHeader>
        <div className='text-center'>
          <div className='flex justify-center'>
            <img
              src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1705379778576'
              alt='blocked'
              width={100}
            />
          </div>
          <Typography variant='h5' mt={2}>
            {t('Gói cước không được hỗ trợ')}
          </Typography>
          <Typography variant='body1' mt={1}>
            {t(
              'Gói cước Free bạn đang sử dụng không hỗ trợ tính năng đăng nhập trên web, vui lòng mở ứng dụng hoặc nâng cấp gói cước để sử dụng',
            )}
          </Typography>
          <div className='flex items-center justify-center mt-6 gap-4'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={
                <img
                  src='https://cdn4.iconfinder.com/data/icons/seo-and-internet-marketing-9/65/10-512.png'
                  alt='upgrade'
                  width={20}
                />
              }
              onClick={openChangePackageModal}
              sx={{ position: 'relative' }}
            >
              {t('Nâng cấp ngay')}
            </Button>
            <Link href='kikilogin://open-app'>
              <Button variant='contained' color='secondary' startIcon={<LaunchIcon />}>
                {t('Mở ứng dụng')}
              </Button>
            </Link>
          </div>
          <div
            className='flex justify-center mt-4 cursor-pointer text-sm text-primary-main hover:text-primary-dark w-full'
            onClick={() => logout()}
          >
            {t('Sử dụng tài khoản khác')}
          </div>
        </div>
      </Modal>
    );
  }

  if (isFree && statistic && statistic.userInfo.usedProfile >= statistic.package.profileLimit) {
    return <FreePackageMaxProfileModal />;
  }

  return null;
  // return <BlockPolicyModal />;
}

export default BlockedModal;
