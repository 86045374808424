import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Image } from '@kikisoftware/uikit';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import DoneIcon from '@mui/icons-material/Done';
function BrowserCard({
  browser,
  isSelected,
  onChange,
  disabled,
  title,
}: {
  browser: { value: string; label: string; icon?: string };
  isSelected: boolean;
  onChange: (type: BrowserType) => void;
  disabled?: boolean;
  title?: string;
}) {
  // const browserStatus = useRecoilValue(browserStatusSelector);
  // const { openModal } = useModal();

  const onSelectBrowser = () => {
    if (disabled || browser.value === 'kikibrowser') return;
    const browserType = browser.value as BrowserType;
    onChange(browserType);
  };

  return (
    <Box
      height={50}
      className='flex justify-between items-center px-3 w-full cursor-pointer overflow-hidden'
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        maxWidth: {
          xs: 1,
          xl: '48.9%',
        },
        position: 'relative',
        opacity: 0.6,
        '&:hover': { borderColor: 'primary.main' },
        ...(!isSelected &&
          browser.value === 'kifox' && {
            '&::before': {
              content: "'New'",
              position: 'absolute',
              top: 5,
              right: -1,
              zIndex: 2,
              color: 'white',
              fontWeight: 700,
              transform: 'rotate(45deg)',
              fontSize: 9,
              textTransform: 'uppercase',
            },
            '&::after': {
              content: "'New'",
              zIndex: 1,
              overflow: 'hidden',
              fontSize: 10,
              fontWeight: 'bold',
              color: 'white',
              textTransform: 'uppercase',
              textAlign: 'center',
              lineHeight: 20,
              width: 120,
              display: 'block',
              backgroundColor: 'primary.main',
              // background: "linear-gradient(#9BC90D 0%, #79A70A 100%)",
              boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
              position: 'absolute',
              top: -73,
              right: -44,
              transform: 'scale(0.27)',
              clipPath: 'polygon(10% 0, 59% 0, 100% 24%, 100% 50%)',
            },
          }),
        ...(isSelected && {
          bgcolor: 'background.default',
          borderColor: 'primary.main',
          opacity: 1,
        }),
        ...((disabled || browser.value === 'kikibrowser') && {
          cursor: 'default',
          '&:hover': { borderColor: 'divider' },
        }),
      }}
      onClick={onSelectBrowser}
      title={title}
    >
      <div className='flex gap-2 items-center w-full'>
        {browser.icon && (
          <Image src={browser.icon} sx={{ width: 24, height: 24, flexBasis: 24, flexShrink: 0 }} asyncLoading={false} />
        )}
        <Typography variant='body2Bold' className='whitespace-nowrap' color={isSelected ? 'primary.main' : 'inherit'}>
          {browser.label}
        </Typography>
      </div>
      {isSelected && (
        <>
          <Box
            className='flex absolute items-center justify-center w-0 h-0 -bottom-1 right-0 z-10'
            sx={{
              borderBottom: '24px solid',
              borderBottomColor: 'primary.main',
              borderLeft: '24px solid transparent',
            }}
          ></Box>
          <DoneIcon sx={{ fontSize: 12, color: 'white', position: 'absolute', bottom: 1, right: 0, zIndex: 20 }} />
        </>
      )}
    </Box>
  );
}

export default BrowserCard;
