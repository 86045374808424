import React from 'react';
import Modal from './Modal';
import { EventModalProps } from './types';
import TemplateLunarNewYear from './templates/TemplateLunarNewYear';
import { KIKI_THEME_EVENTS, useKikiThemeProvider } from '../themes/KikiThemeProvider';

function EventModal({ title, children, ...props }: EventModalProps) {
  const { theme } = useKikiThemeProvider();

  if (theme === KIKI_THEME_EVENTS.default) {
    return (
      <Modal title={title} {...props}>
        {children}
      </Modal>
    );
  }

  return (
    <Modal {...props} title={<TemplateLunarNewYear title={title} />}>
      {children}
    </Modal>
  );
}

export default EventModal;
