import React, { useMemo } from 'react';
import useTextField from 'kikifrontend/hooks/useTextField';
import useDebounce from 'kikifrontend/hooks/useDebounce';
import useTableConfigs from 'kikifrontend/hooks/useTableConfigs';
import Box from '@mui/material/Box';
import { ButtonIcon, Image } from '@kikisoftware/uikit';
import PageViewLayout from 'kikifrontend/layouts/dashboard/PageViewLayout';
import Divider from '@mui/material/Divider';
import NoResults from '../../../../components/NoResults';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { PagePagination } from 'kikifrontend/components';
import PublicPluginCard from 'kikifrontend/features/plugins/components/PublicPluginCard';
import { storePluginsQuery } from 'kikifrontend/features/plugins/queries/publicPlugins.query';
import { PluginInfo } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import { personalPluginsQuery } from 'kikifrontend/features/plugins/queries/personalPlugins.query';
import { mergePluginStore } from 'kikifrontend/features/plugins/utils/mergePluginStore';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import PluginNoSupport from 'kikifrontend/features/plugins/components/PluginNoSupport';

function PublicPluginsView() {
  const { t } = useTranslation();
  const [searchText, onSearchChange] = useTextField();
  const debounceText = useDebounce(searchText);
  const {
    queryResult: { data: publicPlugins, refetch, isFetching },
    tableConfig: { onPageChange, onRowsPerPageChange, onChangeInputPage },
  } = useTableConfigs<PluginInfo>(storePluginsQuery, { keyword: debounceText });
  const { data: personalPlugins } = personalPluginsQuery();

  const mergedPlugins = useMemo(
    () => mergePluginStore(publicPlugins?.results, personalPlugins),
    [publicPlugins?.results, personalPlugins],
  );

  return (
    <Box>
      <div className='w-full'>
        <Image
          src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1690181097324'
          sx={{ borderRadius: 2, width: 1, maxHeight: 211, objectFit: 'cover' }}
        />
      </div>
      <PageViewLayout pb={1} mt={3}>
        {/*<ExtensionSearchBar onTextChange={onSearchChange} />*/}
        <div className='flex justify-between items-center px-6 py-5'>
          <TextField
            placeholder={t('action.search')}
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ color: '#9ca5ae', opacity: 0.8, fontSize: 22 }} />
                </InputAdornment>
              ),
            }}
            sx={{ width: 1, maxWidth: 300 }}
            onChange={onSearchChange}
          />
          <ButtonIcon onClick={() => refetch()} title={t('refresh')}>
            <RefreshOutlinedIcon />
          </ButtonIcon>
        </div>
        <Divider />
        {publicPlugins?.paging?.total === 0 ? (
          <NoResults />
        ) : (
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            mt={0}
            sx={{ px: 3, ...(isFetching && { opacity: 0.5, pointerEvents: 'none' }) }}
          >
            {mergedPlugins.map((plugin) => (
              <Grid key={plugin._id} item xs={12} md={6} lg={4} xl={4}>
                <Paper elevation={0} sx={{ height: '100%' }}>
                  <PublicPluginCard {...plugin} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}

        <Divider sx={{ mt: 5, mb: 2 }} />
        <PagePagination
          count={publicPlugins?.paging?.total ?? 0}
          page={publicPlugins?.paging?.page ? publicPlugins?.paging?.page - 1 : 0}
          onPageChange={onPageChange}
          rowsPerPage={publicPlugins?.paging?.limit ?? 20}
          onRowsPerPageChange={onRowsPerPageChange}
          onChangeInputPage={onChangeInputPage}
        />
      </PageViewLayout>
    </Box>
  );
}

export default PublicPluginsView;
