import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';

interface ThemeTitle {
  title: string | React.ReactNode;
}

const TemplateLunarNewYear = ({ title }: ThemeTitle) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1, position: 'relative' }}>
      <Typography
        component='p'
        sx={{
          color: 'text.modalTitle',
          fontSize: 18,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          zIndex: 2,
          maxWidth: '60%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Typography>
      <Box
        component='img'
        src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703039087360'
        width={270}
        sx={{ transform: 'rotate(13deg)', position: 'absolute', right: 34, top: -80, zIndex: 1 }}
      />
      <Box
        component='img'
        src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703644773629'
        width={80}
        sx={{ position: 'absolute', right: -24, top: -34, zIndex: 0 }}
      />
    </Box>
  );
};

export default TemplateLunarNewYear;
