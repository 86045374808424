import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

function InputPassword(props: TextFieldProps, ref: React.ForwardedRef<HTMLInputElement | HTMLDivElement>) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
              {showPassword ? (
                <Visibility sx={{ color: 'grey.500' }} />
              ) : (
                <VisibilityOff sx={{ color: 'grey.500' }} fontSize='small' />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default React.forwardRef(InputPassword);
