import { atom } from 'recoil';
import { CouponProps } from 'kikifrontend/features/paymentCenter/types/package.types';

export const selectedCouponAtom = atom<CouponProps | undefined>({
  key: 'selectedCouponAtom',
  default: undefined,
});

export const changePackageSelectedCouponAtom = atom<CouponProps | undefined>({
  key: 'changePackageSelectedCouponAtom',
  default: undefined,
});
