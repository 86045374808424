import React from 'react';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@kikisoftware/uikit-date-picker';
// import { TableInput2 } from "@kikisoftware/uikit";
// import { IconButton, Stack } from "@mui/material";
// import { MuiTextFieldProps } from "@mui/x-date-pickers/internals/components/PureDateInput";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import CloseIcon from "@mui/icons-material/Close";

interface FilterDateRangerProps {
  locales?: any;
  onChange?: (value: any) => void;
}

// const renderInput =
//   (onReset: () => void, value: DateRange<Dayjs>) =>
//   (startProps: MuiTextFieldProps, endProps: MuiTextFieldProps) => {
//     return (
//       <Stack
//         direction="row"
//         alignItems="center"
//         px={1}
//         sx={{
//           border: "1px solid #EBECED",
//           borderRadius: "4px",
//           "&:hover": { borderColor: "primary.main" },
//           "&:focus": { borderColor: "primary.main" },
//           "&:focus-within": { borderColor: "primary.main" },
//         }}
//       >
//         <CalendarMonthIcon sx={{ color: "#8B9299", width: 16, height: 18, mt: -0.1 }} />
//         {/* @ts-ignore */}
//         <TableInput2 {...startProps} sx={{ border: "none", maxWidth: 100 }} />
//         <TrendingFlatIcon sx={{ color: "#8B9299", mt: -0.1 }} />
//         {/* @ts-ignore */}
//         <TableInput2 {...endProps} sx={{ border: "none", maxWidth: 95 }} />
//         <IconButton
//           sx={{
//             visibility: value[0] && value[1] ? "visible" : "hidden",
//             width: 26,
//             height: 26,
//             mt: -0.2,
//           }}
//           onClick={onReset}
//         >
//           <CloseIcon sx={{ fontSize: 20 }} />
//         </IconButton>
//       </Stack>
//     );
//   };

function FilterDateRanger({ onChange }: FilterDateRangerProps) {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([dayjs().subtract(7, 'day'), dayjs()]);

  const onReset = () => {
    setValue([dayjs().startOf('day').subtract(7, 'day'), dayjs().startOf('day')]);
    onChange && onChange([dayjs().startOf('day').subtract(7, 'day'), dayjs().startOf('day')]);
  };

  return (
    <>
      <DatePicker<Dayjs>
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          onChange && onChange(newValue);
        }}
        onClear={onReset}
        // renderInput={renderInput(onReset, value)}
      />
    </>
  );
}

export default FilterDateRanger;
