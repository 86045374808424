import React from 'react';
import { Image, Modal, useModal } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useTranslation } from 'react-i18next';
import useCollapseDrawer from 'kikifrontend/hooks/useCollapseDrawer';
import { Link } from 'kikifrontend/components';
import appRoutes from 'kikifrontend/routes/path';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModalTwoFA from 'kikifrontend/components/Modals/ModalTwoFA';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import usePopover from 'kikifrontend/hooks/usePopover';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CreateMultiProfile } from 'kikifrontend/features/apolloScan/components/ApolloFixProfileModal';
function SidebarHeader() {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { onToggleCollapse } = useCollapseDrawer();
  const { anchorEl, openPopover, open, closePopover } = usePopover();

  const open2FAModal = () => openModal(ModalTwoFA);

  const openQuickCreateProfilesModal = () => {
    closePopover();
    openModal(({ onClose }) => (
      <Modal title={t('Tạo hàng loạt') + ' profiles'} onClose={onClose} width={700}>
        <CreateMultiProfile />
      </Modal>
    ));
  };

  return (
    <>
      {/*<Box*/}
      {/*  component='img'*/}
      {/*  src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703735243129'*/}
      {/*  width='200px'*/}
      {/*  className='fixed -top-6 -left-1 opacity-90'*/}
      {/*  sx={{ transform: 'rotate(-20deg)' }}*/}
      {/*/>*/}
      <div className='flex justify-between items-center px-2 relative'>
        <Link to={appRoutes.profiles.fullPath}>
          <div className='flex gap-2 items-center relative'>
            <Image src={Assets.images.Logo} asyncLoading={false} sx={{ width: 44 }} />
            <Typography variant='h6' color='text.primary'>
              KIKILOGIN
            </Typography>
          </div>
        </Link>
        <Button
          variant='outlined'
          color='inherit'
          sx={{ width: 26, height: 26, minWidth: 26, display: { xs: 'none', lg: 'flex' } }}
          onClick={onToggleCollapse}
        >
          <KeyboardDoubleArrowLeftOutlinedIcon color='primary' fontSize='small' />
        </Button>
      </div>
      <div className='mt-5 relative'>
        <Typography variant='caption' textTransform='uppercase' color='text.subColor3'>
          {t('Features')}
        </Typography>
        <div className='flex gap-2 items-center mt-2.5'>
          <div className='flex grow'>
            <Link to={appRoutes.profiles.profileCreate.fullPath} className='w-full'>
              <Button
                variant='contained'
                size='small'
                fullWidth
                startIcon={<AddOutlinedIcon />}
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRight: '1px solid',
                  borderRightColor: 'secondary.main',
                }}
              >
                {t('createProfile')}
              </Button>
            </Link>
            <Button
              variant='contained'
              size='small'
              sx={{ width: 36, minWidth: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={openPopover}
            >
              <ArrowDropDownIcon fontSize='small' />
            </Button>
          </div>
          <Button
            disableElevation
            variant='contained'
            color='secondary'
            size='small'
            startIcon={<SecurityOutlinedIcon />}
            onClick={open2FAModal}
            sx={{ maxWidth: 87 }}
          >
            {t('Get 2FA')}
          </Button>
        </div>
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableAutoFocusItem
          slotProps={{
            paper: {
              sx: {
                mt: 0.5,
                width: 160,
              },
            },
          }}
        >
          <MenuItem onClick={openQuickCreateProfilesModal}>
            <Typography variant='body2'>{t('Tạo hàng loạt')}</Typography>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

export default SidebarHeader;
