import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  adsApiEndpoint,
  apiAutomationEndpoint,
  apiEndpoints,
  apiLocalEndpoint,
  apiLogsEndpoints,
  apiOlympusEndpoints,
  apiSSOEndpoints,
} from 'utils/configs';
import { ErrorApi } from 'kikifrontend/utils/api-services/error';
import { ResponseError } from 'kikifrontend/types/api.types';

class HttpClient {
  protected readonly instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL,
    });

    this.configResponseInterceptor();
  }

  private configResponseInterceptor() {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  }

  protected handleResponse(response: AxiosResponse) {
    const { data } = response;
    if (data && (data.success === false || data.success === 'false')) {
      return Promise.reject(new ErrorApi(response));
    }

    return Promise.resolve(data);
  }

  protected handleError(error: AxiosError<ResponseError>) {
    if (!error.response) return Promise.reject(error);
    return Promise.reject(new ErrorApi(error.response));
  }

  setAuthorization(accessToken: string) {
    this.instance.defaults.headers.common.authorization = accessToken;
  }

  clearAuthorization() {
    delete this.instance.defaults.headers.common.authorization;
  }

  get<T, P = {}>(url: string, params?: P, config?: Omit<AxiosRequestConfig, 'params'>): Promise<T> {
    return this.instance({
      method: 'GET',
      url,
      params,
      ...config,
    }) as Promise<T>;
  }

  post<T, D = {}>(url: string, data?: D, config?: Omit<AxiosRequestConfig, 'data'>): Promise<T> {
    return this.instance({
      method: 'POST',
      url,
      data,
      ...config,
    }) as Promise<T>;
  }

  put<T, D = {}>(url: string, data?: D, config?: Omit<AxiosRequestConfig, 'data'>): Promise<T> {
    return this.instance({
      method: 'PUT',
      url,
      data,
      ...config,
    }) as Promise<T>;
  }

  delete<T>(url: string, config?: Omit<AxiosRequestConfig, 'data'>): Promise<T> {
    return this.instance({
      method: 'DELETE',
      url,
      ...config,
    }) as Promise<T>;
  }
}

const axiosInstance = new HttpClient(apiEndpoints);
const axiosSSO = new HttpClient(apiSSOEndpoints);
const axiosLocal = new HttpClient(apiLocalEndpoint);
const axiosAds = new HttpClient(adsApiEndpoint);
const axiosLogs = new HttpClient(apiLogsEndpoints);
const axiosAutomation = new HttpClient(apiAutomationEndpoint);
const axiosOlympus = new HttpClient(apiOlympusEndpoints);

const http = {
  client: axiosInstance,
  sso: axiosSSO,
  local: axiosLocal,
  ads: axiosAds,
  log: axiosLogs,
  automation: axiosAutomation,
  olympus: axiosOlympus,
};

export default http;
