import React from 'react';
import useTrackParams from 'kikifrontend/hooks/useTrackParams';
import track from 'kikifrontend/utils/analytics';
import { TrackContainerProps } from 'kikifrontend/components/Button/types';
import Box from '@mui/material/Box';

function TrackContainer({ children, eventName, params, onClick, ...props }: TrackContainerProps) {
  const { params: trackParams } = useTrackParams(params);

  const trackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (eventName) {
      track.event(eventName, trackParams);
    }
    onClick?.(e);
  };

  return (
    <Box {...props} onClick={trackClick}>
      {children}
    </Box>
  );
}

export default TrackContainer;
