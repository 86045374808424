import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { useCallback, useMemo } from 'react';

export default function useTrackParams(params?: Record<string, any>) {
  const { statistic } = useRecoilValue(statisticAtom);

  const getTrackParams = useCallback(
    (addedParams?: Record<string, any>) => {
      return {
        package_name: statistic?.package?.name,
        ...addedParams,
      };
    },
    [statistic?.package?.name],
  );

  return useMemo(
    () => ({
      params: getTrackParams(params),
      getTrackParams,
    }),
    [getTrackParams, params],
  );
}
