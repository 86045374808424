import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';
import { KIKI_THEME_EVENTS, useKikiThemeProvider } from '../../themes/KikiThemeProvider';

function BubbleContent({ children, ...props }: React.PropsWithChildren<BoxProps>) {
  const { theme } = useKikiThemeProvider();

  if (theme === KIKI_THEME_EVENTS.default) {
    return (
      <Box
        {...props}
        sx={{
          width: 1,
          height: '100%',
          borderRadius: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.main',
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      {...props}
      sx={{
        width: 1,
        height: '100%',
        borderRadius: 9999,
        display: 'flex',
        jusitfyContent: 'center',
        backgroundColor: 'primary.main',
        background: `url('https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703730603543')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '& svg': { mt: 4 },
      }}
    >
      {children}
    </Box>
  );
}

export default BubbleContent;
