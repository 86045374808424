import { googleAnalyticsConfig, isGAAllowance } from 'kikifrontend/utils/configs';
import posthog from 'posthog-js';
// ----------------------------------------------------------------------

const setup = (...args: any) => {
  if (!isGAAllowance) {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag(...args);
};

const track = {
  pageview: (props: any) => {
    setup('config', googleAnalyticsConfig, props);
  },
  event: (type: any, props: any) => {
    // setup('event', type, props);
    if (!isGAAllowance) return;
    posthog?.capture(type, props);
    window._paq?.push(['trackEvent', JSON.stringify(props), type]);
  },
};

export default track;
