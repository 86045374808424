import React from 'react';
import { Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Control, useForm, useWatch, Controller } from 'react-hook-form';
import {
  FEEDBACK_DEFAULT_FORM_VALUES,
  FEEDBACK_TYPE_OPTIONS,
  FEEDBACK_TYPES,
} from 'kikifrontend/features/feedback/utils/constants';
import SelectLabelFormField from '../../../components/Form/SelectLabelFormField';
import { Link, TextFormField, TipText } from 'kikifrontend/components';
import YesNoFormField from 'kikifrontend/components/Form/YesNoFormField';
import Button from '@mui/material/Button';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isAppConnectedAtom, showLivaChatAtom } from 'kikifrontend/utils/recoil/atom';
import ImageUpload from 'kikifrontend/components/ImageUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useMutation } from '@tanstack/react-query';
import { localServices } from 'kikifrontend/_apis_';
import { FeedbackFormValues } from 'kikifrontend/features/feedback/types/feedback.type';
import { notifyError, notifySuccess } from 'kikifrontend/utils/notification';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import appRoutes from 'kikifrontend/routes/path';
import { isOpenWithApp } from 'kikifrontend/utils/table';

const MAX_CONTENT_LENGTH = 300;
const MIN_CONTENT_LENGTH = 100;

const DebugLogs = ({ control }: { control: Control<typeof FEEDBACK_DEFAULT_FORM_VALUES> }) => {
  const { t } = useTranslation();

  const type = useWatch({
    control,
    name: 'type',
  });

  if (type !== FEEDBACK_TYPES.bugReport) {
    return null;
  }

  return (
    <div className='flex gap-5 mt-6'>
      <Typography variant='body2' sx={{ flexBasis: 90, mt: 0.75 }}>
        {t('Tùy chọn')}
      </Typography>
      <div>
        <YesNoFormField name='attachDebugLogs' control={control} label={t('Gửi kèm debug logs')} disabled />
      </div>
    </div>
  );
};

const FeedbackUI = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: FEEDBACK_DEFAULT_FORM_VALUES,
  });
  const setShowChat = useSetRecoilState(showLivaChatAtom);
  const { mutateAsync, isLoading } = useMutation(localServices.sendFeedback);

  const contentLength = watch('content').length;
  const onSubmit = async (values: typeof FEEDBACK_DEFAULT_FORM_VALUES) => {
    try {
      const sendData: FeedbackFormValues = {
        ...values,
        attachments: Object.values(values.attachments || {}).filter((img) => !!img),
      };
      if (sendData.attachments.length === 0) {
        notifyError(t('feedback_img_min_image', { min: 1 }));
        return;
      }
      await mutateAsync(sendData);
      notifySuccess(t('Gửi thành công'));
      closeModal();
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    }
  };

  const showLiveChat = () => {
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['do', 'chat:open']);
    setShowChat(true);
  };

  const renderImageUpload = () => {
    const imageUpload = [];
    for (let i = 1; i <= 5; i++) {
      imageUpload.push(
        <Controller
          control={control}
          // @ts-ignore
          name={`attachments.image${i}`}
          render={({ field }) => <ImageUpload onUpload={field.onChange} />}
        />,
      );
    }

    return imageUpload;
  };

  return (
    <>
      <div className='flex gap-2 items-center'>
        <TipsAndUpdatesOutlinedIcon color='warning' />
        <Typography variant='body2'>
          {t('Nếu bạn cần xử lý các vấn đề khẩn cấp')}, {t('vui lòng nhấp vào')}{' '}
          <Typography
            component='span'
            variant='body2Bold'
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
              '&:hover': { color: 'primary.dark' },
            }}
            onClick={showLiveChat}
          >
            live chat
          </Typography>{' '}
          {t('để liên hệ trực tiếp với bộ phận CSKH')}
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex gap-5 items-center mt-6'>
          <Typography variant='body2' sx={{ flexBasis: 100 }}>
            {t('Kiểu')}
          </Typography>
          <SelectLabelFormField name='type' label='' control={control} options={FEEDBACK_TYPE_OPTIONS} />
        </div>
        <div className='flex gap-5 mt-6 relative'>
          <Typography variant='body2' sx={{ flexBasis: 100 }}>
            {t('Nội dung')}
          </Typography>
          <TextFormField
            name='content'
            control={control}
            error={!!errors.content}
            helperText={
              errors.content?.message
                ? t(errors.content?.message)
                : t('feedback_content_helper', { min: MIN_CONTENT_LENGTH })
            }
            placeholder={t('Nhập vào ý kiến của bạn')}
            multiline
            rows={4}
            sx={{ width: '100%' }}
            inputProps={{ maxLength: MAX_CONTENT_LENGTH }}
          />
          <Typography variant='caption' sx={{ position: 'absolute', bottom: 30, right: 10 }}>
            {watch('content').length}/{MAX_CONTENT_LENGTH}
          </Typography>
        </div>
        <div className='flex gap-5 mt-6'>
          <Typography variant='body2' sx={{ flexBasis: 85 }}>
            {t('Hình ảnh')}
          </Typography>
          <div>
            <div className='flex gap-5'>{renderImageUpload()}</div>
            <Typography component='p' variant='caption' color='text.subColor' mt={1}>
              {t('Hỗ trợ định dạng: PNG, JPG, JPEG')} {t('Kích thước tối đa', { size: '2' })}
            </Typography>
          </div>
        </div>

        <DebugLogs control={control} />
        <div className='flex justify-end gap-3 mt-6'>
          <Button variant='outlined' onClick={() => closeModal()}>
            {t('Hủy bỏ')}
          </Button>
          <LoadingButton
            type='submit'
            variant='contained'
            startIcon={<SendIcon sx={{ transform: 'rotate(-45deg)', mt: -0.5 }} />}
            sx={{
              '& .MuiButton-startIcon': {
                mr: '4px !important',
              },
            }}
            loading={isLoading}
            disabled={contentLength < MIN_CONTENT_LENGTH || contentLength > MAX_CONTENT_LENGTH}
          >
            {t('Gửi đi')}
          </LoadingButton>
        </div>
      </form>
    </>
  );
};

export const FeedbackErrorUI = () => {
  const { t } = useTranslation();

  return (
    <>
      <TipText text={t('Vui lòng dùng ứng dụng KikiLogin để sử dụng tính năng này')} />
      <Typography variant='body2' component='p' mt={3}>
        <Link to='kikilogin://open-app'>
          {' '}
          <strong>{t('Bấm vào đây')}</strong>
        </Link>{' '}
        {t('để mở ứng dụng KikiLogin và sử dụng được đầy đủ các tính năng. Nếu bạn chưa cài đặt phần mềm, tải xuống')}
        <Link to={appRoutes.supportCenter.fullPath}>
          {' '}
          <strong>{t('here')}</strong>
        </Link>
      </Typography>
    </>
  );
};
function FeedbackModal({ onClose }: ModalProps) {
  const { t } = useTranslation();
  const isAppConnected = useRecoilValue(isAppConnectedAtom);

  return (
    <Modal onClose={onClose} title={t('Feedback')} width={isAppConnected ? 760 : 500}>
      {!isAppConnected || !isOpenWithApp ? <FeedbackErrorUI /> : <FeedbackUI />}
    </Modal>
  );
}

export default FeedbackModal;
