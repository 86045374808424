import { selector } from 'recoil';
import { statisticAtom, systemInformationAtom } from 'kikifrontend/utils/recoil/atom';

export const browserStatusSelector = selector({
  key: 'systemInformationSelector',
  get: ({ get }) => {
    const data = get(systemInformationAtom);
    const currentState = {
      isKikiBrowser: true,
      isKifox: false,
      isGomu: false,
    };

    if (!data) {
      return currentState;
    }

    if (data.appDataGomuStatus === 'checked') {
      currentState.isGomu = true;
    }
    if (data.appDataKiFoxStatus === 'checked') {
      currentState.isKifox = true;
    }

    return currentState;
  },
});

export const isFreePackageSelector = selector<boolean>({
  key: 'isFreePackageSelector',
  get: ({ get }) => {
    const { statistic } = get(statisticAtom);
    if (!statistic) return true;
    return statistic?.package?.name?.toLowerCase() === 'free';
  },
});

export const isLeaderSelector = selector<boolean>({
  key: 'isLeaderSelector',
  get: ({ get }) => {
    const { statistic } = get(statisticAtom);
    if (!statistic) return true;
    return statistic.userInfo?.isRoot;
  },
});
