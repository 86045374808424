import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { pluginServices } from 'kikifrontend/_apis_';

export const personalPluginsQuery = () => {
  return useQuery([queryKeys.plugins.personal.list], () => pluginServices.getAllMyPlugins(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
