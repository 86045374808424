import React, { useRef, useState } from 'react';
// MUI
import { alpha } from '@mui/material/styles';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
// hooks
// components
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import useLocales from 'kikifrontend/hooks/useLocales';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { ButtonIcon } from '@kikisoftware/uikit';

export default function LanguagePopover({ showLabel = true }: { showLabel?: boolean }) {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = async (value: string) => {
    try {
      await onChangeLang(value);
      handleClose();
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    }
  };

  return (
    <>
      {showLabel ? (
        <Button
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            width: 160,
            padding: 0,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
            border: '1px solid',
            borderColor: 'divider',
          }}
          fullWidth={false}
          startIcon={<Box component='img' src={currentLang.icon} alt={currentLang.label} sx={{ width: 20 }} />}
        >
          <Typography variant='body2Bold' color='text.primary'>
            {t(currentLang.label)}
          </Typography>
        </Button>
      ) : (
        <ButtonIcon ref={anchorRef} buttonType='circle' onClick={handleOpen}>
          <Box component='img' src={currentLang.icon} alt={currentLang.label} sx={{ width: 24, borderRadius: 0.5 }} />
        </ButtonIcon>
      )}

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mb: 2.5,
            // ml: 0.5,
            overflow: 'inherit',
            // boxShadow: (theme) => theme.customShadows.z20,
            // @ts-ignore
            border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
            width: 200,
            py: 1,
          },
        }}
      >
        {allLang.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            sx={{ py: 1, px: 2.5 }}
          >
            <ListItemIcon>
              <Box component='img' alt={option.label} src={option.icon} sx={{ width: 28, height: 20 }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t(option.label)}</ListItemText>
            {option.value === currentLang.value && <CheckOutlinedIcon color='success' />}
          </MenuItem>
        ))}

        {/*{addedMenuItems && (*/}
        {/*  <>*/}
        {/*    <Divider />*/}
        {/*    {addedMenuItems.map((item) => (*/}
        {/*      <MenuItem key={item.value} onClick={item.onClick} sx={{ py: 1, px: 2.5 }}>*/}
        {/*        <ListItemIcon>*/}
        {/*          {typeof item.icon === 'string' ? (*/}
        {/*            <Svg src={item.icon} sx={{ width: 28, height: 20, bgcolor: 'text.thirty' }} />*/}
        {/*          ) : (*/}
        {/*            item.icon*/}
        {/*          )}*/}
        {/*        </ListItemIcon>*/}
        {/*        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{item.label}</ListItemText>*/}
        {/*      </MenuItem>*/}
        {/*    ))}*/}
        {/*  </>*/}
        {/*)}*/}
      </Popover>
    </>
  );
}
