import dayjs from 'dayjs';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import { ApplyCouponProps, CouponProps } from 'kikifrontend/features/paymentCenter/types/package.types';
import { couponTypes } from 'kikifrontend/features/paymentCenter/utils/constants';

export const VNDToUSDFloat = (vnd: number) => parseFloat(`${vnd / USDvsVND}`);

export function applyCoupon(
  { nextExpireDate, packagePrice }: { nextExpireDate: string; packagePrice: number },
  coupon?: ApplyCouponProps,
) {
  if (!coupon) {
    return {
      applyCouponNextExpireDate: nextExpireDate,
      applyCouponPackagePrice: packagePrice,
      applyCouponPackagePriceInUSD: VNDToUSDFloat(packagePrice),
    };
  }
  if (coupon.type === couponTypes.addMonth) {
    return {
      applyCouponNextExpireDate: dayjs(nextExpireDate).add(coupon.addMonths, 'month').toISOString(),
      applyCouponPackagePrice: packagePrice,
      applyCouponPackagePriceInUSD: VNDToUSDFloat(packagePrice),
    };
  }

  if (coupon.type === couponTypes.fixed) {
    const price = packagePrice - coupon.amount;
    return {
      applyCouponNextExpireDate: nextExpireDate,
      applyCouponPackagePrice: price,
      applyCouponPackagePriceInUSD: VNDToUSDFloat(price),
    };
  }

  const discountAmount = packagePrice * (coupon.percent / 100);
  const price = packagePrice - (discountAmount > coupon.maxDiscount ? coupon.maxDiscount : discountAmount);
  return {
    applyCouponNextExpireDate: nextExpireDate,
    applyCouponPackagePrice: price,
    applyCouponPackagePriceInUSD: VNDToUSDFloat(price),
  };
}

export function getDiscountLabel(coupon: CouponProps, packagePrice: number) {
  if (coupon.type === couponTypes.fixed) {
    return currencyFormat(coupon.amount, { prefix: '- $', rate: USDvsVND });
  }

  if (coupon.type === couponTypes.addMonth) {
    return `${coupon.amount}`;
  }

  if (!coupon.maxDiscountAmount) {
    coupon.maxDiscountAmount = coupon.amount;
  }

  const discountAmount = packagePrice * (coupon.amount / 100);
  if (discountAmount > coupon.maxDiscountAmount) {
    return currencyFormat(coupon.maxDiscountAmount, { prefix: '- $', rate: USDvsVND });
  }

  return currencyFormat(discountAmount, { prefix: '- $', rate: USDvsVND });
}
