import Box from '@mui/material/Box';
import React from 'react';
import { KIKI_THEME_EVENTS, useKikiThemeProvider } from '../../themes/KikiThemeProvider';

function BubbleContainer({ children, ...props }: React.PropsWithChildren<any>, ref: any) {
  const { theme } = useKikiThemeProvider();

  if (theme === KIKI_THEME_EVENTS.default) {
    return (
      <Box
        ref={ref}
        {...props}
        sx={{
          userSelect: 'none',
          bottom: 160,
          right: 20,
          width: 48,
          height: 48,
          borderRadius: 9999,
          position: 'fixed',
          bgcolor: 'transparent',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        userSelect: 'none',
        bottom: 160,
        right: 20,
        width: 68,
        height: 144,
        borderRadius: 9999,
        position: 'fixed',
        bgcolor: 'transparent',
        overflow: 'hidden',
        transition: 'transform 0.15s ease-in-out',
        transform: 'scale(0.8)',
        '&:hover': {
          transform: 'scale(1)',
          '& svg': {
            transform: 'rotate(360deg)',
            transition: 'transform 1s',
          },
        },
      }}
    >
      {children}
    </Box>
  );
}

export default React.forwardRef(BubbleContainer);
