import { customAlphabet } from 'nanoid';
import {
  AUDIO_INPUTS_EXAMPLE,
  AUDIO_OUTPUTS_EXAMPLE,
  DEVICE_EXAMPLE,
  DEVICES_TYPE,
  VIDEO_INPUTS_EXAMPLE,
} from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { MediaFingerprintType } from 'kikifrontend/features/profiles/profileEditor/types/fingerprint.types';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';

const COMPUTER_NAME_PREFIX = ['DESKTOP', 'PC'];
const COMPUTERNAME_LENGTH = [9, 10, 11, 12, 13];

const prefixId = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 4);
const suffixId = customAlphabet('0123456789', 5);
const deviceId = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 64);

const IP_PREFIX = ['192', '10'];
const getSubnet = (max = 255) => {
  const arr = [];

  for (let i = 0; i <= max; i++) {
    arr.push(i);
  }

  return arr;
};

const getMACSub = () => {
  const arr = [];

  for (let i = 0; i <= 99; i++) {
    if (i < 10) {
      arr.push(`0${i}`);
    } else {
      arr.push(`${i}`);
    }
  }

  return arr;
};

const genRanHex = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

const getRandomComputerName = () => {
  const randomIndex = (COMPUTER_NAME_PREFIX.length * Math.random()) | 0;
  const idx = randomIndex === 0 ? 10 : 12;
  const randomLength = (COMPUTERNAME_LENGTH.length * Math.random()) | 0;
  const prefix = COMPUTER_NAME_PREFIX[randomIndex];
  let randomName = genRanHex(idx);

  if (randomName.length >= COMPUTERNAME_LENGTH[randomLength]) {
    randomName = randomName.substring(0, COMPUTERNAME_LENGTH[randomLength]);
  } else {
    for (let i = 0; i < COMPUTERNAME_LENGTH[randomLength] - randomName.length; i++) {
      randomName = randomName + randomName[(randomName.length * Math.random()) | 0];
    }
  }

  return prefix + '-' + randomName.toUpperCase();
};

const randomFromArray = (array: any[]) => array[Math.floor(Math.random() * array.length)];

const SUBNET = getSubnet();
const MAC_SUB = getMACSub();

const randomIPAddress = () => {
  let ip = IP_PREFIX[(IP_PREFIX.length * Math.random()) | 0];
  for (let i = 0; i <= 2; i++) {
    const randomSub = SUBNET[(SUBNET.length * Math.random()) | 0];
    if (ip === '192' && i === 0) {
      ip = ip + '.168';
    } else {
      ip = ip + '.' + randomSub;
    }
  }

  return ip;
};

const randomWanIP = () => {
  const ips = [];
  for (let i = 1; i <= 255; i++) {
    if ((i >= 10 && i <= 30) || i === 127 || (i >= 170 && i <= 179) || (i >= 190 && i <= 199)) continue;
    ips.push(i);
  }
  const subnet = getSubnet();
  let ip = `${ips[(ips.length * Math.random()) | 0]}`;
  for (let i = 0; i <= 2; i++) {
    const randomSub = subnet[(subnet.length * Math.random()) | 0];
    ip = ip + '.' + randomSub;
  }

  return ip;
};

function randomVideoInputLabel() {
  let item = VIDEO_INPUTS_EXAMPLE[Math.floor(Math.random() * VIDEO_INPUTS_EXAMPLE.length)];
  if (item === 'Integrated Camera') {
    item = item + ` (${prefixId()}:${suffixId()})`;
  }
  return item;
}
function randomAudioInputLabel() {
  return AUDIO_INPUTS_EXAMPLE[Math.floor(Math.random() * AUDIO_INPUTS_EXAMPLE.length)];
}
function randomAudioOutputLabel() {
  return AUDIO_OUTPUTS_EXAMPLE[Math.floor(Math.random() * AUDIO_OUTPUTS_EXAMPLE.length)];
}
function randomDeviceId() {
  let item = DEVICE_EXAMPLE[Math.floor(Math.random() * DEVICE_EXAMPLE.length)];
  if (item === 'random') {
    item = deviceId();
  }
  return item;
}

function randomLabel(type: MediaFingerprintType) {
  if (type === 'videoInputs') {
    return randomVideoInputLabel();
  }
  if (type === 'audioInputs') {
    return randomAudioInputLabel();
  }

  return randomAudioOutputLabel();
}

function generateKiFoxMediaDeviceId() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 43; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + '=';
}

function randomDevice(type: MediaFingerprintType, browserType: BrowserType) {
  return {
    label: randomLabel(type),
    deviceId: browserType === 'kifox' ? generateKiFoxMediaDeviceId() : randomDeviceId(),
    groupId: browserType === 'kifox' ? generateKiFoxMediaDeviceId() : deviceId(),
    kind: DEVICES_TYPE[type],
  };
}

const randomNumber = () => {
  return Math.floor(Math.random() * 10) - 5;
};

const randomCanvasHash = () => {
  const a = randomNumber();
  const b = randomNumber();
  const c = randomNumber();
  const d = randomNumber();
  return `${a},${b},${c},${d}`;
};

const getRandomMACAddress = () => {
  // Địa chỉ MAC bắt đầu bằng 6-byte OUI (Organizationally Unique Identifier)
  const oui = [0x00, 0x16, 0x3e]; // Ví dụ: Intel Corporation OUI

  // Tạo ngẫu nhiên 3 byte còn lại của địa chỉ MAC
  const randomBytes = Array.from({ length: 3 }, () => Math.floor(Math.random() * 256));

  // Kết hợp OUI và byte ngẫu nhiên để tạo địa chỉ MAC hoàn chỉnh
  const macAddress = oui.concat(randomBytes);

  // Chuyển địa chỉ MAC thành chuỗi hex và định dạng lại theo dạng XX:XX:XX:XX:XX:XX
  const macAddressStr = macAddress.map((byte) => byte.toString(16).padStart(2, '0')).join('-');

  return macAddressStr.toUpperCase();
};

export {
  randomIPAddress,
  MAC_SUB,
  randomWanIP,
  randomDevice,
  randomCanvasHash,
  randomFromArray,
  getRandomComputerName,
  getRandomMACAddress,
};
