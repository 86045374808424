import React, { useCallback, useContext, useMemo, useState } from 'react';
import ThemeConfig from './ThemeConfig';
import ThemePrimaryColor from './ThemePrimaryColor';
import { KikiThemeEvent } from './types';

export const KIKI_THEME_EVENTS: Record<KikiThemeEvent, KikiThemeEvent> = {
  default: 'default',
  lunarNewYear: 'lunarNewYear',
};

const kikiThemeContext = React.createContext<{
  theme: KikiThemeEvent;
  updateKikiTheme: (theme: KikiThemeEvent) => void;
}>({
  theme: KIKI_THEME_EVENTS.default,
  updateKikiTheme: () => {
    // SS
  },
});

const KikiThemeProvider = ({
  children,
  theme = KIKI_THEME_EVENTS.default,
}: React.PropsWithChildren<{ theme?: KikiThemeEvent }>) => {
  const [kikiTheme, setKikiTheme] = useState(theme);

  const updateKikiTheme = useCallback((theme: KikiThemeEvent) => {
    setKikiTheme(theme);
  }, []);

  const values = useMemo(() => ({ theme: kikiTheme, updateKikiTheme }), [kikiTheme, updateKikiTheme]);

  return (
    <kikiThemeContext.Provider value={values}>
      <ThemeConfig>
        <ThemePrimaryColor>{children}</ThemePrimaryColor>
      </ThemeConfig>
    </kikiThemeContext.Provider>
  );
};

export const useKikiThemeProvider = () => useContext(kikiThemeContext);

export default KikiThemeProvider;
