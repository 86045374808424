import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectPaymentMethodAmountConfirm from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/SelectPaymentMethodAmountConfirm';
import { BankPaymentProps, CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import useLocales from 'kikifrontend/hooks/useLocales';
import { currencyFormat, USDvsVND, VNDvsUSD } from 'kikifrontend/utils/format';
import { ButtonIcon, Flex, Image, useModal } from '@kikisoftware/uikit';
import Divider from '@mui/material/Divider';
import { CopyText, TrackButton } from 'kikifrontend/components';
import Paper from '@mui/material/Paper';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useAuth } from 'kikifrontend/hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DepositWithExternalWalletView from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/DepositWithExternalWalletView';

const BankInformation = ({
  amount,
  selectMethod,
  method,
}: {
  amount: string;
  selectMethod: (method?: BankPaymentProps) => () => void;
  method: BankPaymentProps;
}) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);
  const { currentLang, allLang } = useLocales();
  const { getSystemStatistic } = useAuth();
  const queryClient = useQueryClient();

  const isVN = useMemo(() => currentLang.value === allLang[1].value, [currentLang]);

  const finalAmount = useMemo(() => (isVN ? amount : parseFloat(amount) * USDvsVND), [isVN, amount]);

  const completePayment = async () => {
    await getSystemStatistic();
    closeModal();
    await queryClient.refetchQueries([queryKeys.paymentCenter.ordersHistory]);
  };

  return (
    <>
      <div className='flex items-center mb-2'>
        <ButtonIcon buttonType='circle' onClick={selectMethod(undefined)}>
          <KeyboardArrowLeftIcon />
        </ButtonIcon>
        <Typography variant='body1Bold'>{t('Payment Amount')}</Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper elevation={0} sx={{ height: '100%', bgcolor: 'grey.200', py: 2.5 }}>
            <Stack alignItems='center' justifyContent='center' px={1}>
              <Typography fontSize={18} fontWeight={600} color='text.subColor2'>
                {t('Scan QR')}
              </Typography>
              <Typography component='p' textAlign='center' variant='body2' color='#8B9299'>
                {t('Open bank app and scan QR code to confirm')}
              </Typography>
              <Box p={1} className='border-corner-qr' mt={1.5}>
                <Box p={0.5} sx={{ border: '1px solid #C1C7CD', width: 166, height: 170 }}>
                  <Image
                    src={`${method.qrLink}?amount=${finalAmount}&addInfo=${`KIKI${statistic?.userInfo?.affCode}`}`}
                    sx={{ width: 160, height: 160 }}
                  />
                </Box>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper elevation={0} sx={{ height: '100%', bgcolor: 'grey.200', py: 2.5 }}>
            <Typography component='p' textAlign='center' fontSize={18} fontWeight={600} color='text.subColor2'>
              {t('Bank transfer')}
            </Typography>
            <Typography component='p' textAlign='center' variant='body2' color='#8B9299' px={4}>
              {t('Please transfer correctly content in this card below')}
            </Typography>
            <Stack
              spacing={0.5}
              width={1}
              sx={{ border: '1px solid', borderColor: 'border.neutral', borderRadius: '5px', px: 2.5, py: 1.5 }}
              mt={1.5}
            >
              <Flex>
                <Typography variant='body2' color='text.subColor4'>
                  {t('Owner')}
                </Typography>
                <Typography variant='body2Bold' color='text.subColor2'>
                  {method.info.cardHolder}
                </Typography>
              </Flex>
              <Flex>
                <Typography variant='body2' color='text.subColor4'>
                  {t('Bank')}
                </Typography>
                <Typography variant='body2Bold' color='text.subColor2'>
                  {method.name}
                </Typography>
              </Flex>
              <Flex>
                <Typography variant='body2' color='text.subColor4'>
                  {t('Card number')}
                </Typography>
                <CopyText text={method.info.cardNumber} sx={{ fontWeight: 700, color: 'text.subColor2' }} />
              </Flex>
              <Flex>
                <Typography variant='body2' color='text.subColor4'>
                  {t('Content')}{' '}
                  <Typography component='span' color='error.main'>
                    *
                  </Typography>
                </Typography>
                <CopyText
                  text={`KIKI${statistic?.userInfo?.affCode}`}
                  sx={{ color: 'text.modalTitle', fontWeight: 700 }}
                />
              </Flex>
              <Flex>
                <Typography variant='body2' color='text.subColor4'>
                  {t('Transaction fee')}
                </Typography>
                <Typography variant='body2' color='#28A745'>
                  {t('Free')}
                </Typography>
              </Flex>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <div className='flex justify-between items-center mt-5'>
        <Typography variant='h6' fontFamily='SVN-Gilroy-Medium'>
          {t('Tổng thanh toán')}
        </Typography>
        <div className='flex gap-1 flex-col items-end'>
          <Typography variant='h5' color='text.modalTitle' fontFamily='SVN-Gilroy-Medium'>
            {currencyFormat(amount, { prefix: '$', rate: isVN ? USDvsVND : 1 })}
          </Typography>
          <Typography variant='body1' color='text.subColor4'>
            ~{currencyFormat(amount, { prefix: 'đ', rate: !isVN ? VNDvsUSD : 1 })}
          </Typography>
        </div>
      </div>
      <Divider sx={{ mt: 2, mb: 3 }} />

      <Box
        px={3}
        py={2}
        sx={{ border: '1px dashed', borderColor: 'border.primary', bgcolor: 'background.neutral', borderRadius: 1 }}
      >
        <Typography variant='body2' color='text.modalTitle' component='p' textAlign='center'>
          {t('payment_bill_description')}
        </Typography>
      </Box>

      <TrackButton
        variant='contained'
        fullWidth
        startIcon={<CheckOutlinedIcon />}
        color='success'
        sx={{ mt: 1.5, color: 'white' }}
        onClick={completePayment}
        eventName='payment_center_deposit_click_success'
        params={{
          payment_method: 'banking',
          amount,
        }}
      >
        {t('I have done this payment')}
      </TrackButton>
    </>
  );
};

function SelectPaymentMethod({ amount, backToInputAmount }: { amount: string; backToInputAmount: () => void }) {
  const [method, setmethod] = useState<CryptoPaymentProps | BankPaymentProps | undefined>();

  const selectMethod = (method?: CryptoPaymentProps | BankPaymentProps) => () => {
    setmethod(method);
  };

  return (
    <>
      {method ? (
        method.type === 'bank' ? (
          <BankInformation amount={amount} method={method as BankPaymentProps} selectMethod={selectMethod} />
        ) : (
          <DepositWithExternalWalletView
            amount={amount}
            method={method as CryptoPaymentProps}
            selectMethod={selectMethod}
          />
        )
      ) : (
        <SelectPaymentMethodAmountConfirm
          amount={amount}
          selectMethod={selectMethod}
          backToInputAmount={backToInputAmount}
        />
      )}
    </>
  );
}

export default SelectPaymentMethod;
