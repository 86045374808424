import { useCallback, useEffect, useState } from 'react';

export default function useWs(url?: string) {
  const [ws, setWs] = useState<WebSocket | undefined>();

  const connectWebSocket = useCallback(() => {
    if (!url) return;
    const newWs = new WebSocket(url);
    setWs(newWs);
  }, [url]);

  useEffect(() => {
    connectWebSocket();
  }, [connectWebSocket]);

  return { ws, retry: connectWebSocket };
}
