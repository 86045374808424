import React from 'react';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { ButtonIcon, Image, useModal } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import appRoutes from 'kikifrontend/routes/path';
import { LanguagePopover, Link } from 'kikifrontend/components';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SystemInformationModal from 'kikifrontend/components/Modals/SystemInformationModal';
import FeedbackModal from 'kikifrontend/features/feedback/components/FeedbackModal';

function SidebarFooterCollapse() {
  const { statistic } = useRecoilValue(statisticAtom);
  const { openModal } = useModal();

  const openSystemModal = () => openModal(SystemInformationModal);
  const openFeedbackModal = () => openModal(FeedbackModal);

  return (
    <>
      <Divider />
      {statistic && (
        <div className='px-5 py-2 w-full flex flex-col items-center mt-4'>
          <div className='flex flex-col items-center gap-2'>
            <Link to={appRoutes.paymentCenter.fullPath} className='grow'>
              <Button
                disableElevation
                variant='contained'
                color='secondary'
                // fullWidth
                sx={{ width: 32, height: 32, minWidth: 32, px: 0.75 }}
              >
                <Image src={Assets.icons.Upgrade} asyncLoading={false} sx={{ width: 32, height: 32 }} />
              </Button>
            </Link>
            <div className='mt-0' />
            <LanguagePopover showLabel={false} />
            <ButtonIcon buttonType='circle' onClick={openSystemModal}>
              <MonitorHeartIcon sx={{ color: 'grey.500' }} />
            </ButtonIcon>
            <ButtonIcon buttonType='circle' onClick={openFeedbackModal}>
              <RateReviewIcon sx={{ color: 'grey.500' }} />
            </ButtonIcon>
          </div>
        </div>
      )}
    </>
  );
}

export default SidebarFooterCollapse;
