import React from 'react';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import appRoutes from 'kikifrontend/routes/path';
import useLocales from 'kikifrontend/hooks/useLocales';
import { useLocation } from 'react-router-dom';

const WithSignIn = ({ children }: any): JSX.Element => {
  const systemInfo = useRecoilValue(statisticAtom);
  const { currentLang } = useLocales();
  const location = useLocation();

  const { isAuthenticated, statistic, isInitialize } = systemInfo;

  if (!isAuthenticated && !statistic && isInitialize) {
    window.location.href = `${appRoutes.login.fullPath}&lang=${currentLang.value}&path=${location.pathname}`;
    return <></>;
  }
  return children;
};

export default WithSignIn;
