import React from 'react';
import { personalPluginsQuery } from 'kikifrontend/features/plugins/queries/personalPlugins.query';
import Divider from '@mui/material/Divider';
import NoResults from '../../../components/NoResults';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PageViewLayout from 'kikifrontend/layouts/dashboard/PageViewLayout';
import MyPluginCard from 'kikifrontend/features/plugins/components/MyPluginCard';
import Box from '@mui/material/Box';

function MyPluginsView() {
  const { data: personalPlugins } = personalPluginsQuery();

  return (
    <div>
      <PageViewLayout pb={3} mb={3} minHeight='calc(100vh - 132px)'>
        <Divider />
        {personalPlugins?.length === 0 ? (
          <Box height='calc(100vh - 132px)'>
            <NoResults />
          </Box>
        ) : (
          <Grid container rowSpacing={3} columnSpacing={3} mt={0} sx={{ px: 3 }}>
            {personalPlugins?.map((plugin) => (
              <Grid key={plugin._id} item xs={12} md={6} lg={4} xl={4}>
                <Paper elevation={0} sx={{ height: '100%' }}>
                  <MyPluginCard {...plugin} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </PageViewLayout>
    </div>
  );
}

export default MyPluginsView;
