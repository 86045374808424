import MuiPopover from '@mui/material/Popover';
import React, { useState } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { Flex } from '@kikisoftware/uikit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
interface FilterDropdownProps {
  locales?: any;
  popover: React.ComponentType | null;
  onChange?: (value: ValueProps) => void;
  initialValue?: ValueProps;
  onClear?: () => void;
}

export interface ValueProps {
  label: string;
  value?: string;
}

const DropdownStyleRoot = styled('button')({
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 40,
  padding: '0 6px 0 10px',
  backgroundColor: 'white',
  cursor: 'text !important',
  '&:hover': {
    borderColor: '#204DC2',
  },
  overflow: 'hidden',
});

function FilterDropdown({ locales, popover: PopoverComponent, onChange, initialValue, onClear }: FilterDropdownProps) {
  const [selectValue, setSelectValue] = useState<ValueProps>(
    initialValue ?? {
      label: 'Chọn nhóm',
      value: undefined,
    },
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const onSelectValue = (newValue: ValueProps) => {
    setSelectValue(newValue);
    onChange && onChange(newValue);
    handleClose();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectValue({ label: 'Chọn nhóm', value: undefined });
    onClear && onClear();
  };

  return (
    <div>
      <DropdownStyleRoot aria-describedby={id} sx={{ width: { md: 240 } }} onClick={handleClick}>
        <Typography
          component='p'
          sx={{
            fontSize: 14,
            color: selectValue.value ? 'text.primary' : '#9ca5ae',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {locales ? locales(selectValue.label) : selectValue.label}
        </Typography>
        <Flex justify='flex-start' sx={{ flexBasis: 50 }}>
          <IconButton
            sx={{
              visibility: selectValue.value ? 'visible' : 'hidden',
              width: 26,
              height: 26,
            }}
            onClick={handleClear}
          >
            <CloseIcon sx={{ fontSize: 20 }} />
          </IconButton>

          <ArrowDropDownIcon />
        </Flex>
      </DropdownStyleRoot>
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1.25 }}
      >
        {PopoverComponent && (
          // @ts-ignore
          <PopoverComponent value={selectValue} onSelectValue={onSelectValue} />
        )}
      </MuiPopover>
    </div>
  );
}

export default FilterDropdown;
