import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SizeOfTeamProps } from 'kikifrontend/features/surveyCustomers/utils/types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Assets from 'kikifrontend/assets';
import { Svg } from '@kikisoftware/uikit';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const MuiSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: 'text.ocean',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

const marks = [
  {
    value: 0,
    label: 'Me',
  },
  {
    value: 25,
    label: '2-4',
  },
  {
    value: 50,
    label: '5-8',
  },
  {
    value: 75,
    label: '9-30',
  },
  {
    value: 100,
    label: '30+',
  },
];

const SizeOfTeam = ({ value, onChange }: SizeOfTeamProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Typography variant='body1Bold'>3. {t('survey_modal_sizeOfTeam')}</Typography>
      <Typography variant='body2'>{t('survey_modal_sizeOfTeam_description')}</Typography>
      <Box>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Svg
            src={Assets.icons.SurveyMe}
            sx={{
              bgcolor: value === 0 ? 'primary.main' : 'text.primary',
              width: 28,
              height: 28,
            }}
          />
          <Svg
            src={Assets.icons.SurveyGroup}
            sx={{
              mx: 10.85,
              bgcolor: value === 25 ? 'primary.main' : 'text.primary',
              width: 24,
              height: 24,
            }}
          />
          <Svg
            src={Assets.icons.SurveyGroup}
            sx={{
              bgcolor: value === 50 ? 'primary.main' : 'text.primary',
              width: 24,
              height: 24,
            }}
          />
          <Svg
            src={Assets.icons.SurveyHome}
            sx={{
              mx: 10.85,
              bgcolor: value === 75 ? 'primary.main' : 'text.primary',
              width: 24,
              height: 24,
            }}
          />
          <Svg
            src={Assets.icons.SurveyHome}
            sx={{
              bgcolor: value === 100 ? 'primary.main' : 'text.primary',
              width: 24,
              height: 24,
            }}
          />
        </Stack>
        <Box sx={{ position: 'relative', px: 1.75 }}>
          <MuiSlider
            aria-label='Custom marks'
            step={25}
            valueLabelDisplay='off'
            marks={marks}
            value={value}
            onChange={onChange}
            sx={{ m: 0 }}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default SizeOfTeam;
