// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 48,
          '& fieldset': {
            borderWidth: '1px',
          },
          '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
            },
          },
          textarea: {
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
              borderRadius: 12,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#B1B5C3',
              borderRadius: 12,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#9298ad',
            },
          },
        },
        sizeSmall: {
          height: 40,
        },
        multiline: {
          height: '100%',
        },
        input: {
          '&.MuiOutlinedInput-input': {
            paddingTop: 12.5,
            paddingBottom: 12.5,
            '&.MuiInputBase-inputMultiline': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.grey[500],
          },
          fontSize: 14,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginTop: -5,
          '&.Mui-focused': {
            transform: 'translate(14px, -5px) scale(0.75)',
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: 'translate(14px, -5px) scale(0.75)',
        },
        sizeSmall: {
          transform: 'translate(14px, 14px) scale(0.9)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
