import React, { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from 'kikifrontend/layouts/dashboard/DashboardLayout';
import AuthenticationLayout from 'kikifrontend/layouts/authentication/AuthenticationLayout';
import appRoutes from 'kikifrontend/routes/path';
import AppLoadingScreen from 'kikifrontend/components/AppLoadingScreen';
import MyPlugins from 'kikifrontend/pages/dashboard/Plugins/MyPlugins';

const Loadable =
  <C extends React.ComponentType<any>, P extends React.ComponentProps<C>>(Component: C) =>
  (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          <AppLoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed',
              }),
            }}
          />
        }
      >
        {/*@ts-ignore*/}
        <Component {...props} />
      </Suspense>
    );
  };

export default function Routes() {
  return useRoutes([
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          // element: <Dashboard />,
          element: <Navigate to='profiles' replace />,
        },
        {
          path: appRoutes.members.path,
          element: <Member />,
        },
        {
          path: appRoutes.synchronization.path,
          element: <Synchronizer />,
        },
        {
          path: appRoutes.supportCenter.path,
          element: <SupportCenter />,
        },
        {
          path: appRoutes.externalServices.path,
          element: <ExternalServices />,
        },
        {
          path: appRoutes.paymentCenter.path,
          element: <PaymentCenter />,
        },
        {
          path: appRoutes.apolloScan.path,
          element: <ApolloScan />,
        },
        {
          path: appRoutes.appSettings.path,
          children: [
            {
              path: appRoutes.appSettings.user.path,
              element: <UserSettings />,
            },
            {
              index: true,
              element: <Navigate to={appRoutes.appSettings.user.path} replace />,
            },
          ],
        },
        {
          path: appRoutes.profiles.path,
          children: [
            {
              path: appRoutes.profiles.profileCreate.path,
              element: <ProfileCreated />,
            },
            {
              path: appRoutes.profiles.profileUpdate.path,
              element: <ProfileUpdated />,
            },
            {
              path: appRoutes.profiles.profileDeleted.path,
              element: <ProfileDeleted />,
            },
            {
              path: appRoutes.profiles.profileTeam.path,
              element: <ProfileTeam />,
            },
            {
              path: appRoutes.profiles.profileShared.path,
              element: <ProfileShared />,
            },
            {
              index: true,
              element: <ProfileList />,
            },
          ],
        },
        {
          path: appRoutes.extensions.path,
          children: [
            {
              path: appRoutes.extensions.personalExtensions.path,
              element: <PersonalExtension />,
            },
            {
              path: `${appRoutes.extensions.detailExtension.path}/:id`,
              element: <DetailExtension />,
            },
            {
              index: true,
              element: <PublicExtension />,
            },
          ],
        },
        {
          path: appRoutes.plugins.path,
          children: [
            {
              path: appRoutes.plugins.personalPlugins.path,
              element: <MyPlugins />,
            },
            {
              index: true,
              element: <PublicPlugins />,
            },
          ],
        },
        {
          path: appRoutes.affiliate.path,
          children: [
            {
              path: appRoutes.affiliate.createdLink.path,
              element: <AffiliateCreatedLink />,
            },
            {
              path: appRoutes.affiliate.statistic.path,
              element: <AffiliateStatistic />,
            },
            {
              index: true,
              element: <Navigate to={appRoutes.affiliate.createdLink.path} replace />,
            },
          ],
        },
        {
          path: appRoutes.historyLog.path,
          children: [
            {
              path: appRoutes.historyLog.login.path,
              element: <HistoryLogLogin />,
            },
            {
              path: appRoutes.historyLog.profile.path,
              element: <HistoryLogProfile />,
            },
            {
              path: appRoutes.historyLog.sessions.path,
              element: <Sessions />,
            },
            {
              index: true,
              element: <Navigate to={appRoutes.historyLog.login.path} replace />,
            },
          ],
        },
        {
          path: appRoutes.adsReport.path,
          children: [
            {
              path: appRoutes.adsReport.facebook.path,
              children: [
                {
                  index: true,
                  element: <Navigate to={appRoutes.adsReport.facebook.accountReport.path} replace />,
                },
                {
                  path: appRoutes.adsReport.facebook.accountReport.path,
                  element: <AdsReportsFacebook />,
                },
                {
                  path: appRoutes.adsReport.facebook.campaignReport.path,
                  element: <CampaignReportsFacebook />,
                },
                {
                  path: appRoutes.adsReport.facebook.adsChange.path,
                  element: <CampaignReportAdsChange />,
                },
              ],
            },
          ],
        },
        {
          path: appRoutes.adsReport.adsTags.path,
          children: [
            {
              element: <AdsTags />,
              index: true,
            },
            {
              path: appRoutes.adsReport.createAdsTag.path,
              element: <AdsTagsForm />,
            },
            {
              path: appRoutes.adsReport.editAdsTag.path,
              element: <AdsTagsForm />,
            },
          ],
        },
        {
          path: appRoutes.automation.path,
          // element: <Dashboard />,
          children: [
            {
              path: appRoutes.automation.v3.path,
              children: [
                {
                  path: appRoutes.automation.v3.editor.path,
                  element: <AutomationEditor isDashboard />,
                },
                {
                  path: appRoutes.automation.v3.detailAutomation.path + '/:id',
                  element: <AutomationDetail />,
                },
                {
                  path: appRoutes.automation.v3.myAutomation.path,
                  element: <AutomationV3List />,
                },
                {
                  path: appRoutes.automation.v3.myTask.path,
                  element: <AutomationTasks />,
                },
                {
                  index: true,
                  element: <PublicAutomation />,
                },
              ],
            },
            {
              path: appRoutes.automation.v4.path,
              children: [
                {
                  path: appRoutes.automation.v4.editor.path,
                  element: <AutomationEditorV4 />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'auth',
      element: <AuthenticationLayout />,
      children: [
        { element: <SessionChecking />, index: true },
        {
          path: 'login',
          element: <Login />,
        },
      ],
    },
    {
      path: 'connected-accounts',
      children: [
        {
          path: appRoutes.connectAccounts.validateGoogleOAuthCode.path,
          element: <ConnectedAccountValidateGoogleSheetCode />,
        },
      ],
    },
    {
      path: 'automation',
      children: [
        {
          path: appRoutes.automation.v3.path,
          children: [
            {
              path: appRoutes.automation.v3.editor.path,
              element: <AutomationEditor />,
            },
          ],
        },
      ],
    },
    {
      path: 'development',
      children: [
        {
          path: appRoutes.components.path,
          children: [
            {
              element: <Components />,
              index: true,
            },
            {
              path: appRoutes.components.color.path,
              element: <Colors />,
            },
            {
              path: appRoutes.components.typography.path,
              element: <Typography />,
            },
            {
              path: appRoutes.components.buttons.path,
              element: <Buttons />,
            },
            {
              path: appRoutes.components.checkbox.path,
              element: <Checkboxes />,
            },
            {
              path: appRoutes.components.input.path,
              element: <Input />,
            },
          ],
        },
        {
          path: appRoutes.hooks.path,
          children: [
            {
              element: <HooksOverview />,
              index: true,
            },
          ],
        },
        {
          index: true,
          element: <Development />,
        },
      ],
    },
    {
      path: '*',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: 'oops', element: <PageOops /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to='/dashboard' replace />,
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const PageOops = Loadable(lazy(() => import('../pages/PageOops')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const SessionChecking = Loadable(lazy(() => import('../pages/authentication/SessionChecking')));
const Components = Loadable(lazy(() => import('../pages/Components')));
const HooksOverview = Loadable(lazy(() => import('../pages/HooksOverview')));
const Synchronizer = Loadable(lazy(() => import('../pages/dashboard/Synchronizer')));
const Development = Loadable(lazy(() => import('kikifrontend/features/_external-pages/Development')));
const Colors = Loadable(
  lazy(() => import('kikifrontend/features/_external-pages/components-overview/foundation/FoundationColors')),
);
const Typography = Loadable(
  lazy(() => import('kikifrontend/features/_external-pages/components-overview/foundation/FoundationTypography')),
);
const Buttons = Loadable(
  lazy(() => import('kikifrontend/features/_external-pages/components-overview/base/button/BaseButton')),
);
const Checkboxes = Loadable(
  lazy(() => import('kikifrontend/features/_external-pages/components-overview/base/BaseCheckbox')),
);
const Input = Loadable(lazy(() => import('kikifrontend/features/_external-pages/components-overview/base/textfield')));
const AffiliateCreatedLink = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Affiliate/CreatedLink')));
const AffiliateStatistic = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Affiliate/Statistic')));
const HistoryLogLogin = Loadable(lazy(() => import('kikifrontend/pages/dashboard/HistoryLog/HistoryLogLogin')));
const HistoryLogProfile = Loadable(lazy(() => import('kikifrontend/pages/dashboard/HistoryLog/HistoryLogProfile')));
const Sessions = Loadable(lazy(() => import('kikifrontend/pages/dashboard/HistoryLog/Sessions')));
const AdsReportsFacebook = Loadable(lazy(() => import('kikifrontend/pages/dashboard/AdsReports/AdsReportsFacebook')));
const AutomationEditor = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v3/AutomationEditor')));
const AutomationEditorV4 = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v4/AutomationEditor')));
const AutomationDetail = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v3/AutomationDetail')));
const AutomationV3List = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v3/AutomationV3List')));
const PublicAutomation = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v3/PublicAutomation')));
const AutomationTasks = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Automation/v3/AutomationTasks')));
const Member = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Member')));
const SupportCenter = Loadable(lazy(() => import('kikifrontend/pages/dashboard/SupportCenter')));
const ExternalServices = Loadable(lazy(() => import('kikifrontend/pages/dashboard/ExternalServices')));
const ProfileTeam = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileTeam')));
const ProfileList = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileList')));
const ProfileShared = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileShared')));
const ProfileDeleted = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileDeleted')));
const ProfileCreated = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileCreated')));
const ProfileUpdated = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Profiles/ProfileUpdated')));
const PublicExtension = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Extensions/PublicExtension')));
const PersonalExtension = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Extensions/PersonalExtension')));
const PublicPlugins = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Plugins/PublicPlugins')));
const DetailExtension = Loadable(lazy(() => import('kikifrontend/pages/dashboard/Extensions/DetailExtension')));
const UserSettings = Loadable(lazy(() => import('kikifrontend/pages/dashboard/AppSettings/UserSettings')));
const PaymentCenter = Loadable(lazy(() => import('kikifrontend/pages/dashboard/PaymentCenter')));
const ApolloScan = Loadable(lazy(() => import('kikifrontend/pages/dashboard/ApolloScan')));
const ConnectedAccountValidateGoogleSheetCode = Loadable(
  lazy(() => import('kikifrontend/pages/connectedAccounts/ConnectedAccountValidateGoogleSheetCode')),
);
const CampaignReportsFacebook = Loadable(
  lazy(() => import('kikifrontend/pages/dashboard/CampaignReport/CampaignReportFacebook')),
);
const CampaignReportAdsChange = Loadable(
  lazy(() => import('kikifrontend/pages/dashboard/CampaignReport/CampaignReportAdsChange')),
);
const AdsTags = Loadable(lazy(() => import('kikifrontend/pages/dashboard/AdsTags')));
const AdsTagsForm = Loadable(lazy(() => import('kikifrontend/pages/dashboard/AdsTags/AdsTagsForm')));
// const AutomationManagement = Loadable(
//   lazy(() => import("kikifrontend/pages/dashboard/automation/AutomationManagement")),
// );
