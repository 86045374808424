import React, { useState } from 'react';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import AmountInput from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/AmountInput';
import useLocales from 'kikifrontend/hooks/useLocales';
import { DEPOSIT_STEPS } from 'kikifrontend/features/paymentCenter/utils/constants';
import SelectPaymentMethod from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/SelectPaymentMethod';

function DepositAmountModal({ onClose, initialAmount }: ModalProps) {
  const { t } = useTranslation();
  const { allLang, currentLang } = useLocales();
  const [amount, setAmount] = useState<string>(
    initialAmount ? initialAmount : currentLang.value !== allLang[1].value ? '500' : '11500000',
  );
  const [step, setStep] = useState(DEPOSIT_STEPS.inputAmount);

  const goToSelectPaymentMethod = () => setStep(DEPOSIT_STEPS.selectPaymentMethod);
  const backToInputAmount = () => setStep(DEPOSIT_STEPS.inputAmount);

  return (
    <Modal onClose={onClose} title={t('Deposit')} width={668} sx={{ '.MuiDialog-container': { py: 0 } }}>
      {step === DEPOSIT_STEPS.inputAmount && (
        <AmountInput amount={amount} setAmount={setAmount} goToSelectPaymentMethod={goToSelectPaymentMethod} />
      )}
      {step === DEPOSIT_STEPS.selectPaymentMethod && (
        <SelectPaymentMethod amount={amount} backToInputAmount={backToInputAmount} />
      )}
    </Modal>
  );
}

export default DepositAmountModal;
