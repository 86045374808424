import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { paymentServices } from 'kikifrontend/_apis_';
import { CouponProps, PackagePricingProps } from 'kikifrontend/features/paymentCenter/types/package.types';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { loadingCalculate } from 'kikifrontend/features/paymentCenter/atom/loading.atom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { couponTypes } from 'kikifrontend/features/paymentCenter/utils/constants';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { Flex } from '@kikisoftware/uikit';
import { dateTimeFormatter } from 'kikifrontend/utils/date-time';
import { TrackButton } from 'kikifrontend/components';
import CouponDetail from 'kikifrontend/features/paymentCenter/components/CouponDetail';

function PackageCoupon({
  selectedCoupon,
  setSelectedCoupon,
  defaultApplyCoupon,
  selectedTime,
  currentPackagePrice,
  eventName,
  trackParams,
}: {
  selectedCoupon?: CouponProps;
  setSelectedCoupon: SetterOrUpdater<CouponProps | undefined>;
  defaultApplyCoupon?: string;
  selectedTime?: string;
  currentPackagePrice?: PackagePricingProps;
  eventName: string;
  trackParams: Record<string, any>;
}) {
  const { t } = useTranslation();
  const { mutateAsync, reset, isError, data, isLoading } = useMutation(paymentServices.couponCheck);
  const setLoading = useSetRecoilState(loadingCalculate);
  const [code, setCode] = useState(defaultApplyCoupon ?? '');

  const couponDetail = data?.data;

  useEffect(() => {
    if (defaultApplyCoupon) {
      void applyCoupon(defaultApplyCoupon, currentPackagePrice);
    } else {
      unSelectedCoupon();
      reset();
      setLoading(false);
    }
    setCode(defaultApplyCoupon ?? '');
  }, [defaultApplyCoupon, currentPackagePrice]);

  const changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    if (isError) {
      reset();
    }
  };

  const unSelectedCoupon = () => {
    if (selectedCoupon) {
      setSelectedCoupon(undefined);
    } else {
      setSelectedCoupon(couponDetail);
    }
  };

  const applyCoupon = async (defaultCode?: string, currentPackagePrice?: PackagePricingProps) => {
    if (!currentPackagePrice || !selectedTime) return;
    try {
      const results = await mutateAsync(
        defaultCode
          ? { code: defaultCode, quantity: selectedTime, package: currentPackagePrice?._id }
          : { code, quantity: selectedTime, package: currentPackagePrice?._id },
      );
      if (results) {
        setSelectedCoupon(results.data);
      }
    } catch (err) {
      setSelectedCoupon(undefined);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: 'background.secondary' }} mt={2} mb={1.5}>
        <Stack direction='row' gap={1.5} alignItems='center'>
          <TextField
            placeholder={t('Nhập mã khuyễn mãi')}
            sx={{
              mt: 0,
              width: 1,
              // bgcolor: 'grey.200',
              borderRadius: 1,
              fieldset: {
                // border: 0,
              },
            }}
            value={code}
            onChange={changeCode}
            size='small'
            inputProps={{
              sx: {
                color: 'text.subColor4',
                fontWeight: 700,
              },
            }}
          />
          <TrackButton
            variant='contained'
            onClick={() => applyCoupon(undefined, currentPackagePrice)}
            sx={{ whiteSpace: 'nowrap' }}
            disabled={!code}
            eventName={eventName}
            params={trackParams}
          >
            {t('Áp dụng')}
          </TrackButton>
        </Stack>
        {isLoading ? (
          <Stack mt={2}>
            <Skeleton height={22} animation='wave' width='100%' />
            <Skeleton height={22} animation='wave' width='100%' />
            <Skeleton height={22} animation='wave' width='100%' />
            <Skeleton height={22} animation='wave' width='100%' />
          </Stack>
        ) : isError ? (
          <Typography component='p' variant='body2' color='red' mt={1} fontWeight={600}>
            {t('PACKAGE_INVALID_COUPON')}
          </Typography>
        ) : (
          couponDetail && (
            <CouponDetail
              bgcolor={selectedCoupon?.code === couponDetail.code ? 'rgba(32, 77, 194, 0.08)' : 'transparent'}
              icon={
                <>
                  {couponDetail.type === couponTypes.percentage && (
                    <PercentOutlinedIcon sx={{ color: 'primary.main', fontSize: 28 }} />
                  )}
                  {couponDetail.type === couponTypes.fixed && (
                    <AttachMoneyOutlinedIcon sx={{ color: 'primary.main', fontSize: 28 }} />
                  )}
                  {couponDetail.type === couponTypes.addMonth && (
                    <MoreTimeOutlinedIcon sx={{ color: 'primary.main', fontSize: 28 }} />
                  )}
                </>
              }
              description={couponDetail.description}
              code={couponDetail.code}
              endTime={dateTimeFormatter(couponDetail.endTime)}
              selectedButtonLabel={t(selectedCoupon?.code === couponDetail.code ? 'Bỏ chọn' : 'Chọn')}
              onSelectClick={unSelectedCoupon}
            />
          )
        )}
      </Box>
    </div>
  );
}

export default PackageCoupon;
