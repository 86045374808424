import { atom } from 'recoil';
import { SynchronizerCurrentStatus } from 'kikifrontend/features/synchronizer/interface/synchronizer.interface';

export const syncProfileMainWindowAtom = atom<string | undefined>({
  key: 'syncProfileMainWindowAtom',
  default: undefined,
});

export const isProfileSyncingAtom = atom<SynchronizerCurrentStatus | undefined>({
  key: 'isProfileSyncingAtom',
  default: undefined,
});

export const lastSyncProfileAtom = atom<string | undefined>({
  key: 'lastSyncProfileAtom',
  default: undefined,
});
