import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';

export default function useHelperChat() {
  const { statistic } = useRecoilValue(statisticAtom);

  const { chatToken, userInfo, package: pkg } = statistic || {};

  const { email, phone } = userInfo || {};

  const { expireAt, name, buyAt } = pkg || {};
  const businessInfo = localStorage.getItem('businessInfo');

  useEffect(() => {
    if (chatToken && email && expireAt && name && userInfo) {
      try {
        const businessInfoJson = businessInfo ? JSON.parse(businessInfo) : undefined;
        const role = userInfo.isRoot ? 'Leader' : 'Nhân viên';
        window.$crisp.push(['set', 'user:email', [email, chatToken]]);
        window.$crisp.push(['set', 'user:user_id', userInfo._id]);
        window.$crisp.push(['set', 'user:phone', phone ?? email]);
        window.$crisp.push(['set', 'user:nickname', email]);
        window.$crisp.push(['set', 'session:data', ['vai_tro', role]]);
        window.$crisp.push(['set', 'session:data', ['goi_cuoc', name]]);
        window.$crisp.push(['set', 'session:data', ['platform', businessInfoJson?.platform?.join(',')]]);
        window.$crisp.push(['set', 'session:data', ['business', businessInfoJson?.business?.join(',')]]);
        window.$crisp.push(['set', 'session:data', ['teamSize', businessInfoJson?.teamSize]]);
        window.$crisp.push(['set', 'session:data', ['phien_ban', userInfo?.currentVersion?.kikiLogin?.version || '']]);
        window.$crisp.push(['set', 'session:data', ['ngay_mua', dayjs(buyAt).format('DD/MM/YYYY')]]);
        window.$crisp.push(['set', 'session:data', ['ngay_het_han', dayjs(expireAt).format('DD/MM/YYYY')]]);
      } catch (e) {
        console.log('Init chat Error', e);
      }
    }
  }, [chatToken, email, expireAt, buyAt, name, phone, userInfo, businessInfo]);
}
