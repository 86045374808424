import { QueryClientConfig } from '@tanstack/react-query';
import Assets from 'kikifrontend/assets';

export const isOpenWithApp = window.navigator.userAgent.indexOf('KikiLogin') !== -1;

export const INITIAL_REACT_QUERY_CONFIGS: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
};
export const flagImageURL = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/';
export const browserImageUrl = 'https://raw.githubusercontent.com/alrra/browser-logos/main/src/';
export const KIKILOGIN_DOCUMENTATION_URL = 'https://tailieu.kikilogin.com/';
export const GOMU_NOT_INSTALLED_ERROR = 'GOMU_BROWSER_NOT_INSTALLED';
export const KIFOX_BROWSER_NOT_INSTALLED = 'KIFOX_BROWSER_NOT_INSTALLED';

export const DEFAULT_ROW_PER_PAGE_OPTIONS = [5, 10, 20, 50, 200, 500];
export const DEFAULT_PAGINATION_X = {
  page: 1,
  limit: 20,
  total: 0,
};

export const proxyIpType = {
  v4: 'IPv4',
  v6: 'IPv6',
} as const;

export const proxyStatus = {
  unknown: 'unknown',
  error: 'error',
  active: 'active',
} as const;

export const proxyType = {
  http: 'http',
  https: 'https',
  socks5: 'socks5',
} as const;

export const DEFAULT_DATA_GRID_SX = {
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cellCheckbox:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    backgroundColor: '#B1B5C3',
    borderRadius: 12,
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const defaultToolbarTitle = (t: (key?: string) => string | undefined) => ({
  columnsAdjust: t('columnsAdjust'),
  refresh: t('refresh'),
  findBy: t('findBy'),
  findColumnAdjust: t('findColumnAdjust'),
  gotoPage: t('gotoPage'),
  labelRowPerPage: t('labelRowPerPage'),
  labelDisplayPagination: t('labelDisplayPagination'),
});

export const SOCKET_EVENT = {
  PROFILE_CHANGE_EVENT: 'PROFILE_CHANGE_EVENT',
  SUBSCRIBE_NOTIFICATION_EVENT: 'SUBSCRIBE_NOTIFICATION_EVENT',
  UN_SUBSCRIBE_NOTIFICATION_EVENT: 'UN_SUBSCRIBE_NOTIFICATION_EVENT',
};

export const LIST_ICON = [
  {
    key: 1,
    src: Assets.icons.IcoChromium,
    name: 'Chromium',
    workingPlatform: 'other',
  },
  {
    key: 2,
    src: Assets.icons.IcoChrome,
    name: 'Google Chrome',
    workingPlatform: 'other',
  },
  {
    key: 3,
    src: Assets.icons.IcoApple,
    name: 'Apple',
    workingPlatform: 'other',
  },
  {
    key: 4,
    src: Assets.icons.IcoAndroid,
    name: 'Android',
    workingPlatform: 'other',
  },

  {
    key: 5,
    src: Assets.icons.IcoCredit,
    name: 'Credit',
    workingPlatform: 'other',
  },
  {
    key: 6,
    src: Assets.icons.IcoFacebook,
    name: 'Facebook',
    workingPlatform: 'facebook',
  },
  {
    key: 7,
    src: Assets.icons.IcoFinder,
    name: 'Finder',
    workingPlatform: 'other',
  },
  {
    key: 8,
    src: Assets.icons.IcoGmail,
    name: 'Gmail',
    workingPlatform: 'gmail',
  },
  {
    key: 9,
    src: Assets.icons.IcoGoogle,
    name: 'Google',
    workingPlatform: 'other',
  },
  {
    key: 10,
    src: Assets.icons.IcoGoogleSheetV2,
    name: 'Google Sheet',
    workingPlatform: 'other',
  },
  {
    key: 11,
    src: Assets.icons.IcoInternetExplorer,
    name: 'Internet Explorer',
    workingPlatform: 'other',
  },
  {
    key: 12,
    src: Assets.icons.IcoKey,
    name: 'Key',
    workingPlatform: 'other',
  },
  {
    key: 13,
    src: Assets.icons.IcoLinux,
    name: 'Linux',
    workingPlatform: 'other',
  },
  {
    key: 14,
    src: Assets.icons.IcoLive,
    name: 'Live',
    workingPlatform: 'other',
  },
  {
    key: 15,
    src: Assets.icons.IcoMicrosoft,
    name: 'Microsoft',
    workingPlatform: 'other',
  },
  {
    key: 16,
    src: Assets.icons.IcoPhoneCall,
    name: 'Phone',
    workingPlatform: 'other',
  },
  {
    key: 17,
    src: Assets.icons.IcoSafari,
    name: 'Safari',
    workingPlatform: 'other',
  },
  {
    key: 18,
    src: Assets.icons.IcoShield,
    name: 'Shield',
    workingPlatform: 'other',
  },
  {
    key: 19,
    src: Assets.icons.IcoUserV2,
    name: 'User',
    workingPlatform: 'other',
  },
  {
    key: 20,
    src: Assets.icons.IcoWindows,
    name: 'Windows',
    workingPlatform: 'other',
  },
  {
    key: 21,
    src: Assets.icons.IcoAmazon,
    name: 'Amazon',
    workingPlatform: 'amazon',
  },
  {
    key: 22,
    src: Assets.icons.IcoEbay,
    name: 'Ebay',
    workingPlatform: 'ebay',
  },
  {
    key: 23,
    src: Assets.icons.IcoEtsy,
    name: 'Etsy',
    workingPlatform: 'etsy',
  },
  {
    key: 24,
    src: Assets.icons.IcoPaypal,
    name: 'Paypal',
    workingPlatform: 'paypal',
  },
  {
    key: 25,
    src: Assets.icons.IcoShopee,
    name: 'Shopee',
    workingPlatform: 'shopee',
  },
  {
    key: 26,
    src: Assets.icons.IcoTiktok,
    name: 'Tiktok',
    workingPlatform: 'tiktok',
  },
];

export const IP_GEOLOCATION_API_URL = 'https://api.ipgeolocation.io/ipgeo?apiKey=';

export const IP_GEOLOCATION_API_KEY = [
  'af83745cbd78449797c6d20a9c32293a',
  '063b3b23c48641528d6117cc3f13bb7c',
  '3f0f77bc81784c959935d8fbc752196e',
  '12e081d443c04c338d7d112a63cd223b',
];

export const TABLE_SETTING_FIELD = 'tableSettings';

export const SOCKET_EVENTS = {
  pingClient: 'pingClient',
  connectSuccess: 'connectSuccess',
};

export const RECEIVED_SOCKET_EVENTS = {
  pingClient: 'ping.pingClient',
  connectSuccess: 'connectSuccess',
  browserDownloadingBrowser: 'browser.downloadingBrowser',
  browserBrowserExtracted: 'browser.browserExtracted',
  browserExtractingBrowser: 'browser.extractingBrowser',
  browserDownloadError: 'browser.downloadError',
  updateProfile: 'profile.updateStatus',
};
