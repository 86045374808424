import MuiPopover from '@mui/material/Popover';
import React, { useEffect, useState } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { Flex, Image } from '@kikisoftware/uikit';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

interface FilterDropdownProps<T> {
  locales?: any;
  onChange?: (value: ValueProps) => void;
  popover: React.ComponentType<T & { value: string; onSelectValue: (newVal: any) => void }> | null;
  initialValue?: ValueProps;
  resetValue?: ValueProps;
  onClear?: () => void;
  resetValueOnFilterStateChange?: (
    initialValue: ValueProps,
    onReset: React.Dispatch<React.SetStateAction<ValueProps>>,
  ) => void;
  disabled?: boolean;
  dropdownStyle?: SxProps<Theme>;
  labelStyle?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  popoverWidth?: number;
  preventEventOnPopoverOpen?: boolean;
  className?: string;
  [X: string]: any;
}

export interface ValueProps {
  label: string;
  value?: string;
  icon?: React.ReactNode | string;
}

const DropdownStyleRoot = styled('button')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.border.neutral,
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 40,
  padding: '0 6px 0 6px',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  overflow: 'hidden',
}));

const disableStyle: SxProps<Theme> = { pointerEvents: 'none', opacity: 0.4, cursor: 'default' };

function FilterDropdown<T>({
  locales,
  popover: PopoverComponent,
  onChange,
  initialValue,
  onClear,
  resetValueOnFilterStateChange,
  disabled = false,
  dropdownStyle,
  labelStyle,
  sx,
  popoverWidth,
  preventEventOnPopoverOpen = false,
  className,
  resetValue,
  ...props
}: FilterDropdownProps<T>) {
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState<ValueProps>(
    initialValue ?? {
      label: 'Chọn nhóm',
      value: undefined,
      icon: undefined,
    },
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (resetValueOnFilterStateChange && initialValue) {
      resetValueOnFilterStateChange(initialValue, setSelectValue);
    }
  }, [resetValueOnFilterStateChange]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (preventEventOnPopoverOpen) {
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const onSelectValue = (newValue: ValueProps) => {
    setSelectValue(newValue);
    onChange && onChange(newValue);
    handleClose();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectValue(resetValue ?? initialValue ?? { label: 'Chọn nhóm', value: undefined });
    onClear && onClear();
  };

  return (
    // @ts-ignore
    <Box sx={disabled ? { ...disableStyle, ...sx } : sx}>
      <DropdownStyleRoot
        aria-describedby={id}
        sx={{ width: '100%', minWidth: { md: 240 }, maxWidth: { md: 260 }, ...dropdownStyle }}
        onClick={handleClick}
        className={className}
      >
        <Stack direction='row' alignItems='center' gap={1} sx={{ maxWidth: '80%', pl: 1, ...labelStyle }}>
          {typeof selectValue.icon === 'string' ? (
            <Image src={selectValue.icon} sx={{ width: 24, height: 24, borderRadius: '50%' }} />
          ) : (
            selectValue.icon
          )}
          <Typography
            component='p'
            sx={{
              fontSize: 14,
              color: selectValue.value ? 'text.primary' : '#9ca5ae',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {locales ? locales(selectValue.label) : t(selectValue.label)}
          </Typography>
        </Stack>
        <Flex justify='flex-start'>
          {selectValue.value && (
            <IconButton
              sx={{
                visibility: selectValue.value ? 'visible' : 'hidden',
                width: 26,
                height: 26,
              }}
              onClick={handleClear}
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}

          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: 18, color: 'text.subColor4' }} />
        </Flex>
      </DropdownStyleRoot>
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          mt: 1.25,
          '.MuiPaper-root': {
            backgroundImage: 'none',
            bgcolor: 'background.fourthly',
            ...(popoverWidth && {
              width: popoverWidth,
            }),
          },
        }}
      >
        {PopoverComponent && (
          // @ts-ignore
          <PopoverComponent {...props} value={selectValue} onSelectValue={onSelectValue} closePopover={handleClose} />
        )}
      </MuiPopover>
    </Box>
  );
}

export default FilterDropdown;
