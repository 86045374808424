import { Stack } from "@mui/material";
import React from "react";
import { FlexProps } from "./types";

export default function Flex({ children, ...props }: React.PropsWithChildren<FlexProps>) {
  const { direction = "row", justify = "space-between", align = "center", ...others } = props;
  return (
    <Stack {...others} direction={direction} justifyContent={justify} alignItems={align}>
      {children}
    </Stack>
  );
}
