import React from 'react';

export default function usePopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLDivElement | null>(null);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-custom' : undefined;

  const togglePopover = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      openPopover(event);
    }
  };

  return {
    anchorEl,
    openPopover,
    closePopover,
    popoverId: id,
    open,
    togglePopover,
  };
}
