export const DEFAULT_PAGE_SIZE_OPTION = ['5', '10', '20', '100'];
export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 20,
  total: 0,
};

export const DEFAULT_PAGINATION_X = {
  page: 1,
  limit: 20,
  total: 0,
};
