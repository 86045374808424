import http from 'kikifrontend/utils/api-services/http';
import { ResponseApi } from 'kikifrontend/types/api.types';
import { SubmitSurveyData } from 'kikifrontend/features/surveyCustomers/utils/types';
import { WebConfigProps } from 'kikifrontend/types/common.types';
import { BlockedProps, BrowserProfile } from 'kikifrontend/types/user.types';

async function updateUserLanguage(data: any) {
  return http.sso.post('/api/user/settings/language', data);
}
async function getWebConfig() {
  return http.client.get<ResponseApi<WebConfigProps>>('/api/web-config').then((res) => res.data);
}

async function getUserSettings() {
  return http.sso.get<ResponseApi<any>>('/api/user/settings').then((res) => res.data);
}

async function checkSurvey() {
  return http.sso.get<ResponseApi<any>>('/api/user/business').then((res) => res.data);
}

async function settingTable(data: any) {
  return http.sso.post('/api/user-settings/table', data);
}

async function submitSurvey(data: SubmitSurveyData) {
  return http.sso.post('/api/user/business', data);
}
async function updateUserTheme(theme: 'light' | 'dark') {
  return http.sso.post('/api/user/settings/theme', { theme });
}

async function changeUserSettings(userId: string, browserProfile: BrowserProfile) {
  return http.client.put(`/api/user/${userId}/update-settings`, { browserProfile });
}

async function getExternalServicesResources() {
  return http.client.get<ResponseApi<WebConfigProps>>('/api/web-config?fields=resourceCenter').then((res) => res.data);
}

async function checkBlockUser(userId?: string) {
  return http.log.post<ResponseApi<BlockedProps>>(`/api/blocked-user/check`, { userId }).then((res) => res.data);
}

export const userServices = {
  updateUserLanguage,
  getUserSettings,
  checkSurvey,
  settingTable,
  submitSurvey,
  getWebConfig,
  updateUserTheme,
  changeUserSettings,
  getExternalServicesResources,
  checkBlockUser,
};
