import * as yup from 'yup';
import {
  CANVAS_PROTECT_MODE,
  MAX_PROXY_CHANGE_IP_URL_LENGTH,
  WEB_RTC_MODE_OPTIONS,
} from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { TestContextExtended } from 'kikifrontend/types/yup.types';
import { ProfileEditorFormValue } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import { validatePortsString } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/validatePorts';

const isValidCanvas = (canvas?: string) => /^([-]?[0-5]),([-]?[0-5]),([-]?[0-5]),([-]?[0-5])$/.test(canvas ?? '');

const isValidWebglImageNoiseSeed = (noiseSeed?: string) => {
  if (!noiseSeed) return false;
  if (Number(noiseSeed) < 100 || Number(noiseSeed) > 999999999) return false;
  return true;
};

export const profileEditorValidation = yup.object().shape({
  browserType: yup.string(),
  userAgent: yup.string().required('Trường này là bắt buộc'),
  webRTC: yup.object({
    mode: yup.string(),
    localIp: yup.string().when('mode', (mode) => {
      if (mode === WEB_RTC_MODE_OPTIONS[0].value) {
        return yup.string().required('Trường này là bắt buộc');
      }
      return yup.string();
    }),
    isRandomWanIP: yup.boolean(),
    wanIp: yup.string().when(['mode', 'isRandomWanIP'], (mode, isRandomWanIP) => {
      if (mode === WEB_RTC_MODE_OPTIONS[0].value && !isRandomWanIP) {
        return yup.string().required('Trường này là bắt buộc');
      }
      return yup.string();
    }),
  }),
  advancedFingerprint: yup.object().when('browserType', (browserType) => {
    if (browserType === 'gomubrowser') {
      return yup.object({
        protectPortScanAllowedPort: yup.string().test('isValidPortsScan', 'Invalid ports format', validatePortsString),
        autoGeolocation: yup.boolean(),
        protectCanvas: yup.boolean(),
        canvasProtectMode: yup.string(),
        canvasHash: yup.string().when(['protectCanvas', 'canvasProtectMode'], (protectCanvas, canvasProtectMode) => {
          if (protectCanvas && canvasProtectMode === CANVAS_PROTECT_MODE[1].value) {
            return yup
              .string()
              .required('Trường này là bắt buộc')
              .test('validCanvas', 'profile_canvas_hash_validate_error', isValidCanvas);
          }
          return yup.string();
        }),
        webgl: yup.object({
          imageMode: yup.string(),
          imageNoiseSeed: yup.string().when('imageMode', (imageMode) => {
            if (imageMode === 'noise') {
              return yup
                .string()
                .required('Trường này là bắt buộc')
                .test('validCanvas', 'profile_webgl_image_noise_seed_validate_error', isValidWebglImageNoiseSeed);
            }
            return yup.string();
          }),
        }),
        webGPU: yup.object({
          mode: yup.string(),
          vendor: yup.string().when('mode', (mode) => {
            if (mode === 'replacement') {
              return yup.string().required('Trường này là bắt buộc');
            }
            return yup.string();
          }),
          architecture: yup.string().when('mode', (mode) => {
            if (mode === 'replacement') {
              return yup.string().required('Trường này là bắt buộc');
            }
            return yup.string();
          }),
        }),
        screenConfig: yup.object({
          mode: yup.string(),
          dpr: yup.string().when('mode', (mode) => {
            if (mode === 'replacement') {
              return yup.string().required('Trường này là bắt buộc');
            }
            return yup.string();
          }),
          depth: yup.string().when('mode', (mode) => {
            if (mode === 'replacement') {
              return yup.string().required('Trường này là bắt buộc');
            }
            return yup.string();
          }),
          maxTouchPoint: yup.string().when('mode', (mode) => {
            if (mode === 'replacement') {
              return yup.string().required('Trường này là bắt buộc');
            }
            return yup.string();
          }),
        }),
      });
    }

    return yup.object({
      autoGeolocation: yup.boolean(),
      protectCanvas: yup.boolean(),
      canvasProtectMode: yup.string(),
      canvasHash: yup.string().when(['protectCanvas', 'canvasProtectMode'], (protectCanvas, canvasProtectMode) => {
        if (protectCanvas && canvasProtectMode === CANVAS_PROTECT_MODE[1].value) {
          return yup
            .string()
            .required('Trường này là bắt buộc')
            .test('validCanvas', 'profile_canvas_hash_validate_error', isValidCanvas);
        }
        return yup.string();
      }),
    });
  }),
  computerInfomation: yup.object({
    deviceName: yup.string().test('testDeviceName', 'Trường này là bắt buộc', function (deviceName) {
      const { from } = this as TestContextExtended<ProfileEditorFormValue>;
      const { value } = from[from.length - 1];
      return (
        value.browserType === 'kikibrowser' ||
        value.browserType === 'kifox' ||
        (value.browserType === 'gomubrowser' && !!deviceName)
      );
    }),
    macAddress: yup.string().test('testDeviceName', 'Địa chỉ MAC không hợp lệ', function (macAddress) {
      const { from } = this as TestContextExtended<ProfileEditorFormValue>;
      const { value } = from[from.length - 1];
      return (
        value.browserType === 'kikibrowser' ||
        value.browserType === 'kifox' ||
        (value.browserType === 'gomubrowser' &&
          !!macAddress &&
          /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(macAddress))
      );
    }),
  }),
  addFormField: yup.object().shape({
    latitude: yup.string().test('testAutoLocation', 'Trường này là bắt buộc', function (latitude) {
      const { from } = this as TestContextExtended<ProfileEditorFormValue>;
      const { value } = from[from.length - 1];
      return value.advancedFingerprint.autoGeolocation || (!!latitude && !value.advancedFingerprint.autoGeolocation);
    }),
    longitude: yup.string().test('testAutoLocation', 'Trường này là bắt buộc', function (longitude) {
      const { from } = this as TestContextExtended<ProfileEditorFormValue>;
      const { value } = from[from.length - 1];
      return value.advancedFingerprint.autoGeolocation || (!!longitude && !value.advancedFingerprint.autoGeolocation);
    }),
  }),
});

export const proxyValidation = yup.object().shape({
  proxyChangeIpUrl: yup
    .string()
    .max(MAX_PROXY_CHANGE_IP_URL_LENGTH, `max_length_validation`)
    .test('proxyChangeIpUrl', 'URL phải bắt đầu bằng http(s)://', (variable: any) => {
      if (!variable || variable === '') return true;
      return variable.startsWith('http://') || variable.startsWith('https://');
    }),
});
