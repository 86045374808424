import { useRecoilState } from 'recoil';
import { showLivaChatAtom } from 'kikifrontend/utils/recoil/atom';
import React from 'react';

export default function useLiveChat() {
  const [isShowChat, setIsShowChat] = useRecoilState(showLivaChatAtom);

  const toggleLiveChat = (e: React.MouseEvent<HTMLElement>, cb?: () => void) => {
    if (isShowChat) {
      window.$crisp.push(['do', 'chat:hide']);
      setIsShowChat(false);
    } else {
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push(['config', 'container:index', [999999]]);
      setIsShowChat(true);
    }
    cb?.();
  };

  return {
    isShowChat,
    toggleLiveChat,
  };
}
