import http from './api-services/http';

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    http.client.setAuthorization(accessToken);
    http.sso.setAuthorization(accessToken);
    http.ads.setAuthorization(accessToken);
    http.local.setAuthorization(accessToken);
    http.log.setAuthorization(accessToken);
    http.automation.setAuthorization(accessToken);
    http.olympus.setAuthorization(accessToken);
  } else {
    localStorage.removeItem('accessToken');

    http.client.clearAuthorization();
    http.sso.clearAuthorization();
    http.local.clearAuthorization();
    http.ads.clearAuthorization();
    http.log.clearAuthorization();
    http.automation.clearAuthorization();
    http.olympus.clearAuthorization();
  }
};

const setSessionInfo = (data: any) => {
  if (data) {
    localStorage.setItem('userInfo', JSON.stringify(data));
  } else {
    localStorage.removeItem('userInfo');
  }
};

export { setSession, setSessionInfo };
