import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PremiumCTAButtons from './PremiumCTAButtons';

const DEFAULT_IMG_SRC = 'https://i.pinimg.com/originals/94/99/06/94990674b4c965dd8440c66a369acd27.png';

export const PremiumCard = ({
  title,
  content,
  image = DEFAULT_IMG_SRC,
  showCTAButtons = true,
  footer,
  imageWidth = 120,
  contentClassName,
}: {
  title?: string;
  content?: string | ReactNode;
  image?: string;
  showCTAButtons?: boolean;
  footer?: React.ReactNode;
  imageWidth?: number;
  contentClassName?: string;
  isInSecondModal?: boolean;
}) => {
  const { t } = useTranslation();

  const _title = title ?? t('Đây là tính năng trả phí');
  const _content =
    content ?? t('Gói cước hiện tại của bạn không hỗ trợ tính năng này. Vui lòng nâng cấp gói cước để sử dụng');

  return (
    <Box
      className='flex flex-col items-center gap-0 justify-center rounded-lg text-black'
      sx={
        {
          // background: 'linear-gradient(0deg, rgba(75,124,228,1) 0%, rgba(64,101,231,1) 27%, rgba(54,76,213,1) 100%)',
        }
      }
    >
      <img src={image} alt='upgrade' width={imageWidth} />
      <div className={contentClassName}>
        <Typography component='p' variant='h6' className='text-center' sx={{ mb: 1 }}>
          {_title}
        </Typography>
        <Typography variant='body2' className='text-center' component='p' sx={{ maxWidth: 400 }}>
          {_content}
        </Typography>
      </div>
      {showCTAButtons && <PremiumCTAButtons />}
      {footer}
    </Box>
  );
};
function PremiumFeature({ title, content, children }: React.PropsWithChildren<{ title?: string; content?: string }>) {
  const isFree = useRecoilValue(isFreePackageSelector);

  return (
    <>
      <PremiumCard title={title} content={content} />
      <div className={isFree ? 'pointer-events-none opacity-60 mt-5' : ''}>{children}</div>
    </>
  );
}

export default PremiumFeature;
