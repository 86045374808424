import { paymentServices } from 'kikifrontend/_apis_';
import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { useTranslation } from 'react-i18next';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';

export const getRemainingBalanceQuery = () => {
  const { t } = useTranslation();
  return useQuery([queryKeys.paymentCenter.remainingBalance], () => paymentServices.calculateRemainingBalance(), {
    onError: (err) => {
      ErrorUtils.handleCommonError(err, t);
    },
  });
};
