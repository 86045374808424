import { ProfileInfo } from 'kikifrontend/types/profile.types';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { StatisticProps } from 'kikifrontend/types/statistic.type';

export const getTrackParams = (profile: Partial<ProfileInfo>, statistic?: StatisticProps) => ({
  package_name: statistic?.package?.name,
  profile_platform: profile.platform,
  have_proxy: profile.proxy?.isUsedProxy,
  browser_type: profile.browserType,
  profile_os: profile.system?.value,
});

export const useProfileTrackParams = (profile: Partial<ProfileInfo>) => {
  const { statistic } = useRecoilValue(statisticAtom);
  return getTrackParams(profile, statistic);
};
