/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useEffect, useState } from 'react';
// material
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface CollapseDrawer {
  isCollapse: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  onToggleCollapse: () => void;
  onHoverEnter: () => void;
  onHoverLeave: () => void;
  onCollapse: () => void;
  onExpand: () => void;
  currentTab?: string;
  onChangeTab: (tab: string) => void;
}

const initialState = {
  isCollapse: false,
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  onCollapse: () => {},
  onExpand: () => {},
  currentTab: undefined,
  onChangeTab: () => {},
};

const CollapseDrawerContext = createContext<CollapseDrawer>(initialState);

function CollapseDrawerProvider({ children }: React.PropsWithChildren) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });
  const [currentTab, setCurrentTab] = useState<string | undefined>();

  const onChangeTab = (tab: string) => () => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isMobile]);

  const handleCollapse = () => {
    setCollapse({ ...collapse, click: true });
  };

  const handleExpand = () => {
    setCollapse({ ...collapse, click: false });
  };

  const handleToggleCollapse = () => {
    // console.log('zzz')
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    // if (collapse.click) {
    //     setCollapse({ ...collapse, hover: true });
    // }
  };

  const handleHoverLeave = () => {
    // setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        onCollapse: handleCollapse,
        onExpand: handleExpand,
        currentTab: currentTab,
        onChangeTab: onChangeTab,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
