import http from 'kikifrontend/utils/api-services/http';
import { QueryFilterData, ResponseApi, ResponseData } from 'kikifrontend/types/api.types';
import { PaymentMethods } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import { OrderHistoryProps } from 'kikifrontend/features/paymentCenter/types/ordersHistory.types';
import {
  CouponProps,
  PackagePricingProps,
  RemainingBalanceProps,
} from 'kikifrontend/features/paymentCenter/types/package.types';

const getPaymentMethods = async () => {
  return http.client.get<ResponseApi<PaymentMethods>>('/api/payment/methods').then((res) => res.data);
};

const withdrawAffAmount = async () => {
  return http.sso.post('/api/user/withdraw-aff-money');
};

const getPaymentOrdersHistory = async (query: QueryFilterData) => {
  return http.sso
    .post<ResponseApi<ResponseData<OrderHistoryProps[]>>>('/api/orders/filter', query)
    .then((res) => res.data);
};

const getPackagesPrice = async () => {
  return http.client.get<ResponseApi<PackagePricingProps[]>>('/api/package');
};

const couponCheck = async (data: { code: string; package: string; quantity: string }) => {
  return http.sso.post<ResponseApi<CouponProps>>('/api/v1/coupons/check', data);
};

const extendPackage = async (data: { quantity: string; coupon?: string }) => {
  return http.client.post('/api/package/extend', data);
};

const changePackage = async (data: { quantity: string; coupon?: string; package: string }) => {
  return http.client.post('/api/package/change', data);
};

const calculateRemainingBalance = async () => {
  return http.sso.get<ResponseApi<RemainingBalanceProps>>('/api/package/calculate-remaining-money');
};

export const paymentServices = {
  getPaymentMethods,
  withdrawAffAmount,
  getPaymentOrdersHistory,
  getPackagesPrice,
  couponCheck,
  extendPackage,
  changePackage,
  calculateRemainingBalance,
};
