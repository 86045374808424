import { DescribeBusinessProps } from 'kikifrontend/features/surveyCustomers/utils/types';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

const listBusinessDes = ['Marketing Agency', 'E-Commerce', 'Affiliate Marketing', 'Online Service', 'Data Service'];
const DescribeBusiness = ({ describeBusinessData, onSelectDes, value, onOtherChange }: DescribeBusinessProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant='body1Bold'>2. {t('survey_modal_describeBusiness')}</Typography>
      <Typography variant='body2'>{t('survey_modal_describeBusiness_description')}</Typography>
      <Box>
        {listBusinessDes.map((business) => (
          <Box key={business}>
            <FormControlLabel
              label={business}
              control={<Checkbox checked={describeBusinessData.includes(business)} onChange={onSelectDes(business)} />}
            />
          </Box>
        ))}
        <FormControlLabel
          label='Other'
          control={<Checkbox checked={describeBusinessData.includes('Other')} onChange={onSelectDes('Other')} />}
        />
      </Box>
      {describeBusinessData.includes('Other') && (
        <Box mt={2}>
          <TextField
            value={value}
            onChange={onOtherChange('describeBusiness')}
            sx={{ width: 1 }}
            placeholder={t('Nhập dữ liệu')}
          />
        </Box>
      )}
    </Stack>
  );
};

export default DescribeBusiness;
