import Logo from 'kikifrontend/assets/images/kiki-logo.png';
import AffiliateLabel1 from 'kikifrontend/assets/images/affiliate-label1.png';
import AffiliateLabel2 from 'kikifrontend/assets/images/affiliate-label2.png';
import AffiliateLabel3 from 'kikifrontend/assets/images/affiliate-label3.png';
import ZaloIcon from 'kikifrontend/assets/images/img-zalo.png';
import ImgAdsTip from 'kikifrontend/assets/images/img-ads-tip.png';
import ImgUnknownFlag from 'kikifrontend/assets/images/unknown-flag.png';
import ImgProxyIcon from 'kikifrontend/assets/images/proxy-icon.png';
import ImgKikiLogin from 'kikifrontend/assets/images/imgKikiLogin.png';
import ImgExtensionBanner from 'kikifrontend/assets/images/imgExtensionBanner.png';
import imgFeatureAlpha from 'kikifrontend/assets/images/imgFeatureAlpha.png';
import imgFeatureAutomation from 'kikifrontend/assets/images/imgFeatureAutomation.png';
import imgFeatureEnvironment from 'kikifrontend/assets/images/imgFeatureEnvironment.png';
import imgFeatureTeam from 'kikifrontend/assets/images/imgFeatureTeam.png';
import imgFeatureResource from 'kikifrontend/assets/images/imgFeatureResource.png';
import imgFeatureChatGPT from 'kikifrontend/assets/images/imgFeatureChatGPT.png';
import ImgNoPermission from 'kikifrontend/assets/images/imgNoPermission.png';

import Upgrade from 'kikifrontend/assets/icons/upgrade.svg';
import AnalyticAccountLive from 'kikifrontend/assets/icons/analytic-account-live.svg';
import AnalyticUser from 'kikifrontend/assets/icons/analytic-user.svg';
import AnalyticPaid from 'kikifrontend/assets/icons/analytic-paid.svg';
import UserGroup from 'kikifrontend/assets/icons/analytic-user-group.svg';
import AdsLimit from 'kikifrontend/assets/icons/ico-ads-limit.svg';
import Infinity from 'kikifrontend/assets/icons/ico-infinity.svg';
import TotalPayment from 'kikifrontend/assets/icons/ico-total-payment.svg';
import SharedGroup from 'kikifrontend/assets/icons/icon-shared-group.svg';
import Wallet from 'kikifrontend/assets/icons/ico-wallet.svg';
import CSV from 'kikifrontend/assets/icons/ico-csv.svg';
import CloseFullScreen from 'kikifrontend/assets/icons/ico-close-full-screen.svg';
import FacebookIcon from 'kikifrontend/assets/icons/ico-facebook-logo.svg';
import NotificationIcon from 'kikifrontend/assets/icons/ico-notification.svg';
import AdsReports from 'kikifrontend/assets/icons/ico-ads-reports.svg';
import ArrowLeft from 'kikifrontend/assets/icons/ico-arrow-left.svg';
import AffiliateFacebook from 'kikifrontend/assets/icons/ico-affiliate-facebook.svg';
import AffiliateTelegram from 'kikifrontend/assets/icons/ico-affiliate-telegram.svg';
import AffiliateTwitter from 'kikifrontend/assets/icons/ico-affiliate-twitter.svg';
import SurveyMe from 'kikifrontend/assets/icons/ico-survey-me.svg';
import SurveyHome from 'kikifrontend/assets/icons/ico-survey-home.svg';
import SurveyGroup from 'kikifrontend/assets/icons/ico-survey-group.svg';
import YoutubeIcon from 'kikifrontend/assets/icons/ico-youtube.svg';
import Global from 'kikifrontend/assets/icons/ico-global.svg';
import IcoAdsCurrency from 'kikifrontend/assets/icons/ico-ads-report-currency.svg';
import IcoEdit from 'kikifrontend/assets/icons/ico-edit.svg';
import IcoExport from 'kikifrontend/assets/icons/ico-export-data.svg';
import IcoCopy from 'kikifrontend/assets/icons/ico-copy.svg';
import IcoHistoryLogs from 'kikifrontend/assets/icons/ico-history-logs.svg';
import IcoDistribution from 'kikifrontend/assets/icons/ico-distribution.svg';
import IcoImage from 'kikifrontend/assets/icons/ico-image.svg';
import IcoNoImage from 'kikifrontend/assets/icons/ico-no-image.svg';
import IcoNoImage1 from 'kikifrontend/assets/icons/ico-no-image-1.svg';
import IcoPencil from 'kikifrontend/assets/icons/ico-pencil.svg';
import IcoShowMore from 'kikifrontend/assets/icons/ico-show-more.svg';
import IcoTag from 'kikifrontend/assets/icons/ico-tag.svg';
import IcoAdd from 'kikifrontend/assets/icons/ico-add.svg';
import IcoEmptyData from 'kikifrontend/assets/icons/ico-empty-data.svg';
import IcoUser from 'kikifrontend/assets/icons/ico-user.svg';
import IcoCampaign from 'kikifrontend/assets/icons/ico-campaign.svg';
import IcoAdset from 'kikifrontend/assets/icons/ico-adset.svg';
import IcoMap from 'kikifrontend/assets/icons/ico-map.svg';
import IcoMoney100 from 'kikifrontend/assets/icons/ico-money-100.svg';
import IcoCurrency from 'kikifrontend/assets/icons/ico-currency.svg';
import IcoUserGroups from 'kikifrontend/assets/icons/ico-user-groups.svg';
import IcoTimer from 'kikifrontend/assets/icons/ico-timer.svg';
import IcoRunStatus from 'kikifrontend/assets/icons/ico-run-status.svg';
import IcoPersonSearch from 'kikifrontend/assets/icons/ico-person-search.svg';
import IcoPlay from 'kikifrontend/assets/icons/ico-play.svg';
import IcoDoubleArrow from 'kikifrontend/assets/icons/ico-double-arrow.svg';
import IcoAffiliate from 'kikifrontend/assets/icons/icoAffiliate.svg';
import IcoDot from 'kikifrontend/assets/icons/icoDot.svg';
import IcoExtension from 'kikifrontend/assets/icons/icoExtension.svg';
import IcoAppSettings from 'kikifrontend/assets/icons/icoAppSettings.svg';
import IcoPaymentCenter from 'kikifrontend/assets/icons/icoPaymentCenter.svg';
import IcoSupportCenter from 'kikifrontend/assets/icons/icoSupportCenter.svg';
import IcoArrowRight from 'kikifrontend/assets/icons/icoArrowRight.svg';
import IcoActionSuccess from 'kikifrontend/assets/icons/icoActionSuccess.svg';
import IcoFailed from 'kikifrontend/assets/icons/icoFailed.svg';
import IcoFingerprint from 'kikifrontend/assets/icons/icoFingerprint.svg';
import IcoRobot from 'kikifrontend/assets/icons/icoRobot.svg';
import IcoExtensionV2 from 'kikifrontend/assets/icons/icoExtensionV2.svg';
import IcoMember from 'kikifrontend/assets/icons/icoMember.svg';
import IcoAutomation from 'kikifrontend/assets/icons/icoAutomation.svg';
import IcoKiFox from 'kikifrontend/assets/icons/icoKiFox.svg';
import IcoGlobal from 'kikifrontend/assets/icons/icoGlobal.svg';
import IcoCreateProfile from 'kikifrontend/assets/icons/icoCreateProfile.svg';
import IcoGomu from 'kikifrontend/assets/icons/icoGomu.svg';
import IcoProfileStop from 'kikifrontend/assets/icons/icoStop.svg';
import IcoProfileList from 'kikifrontend/assets/icons/icoProfileList.svg';
import IcoDark from 'kikifrontend/assets/icons/icoDark.svg';
import IcoLight from 'kikifrontend/assets/icons/icoLight.svg';
import IcoChat from 'kikifrontend/assets/icons/icoChat.svg';
import IcoWarning from 'kikifrontend/assets/icons/icoWarning.svg';
import IcoPartner from 'kikifrontend/assets/icons/iconPartner.svg';
import Ico2FAProtect from 'kikifrontend/assets/icons/ico2FAProtect.svg';
import IcoCreaditBlock from 'kikifrontend/assets/icons/icoCreaditBlock.svg';
import IcoAccountAlert from 'kikifrontend/assets/icons/icoAccountAlert.svg';
import IcoAppleOutline from 'kikifrontend/assets/icons/icoAppleOutline.svg';
import IcoLinuxOutline from 'kikifrontend/assets/icons/icoLinuxOutline.svg';
import IcoWindowsOutline from 'kikifrontend/assets/icons/icoWindowsOutline.svg';
import IcoApolloCheck from 'kikifrontend/assets/icons/icoApolloCheck.svg';

import IcoAutomationNavigation from 'kikifrontend/assets/icons/automation/icoNavigation.svg';
import IcoAutomationMouseEvent from 'kikifrontend/assets/icons/automation/icoMouseEvent.svg';
import IcoAutomationKeyboard from 'kikifrontend/assets/icons/automation/icoKeyboard.svg';
import IcoAutomationData from 'kikifrontend/assets/icons/automation/icoData.svg';
import IcoAutomationOther from 'kikifrontend/assets/icons/automation/icoOther.svg';
import IcoNewTab from 'kikifrontend/assets/icons/automation/icoNewTab.svg';
import IcoActiveTab from 'kikifrontend/assets/icons/automation/icoActiveTab.svg';
import IcoCloseTab from 'kikifrontend/assets/icons/automation/icoCloseTab.svg';
import IcoOpenURL from 'kikifrontend/assets/icons/automation/icoOpenURL.svg';
import IcoReloadPage from 'kikifrontend/assets/icons/automation/icoReloadPage.svg';
import IcoMouseClick from 'kikifrontend/assets/icons/automation/icoMouseClick.svg';
import IcoMouseMove from 'kikifrontend/assets/icons/automation/icoMouseMove.svg';
import IcoMouseScroll from 'kikifrontend/assets/icons/automation/icoMouseScroll.svg';
import IcoPressKey from 'kikifrontend/assets/icons/automation/icoPressKey.svg';
import IcoTypeText from 'kikifrontend/assets/icons/automation/icoTypeText.svg';
import IcoElementExists from 'kikifrontend/assets/icons/automation/icoElementExists.svg';
import IcoGetURL from 'kikifrontend/assets/icons/automation/icoGetURL.svg';
import IcoGetNote from 'kikifrontend/assets/icons/automation/icoGetNote.svg';
import IcoGetText from 'kikifrontend/assets/icons/automation/icoGetText.svg';
import IcoGetValue from 'kikifrontend/assets/icons/automation/icoGetValue.svg';
import IcoGetAttribute from 'kikifrontend/assets/icons/automation/icoGetAttribute.svg';
import IcoGetProperties from 'kikifrontend/assets/icons/automation/icoGetProperties.svg';
import IcoGetCaptcha from 'kikifrontend/assets/icons/automation/icoGetCaptcha.svg';
import IcoGetOTP from 'kikifrontend/assets/icons/automation/icoGetOTP.svg';
import IcoRandom from 'kikifrontend/assets/icons/automation/icoRandom.svg';
import IcoFileUpload from 'kikifrontend/assets/icons/automation/icoFileUpload.svg';
import IcoReadFile from 'kikifrontend/assets/icons/automation/icoReadFile.svg';
import IcoWriteFile from 'kikifrontend/assets/icons/automation/icoWriteFile.svg';
import IcoHttp from 'kikifrontend/assets/icons/automation/icoHttp.svg';
import IcoSetVariable from 'kikifrontend/assets/icons/automation/icoSetVariable.svg';
import IcoPause from 'kikifrontend/assets/icons/automation/icoPause.svg';
import IcoIf from 'kikifrontend/assets/icons/automation/icoIf.svg';
import IcoEval from 'kikifrontend/assets/icons/automation/icoEval.svg';
import IcoForEach from 'kikifrontend/assets/icons/automation/icoFoeEach.svg';
import IcoWhile from 'kikifrontend/assets/icons/automation/icoWhile.svg';
import IcoFor from 'kikifrontend/assets/icons/automation/icoFor.svg';
import IcoComment from 'kikifrontend/assets/icons/automation/icoComment.svg';
import IcoStop from 'kikifrontend/assets/icons/automation/icoStop.svg';
import IcoStart from 'kikifrontend/assets/icons/automation/icoStart.svg';
import IcoDebug from 'kikifrontend/assets/icons/automation/icoDebug.svg';
import IcoDatabase from 'kikifrontend/assets/icons/automation/icoDatabse.svg';
import IcoGoogleSheet from 'kikifrontend/assets/icons/automation/icoGoogleSheet.svg';
import IcoExpand from 'kikifrontend/assets/icons/automation/icoExpand.svg';
import IcoSave from 'kikifrontend/assets/icons/automation/icoSave.svg';
import IcoCloseSidebar from 'kikifrontend/assets/icons/automation/icoCloseSidebar.svg';
import IcoSwitchFrame from 'kikifrontend/assets/icons/automation/icoSwitchFrame.svg';
import IcoSpinContent from 'kikifrontend/assets/icons/automation/icoSpinContent.svg';
import IcoClipboard from 'kikifrontend/assets/icons/automation/icoClipboard.svg';
import IcoConfirmDialog from 'kikifrontend/assets/icons/automation/icoConfirmDialog.svg';
import IcoPageNavigator from 'kikifrontend/assets/icons/automation/icoPageNavigator.svg';
import IcoCookie from 'kikifrontend/assets/icons/automation/icoCookie.svg';
import IcoGPT from 'kikifrontend/assets/icons/automation/icoGPT.svg';

import IcoChromium from 'kikifrontend/assets/icons/platform/icoChromium.svg';
import IcoAndroid from 'kikifrontend/assets/icons/platform/icoAndroid.svg';
import IcoApple from 'kikifrontend/assets/icons/platform/icoApple.svg';
import IcoChrome from 'kikifrontend/assets/icons/platform/icoChrome.svg';
import IcoCredit from 'kikifrontend/assets/icons/platform/icoCredit.png';
import IcoFacebook from 'kikifrontend/assets/icons/platform/icoFacebook.svg';
import IcoFinder from 'kikifrontend/assets/icons/platform/icoFinder.svg';
import IcoGmail from 'kikifrontend/assets/icons/platform/icoGmail.svg';
import IcoGoogle from 'kikifrontend/assets/icons/platform/icoGoogle.svg';
import IcoGoogleSheetV2 from 'kikifrontend/assets/icons/platform/icoGoogleSheet.svg';
import IcoInternetExplorer from 'kikifrontend/assets/icons/platform/icoInternetExplorer.svg';
import IcoKey from 'kikifrontend/assets/icons/platform/icoKey.png';
import IcoLinux from 'kikifrontend/assets/icons/platform/icoLinux.png';
import IcoMicrosoft from 'kikifrontend/assets/icons/platform/icoMicrosoft.svg';
import IcoLive from 'kikifrontend/assets/icons/platform/icoLive.png';
import IcoPhoneCall from 'kikifrontend/assets/icons/platform/icoPhoneCall.png';
import IcoSafari from 'kikifrontend/assets/icons/platform/icoSafari.png';
import IcoShield from 'kikifrontend/assets/icons/platform/icoShield.png';
import IcoShopee from 'kikifrontend/assets/icons/platform/icoShopee.png';
import IcoTiktok from 'kikifrontend/assets/icons/platform/icoTiktok.png';
import IcoUserV2 from 'kikifrontend/assets/icons/platform/icoUser.png';
import IcoWindows from 'kikifrontend/assets/icons/platform/icoWindows.png';
import IcoPaypal from 'kikifrontend/assets/icons/platform/icoPaypal.png';
import IcoEbay from 'kikifrontend/assets/icons/platform/icoEbay.png';
import IcoAmazon from 'kikifrontend/assets/icons/platform/icoAmazon.png';
import IcoEtsy from 'kikifrontend/assets/icons/platform/icoEtsy.png';
import IcoLinuxV2 from 'kikifrontend/assets/icons/platform/icoLinuxV2.svg';
import IcoWindowsV2 from 'kikifrontend/assets/icons/platform/icoWindowsV2.svg';
import IcoMacOS from 'kikifrontend/assets/icons/platform/icoMacOS.svg';
import IcoYoutube from 'kikifrontend/assets/icons/platform/icoYoutube.png';
import IcoTableActions from 'kikifrontend/assets/icons/icoTableActions.svg';
import IcoUpload from 'kikifrontend/assets/icons/icoUpload.svg';

export class AssetIcons {
  static Upgrade = Upgrade;
  static AnalyticPaid = AnalyticPaid;
  static AnalyticUser = AnalyticUser;
  static AnalyticAccountLive = AnalyticAccountLive;
  static UserGroup = UserGroup;
  static AdsLimit = AdsLimit;
  static Infinity = Infinity;
  static TotalPayment = TotalPayment;
  static SharedGroup = SharedGroup;
  static Wallet = Wallet;
  static CSV = CSV;
  static CloseFullScreen = CloseFullScreen;
  static FacebookIcon = FacebookIcon;
  static NotificationIcon = NotificationIcon;
  static AdsReports = AdsReports;
  static ArrowLeft = ArrowLeft;
  static AffiliateFacebook = AffiliateFacebook;
  static AffiliateTelegram = AffiliateTelegram;
  static AffiliateTwitter = AffiliateTwitter;
  static SurveyMe = SurveyMe;
  static SurveyHome = SurveyHome;
  static SurveyGroup = SurveyGroup;
  static YoutubeIcon = YoutubeIcon;
  static Global = Global;
  static IcoAdsCurrency = IcoAdsCurrency;
  static IcoEdit = IcoEdit;
  static IcoExport = IcoExport;
  static IcoCopy = IcoCopy;
  static IcoHistoryLogs = IcoHistoryLogs;
  static IcoDistribution = IcoDistribution;
  static IcoImage = IcoImage;
  static IcoNoImage = IcoNoImage;
  static IcoPencil = IcoPencil;
  static IcoShowMore = IcoShowMore;
  static IcoTag = IcoTag;
  static IcoAdd = IcoAdd;
  static IcoNoImage1 = IcoNoImage1;
  static IcoEmptyData = IcoEmptyData;
  static IcoUser = IcoUser;
  static IcoCampaign = IcoCampaign;
  static IcoAdset = IcoAdset;
  static IcoMap = IcoMap;
  static IcoMoney100 = IcoMoney100;
  static IcoCurrency = IcoCurrency;
  static IcoUserGroups = IcoUserGroups;
  static IcoTimer = IcoTimer;
  static IcoRunStatus = IcoRunStatus;
  static IcoPersonSearch = IcoPersonSearch;
  static IcoPlay = IcoPlay;
  static IcoDoubleArrow = IcoDoubleArrow;
  static IcoAutomationNavigation = IcoAutomationNavigation;
  static IcoAutomationMouseEvent = IcoAutomationMouseEvent;
  static IcoAutomationKeyboard = IcoAutomationKeyboard;
  static IcoAutomationData = IcoAutomationData;
  static IcoAutomationOther = IcoAutomationOther;
  static IcoNewTab = IcoNewTab;
  static IcoActiveTab = IcoActiveTab;
  static IcoCloseTab = IcoCloseTab;
  static IcoOpenURL = IcoOpenURL;
  static IcoReloadPage = IcoReloadPage;
  static IcoMouseClick = IcoMouseClick;
  static IcoMouseMove = IcoMouseMove;
  static IcoMouseScroll = IcoMouseScroll;
  static IcoPressKey = IcoPressKey;
  static IcoTypeText = IcoTypeText;
  static IcoElementExists = IcoElementExists;
  static IcoGetNote = IcoGetNote;
  static IcoGetURL = IcoGetURL;
  static IcoGetText = IcoGetText;
  static IcoGetValue = IcoGetValue;
  static IcoGetAttribute = IcoGetAttribute;
  static IcoGetProperties = IcoGetProperties;
  static IcoGetCaptcha = IcoGetCaptcha;
  static IcoGetOTP = IcoGetOTP;
  static IcoRandom = IcoRandom;
  static IcoFileUpload = IcoFileUpload;
  static IcoReadFile = IcoReadFile;
  static IcoWriteFile = IcoWriteFile;
  static IcoHttp = IcoHttp;
  static IcoSetVariable = IcoSetVariable;
  static IcoPause = IcoPause;
  static IcoIf = IcoIf;
  static IcoEval = IcoEval;
  static IcoForEach = IcoForEach;
  static IcoWhile = IcoWhile;
  static IcoFor = IcoFor;
  static IcoComment = IcoComment;
  static IcoStop = IcoStop;
  static IcoStart = IcoStart;
  static IcoDebug = IcoDebug;
  static IcoDatabase = IcoDatabase;
  static IcoGoogleSheet = IcoGoogleSheet;
  static IcoExpand = IcoExpand;
  static IcoSave = IcoSave;
  static IcoCloseSidebar = IcoCloseSidebar;
  static IcoSwitchFrame = IcoSwitchFrame;
  static IcoPageNavigator = IcoPageNavigator;
  static IcoConfirmDialog = IcoConfirmDialog;
  static IcoSpinContent = IcoSpinContent;
  static IcoClipboard = IcoClipboard;
  static IcoCookie = IcoCookie;
  static IcoGPT = IcoGPT;
  static IcoAffiliate = IcoAffiliate;
  static IcoMicrosoft = IcoMicrosoft;
  static IcoLinux = IcoLinux;
  static IcoKey = IcoKey;
  static IcoInternetExplorer = IcoInternetExplorer;
  static IcoGoogleSheetV2 = IcoGoogleSheetV2;
  static IcoGoogle = IcoGoogle;
  static IcoGmail = IcoGmail;
  static IcoFinder = IcoFinder;
  static IcoFacebook = IcoFacebook;
  static IcoCredit = IcoCredit;
  static IcoChrome = IcoChrome;
  static IcoApple = IcoApple;
  static IcoAndroid = IcoAndroid;
  static IcoChromium = IcoChromium;
  static IcoDot = IcoDot;
  static IcoLive = IcoLive;
  static IcoPhoneCall = IcoPhoneCall;
  static IcoWindows = IcoWindows;
  static IcoUserV2 = IcoUserV2;
  static IcoTiktok = IcoTiktok;
  static IcoShield = IcoShield;
  static IcoSafari = IcoSafari;
  static IcoShopee = IcoShopee;
  static IcoAmazon = IcoAmazon;
  static IcoEbay = IcoEbay;
  static IcoEtsy = IcoEtsy;
  static IcoPaypal = IcoPaypal;
  static IcoTableActions = IcoTableActions;
  static IcoLinuxV2 = IcoLinuxV2;
  static IcoWindowsV2 = IcoWindowsV2;
  static IcoMacOS = IcoMacOS;
  static IcoUpload = IcoUpload;
  static IcoExtension = IcoExtension;
  static IcoAppSettings = IcoAppSettings;
  static IcoSupportCenter = IcoSupportCenter;
  static IcoPaymentCenter = IcoPaymentCenter;
  static IcoArrowRight = IcoArrowRight;
  static IcoActionSuccess = IcoActionSuccess;
  static IcoFailed = IcoFailed;
  static IcoExtensionV2 = IcoExtensionV2;
  static IcoRobot = IcoRobot;
  static IcoFingerprint = IcoFingerprint;
  static IcoMember = IcoMember;
  static IcoYoutube = IcoYoutube;
  static IcoAutomation = IcoAutomation;
  static IcoLogo = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1690189540035';
  static IcoKiFox = IcoKiFox;
  static IcoGlobal = IcoGlobal;
  static IcoCreateProfile = IcoCreateProfile;
  static IcoGomu = IcoGomu;
  static IcoProfileStop = IcoProfileStop;
  static IcoProfileList = IcoProfileList;
  static IcoLight = IcoLight;
  static IcoDark = IcoDark;
  static IcoWarning = IcoWarning;
  static IcoChat = IcoChat;
  static IcoPartner = IcoPartner;
  static Ico2FA = Ico2FAProtect;
  static IcoAccountAlert = IcoAccountAlert;
  static IcoPaymentAlert = IcoCreaditBlock;
  static IcoAppleOutline = IcoAppleOutline;
  static IcoLinuxOutline = IcoLinuxOutline;
  static IcoWindowsOutline = IcoWindowsOutline;
  static IcoApolloCheck = IcoApolloCheck;
  static IcoJavaScript =
    'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1689005781910';
  static IcoApolloLookup =
    'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688975157989';
  static IcoApolloScanning =
    'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688975158055';
  static IcoApolloLProfile =
    'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688975158034';
  static IcoAuthy = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1697096983724';
  static IcoAuthenticator =
    'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1697096983794';
  static IcoTitle = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1708067647105';
}

class AssetImages {
  static Logo = Logo;
  static AffiliateLabel1 = AffiliateLabel1;
  static AffiliateLabel2 = AffiliateLabel2;
  static AffiliateLabel3 = AffiliateLabel3;
  static ZaloIcon = ZaloIcon;
  static ImgAdsTip = ImgAdsTip;
  static ImgUnknownFlag = ImgUnknownFlag;
  static ImgProxyIcon = ImgProxyIcon;
  static ImgKikiLogin = ImgKikiLogin;
  static ImgExtensionBanner = ImgExtensionBanner;
  static ImgFeatureChatGPT = imgFeatureChatGPT;
  static ImgFeatureResource = imgFeatureResource;
  static ImgFeatureTeam = imgFeatureTeam;
  static ImgFeatureEnvironment = imgFeatureEnvironment;
  static ImgFeatureAutomation = imgFeatureAutomation;
  static ImgFeatureAlpha = imgFeatureAlpha;
  static ImgNoPermission = ImgNoPermission;
  static ImgApolloGod = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688963627782';
}

export default class Assets {
  static icons = AssetIcons;
  static images = AssetImages;
}
