import React, { ReactNode } from 'react';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import Typography from '@mui/material/Typography';
import { classNames } from 'kikifrontend/utils/string';

function TipText({ text, className = '' }: { text: string | ReactNode; className?: string }) {
  return (
    <div className={classNames('flex gap-2 items-center', className)}>
      <TipsAndUpdatesOutlinedIcon color='warning' />
      <Typography variant='body2'>{text}</Typography>
    </div>
  );
}

export default TipText;
