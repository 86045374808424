import { configureChains, createStorage, createConfig } from 'wagmi';
import { bscTestnet, bsc, sepolia, mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { walletConnectProjectId } from 'kikifrontend/utils/configs';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';

export const CHAINS = [bsc, bscTestnet, sepolia, mainnet];

export const { chains, publicClient } = configureChains(CHAINS, [publicProvider()]);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommend',
    wallets: [
      metaMaskWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({ projectId: walletConnectProjectId, chains }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  connectors: connectors,
  autoConnect: true,
  publicClient,
  logger: {
    warn: (message) => console.log({ message }),
  },
  storage: createStorage({ storage: window.localStorage }),
});
