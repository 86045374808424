import createAvatar from 'kikifrontend/utils/createAvatar';
import MAvatar from './MAvatar';

export default function MyAvatar({ email, color, ...other }: { email: string; color?: string; [X: string]: any }) {
  return (
    <MAvatar color={color ?? createAvatar(email)?.color} {...other}>
      {createAvatar(email)?.name}
    </MAvatar>
  );
}
