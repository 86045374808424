import React from 'react';
import { Image } from '@kikisoftware/uikit';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

function NoResults() {
  const { t } = useTranslation();

  return (
    <Box className='w-full h-full flex items-center justify-center flex-col'>
      <Image src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688539789620' />
      <Typography variant='h5' fontFamily='SVN-Gilroy-Medium'>
        {t('No Result Found')}
      </Typography>
      <Typography variant='body1' sx={{ mt: 1 }}>
        {t('No content matched your criteria. Try searching for something else')}
      </Typography>
    </Box>
  );
}

export default NoResults;
