const queryKeys = {
  checkBlocked: 'checkBlocked',
  externalServices: {
    resources: 'resources',
  },
  adsReport: {
    listData: 'ads-report-data',
    adsAccount: 'ads-accounts',
    adsStatistic: 'ads-statistic',
    listTeamMember: 'list-team-members',
    tags: 'all-report-tags',
    infinityTags: 'all-report-tags-with-infinite',
    tagDetail: 'tag-detail',
    adsAvgOldAccount: 'ads-avg-old-account',
    memberAdsAvgOldAccount: 'member-ads-avg-old-account',
    memberAdsAvgAccountStatus: 'member-ads-avg-account-status',
  },
  campaignReport: {
    facebookReportAllAccount: 'facebook-report-all-account',
    accountInfiniteQuery: 'account-infinite-query',
    adsHistoryAccountInfiniteQuery: 'adsHistory-account-infinite-query',
    campaignInfiniteQuery: 'campaign-infinite-query',
    campaignAdsHistoryInfiniteQuery: 'campaign-adsHistory-infinite-query',
    campaignStatistic: 'campaign-statistic',
    adsetsInfiniteQuery: 'adsets-infinite-query',
    adsetsAdsHistoryInfiniteQuery: 'adsets-adsHistory-infinite-query',
    creativeFingerprintHistory: 'creative-fingerprint-history',
    creativeFingerprintChangedToday: 'creative-fingerprint-changed-today',
    facebookAdsHistory: 'facebook-ads-history',
    facebookAdsHistoryById: 'facebook-ads-history-by-id',
  },
  members: {
    listData: 'list-members',
    infiniteQueryListMember: 'infinite-query-list-member',
  },
  affiliate: {
    createdLinks: 'affiliate-links',
    inviteByMe: 'invited-by-me',
    randomAffCode: 'random-affCode',
  },
  historyLogs: {
    loginHistory: 'login-history',
    profileHistory: 'profile-history',
    sessions: 'sessions',
  },
  connectedAccounts: {
    listConnectedAccounts: 'list-connected-accounts',
    listGoogleWorkSheet: 'list-google-work-sheet',
    selectGoogleWorkSheet: 'select-google-work-sheet',
  },
  automation: {
    detailAutomation: 'detailAutomation',
    listAutomations: 'listAutomations',
    previewAutomation: 'previewAutomation',
    listAutomationsStore: 'listAutomationsStore',
    infiniteListAutomations: 'infiniteListAutomations',
    debugLogs: 'debugLogs',
    debugLogsV4: 'debugLogsV4',
    debugLogsTask: 'debugLogsTask',
    getAllTasks: 'getAllTasks',
  },
  profiles: {
    deletedProfiles: 'deletedProfiles',
    getFingerprints: 'getFingerprints',
    profileEditorConfig: 'profileEditorConfig',
    checkProxy: 'checkProxy',
    detailProfile: 'detailProfile',
    listProfile: 'listProfile',
    listSharedProfile: 'listSharedProfile',
    taskProfile: 'taskProfile',
    sharedUsers: 'sharedUsers',
    infiniteMyGroups: 'infiniteMyGroups',
    infiniteSharedGroups: 'infiniteSharedGroups',
    teamProfilesList: 'teamProfilesList',
    groupTask: 'groupTask',
    sharedGroupTask: 'sharedGroupTask',
    infiniteTags: 'infiniteTags',
    listTags: 'listTags',
    detailGroup: 'detailGroup',
  },
  webConfig: 'webConfig',
  checkUserIp: 'checkUserIp',
  extensions: {
    personal: {
      list: 'personalExtensionList',
    },
    publicExtensionData: 'publicExtensionData',
    extensionSetting: 'extensionSetting',
    publicExtensionFilter: 'publicExtensionFilter',
    allExtensions: 'allExtensions',
    detailExtensions: 'detailExtensions',
  },
  plugins: {
    personal: {
      list: 'personalPluginsList',
    },
    publicPluginData: 'publicPluginData',
    publicPluginFilter: 'publicPluginFilter',
    allPlugins: 'allPlugins',
    detailPlugin: 'detailPlugin',
  },
  kikiSystem: 'kikiSystem',
  paymentCenter: {
    paymentMethods: 'paymentMethods',
    ordersHistory: 'ordersHistory',
    packagePrice: 'packagePrice',
    remainingBalance: 'remainingBalance',
  },
  group: {
    listGroup: 'listGroup',
  },
  users: {
    me: 'me',
  },
  pingAutomation: 'pingAutomation',
  systemSettings: 'system-settings',
  externalTranslations: 'externalTranslations',
  auth: {
    generate2FA: 'generate2FA',
  },
  synchronizer: {
    runningProfiles: 'runningProfiles',
    syncStatusQuery: 'syncStatusQuery',
  },
};

export default queryKeys;
