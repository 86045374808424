import React from 'react';
import { Image, Modal, ModalProps } from '@kikisoftware/uikit';
import { SaleContactInfo } from 'kikifrontend/types/user.types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface SaleQrModalProps extends ModalProps {
  sale?: Partial<SaleContactInfo>;
}

function SaleQrModal({ onClose, sale }: SaleQrModalProps) {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} title={t('Quét mã QR')}>
      <div className='flex flex-col items-center'>
        <Image src={sale?.avatar ?? ''} sx={{ width: 40, height: 40, borderRadius: 20 }} />
        <Typography variant='body2Bold' component='p' sx={{ mt: 1, mb: 3 }}>
          {sale?.name}
        </Typography>
        <Image src={sale?.telegram ?? sale?.zalo ?? ''} sx={{ width: 256, height: 256 }} />
        <Button variant='contained' fullWidth sx={{ mt: 3 }} onClick={onClose}>
          OK
        </Button>
      </div>
    </Modal>
  );
}

export default SaleQrModal;
