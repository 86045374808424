import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  ProfileEditorFormValue,
  ProfileProxyConfig,
} from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import {
  getRandomComputerName,
  getRandomMACAddress,
  randomCanvasHash as randomCanvasHashFn,
  randomFromArray,
  randomIPAddress,
  randomWanIP,
} from 'kikifrontend/features/profiles/profileEditor/utils/helpers/random';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { profileApis } from 'kikifrontend/_apis_/profiles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { notifySuccess } from 'kikifrontend/utils/notification';
import appRoutes from 'kikifrontend/routes/path';
import React from 'react';
import { useAuth } from 'kikifrontend/hooks/useAuth';
import { StatisticProps } from 'kikifrontend/types/statistic.type';
import { updateNewFingerprint } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/getNewFingerprint';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loadingNewFingerprintAtom } from 'kikifrontend/features/profiles/profileEditor/atoms/profileEditorForm.atom';
import track from 'kikifrontend/utils/analytics';
import { getTrackParams } from 'kikifrontend/features/profiles/profileList/hooks/useProfileTrackParams';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';

const mutateProfile = async ({
  getSystemStatistic,
  ...data
}: {
  getSystemStatistic: () => Promise<StatisticProps | undefined>;
  profileId?: string;
  config: ProfileEditorFormValue;
}) => {
  const result = await profileApis.mutateAccountProfile(data);
  await getSystemStatistic();
  return Promise.resolve(result);
};

export const useProfileEditorFormActionsListener = (form: UseFormReturn<ProfileEditorFormValue>) => {
  const { t } = useTranslation();
  const { browserType, system } = useWatch({ control: form.control });
  const setLoadingNewFingerprint = useSetRecoilState(loadingNewFingerprintAtom);

  const getNewFingerprint = async () => {
    if (!browserType || !system || !system.value) return;
    try {
      setLoadingNewFingerprint(true);
      await updateNewFingerprint({ browserType, system }, form);
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    } finally {
      setLoadingNewFingerprint(false);
    }
  };

  return {
    getNewFingerprint,
  };
};

export const useProfileEditorFormActions = (form: UseFormReturn<ProfileEditorFormValue>) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useMutation(mutateProfile);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getSystemStatistic } = useAuth();
  const [query] = useSearchParams();
  const { statistic } = useRecoilValue(statisticAtom);

  const randomWebRTCLocalIP = () => {
    form.setValue('webRTC.localIp', randomIPAddress());
  };

  const randomWebRTCWanIP = () => {
    form.setValue('webRTC.wanIp', randomWanIP());
  };

  const randomCanvasHash = () => {
    form.setValue('advancedFingerprint.canvasHash', randomCanvasHashFn());
  };

  const randomHardwareConcurrency = () => {
    form.setValue('computerInfomation.hardwareConcurrency', randomFromArray([1, 2, 4, 8]));
  };

  const randomDeviceMemory = () => {
    form.setValue('computerInfomation.deviceMemory', randomFromArray([1, 2, 4, 8]));
  };

  const randomComputerName = () => {
    form.setValue('computerInfomation.deviceName', getRandomComputerName());
  };
  const randomMacAddress = () => {
    form.setValue('computerInfomation.macAddress', getRandomMACAddress());
  };

  const onSaveProxy = (values: ProfileProxyConfig) => {
    form.setValue('addFormField.proxyTimezone', values.proxyTimezone ?? '');
    form.setValue('proxy', values);
    // if (autoTimezone) {
    //   if (values.isUsedProxy && values.proxyTimezone) {
    //     form.setValue('timezone', values.proxyTimezone);
    //   } else if (userIp) {
    //     form.setValue('timezone', userIp?.timezone);
    //   }
    // }
    //
    // if (isAutoLanguage) {
    //   if (values.isUsedProxy && values.proxyCountry) {
    //     form.setValue('language', getLanguageByCountryFromLocales(values.proxyCountry));
    //   } else if (userIp) {
    //     form.setValue('language', userIp.language);
    //   }
    // }
  };

  const onSubmitForm = async (values: ProfileEditorFormValue, { keepPage }: { keepPage: boolean }) => {
    try {
      await mutateAsync({
        getSystemStatistic,
        profileId: id,
        config: {
          ...values,
          advancedFingerprint: {
            ...values.advancedFingerprint,
            doNotTrack: Number(values.advancedFingerprint.doNotTrack),
            geolocation: [values.addFormField.latitude, values.addFormField.longitude],
            webglRenderer: values.advancedFingerprint.webgl.unmaskedRenderer,
            webglVendor: values.advancedFingerprint.webgl.unmaskedVendor,
            protectWebgl: values.advancedFingerprint.webgl.mode === 'replacement',
          },
        },
      });
      if (!id) {
        track.event('profile_create', getTrackParams(values, statistic));
      }
      notifySuccess(id ? t('Lưu thành công') : t('Tạo thành công'));
      if (!keepPage) {
        navigate(query.get('backUrl') ?? `${appRoutes.profiles.fullPath}`);
      } else {
        form.reset(values);
      }
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    }
  };

  const preventEnterOnSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      // e.preventDefault();
    }
  };

  return {
    randomWebRTCLocalIP,
    randomWebRTCWanIP,
    randomCanvasHash,
    randomHardwareConcurrency,
    randomDeviceMemory,
    onSubmitForm,
    onSaveProxy,
    preventEnterOnSubmit,
    randomComputerName,
    randomMacAddress,
    mutateState: {
      isLoading,
    },
  };
};
