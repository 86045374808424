// ----------------------------------------------------------------------

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

// function responsiveFontSizes({ sm, md, lg, xl }: { [X: string]: number }) {
//   return {
//     '@media (min-width:600px)': {
//       fontSize: pxToRem(sm),
//     },
//     '@media (min-width:900px)': {
//       fontSize: pxToRem(md),
//     },
//     '@media (min-width:1200px)': {
//       fontSize: pxToRem(lg),
//     },
//     '@media (min-width:1600px)': {
//       fontSize: pxToRem(xl),
//     },
//   };
// }

const fontWeightRegular = 400;
const fontWeightMedium = 600;
const fontWeightBold = 700;

const FONT_PRIMARY = 'SVN-Gilroy, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const hero = {
  lineHeight: 1,
  fontSize: pxToRem(96),
};

const h1 = {
  lineHeight: 1.2,
  fontSize: pxToRem(64),
};

const h2 = {
  lineHeight: 1.2,
  fontSize: pxToRem(48),
};

const h3 = {
  lineHeight: 1.2,
  fontSize: pxToRem(40),
};

const h4 = {
  lineHeight: 1.2,
  fontSize: pxToRem(32),
};

const h5 = {
  lineHeight: 1.5,
  fontSize: pxToRem(24),
};

const h6 = {
  lineHeight: 1.5,
  fontSize: pxToRem(20),
};

const title = {
  lineHeight: 1.5,
  fontSize: pxToRem(18),
};

const body1 = {
  lineHeight: 1.5,
  fontSize: pxToRem(16),
};

const body2 = {
  lineHeight: 1.5,
  fontSize: pxToRem(14),
};

const caption = {
  lineHeight: 1.5,
  fontSize: pxToRem(12),
};

const generateFont = (fontName: string, rootFont: { lineHeight: number; fontSize: string }, onlyBold?: boolean) => {
  if (onlyBold) {
    return {
      [fontName]: {
        ...rootFont,
        fontWeight: 700,
      },
    };
  }
  return {
    [fontName]: {
      ...rootFont,
      fontWeight: 400,
    },
    [`${fontName}Semibold`]: {
      ...rootFont,
      fontWeight: 600,
    },
    [`${fontName}Bold`]: {
      ...rootFont,
      fontWeight: 700,
    },
  };
};

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  ...generateFont('hero', hero),
  ...generateFont('h1', h1, true),
  ...generateFont('h2', h2, true),
  ...generateFont('h3', h3, true),
  ...generateFont('h4', h4, true),
  ...generateFont('h5', h5, true),
  ...generateFont('h6', h6, true),
  ...generateFont('title', title),
  ...generateFont('caption', caption),
  ...generateFont('body1', body1),
  ...generateFont('body2', body2),
};

export default typography;
