import React from 'react';
import { Flex, Image, Modal, ModalProps } from '@kikisoftware/uikit';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { useAccount } from 'wagmi';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { authServices } from 'kikifrontend/_apis_';
import { currencyFormat, middleTruncate } from 'kikifrontend/utils/format';
import TokenWithAmount from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/TokenWithAmount';
import Assets from 'kikifrontend/assets';

function DepositSuccessfullyModal({ billSummary }: ModalProps) {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { crypto, amount, flexibleFee, fee, fixedFee, tx } = billSummary;

  const handleClose = async () => {
    const newData = {
      serviceCode: 'kikilogin',
      sessionId: localStorage.getItem('authenticated') as string,
    };
    await authServices.ssoLogin(newData);
    window.location.reload();
  };

  return (
    <Modal onClose={handleClose} title={t('Nạp tiền thành công')} width={622}>
      <Box position='relative'>
        <Image src={Assets.icons.IcoActionSuccess} asyncLoading={false} alt='' sx={{ mx: 'auto', width: 1, ml: 2 }} />
        <Box sx={{ width: '100%', mt: 2 }}>
          <Typography component='p' textAlign='center' variant='h5'>
            {t('Cảm ơn')}
          </Typography>
          <Typography component='p' textAlign='center' variant='body2' mt={2.5}>
            {t('Thanh toán của bạn đã hoàn tất')}
          </Typography>
        </Box>
      </Box>
      <Stack spacing={1} px={3} py={1.5} sx={{ border: '1px solid #F1F3F4', borderRadius: 1 }} mt={3}>
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Địa chỉ ví')}
          </Typography>
          <Typography variant='textSm'>{address ? middleTruncate(address) : '--'}</Typography>
        </Flex>
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Số tiền nạp')}
          </Typography>
          <TokenWithAmount
            amount={amount}
            tokenName={crypto.name}
            tokenIcon={crypto.logo}
            iconSize={20}
            token={crypto}
          />
        </Flex>
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Network')}
          </Typography>
          <Typography variant='textSm'>{crypto.network.name}</Typography>
        </Flex>
        <Flex sx={{ alignItems: 'flex-start' }}>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Phí giao dịch')}
          </Typography>
          <Stack alignItems='flex-end'>
            <Typography variant='textSm'>
              {currencyFormat(fee)} {crypto.name}
            </Typography>
            <Typography variant='textSm' color='#A3ADB7' fontStyle='italic'>
              {t('Phí')}: {currencyFormat(fee)} ({fixedFee}$ + {flexibleFee}% {t('Số tiền thanh toán')})
            </Typography>
          </Stack>
        </Flex>
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            Transaction hash
          </Typography>

          <Link
            href={`${crypto.network.explorer}/tx/${tx.transactionHash}`}
            target='_blank'
            rel='noopener noreferrer'
            underline='none'
          >
            <Typography variant='textSm' color='primary.main'>
              {truncate(`${crypto.network.explorer}/tx/${tx.transactionHash}`, { length: 42 })}
            </Typography>
          </Link>
        </Flex>
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Trạng thái')}
          </Typography>
          <Typography variant='textSm' color='success.main'>
            {t('finished')}
          </Typography>
        </Flex>
        <Divider />
        <Flex>
          <Typography variant='textSm' color='#A3ADB7'>
            {t('Tổng thanh toán')}
          </Typography>
          <TokenWithAmount
            amount={
              <Typography variant='textXlBold'>
                {amount} {crypto.name}
              </Typography>
            }
            tokenName={crypto.name}
            tokenIcon={crypto.logo}
          />
        </Flex>
      </Stack>
    </Modal>
  );
}

export default DepositSuccessfullyModal;
