import React from 'react';
import Stack from '@mui/material/Stack';
import { Outlet } from 'react-router-dom';

const bgStyle = {
  background: "url('/static/auth/background.jpeg')",
  backgroundColor: 'rgba(255, 255, 255, 0.86)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
};

function AuthenticationLayout() {
  return (
    <Stack sx={{ ...bgStyle }} width={1} height='100vh' justifyContent='center' alignItems='center'>
      <Outlet />
    </Stack>
  );
}

export default AuthenticationLayout;
