import React, { useState } from 'react';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { getPackagesPriceQuery } from 'kikifrontend/features/paymentCenter/queries/packagesPrice.query';
import { useRecoilValue } from 'recoil';
import { packagesCanChange } from 'kikifrontend/features/paymentCenter/selectors/changePackage.selector';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import Chip from '@mui/material/Chip';
import PackageCard from 'kikifrontend/features/paymentCenter/components/ChangePackage/PackageCard';
import Grid from '@mui/material/Grid';
import SwitchBase from 'components/styled/SwitchBase';

function ChangePackageListPackagesModal({ onClose }: ModalProps) {
  const { t } = useTranslation();
  const { data } = getPackagesPriceQuery();
  const packages = useRecoilValue(packagesCanChange(data?.data));
  const { statistic } = useRecoilValue(statisticAtom);
  const [isYearly, setIsYearly] = useState(true);

  return (
    <Modal
      onClose={onClose}
      title={t('changePackage')}
      width={packages.length === 3 ? 1000 : 1300}
      sx={{ '.MuiDialog-container': { py: 0 } }}
    >
      <Typography variant='h5' textAlign='center' color='text.subColor2'>
        {t('Bạn hiện đang dùng gói')}{' '}
        <Typography variant='h5' color='primary.main' sx={{ display: 'inline' }}>
          {statistic?.package?.name}
        </Typography>
      </Typography>
      <div className='flex justify-center items-center gap-6 mt-6'>
        <Typography variant='body1Semibold' color={isYearly ? 'text.subColor4' : 'text.subColor2'}>
          {t('Hàng tháng')}
        </Typography>
        <SwitchBase checked={isYearly} onChange={(e) => setIsYearly(e.target.checked)} />
        <div className='flex gap-2 items-center'>
          <Typography variant='body1Semibold' color={isYearly ? 'text.subColor2' : 'text.subColor4'}>
            {t('Hàng năm')}
          </Typography>
          <Chip label='-50%' color='success' size='small' sx={{ color: 'white', borderRadius: 0.5 }} />
        </div>
      </div>

      <Grid container spacing={2.5} mt={3}>
        {packages?.map((pkg) => (
          <Grid key={pkg._id} item xs={12} md={6} lg={12 / packages.length}>
            <PackageCard pack={pkg} isYearly={isYearly} />
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
}

export default ChangePackageListPackagesModal;
