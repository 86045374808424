import React, { forwardRef, useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ButtonIconProps } from './types';

const ButtonIcon = forwardRef<any, ButtonIconProps>(
  (
    {
      children,
      title,
      buttonType = 'rectangle',
      sx,
      loading,
      placement = 'bottom',
      disabled,
      debounceTime,
      onClick,
      ...props
    },
    ref,
  ) => {
    const [isAllowClick, setIsAllowClick] = useState(true);

    useEffect(() => {
      let timer: ReturnType<typeof setTimeout>;
      if (debounceTime) {
        timer = setTimeout(() => {
          if (!isAllowClick) {
            setIsAllowClick(true);
          }
        }, debounceTime);
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [isAllowClick, debounceTime]);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (debounceTime) {
        setIsAllowClick(false);
      }

      if (onClick && isAllowClick) {
        onClick(event);
      }
    };

    if (buttonType === 'circle') {
      if (!title) {
        return (
          <IconButton ref={ref} sx={sx} disabled={disabled || loading} {...props} onClick={handleButtonClick}>
            {children}
          </IconButton>
        );
      }

      return (
        <Tooltip arrow placement={placement} title={title}>
          <IconButton
            ref={ref}
            disabled={disabled || loading}
            sx={{ width: 40, height: 40, ...sx }}
            {...props}
            onClick={handleButtonClick}
          >
            {loading ? <CircularProgress style={{ width: 15, height: 15 }} /> : children}{' '}
          </IconButton>
        </Tooltip>
      );
    }
    if (!title) {
      return (
        <IconButton
          {...props}
          disabled={disabled || loading}
          ref={ref}
          sx={{
            width: 40,
            height: 40,
            borderColor: 'divider',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 1,
            ...sx,
          }}
          onClick={handleButtonClick}
        >
          {children}
        </IconButton>
      );
    }

    return (
      <Tooltip arrow placement={placement} title={title}>
        <IconButton
          ref={ref}
          {...props}
          disabled={disabled || loading}
          sx={{
            width: 40,
            height: 40,
            borderColor: 'divider',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 1,
            ...sx,
          }}
          onClick={handleButtonClick}
        >
          {loading ? <CircularProgress style={{ width: 15, height: 15 }} /> : children}
        </IconButton>
      </Tooltip>
    );
  },
);

export default ButtonIcon;
