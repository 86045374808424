import { useSetRecoilState } from 'recoil';
import { webConfigAtom } from 'kikifrontend/utils/recoil/atom';
import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { userServices } from 'kikifrontend/_apis_';
import { saveToLocalStorage } from 'kikifrontend/utils/localstorage';

export default function useWebConfig() {
  const setWebConfig = useSetRecoilState(webConfigAtom);
  return useQuery({
    queryKey: [queryKeys.webConfig],
    queryFn: userServices.getWebConfig,
    onSuccess: (data) => {
      setWebConfig(data);
      saveToLocalStorage('webConfig', data);
    },
  });
}
