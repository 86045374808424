import React from 'react';
import { Image, Modal, ModalProps } from '@kikisoftware/uikit';
import { DescriptionClass, EditorPick } from 'kikifrontend/types/common.types';
import Box from '@mui/material/Box';
import useLocales from 'kikifrontend/hooks/useLocales';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

interface ExternalServiceModalProps extends ModalProps {
  service: EditorPick;
}

export function unEscapeHTML(htmlStr: string) {
  htmlStr = htmlStr.replace(/&lt;/g, '<');
  htmlStr = htmlStr.replace(/&gt;/g, '>');
  htmlStr = htmlStr.replace(/&quot;/g, '"');
  htmlStr = htmlStr.replace(/&#39;/g, "'");
  htmlStr = htmlStr.replace(/&amp;/g, '&');
  return htmlStr;
}

function ExternalServiceDetailModal({ onClose, service }: ExternalServiceModalProps) {
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const description = unEscapeHTML(
    (service.description as DescriptionClass)?.[currentLang.value] ?? (service.description as DescriptionClass).vi,
  );
  const shortDescription =
    (service.shortDescription as DescriptionClass)?.[currentLang.value] ??
    (service.shortDescription as DescriptionClass)?.vi ??
    '--';

  return (
    <Modal onClose={onClose} title={t('Chi tiết')} width={800} DialogContentProps={{ sx: { p: 0 } }} hideHeader>
      <div className='flex gap-10 items-center bg-grey-200 px-8 py-2'>
        <Image src={service.icon} alt={service.productName} sx={{ width: 120, height: 120 }} />
        <div className='grow'>
          <Typography variant='h6'>{service.productName}</Typography>
          <Typography variant='body2' color='text.subColor4'>
            {shortDescription}
          </Typography>
        </div>
        <Box width={120} className='flex flex-col gap-4 shrink-0'>
          <Link href={service.directLink} target='_blank' rel='noreferrer noopener'>
            <Button variant='contained' fullWidth startIcon={<OpenInNewOutlinedIcon />}>
              {t('Truy cập')}
            </Button>
          </Link>
          {/*<Button variant='outlined' startIcon={<Image src={Assets.icons.IcoChat} />} onClick={toggleLiveChat}>*/}
          {/*  Live chat*/}
          {/*</Button>*/}
        </Box>
      </div>
      <div className='p-8'>
        <Box
          className='text-sm'
          sx={{ '& p, h1, h2, h3, h4, h5, h6, ul, li': { mt: 1 }, '& li': { ml: 3 }, '& span': { lineHeight: 1.5 } }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </Modal>
  );
}

export default ExternalServiceDetailModal;
