import React from 'react';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ButtonIcon, Svg } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'kikifrontend/utils/copy';
import Assets from 'kikifrontend/assets';
import useCopy from 'kikifrontend/hooks/useCopy';
import Tooltip from '@mui/material/Tooltip';

function CopyText({
  text,
  sx,
  showButtonCopy = true,
}: {
  text: string;
  sx?: SxProps<Theme>;
  showButtonCopy?: boolean;
}) {
  const { t } = useTranslation();
  const [isCopied, onCopy] = useCopy();

  const copyText = () => {
    copyToClipboard(text);
    onCopy();
  };

  if (!showButtonCopy) {
    return (
      <Tooltip arrow title={isCopied ? t('copied') : t('Click để sao chép')}>
        <Typography sx={{ fontSize: 14, fontWeight: 500, ...sx }} onClick={copyText}>
          {text}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Stack direction='row' spacing={0.5} alignItems='center'>
      <Typography sx={{ fontSize: 14, fontWeight: 500, ...sx }}>{text}</Typography>
      <ButtonIcon
        buttonType='circle'
        title={isCopied ? t('copied') : t('Click để sao chép')}
        onClick={copyText}
        sx={{ minWidth: 26, height: 26, width: 26, p: 0.6 }}
      >
        <Svg src={Assets.icons.IcoCopy} sx={{ bgcolor: 'text.subColor', display: 'block', width: 20, height: 20 }} />
      </ButtonIcon>
    </Stack>
  );
}

export default CopyText;
