import * as Sentry from '@sentry/react';
import { ScopeContext } from '@sentry/types/types/scope';
import { sentryDSN } from 'kikifrontend/utils/configs';

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
  beforeSend: (event, hint) => {
    if ((hint.captureContext as Partial<ScopeContext>)?.tags?.appCrashed) {
      return event;
    }
    return null;
  },
  denyUrls: ['localhost'],
});
