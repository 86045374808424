import React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

function Dot({
  size,
  color,
  sx,
  className,
  ring,
}: {
  size: number;
  color: string;
  sx?: SxProps<Theme>;
  className?: string;
  ring?: boolean;
}) {
  return (
    <Box
      sx={{ width: size, height: size, borderRadius: '50%', bgcolor: color, position: 'relative', ...sx }}
      className={className}
    >
      {ring && (
        <Box
          component='span'
          className='absolute inline-flex h-full w-full rounded-full opacity-30'
          sx={{ bgcolor: color, transform: 'scale(1.75)' }}
        />
      )}
    </Box>
  );
}

export default Dot;
