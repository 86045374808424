import React from 'react';
import { PluginInfo } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import { useTranslation } from 'react-i18next';
import { Flex, Image, Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pluginPeriodAtom } from 'kikifrontend/features/plugins/atoms/pluginPeriod.atom';
import { currencyFormat } from 'kikifrontend/utils/format';
import SimpleBar from 'simplebar-react';
import { pluginCalcSelector } from 'kikifrontend/features/plugins/selectors/pluginCalc.selector';
import Box from '@mui/material/Box';
import DepositAmountModal from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/DepositAmountModal';
import useLocales from 'kikifrontend/hooks/useLocales';
import Divider from '@mui/material/Divider';
import { buyPluginMutation, extendPluginMutation } from 'kikifrontend/features/plugins/mutations/buyPlugin.mutation';
import { isLeaderSelector } from 'kikifrontend/utils/recoil/selectors';

interface Props extends ModalProps {
  plugin: PluginInfo;
  type?: string;
}

function valuetext(value: number) {
  return `${value} tháng`;
}

function BuyPluginPeriod() {
  const { t } = useTranslation();
  const [period, setPeriod] = useRecoilState(pluginPeriodAtom);

  const months = [
    {
      value: 0,
      label: `1 ${t('perMonth')}`,
    },
    {
      value: 1,
      label: `3 ${t('perMonth')}`,
    },
    {
      value: 2,
      label: `6 ${t('perMonth')}`,
    },
    {
      value: 3,
      label: `1 ${t('myAccount_package_year')}`,
    },
    {
      value: 4,
      label: `2 ${t('myAccount_package_year')}`,
    },
  ];

  return (
    <div className='text-center mt-6'>
      <Slider
        value={period}
        onChange={(e, newPeriod) => setPeriod(newPeriod as number)}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay='off'
        marks={months}
        max={4}
        min={0}
        sx={{ maxWidth: 500 }}
      />
    </div>
  );
}

function PluginBuyCardModal({ onClose, plugin, type }: Props) {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const pluginBill = useRecoilValue(pluginCalcSelector({ plugin }));
  const { allLang, currentLang } = useLocales();
  const { mutateAsync, isLoading } = buyPluginMutation(onClose);
  const { mutateAsync: extendMutateAsync, isLoading: extendLoading } = extendPluginMutation(onClose);
  const isLeader = useRecoilValue(isLeaderSelector);

  const depositAmount = () => {
    const isVn = currentLang.value === allLang[1].value;
    openModal(DepositAmountModal, {
      initialAmount: isVn ? pluginBill.amountNeedToDepositInVND : pluginBill.amountNeedToDepositInUSD,
    });
  };

  return (
    <Modal onClose={onClose} title={t('Thanh toán')} width={600}>
      <div className='flex items-center gap-4'>
        {plugin && <Image src={plugin.icon} alt={plugin.name} sx={{ width: 80, height: 80 }} />}
        <div className='flex flex-col grow'>
          <Typography variant='h6' color='text.modalTitle'>
            {plugin.name}
          </Typography>
          {/*<Typography variant='body1' color='text.subColor4'>*/}
          {/*  {plugin.description}*/}
          {/*</Typography>*/}
        </div>
        <Typography variant='h6' color='text.modalTitle' ml={2}>
          {currencyFormat(pluginBill.priceInUSDPerMonth, {
            prefix: '$',
            suffix: pluginBill.isExpires ? `/${t('perMonth')}` : '',
          })}
        </Typography>
      </div>
      {pluginBill.isExpires && <BuyPluginPeriod />}

      {/*<Stack direction='row' gap={1.5} alignItems='center' mt={3}>*/}
      {/*  <TextField*/}
      {/*    placeholder={t('Nhập mã khuyễn mãi')}*/}
      {/*    sx={{*/}
      {/*      mt: 0,*/}
      {/*      width: 1,*/}
      {/*      // bgcolor: 'grey.200',*/}
      {/*      borderRadius: 1,*/}
      {/*      fieldset: {*/}
      {/*        // border: 0,*/}
      {/*      },*/}
      {/*    }}*/}
      {/*    size='small'*/}
      {/*    inputProps={{*/}
      {/*      sx: {*/}
      {/*        color: 'text.subColor4',*/}
      {/*        fontWeight: 700,*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <TrackButton variant='contained' sx={{ whiteSpace: 'nowrap' }}>*/}
      {/*    {t('Áp dụng')}*/}
      {/*  </TrackButton>*/}
      {/*</Stack>*/}
      {/*<CouponDetail*/}
      {/*  bgcolor='rgba(32, 77, 194, 0.08)'*/}
      {/*  icon={<PercentOutlinedIcon sx={{ color: 'primary.main', fontSize: 28 }} />}*/}
      {/*  description='Mã giảm 50% khi mua Plugin từ 3 tháng'*/}
      {/*  code='PLNXHA1'*/}
      {/*  endTime={dateTimeFormatter()}*/}
      {/*  onSelectClick={() => {}}*/}
      {/*  selectedButtonLabel='Bỏ chọn'*/}
      {/*/>*/}

      <SimpleBar style={{ height: 80, marginTop: 16 }}>
        <Flex>
          <Typography variant='body1'>{t('Số dư tài khoản')}</Typography>
          <Typography variant='body1Semibold' color='text.subColor2'>
            {currencyFormat(pluginBill.balanceInUSD, { prefix: '$' })}
          </Typography>
        </Flex>
        <Flex>
          <Typography variant='body1'>{t('Ngày hết hạn')}</Typography>
          <Typography variant='body1Semibold' color='text.subColor2'>
            {pluginBill.isExpires ? pluginBill.nextExpireDate : t('Vĩnh viễn')}
          </Typography>
        </Flex>
        <Flex>
          <Typography variant='body1'>{t('Khuyến mãi')}</Typography>
          <Typography variant='body1Semibold' color='text.subColor2'>
            --
          </Typography>
        </Flex>
      </SimpleBar>
      <Divider />
      <Flex sx={{ mt: 1.5 }}>
        <Typography variant='h5' color='text.subColor2' fontFamily='SVN-Gilroy-Bold'>
          {t('Tổng thanh toán')}
        </Typography>
        <Typography variant='h5' color='primary.main' fontFamily='SVN-Gilroy-Medium'>
          {currencyFormat(pluginBill.priceInUSDByPeriod, { prefix: '$' })}
        </Typography>
      </Flex>
      <Flex>
        <div />
        <Typography variant='body1' color='text.subColor'>
          ≈ {currencyFormat(pluginBill.priceInVNDByPeriod, { prefix: 'đ' })}
        </Typography>
      </Flex>
      <Divider sx={{ mt: 2, mb: 1 }} />
      {!pluginBill.isReadyPay && (
        <Box
          className='w-full text-center py-2 flex gap-2 justify-center'
          mt={2}
          sx={{ bgcolor: 'rgba(239, 70, 111,' + ' 0.1)' }}
        >
          <Typography variant='body1' color='error.light'>
            {t('Số dư không đủ. Vui lòng nạp thêm', {
              amount: currencyFormat(pluginBill.amountNeedToDepositInUSD, { prefix: '$' }),
            })}
            .
          </Typography>
          {isLeader && (
            <Typography
              variant='body1Bold'
              color='error.light'
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={depositAmount}
            >
              {t('Nạp ngay')}
            </Typography>
          )}
        </Box>
      )}
      <LoadingButton
        variant='contained'
        loading={isLoading || extendLoading}
        fullWidth
        sx={{ mt: 2 }}
        disabled={!pluginBill.isReadyPay}
        onClick={() =>
          type === 'renew'
            ? extendMutateAsync({ pluginId: pluginBill._id, period: pluginBill.period })
            : mutateAsync({
                pluginId: pluginBill._id,
                period: pluginBill.period,
              })
        }
        color='success'
      >
        {t('Thanh toán')}
      </LoadingButton>
    </Modal>
  );
}

export default PluginBuyCardModal;
