import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

const TableInput2 = styled(InputBase)(({ theme }) => {
  return {
    // background: "rgb(239, 241, 244)",
    width: '100%',
    height: 40,
    border: '1px solid',
    borderColor: theme.palette.divider,
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: '4px',
    fontSize: 14,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
      background: 'white',
    },
    input: {
      '&::placeholder': {
        fontSize: 14,
        lineHeight: 1,
        // @ts-ignore
        color: theme.palette.text.subColor4,
        opacity: 1,
      },
    },
  };
});
export default TableInput2;
