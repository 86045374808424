import React from 'react';
import { CreationAutocompleteOptions } from 'kikifrontend/components';
import Box from '@mui/material/Box';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const SelectLabel = styled(Box)<{ isSelected: boolean; isFirst: boolean; isLast: boolean }>(
  ({ theme, isSelected, isFirst, isLast }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background: 'transparent',
    color: theme.palette.primary.main,
    flexGrow: 1,
    padding: '0 20px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    fontSize: 14,
    cursor: 'pointer',
    ...(isSelected && {
      background: theme.palette.primary.main,
      color: 'white',
    }),
    ...(isFirst && {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    }),
    ...(isLast && {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    }),
  }),
);

function SelectGroup({
  options,
  onChange,
  value,
  labelStyle,
  sx,
}: {
  options: CreationAutocompleteOptions[];
  onChange: (value: string) => void;
  value: string;
  labelStyle?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation();
  return (
    <Box className='rounded-lg overflow-hidden flex' sx={{ height: 40, ...sx }}>
      {options.map((option, idx) => (
        <SelectLabel
          key={`${option.value}`}
          isSelected={value === option.value}
          isFirst={idx === 0}
          isLast={idx === options.length - 1}
          onClick={() => onChange(option.value as string)}
          sx={{
            ...(option.disabled && {
              pointerEvents: 'none',
              opacity: 0.5,
            }),
            ...labelStyle,
          }}
        >
          {option.icon}
          {t(option.label)}
        </SelectLabel>
      ))}
    </Box>
  );
}

export default SelectGroup;
