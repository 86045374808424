import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { LicenseInfo } from '@mui/x-license-pro';
import { RecoilRoot } from 'recoil';
import 'kikifrontend/utils/sentry';
import 'kikifrontend/locales/i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'simplebar/dist/simplebar.min.css';
import 'simplebar/src/simplebar.css';
import 'swiper/css';
import 'kikifrontend/styles/index.css';
import '@rainbow-me/rainbowkit/styles.css';
import 'reactflow/dist/style.css';
import { Buffer } from 'buffer/';
import { BrowserRouter } from 'react-router-dom';
import { automationVariablesMappingValueAtom } from 'kikifrontend/features/automation/v3/AutomationEditor/atom/variables.atom';
import posthog from 'posthog-js';
import { postHogHost, postHogKey } from 'kikifrontend/utils/configs';
import { removeSensitiveParams } from 'kikifrontend/utils/helpers';

window.global ||= window;
// @ts-ignore
window.Buffer = Buffer;
// window.stream = stream;

LicenseInfo.setLicenseKey(
  '05dbf95d84344aac50970d054817aa0dTz1NVUktMTIzLEU9MTkwOTA1MzkzNTAwMCxTPXByZW1pdW0sTE09cGVycGV0dWFsLEtWPTI=',
);

posthog.init(postHogKey, {
  api_host: postHogHost,
  autocapture: false,
  capture_pageview: false,
  session_recording: {
    maskNetworkRequestFn: (request) => {
      // ... or remove the query string from the URL
      request.url = request.url.split('?')[0];
      return request;
    },
  },
  sanitize_properties: function (properties) {
    if (properties[`$current_url`]) {
      properties[`$current_url`] = removeSensitiveParams(properties[`$current_url`]);
    }
    return properties;
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <BrowserRouter>
      <RecoilRoot
        initializeState={({ set }) => {
          set(automationVariablesMappingValueAtom, []);
        }}
      >
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </HelmetProvider>,
);
