import React, { useRef, useEffect } from 'react';
import usePopover from 'kikifrontend/hooks/usePopover';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Link from '@mui/material/Link';
import { KIKILOGIN_DOCUMENTATION_URL } from 'kikifrontend/utils/constants';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { BubbleContainer, BubbleContent, useModal } from '@kikisoftware/uikit';
import FeedbackModal from 'kikifrontend/features/feedback/components/FeedbackModal';
import useLiveChat from 'kikifrontend/hooks/useLiveChat';

function DraggableBubble() {
  const { t } = useTranslation();
  const bubbleRef = useRef<HTMLDivElement | null>(null);
  const position = useRef({ x: 0, y: 0 });
  const dragging = useRef(false);
  const allowOpenPopup = useRef(true);
  const { anchorEl, openPopover, open, closePopover } = usePopover();
  const { isShowChat, toggleLiveChat } = useLiveChat();
  const { openModal } = useModal();

  const handleMouseDown = () => {
    dragging.current = true;
    if (open) {
      closePopover();
      allowOpenPopup.current = false;
      setTimeout(() => {
        allowOpenPopup.current = true;
      }, 1000);
    }
  };

  const handleMouseUp = () => {
    dragging.current = false;
    requestAnimationFrame(() => {
      allowOpenPopup.current = true;
    });
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (dragging.current) {
      allowOpenPopup.current = false;
      const newPosition = {
        x: event.clientX - 20,
        y: event.clientY - 20,
      };

      // Check if the new position is within the viewport
      if (newPosition.x < 0) {
        newPosition.x = 0;
      } else if (newPosition.x > window.innerWidth - 40) {
        newPosition.x = window.innerWidth - 40;
      }

      if (newPosition.y < 0) {
        newPosition.y = 0;
      } else if (newPosition.y > window.innerHeight - 40) {
        newPosition.y = window.innerHeight - 40;
      }

      position.current = newPosition;
      if (bubbleRef.current) {
        bubbleRef.current.style.top = `${newPosition.y}px`;
        bubbleRef.current.style.left = `${newPosition.x}px`;
      }
    }
  };

  const onOpenPopover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!allowOpenPopup.current) return;
    e.stopPropagation();
    e.preventDefault();
    if (open) {
      closePopover();
    } else {
      openPopover(e);
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const openFeedbackModal = () => {
    openModal(FeedbackModal);
    closePopover();
  };

  return (
    <BubbleContainer
      ref={bubbleRef}
      style={{
        cursor: dragging.current ? 'grabbing' : 'grab',
        zIndex: 1000001 - 2,
      }}
      onMouseDown={handleMouseDown}
      // className='select-none bottom-40 right-5 w-[68px] h-[144px] rounded-full fixed bg-tranparent overflow-hidden'
      // sx={{
      //   transition: 'transform 0.15s ease-in-out',
      //   transform: 'scale(0.8)',
      //   '&:hover': {
      //     transform: 'scale(1)',
      //     '& svg': {
      //       transform: 'rotate(360deg)',
      //       transition: 'transform 1s',
      //     },
      //   },
      // }}
    >
      <BubbleContent className='w-full h-full rounded-full flex justify-center bg-primary-main' onClick={onOpenPopover}>
        <svg
          height='20'
          width='20'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          viewBox='0 0 281.232 281.232'
          xmlSpace='preserve'
          fill='#ffffff'
        >
          <g strokeWidth={0} />
          <g strokeLinecap='round' strokeLinejoin='round' />
          <g>
            <g>
              <path
                style={{ fill: '#ffffff' }}
                d='M231.634,79.976v-0.751C231.634,30.181,192.772,0,137.32,0c-31.987,0-57.415,9.018-77.784,22.98 c-11.841,8.115-12.907,25.906-4.232,37.355l6.326,8.349c8.675,11.444,24.209,12.532,36.784,5.586 c11.46-6.331,23.083-9.758,34-9.758c18.107,0,28.294,7.919,28.294,20.75v0.375c0,16.225-15.469,39.411-59.231,43.181l-1.507,1.697 c-0.832,0.936,0.218,13.212,2.339,27.413l1.741,11.58c2.121,14.201,14.065,25.71,26.668,25.71s23.839-5.406,25.08-12.069 c1.256-6.668,2.268-12.075,2.268-12.075C199.935,160.882,231.634,127.513,231.634,79.976z'
              />
              <path
                style={{ fill: '#ffffff' }}
                d='M118.42,217.095c-14.359,0-25.993,11.64-25.993,25.999v12.14c0,14.359,11.64,25.999,25.993,25.999 h22.322c14.359,0,25.999-11.64,25.999-25.999v-12.14c0-14.359-11.645-25.999-25.999-25.999H118.42z'
              />
            </g>
          </g>
        </svg>
      </BubbleContent>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableAutoFocusItem
        slotProps={{
          paper: {
            sx: {
              mt: -1.75,
              width: 150,
            },
          },
        }}
      >
        <Link href={KIKILOGIN_DOCUMENTATION_URL} target='_blank' rel='noreferrer noopener' underline='none'>
          <MenuItem onClick={closePopover}>
            <div className='flex items-center gap-2'>
              <ArticleOutlinedIcon fontSize='small' sx={{ color: 'text.primary' }} />
              <Typography variant='body2' color='text.primary'>
                {t('kikiAutoDocument')}
              </Typography>
            </div>
          </MenuItem>
        </Link>
        <MenuItem onClick={(e) => toggleLiveChat(e, closePopover)}>
          <div className='flex items-center gap-2'>
            <ChatBubbleOutlineOutlinedIcon fontSize='small' />
            <Typography variant='body2' color='text.primary'>
              {isShowChat ? 'Hide chat' : 'Live chat'}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={openFeedbackModal}>
          <div className='flex items-center gap-2'>
            <RateReviewOutlinedIcon fontSize='small' />
            <Typography variant='body2' color='text.primary'>
              {t('sidebar.feedBack')}
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </BubbleContainer>
  );
}

export default DraggableBubble;
