import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalStyles, KikiThemeProvider, ModalProvider, RtlLayout, SettingsProvider } from '@kikisoftware/uikit';
import { CollapseDrawerProvider } from 'kikifrontend/layouts/dashboard/CollapseDrawerContext';
import LayoutProvider from 'kikifrontend/layouts/dashboard/LayoutProvider';
import AuthProvider from 'kikifrontend/layouts/dashboard/AuthProvider';
import { ToastContainer } from 'react-toastify';
import Routes from 'kikifrontend/routes';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import AppLoadingScreen, { ProgressBarStyle } from 'kikifrontend/components/AppLoadingScreen';
import { INITIAL_REACT_QUERY_CONFIGS } from 'kikifrontend/utils/constants';
import ThemeLocalization from 'kikifrontend/components/ThemeLocalization';
import { ScrollToTop } from 'kikifrontend/components';
import CrashFallback from 'kikifrontend/pages/CrashFallback';
import { appErrorHandler } from 'kikifrontend/utils/helpers';
import { ErrorBoundary } from 'react-error-boundary';
import AppSocketProvider from 'kikifrontend/layouts/dashboard/AppSocketProvider';
import { wagmiConfig } from 'kikifrontend/blockchains/configs/wallet';
import { WagmiConfig } from 'wagmi';
import GoogleAnalytics from 'kikifrontend/components/GoogleAnalytics';
import React from 'react';

export const queryClient = new QueryClient(INITIAL_REACT_QUERY_CONFIGS);

function App() {
  const { isInitialize, statistic } = useRecoilValue(statisticAtom);

  return (
    <ErrorBoundary FallbackComponent={CrashFallback} onError={appErrorHandler(statistic)}>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <AuthProvider>
              <AppSocketProvider>
                <KikiThemeProvider theme='default'>
                  <ThemeLocalization>
                    <RtlLayout>
                      <WagmiConfig config={wagmiConfig}>
                        <ModalProvider>
                          <GlobalStyles />
                          <ProgressBarStyle />
                          <ScrollToTop />
                          <LayoutProvider>
                            <ToastContainer theme='colored' />
                            <GoogleAnalytics />
                            {isInitialize ? <Routes /> : <AppLoadingScreen />}
                          </LayoutProvider>
                        </ModalProvider>
                      </WagmiConfig>
                    </RtlLayout>
                  </ThemeLocalization>
                </KikiThemeProvider>
              </AppSocketProvider>
            </AuthProvider>
          </CollapseDrawerProvider>
        </SettingsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
