/* eslint-disable */
import React, { createContext, useContext, useState } from 'react';
import { HeaderTitleProps, LayoutProviderProps } from 'kikifrontend/layouts/dashboard/types';

const layoutProviderContext = createContext<LayoutProviderProps>({
  showHeader: true,
  currentTabTitle: undefined,
  titles: [],
  onShowHeader: () => {},
  onHideHeader: () => {},
  onSetCurrenTabTitle: () => {},
  onSetHeaderTitles: () => {},
});

export const useLayoutProvider = () => useContext<LayoutProviderProps>(layoutProviderContext);

function LayoutProvider({ children }: React.PropsWithChildren) {
  const [showHeader, setShowHeader] = useState(true);
  const [currentTabTitle, setCurrentTabTitle] = useState<string | undefined>();
  const [titles, setTitles] = useState<HeaderTitleProps[]>([]);

  const onShowHeader = () => setShowHeader(true);
  const onHideHeader = () => setShowHeader(false);

  const onSetCurrenTabTitle = (tabTitle: string) => {
    setCurrentTabTitle(tabTitle);
  };

  const onSetHeaderTitles = (t: HeaderTitleProps[]) => setTitles(t);

  return (
    <layoutProviderContext.Provider
      value={{
        showHeader,
        currentTabTitle,
        onSetCurrenTabTitle,
        onShowHeader,
        onHideHeader,
        titles,
        onSetHeaderTitles,
      }}
    >
      {children}
    </layoutProviderContext.Provider>
  );
}

export default LayoutProvider;
