import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ButtonIcon, TableInput2 } from '@kikisoftware/uikit';
import { DataGridV2StringSearchGroupProps } from './types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import SelectFields from './SelectFields';

export const DataGridV2Input = styled(TextField)(() => ({
  borderRadius: 0,
  '& .MuiInputBase-root': {
    borderRadius: 0,
  },
  '& fieldset': {
    borderLeft: 0,
    borderRight: 0,
  },
}));

export default function DataGridV2StringSearchGroup({
  placeholder,
  icon,
  value,
  onChange,
  filterByFieldConfig,
  autoFocus,
  onClear,
  sx,
}: DataGridV2StringSearchGroupProps) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        width: '100%',
        maxWidth: 300,
        position: 'relative',
        '&:hover, &:focus-within': {
          '& .search-icon': {
            borderColor: 'primary.main',
          },
          '& .MuiInputBase-root': {
            borderColor: 'primary.main',
            borderRightColor: 'divider',
          },
          '& input': {
            borderColor: 'primary.main',
            borderRightColor: 'divider',
          },
          '& .button-filter': {
            borderColor: 'primary.main',
            borderLeft: 'none',
          },
        },
        ...sx,
      }}
    >
      <Stack
        justifyContent='center'
        alignItems='flex-end'
        sx={{
          width: 34,
          minWidth: 34,
          minHeight: 36,
          height: 40,
          borderColor: 'divider',
          color: 'text.primary',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRight: 'none',
        }}
        className='search-icon'
      >
        {icon ?? <SearchIcon sx={{ color: 'text.subColor4', opacity: 0.8, fontSize: 22 }} />}
      </Stack>
      <TableInput2
        placeholder={placeholder}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange as (text: React.ChangeEvent<HTMLInputElement>) => void}
        sx={{
          maxWidth: 300,
          borderRadius: 0,
          borderLeft: 'none',
        }}
        name='string'
        endAdornment={
          <ButtonIcon
            buttonType='circle'
            sx={{
              visibility: value ? 'visible' : 'hidden',
              width: 26,
              height: 26,
            }}
            onClick={onClear}
          >
            <CloseIcon sx={{ fontSize: 20 }} />
          </ButtonIcon>
        }
      />
      <SelectFields {...filterByFieldConfig} />
    </Stack>
  );
}
