import { NewFingerprintProps } from 'kikifrontend/features/profiles/profileEditor/types/fingerprint.types';
import { ProfileEditorFormValue } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import {
  PROFILE_EDITOR_DEFAULT_FORM_VALUE,
  PROFILE_EDITOR_MAPPING_OS_LABEL,
} from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { ResponseData } from 'kikifrontend/types/api.types';
import { GroupProps } from 'kikifrontend/types/group.types';
import { UserIpProps } from 'kikifrontend/types/user.types';
import { StatisticProps } from 'kikifrontend/types/statistic.type';
import { ProfileProps } from 'kikifrontend/types/profile.types';
import { convertProfileInfoToFormValues } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/convertProfileInfoToFormValues';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import {
  getRandomComputerName,
  getRandomMACAddress,
} from 'kikifrontend/features/profiles/profileEditor/utils/helpers/random';
import { toStringObject } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/toStringObject';

export const generateDefaultValueFromFingerprintConfig = ({
  fingerprintConfig,
  group,
  groups,
  userIp,
  statistic,
  profileDetail,
  system,
  browserType,
  name,
}: {
  fingerprintConfig?: NewFingerprintProps;
  group?: string | null;
  groups?: ResponseData<GroupProps[]>;
  userIp?: UserIpProps;
  statistic?: StatisticProps;
  profileDetail?: ProfileProps;
  system: string;
  browserType: BrowserType;
  name?: string;
}): ProfileEditorFormValue => {
  if (profileDetail) {
    return convertProfileInfoToFormValues(profileDetail);
  }
  if (!fingerprintConfig)
    return {
      ...PROFILE_EDITOR_DEFAULT_FORM_VALUE,
      browserType,
    };

  const defaultFormValue = { ...PROFILE_EDITOR_DEFAULT_FORM_VALUE };
  defaultFormValue.name = name ?? `kiki profile ${new Date().getTime()}`;
  defaultFormValue.webRTC.mode = fingerprintConfig.webRTC.status;
  defaultFormValue.webRTC.localIp = fingerprintConfig.webRTC.lanIP;
  defaultFormValue.advancedFingerprint.enableAudioFingerprint = fingerprintConfig.audioFingerprint.status;
  defaultFormValue.advancedFingerprint.protectCanvas = fingerprintConfig.canvasFingerprint.enable;
  defaultFormValue.advancedFingerprint.canvasHash = fingerprintConfig.canvasFingerprint.shift?.join(',');
  defaultFormValue.advancedFingerprint.enableWebRTCStream = fingerprintConfig.webRTCMediaStream;
  defaultFormValue.advancedFingerprint.protectWebgl = fingerprintConfig.webglFingerprint.enable;
  defaultFormValue.advancedFingerprint.webglVendor = fingerprintConfig.webglFingerprint.vendor;
  defaultFormValue.advancedFingerprint.webglRenderer = fingerprintConfig.webglFingerprint.renderer;
  defaultFormValue.advancedFingerprint.disableWebcam = fingerprintConfig.disableWebcam;
  defaultFormValue.advancedFingerprint.doNotTrack = fingerprintConfig.doNotTrack;
  defaultFormValue.advancedFingerprint.protectFontFingerprint = fingerprintConfig.fontFingerprint;
  defaultFormValue.advancedFingerprint.ignoreCertError = fingerprintConfig.ignoreCertError;
  defaultFormValue.advancedFingerprint.muteAudio = fingerprintConfig.muteAudio;
  defaultFormValue.computerInfomation.hardwareConcurrency = fingerprintConfig.hardwareConcurrency;
  defaultFormValue.computerInfomation.deviceMemory = fingerprintConfig.deviceMemory;
  defaultFormValue.computerInfomation.deviceName = getRandomComputerName();
  defaultFormValue.computerInfomation.macAddress = getRandomMACAddress();
  defaultFormValue.otherConfig.disableImages = fingerprintConfig.disableImage;
  defaultFormValue.platform = fingerprintConfig.platform;
  defaultFormValue.resolution = { value: fingerprintConfig.resolution, label: fingerprintConfig.resolution };
  defaultFormValue.advancedFingerprint.enableSpeechInput = fingerprintConfig.speechInput;
  defaultFormValue.userAgent = fingerprintConfig.userAgent;
  defaultFormValue.mediaDeviceFingerprint.videoInputs = fingerprintConfig.mediaDevices.videoInputs;
  defaultFormValue.mediaDeviceFingerprint.audioInputs = fingerprintConfig.mediaDevices.audioInputs;
  defaultFormValue.mediaDeviceFingerprint.audioOutputs = fingerprintConfig.mediaDevices.audioOutputs;
  defaultFormValue.addFormField.numberOfVideoInput = fingerprintConfig.mediaDevices.videoInputs?.length ?? 0;
  defaultFormValue.addFormField.numberOfAudioInput = fingerprintConfig.mediaDevices.audioInputs?.length ?? 0;
  defaultFormValue.addFormField.numberOfAudioOutput = fingerprintConfig.mediaDevices.audioOutputs?.length ?? 0;
  defaultFormValue.group = group ?? groups?.results?.[0]?._id ?? '';
  defaultFormValue.browserType = browserType;
  defaultFormValue.system = {
    value: system,
    label: PROFILE_EDITOR_MAPPING_OS_LABEL[system as keyof typeof PROFILE_EDITOR_MAPPING_OS_LABEL],
  };
  if (userIp) {
    defaultFormValue.timezone = userIp.timezone;
    defaultFormValue.language = userIp.language;
    defaultFormValue.webRTC.wanIp = userIp.query;
    defaultFormValue.addFormField.latitude = userIp.lat;
    defaultFormValue.addFormField.longitude = userIp.lon;
  }

  if (statistic && statistic.userInfo.settings?.browserProfile) {
    defaultFormValue.savingOptions.bookmark = statistic.userInfo.settings.browserProfile.saveBookmark;
    defaultFormValue.savingOptions.history = statistic.userInfo.settings.browserProfile.saveHistory;
    defaultFormValue.savingOptions.password = statistic.userInfo.settings.browserProfile.savePassword;
    defaultFormValue.proxy.proxyBypassList =
      statistic.userInfo.settings.browserProfile.proxyBypassList?.split(';')?.join('\n') ?? '';
  }

  if (browserType === 'kifox') {
    defaultFormValue.savingOptions.bookmark = true;
    defaultFormValue.savingOptions.history = true;
    defaultFormValue.savingOptions.password = true;
  }

  if (browserType === 'kikibrowser') {
    defaultFormValue.advancedFingerprint.webgl.mode = fingerprintConfig.webglFingerprint.enable
      ? 'replacement'
      : 'real';

    defaultFormValue.advancedFingerprint.webgl.unmaskedVendor = fingerprintConfig.webglFingerprint.vendor;
    defaultFormValue.advancedFingerprint.webgl.unmaskedRenderer = fingerprintConfig.webglFingerprint.renderer;
  }

  if (browserType === 'gomubrowser' || browserType === 'kifox') {
    if (fingerprintConfig.webglFingerprint) {
      defaultFormValue.advancedFingerprint.webgl.mode = fingerprintConfig.webglFingerprint.mode;
      defaultFormValue.advancedFingerprint.webgl.extensions = fingerprintConfig.webglFingerprint.extensions;
      defaultFormValue.advancedFingerprint.webgl.params = toStringObject(fingerprintConfig.webglFingerprint.parameters);
      defaultFormValue.advancedFingerprint.webgl.imageMode = fingerprintConfig.webglFingerprint.imageMode;
      defaultFormValue.advancedFingerprint.webgl.imageNoiseSeed = fingerprintConfig.webglFingerprint.imageNoiseSeed;
      defaultFormValue.advancedFingerprint.webgl.unmaskedVendor = fingerprintConfig.webglFingerprint.vendor;
      defaultFormValue.advancedFingerprint.webgl.unmaskedRenderer = fingerprintConfig.webglFingerprint.renderer;
    }

    if (fingerprintConfig.speechVoices) {
      defaultFormValue.advancedFingerprint.speechSynthesis.voices = fingerprintConfig.speechVoices.voices;
      defaultFormValue.advancedFingerprint.speechSynthesis.mode = fingerprintConfig.speechVoices.mode;
    }

    if (fingerprintConfig.plugins) {
      defaultFormValue.advancedFingerprint.plugin.mode = fingerprintConfig.plugins.mode;
      defaultFormValue.advancedFingerprint.plugin.plugins = fingerprintConfig.plugins.pluginList;
    }

    if (fingerprintConfig.webgpu) {
      defaultFormValue.advancedFingerprint.webGPU.mode = fingerprintConfig.webgpu.mode;
      defaultFormValue.advancedFingerprint.webGPU.vendor = fingerprintConfig.webgpu.vendor;
      defaultFormValue.advancedFingerprint.webGPU.architecture = fingerprintConfig.webgpu.architecture;
      defaultFormValue.advancedFingerprint.webGPU.device = fingerprintConfig.webgpu.device ?? '';
      defaultFormValue.advancedFingerprint.webGPU.driver = fingerprintConfig.webgpu.driver ?? '';
      defaultFormValue.advancedFingerprint.webGPU.description = fingerprintConfig.webgpu.description ?? '';
      defaultFormValue.advancedFingerprint.webGPU.limits = toStringObject(fingerprintConfig.webgpu.limits);
    }

    if (fingerprintConfig.clientHints) {
      defaultFormValue.advancedFingerprint.clientHint = fingerprintConfig.clientHints;
      defaultFormValue.advancedFingerprint.clientRect = fingerprintConfig.clientRects;
    }

    if (fingerprintConfig.mimeTypes) {
      defaultFormValue.advancedFingerprint.mimeTypes.types = fingerprintConfig.mimeTypes.types;
      defaultFormValue.advancedFingerprint.mimeTypes.mode = fingerprintConfig.mimeTypes.mode;
    }

    if (fingerprintConfig.screen) {
      defaultFormValue.advancedFingerprint.screenConfig.mode = fingerprintConfig.screen.mode;
      defaultFormValue.advancedFingerprint.screenConfig.dpr = fingerprintConfig.screen.dpr;
      defaultFormValue.advancedFingerprint.screenConfig.depth = fingerprintConfig.screen.depth;
      defaultFormValue.advancedFingerprint.screenConfig.maxTouchPoint = fingerprintConfig.screen.maxTouchPoint;
    }

    if (fingerprintConfig.audioFingerprint) {
      defaultFormValue.advancedFingerprint.audioContext.mode = fingerprintConfig.audioFingerprint.mode;
      defaultFormValue.advancedFingerprint.audioContext.noiseSeed = fingerprintConfig.audioFingerprint.noiseSeed;
    }

    if (fingerprintConfig.battery) {
      defaultFormValue.advancedFingerprint.battery.mode = fingerprintConfig.battery.mode;
    }

    if (fingerprintConfig.network) {
      defaultFormValue.advancedFingerprint.network.mode = fingerprintConfig.network.mode;
    }

    if (fingerprintConfig.fonts) {
      defaultFormValue.advancedFingerprint.font.mode = fingerprintConfig.fonts.mode;
      defaultFormValue.advancedFingerprint.font.fonts = fingerprintConfig.fonts.fontList;
    }

    if (fingerprintConfig.clientHints) {
      defaultFormValue.advancedFingerprint.clientHint = fingerprintConfig.clientHints;
    }
  }

  return defaultFormValue;
};
