import React from 'react';
import { PluginInfo } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Link from '@mui/material/Link';
import { useRecoilValue } from 'recoil';
import { isAppConnectedAtom } from 'kikifrontend/utils/recoil/atom';
import { Modal, useModal } from '@kikisoftware/uikit';
import { FeedbackErrorUI } from 'kikifrontend/features/feedback/components/FeedbackModal';
import { getStringFromLocalStorage } from 'kikifrontend/utils/localstorage';
import { isOpenWithApp } from 'kikifrontend/utils/table';
import PluginBuyCardModal from 'kikifrontend/features/plugins/components/PluginBuyCardModal';
import { buyPluginMutation } from 'kikifrontend/features/plugins/mutations/buyPlugin.mutation';
import { browserStatusSelector } from 'kikifrontend/utils/recoil/selectors';
import DownloadBrowserModal from 'kikifrontend/components/Modals/DownloadBrowserModal';

function InstallButton({ pluginId }: { pluginId: string }) {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = buyPluginMutation();

  return (
    <LoadingButton
      variant='contained'
      startIcon={<FileDownloadOutlinedIcon />}
      loading={isLoading}
      onClick={() => mutateAsync({ pluginId })}
    >
      {t('Install')}
    </LoadingButton>
  );
}

function BuyButton({ plugin }: { plugin: PluginInfo }) {
  const { t } = useTranslation();
  const { openModal, isModalHasOpening } = useModal();

  const openBuyPluginModal = () =>
    openModal(PluginBuyCardModal, { plugin }, undefined, { isSecondModal: isModalHasOpening });

  return (
    <Button variant='contained' startIcon={<ShoppingCartOutlinedIcon />} onClick={openBuyPluginModal}>
      {t('Mua ngay')}
    </Button>
  );
}

function ExtendButton({ plugin }: { plugin: PluginInfo }) {
  const { t } = useTranslation();
  const { openModal, isModalHasOpening } = useModal();

  const openBuyPluginModal = () =>
    openModal(PluginBuyCardModal, { plugin, type: 'renew' }, undefined, { isSecondModal: isModalHasOpening });

  return (
    <Button variant='contained' startIcon={<ShoppingCartOutlinedIcon />} onClick={openBuyPluginModal}>
      {t('extendProxy')}
    </Button>
  );
}

export function UsePluginButton({ plugin }: { plugin: PluginInfo }) {
  const { t } = useTranslation();
  const isAppConnected = useRecoilValue(isAppConnectedAtom);
  const browserStatus = useRecoilValue(browserStatusSelector);
  const { openModal } = useModal();

  if (!isAppConnected || !isOpenWithApp || !browserStatus.isGomu) {
    return (
      <Button
        variant='contained'
        startIcon={<OpenInNewOutlinedIcon />}
        onClick={() =>
          !browserStatus.isGomu
            ? openModal(DownloadBrowserModal, { browserType: 'gomubrowser' })
            : openModal(({ onClose }) => (
                <Modal onClose={onClose} hideHeader width={500}>
                  <FeedbackErrorUI />
                </Modal>
              ))
        }
      >
        {t('Sử dụng')}
      </Button>
    );
  }

  const accessToken = getStringFromLocalStorage('accessToken');
  return (
    <Link href={`kikilogin-open-plugin?sessionId=${accessToken}&pluginId=${plugin._id}`} target='_blank'>
      <Button variant='contained' startIcon={<OpenInNewOutlinedIcon />}>
        {t('Sử dụng')}
      </Button>
    </Link>
  );
}
function PluginUseButton({ plugin, dayRemaining = 0 }: { plugin: PluginInfo; dayRemaining?: number }) {
  // return <BuyButton plugin={plugin} />;
  if (dayRemaining && dayRemaining <= 0) {
    return <ExtendButton plugin={plugin} />;
  }

  if (plugin.isUsed) {
    return <UsePluginButton plugin={plugin} />;
  }

  if (plugin.price !== 0) {
    return <BuyButton plugin={plugin} />;
  }

  return <InstallButton pluginId={plugin._id} />;
}

export default PluginUseButton;
