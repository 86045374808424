import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { PropsWithChildren, useContext } from 'react';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionItemProps } from 'kikifrontend/components/Accordion/types';
import { AccordionContext } from 'kikifrontend/components/Accordion/AccordionContext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function AccordionItem({ header, children, id, sx }: PropsWithChildren<AccordionItemProps>) {
  const { expanded, onChange, disableOnlyOneAccordionExpand } = useContext(AccordionContext);

  const isExpand = expanded === id;
  const expandActions = disableOnlyOneAccordionExpand ? undefined : { expanded: isExpand, onChange: onChange(id) };

  return (
    <Accordion
      {...expandActions}
      elevation={0}
      sx={{
        '.Mui-expanded': { my: 0 },
        border: '1px solid',
        '&.MuiAccordion-root': { my: 0 },
        borderColor: 'border.neutral',
        borderBottom: 0,
        ...sx,
      }}
    >
      <AccordionSummary
        sx={{
          '&.Mui-expanded': { minHeight: 48 },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
            my: 0,
          },
          '.MuiAccordionSummary-content.Mui-expanded': { m: 0 },
        }}
        expandIcon={<KeyboardArrowRightIcon sx={{ color: 'grey.500' }} />}
      >
        <div className='flex justify-between items-center w-full'>
          <Typography variant='body2Bold'>{header}</Typography>
          {/*<KeyboardArrowRightIcon sx={{ color: 'grey.500', transform: !isExpand ? 'rotate(0)' : 'rotate(90deg)' }} />*/}
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid', borderColor: 'border.neutral', bgcolor: 'background.onLayout3' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionItem;
