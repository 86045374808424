import React from 'react';
import { Svg } from '@kikisoftware/uikit';
import appRoutes from 'kikifrontend/routes/path';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import { isDevelopment } from 'kikifrontend/utils/configs';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

export interface SidebarItem {
  title: string;
  path: string;
  icon?: React.ReactNode;
  hideWithMember?: boolean;
  isHidden?: boolean;
  info?: any;
  children?: SidebarItem[];
  onlyDev?: boolean;
}

export interface SidebarItems {
  subheader?: string;
  items: SidebarItem[];
}

const getIcon = (name: string) => (
  <Svg src={`/static/sidebar-icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

export const ICONS = {
  allProfile: getIcon('all-profiles'),
  member: getIcon('member'),
  automation: getIcon('automation'),
  myAccount: getIcon('my-account'),
  affiliate: getIcon('affiliate'),
  support: getIcon('support'),
  farm: getIcon('farm'),
  wallet: getIcon('payment-center'),
  setting: getIcon('setting'),
  plugin: getIcon('plugin'),
  historyLog: getIcon('history-log'),
  extension: getIcon('extension'),
  adsReports: getIcon('ico-ads-reports'),
  apollo: getIcon('icoApollo'),
  sync: getIcon('sync'),
};

// const sidebarConfig = (userInfo?: UserInfoProps): SidebarItems[] => [
const sidebarConfig = (): SidebarItems[] => [
  {
    items: [
      {
        title: 'sidebar.allProfiles',
        path: appRoutes.profiles.fullPath,
        icon: ICONS.allProfile,
      },
      {
        title: 'Apollo Scan',
        path: appRoutes.apolloScan.fullPath,
        icon: ICONS.apollo,
        // hideWithMember: true,
      },
      {
        title: 'sidebar.scripts',
        path: '/dashboard/automation/v3',
        icon: ICONS.automation,
      },
      // {
      //   title: 'sidebar.farm',
      //   path: '/dashboard/device-management',
      //   icon: ICONS.farm,
      //   isHidden: !userInfo?.package?.packageDetail?.resources?.kikiFarm,
      // },
      {
        title: 'Mini Tools',
        path: appRoutes.plugins.fullPath,
        icon: <BuildOutlinedIcon sx={{ width: 20 }} />,
      },

      {
        title: 'Synchronizer',
        path: appRoutes.synchronization.fullPath,
        icon: ICONS.sync,
      },
    ],
  },
  {
    subheader: 'Control center',
    items: [
      {
        title: 'sidebar.teamMember',
        path: '/dashboard/members',
        icon: ICONS.member,
        hideWithMember: true,
      },
      {
        title: 'sidebar.extensions',
        path: appRoutes.extensions.fullPath,
        icon: ICONS.extension,
        // hideWithMember: true,
      },
      {
        title: 'sidebar.affiliate',
        path: appRoutes.affiliate.fullPath,
        icon: ICONS.affiliate,
        hideWithMember: true,
      },
      {
        title: 'Accounts report',
        path: appRoutes.adsReport.fullPath,
        icon: ICONS.adsReports,
        children: [
          {
            title: 'Facebook Report',
            path: appRoutes.adsReport.facebook.fullPath,
          },
        ],
        hideWithMember: true,
        onlyDev: !isDevelopment,
      },
      {
        title: 'Payment center',
        path: appRoutes.paymentCenter.fullPath,
        icon: <PaymentIcon />,
        hideWithMember: true,
      },
      {
        title: 'sidebar.supportCenter',
        path: appRoutes.supportCenter.fullPath,
        icon: <SupportAgentOutlinedIcon />,
      },
      {
        title: 'Cổng thông tin',
        path: appRoutes.externalServices.fullPath,
        icon: <InfoOutlinedIcon />,
      },
    ],
  },
];

export default sidebarConfig;
