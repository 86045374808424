import { FilterFieldProps } from '@kikisoftware/uikit-table-v2';
import { TFunction } from 'i18next';
import { BigNumber } from 'bignumber.js';
import numeral from 'numeral';

export const USDvsVND = 23000;

export const VNDvsUSD = 1 / USDvsVND;

export const VNDToUSD = (vnd: number) => Number(`${vnd / USDvsVND}`);

interface CurrencyFormatOptions {
  prefix?: string;
  suffix?: string;
  digits?: number;
  defaultValue?: string | number;
  rate?: number;
  notation?: 'standard' | 'compact';
  minimumFractionDigits?: number;
}

const defaultCurrencyOptions: CurrencyFormatOptions = {
  prefix: '',
  suffix: '',
  digits: 2,
  rate: 1,
  minimumFractionDigits: 0,
  notation: 'standard',
  defaultValue: '-',
};

export const formatStringLabel = (label: string, { prefix = '', suffix = '' }) =>
  `${prefix ?? ''}${label}${suffix ?? ''}`;

export const currencyFormat = (
  amount?: string | number | null,
  options: CurrencyFormatOptions = defaultCurrencyOptions,
): string => {
  const { prefix, suffix, digits, rate, notation, minimumFractionDigits, defaultValue } = {
    ...defaultCurrencyOptions,
    ...options,
  };
  if (!amount && amount !== 0) return `${defaultValue}` ?? '--';

  if (typeof amount === 'string' && Number.isNaN(Number(amount)) && Number.isNaN(parseFloat(amount + ''))) {
    return amount;
  }
  const convertCurrency =
    new Intl.NumberFormat('en-US', {
      notation,
      maximumFractionDigits: digits ?? 2,
      minimumFractionDigits,
    }).format(parseFloat(amount + '') / (rate as number)) + '';
  return formatStringLabel(convertCurrency, { prefix, suffix });
};

export const formatFilterFieldsLabel = (
  filterFields: FilterFieldProps[],
  i18n: TFunction<'translation', undefined, 'translation'>,
) =>
  filterFields
    .filter((field) => field.accept)
    .map((field) => i18n(field.label))
    .join(',');

export const formatFilterFieldsName = (filterFields: FilterFieldProps[]) =>
  filterFields
    .filter((field) => field.accept)
    .map((field) => field.columnField)
    .join(',');

export const middleTruncate = (string: string, options = { head: 4, last: 4 }): string => {
  return `${string.substring(0, options.head)}...${string.substring(string.length - options.last)}`;
};

export function toShorthandNumber(amount: BigNumber, suffix = '', precision?: number) {
  if (!amount) return '0';
  const sh = new BigNumber(
    amount
      .toString()
      .split('.')
      .map((part, index) => {
        if (index === 0) return part;
        return part.substring(0, (precision ?? 0) + 1);
      })
      .filter((el) => el)
      .join('.'),
  );
  if (precision) {
    return sh.toFixed(precision).concat(suffix);
  }
  return sh.toFixed().concat(suffix);
}

export function formatAsShorthandNumbers(amount: BigNumber, precision?: number) {
  // if (amount.absoluteValue().gte(billion)) {
  //   return toShorthandNumber(amount.dividedBy(billion), 'B', precision)
  // }
  // if (amount.absoluteValue().gte(million)) {
  //   return toShorthandNumber(amount.dividedBy(million), 'M', precision)
  // }
  // if (amount.absoluteValue().gte(thousand)) {
  //   return toShorthandNumber(amount.dividedBy(thousand), 'K', precision)
  // }
  return toShorthandNumber(amount, '', precision);
}

export function formatBalanceInCurrency(number: string | number, digits = 2) {
  const amount = parseFloat(formatAsShorthandNumbers(new BigNumber(number), digits));
  const amountSplit = `${amount}`.split('.');
  const formatPositivePart = `${numeral(amountSplit[0]).format('0,0')}`;
  amountSplit[0] =
    amountSplit[0].indexOf('-') !== -1 && formatPositivePart === '0'
      ? `-${formatPositivePart}`
      : numeral(amountSplit[0]).format('0,0');

  if (amountSplit[1]) {
    return amountSplit.join('.');
  }
  return amountSplit[0];
}

export function formatBalanceInCrypto(number: string | number) {
  return formatBalanceInCurrency(number, 5);
}
