import { useCallback, useEffect, useState } from 'react';

export default function useCopy() {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCopied) {
        setIsCopied(false);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  const onCopy = useCallback(() => {
    setIsCopied(true);
  }, []);

  return [isCopied, onCopy] as const;
}
