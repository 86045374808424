import http from 'kikifrontend/utils/api-services/http';
import { QueryFilterData, ResponseApi, ResponseData } from 'kikifrontend/types/api.types';
import { PluginInfo, PluginProps } from 'kikifrontend/features/plugins/interfaces/plugin.interface';

const getAllStorePlugins = async (query: QueryFilterData) => {
  return http.client
    .post<ResponseApi<ResponseData<PluginInfo[]>>>('/api/plugin/filter-store', query)
    .then((res) => res.data);
};

const getAllMyPlugins = async () => {
  return http.client
    .post<ResponseApi<ResponseData<PluginProps[]>>>('/api/plugin/filter', { page: 1, limit: 300 })
    .then((res) => res.data?.results);
};

const buyPlugin = async ({ pluginId, period }: { pluginId: string; period?: number }) => {
  return http.client.post('/api/plugin/buy', { pluginId, period });
};

const extendPlugin = async ({ pluginId, period }: { pluginId: string; period?: number }) => {
  return http.client.post('/api/plugin/extend', { pluginId, period });
};

const getDetailPlugin = async (pluginId: string) => {
  return http.client.post<ResponseApi<PluginInfo>>('/api/plugin/detail', { pluginId }).then((res) => res.data);
};

export const pluginServices = {
  getAllStorePlugins,
  getAllMyPlugins,
  buyPlugin,
  getDetailPlugin,
  extendPlugin,
};
