import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#FCFCFD',
  200: '#F4F5F6',
  300: '#E6E8EC',
  400: '#B1B5C3',
  500: '#777E90',
  600: '#353945',
  700: '#23262F',
  800: '#141416',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#ecefff',
  dark: '#dadef5',
  darker: '#091A7A',
  contrastText: '#405CFF',
};
const SECONDARY_DARK = {
  lighter: '#D6E4FF',
  light: GREY[500],
  main: GREY[300],
  dark: GREY[400],
  darker: '#091A7A',
  contrastText: GREY[600],
};
const INFO = {
  name: 'blue',
  lighter: '#F4F8FF',
  light: '#6697FF',
  main: '#405CFF',
  dark: '#2847FC',
  darker: '#00137A',
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: 'rgba(45, 211, 31, 0.05)',
  light: '#AAF27F',
  main: '#45B26B',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: '#fff',
};
const WARNING = {
  lighter: alpha('#E5A400', 0.1),
  light: '#FFE16A',
  main: '#E8B314',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: '#fff',
};
const ERROR = {
  lighter: alpha('#EF466F', 0.1),
  light: '#EF466F',
  main: '#FF0000',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  secondaryDark: { ...SECONDARY_DARK },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    secondary: COMMON.secondary,
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
      subColor: GREY[500],
      subColor2: GREY[600],
      subColor3: GREY[400],
      subColor4: GREY[500],
      subColor5: GREY[700],
      modalTitle: INFO.main,
    },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
      layout: '#F4F5F6',
      onLayout: '#ffffff',
      onLayout2: GREY[200],
      onLayout3: GREY[200],
      primary: 'rgb(244, 248, 255)',
      secondary: '#F8F9FF',
      fourthly: '#fff',
      thirty: '#F7F8FC',
      error: '#FDEEEE',
      warning: '#FDF8E8',
      info: 'rgba(36, 116, 255, 0.15)',
      warning2: '#FDFAED',
      danger: '#FDEDF1',
      success: '#ECF7F0',
      grey: '#F4F5F6',
    },
    border: { neutral: 'rgba(145, 158, 171, 0.24)', primary: INFO.main },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    secondary: COMMON.secondaryDark,
    text: {
      primary: GREY[400],
      secondary: GREY[500],
      disabled: GREY[600],
      subColor: GREY[200],
      subColor2: GREY[300],
      subColor3: GREY[300],
      subColor4: GREY[400],
      subColor5: GREY[100],
      modalTitle: GREY[200],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      primary: GREY[600],
      layout: '#161C24',
      onLayout: '#141416',
      thirty: '#141416',
      onLayout2: '#141416',
      onLayout3: '#202022',
      secondary: GREY[700],
      fourthly: 'rgb(35, 38, 47)',
      error: '#141416',
      warning: '#141416',
      info: '#141416',
      warning2: '#2E2B1E',
      danger: '#2E1C20',
      success: '#1A231D',
      grey: '#181D23',
    },
    border: { neutral: 'rgba(145, 158, 171, 0.24)', primary: 'rgba(145, 158, 171, 0.24)' },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
