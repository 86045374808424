import React from 'react';
import { PackageChangeProps } from 'kikifrontend/features/paymentCenter/types/package.types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Stack from '@mui/material/Stack';
import { useModal } from '@kikisoftware/uikit';
import ChangePackageModal from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageModal';
import { TrackButton } from 'kikifrontend/components';

const iconStyle = { fontSize: 16, mr: 0.3, color: 'text.modalTitle' };

function PackageCard({ pack, isYearly }: { pack: PackageChangeProps; isYearly: boolean }) {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const onSelectPackage = () => openModal(ChangePackageModal, { pack });

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'border.neutral',
        height: '100%',
        px: 2.5,
        py: 3,
        position: 'relative',
        ...(pack.isBestSeller && {
          '&::before': {
            content: "'bestseller'",
            position: 'absolute',
            top: 25,
            right: -4,
            zIndex: 2,
            color: 'black',
            fontWeight: 700,
            transform: 'rotate(45deg)',
            fontSize: 16,
            textTransform: 'uppercase',
          },
          '&::after': {
            content: "'bestseller'",
            zIndex: 1,
            overflow: 'hidden',
            fontSize: 10,
            fontWeight: 'bold',
            color: 'black',
            textTransform: 'uppercase',
            textAlign: 'center',
            lineHeight: 20,
            width: 120,
            display: 'block',
            backgroundColor: '#FFC109',
            // background: "linear-gradient(#9BC90D 0%, #79A70A 100%)",
            boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
            position: 'absolute',
            top: 0,
            right: 0,
            clipPath: 'polygon(10% 0, 59% 0, 100% 24%, 100% 50%)',
          },
        }),
      }}
    >
      <Typography
        component='p'
        textAlign='center'
        color='text.subColor2'
        variant='h5'
        textTransform='uppercase'
        fontFamily='SVN-Gilroy-Medium'
        mt={3}
      >
        {pack.name}
      </Typography>
      <Typography component='p' textAlign='center' color='text.subColor4' variant='body2' sx={{ height: 42, mt: 1 }}>
        {pack.description}
      </Typography>

      <div className='flex gap-2 justify-center items-start mt-6'>
        <Typography color='text.modalTitle' variant='h4' fontFamily='SVN-Gilroy-Medium' component='p' sx={{ mt: 3 }}>
          $
        </Typography>
        <div>
          <Typography
            color='text.modalTitle'
            fontSize={96}
            fontWeight={700}
            fontFamily='SVN-Gilroy-Medium'
            lineHeight={1}
          >
            {isYearly
              ? pack.yearlyPricingInUSD < 0
                ? '??'
                : pack.yearlyPricingInUSD
              : pack.monthlyPricingInUSD < 0
              ? '??'
              : pack.monthlyPricingInUSD}
          </Typography>
          <div className='flex gap-2 mt-1'>
            <Typography variant='body1' color='text.subColor4' textTransform='uppercase'>
              {t('perMonthly')}
            </Typography>
            {isYearly && <Chip label='-50%' color='success' size='small' sx={{ color: 'white', borderRadius: 0.5 }} />}
          </div>
        </div>
      </div>

      {pack.monthlyPricingInUSD < 0 || pack.yearlyPricingInUSD < 0 ? (
        <>
          <Typography variant='body2Bold' color='error.light' textAlign='center' component='p' mt={4}>
            {t('Không lấy được thông tin gói cước')}. {t('Vui lòng')}{' '}
            <Typography
              component='span'
              variant='body2Bold'
              color='primary.main'
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => window.location.reload()}
            >
              {t('tải lại trang')}
            </Typography>
          </Typography>
        </>
      ) : (
        <TrackButton
          variant='contained'
          size='large'
          fullWidth
          sx={{ mt: 4 }}
          endIcon={<ArrowForwardIcon />}
          onClick={onSelectPackage}
          disabled={pack.yearlyPricingInUSD < 0 || pack.monthlyPricingInUSD < 0}
          eventName='payment_center_change_package_select_package'
          params={{
            selected_package_name: pack.name,
          }}
        >
          {t('Chọn gói')}
        </TrackButton>
      )}
      <Stack gap={1} mt={4} width={1}>
        <Stack direction='row' gap={1} alignItems='center' mt={1}>
          <CheckCircleOutlineIcon sx={iconStyle} />
          <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
            {t('saveUpTo', { profiles: pack.profileLimit })}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1} alignItems='flex-start'>
          <CheckCircleOutlineIcon sx={iconStyle} />
          <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
            {t('shareProfile')}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1} alignItems='flex-start'>
          <CheckCircleOutlineIcon sx={iconStyle} />
          <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
            {pack.memberLimit > 0 ? t('memberSeat', { member: pack.memberLimit }) : t('Tài khoản cá nhân')}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1} alignItems='flex-start'>
          <CheckCircleOutlineIcon sx={iconStyle} />
          <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
            Kiki Automation 4.0
          </Typography>
        </Stack>
        {pack.useFarm && (
          <Stack direction='row' gap={1} alignItems='flex-start'>
            <CheckCircleOutlineIcon sx={iconStyle} />
            <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
              Kiki Farm
            </Typography>
          </Stack>
        )}
        {pack.useAPIAutomate && (
          <Stack direction='row' gap={1} alignItems='flex-start'>
            <CheckCircleOutlineIcon sx={iconStyle} />
            <Typography component='p' textAlign='left' variant='body2Semibold' color='text.subColor2'>
              {t('API tự động')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}

export default PackageCard;
