import { GridFilterModel } from '@mui/x-data-grid-premium';
import { isString } from 'lodash';

export function flatFilterModel(filterModel: GridFilterModel) {
  return filterModel.items.reduce(
    (acc, cur) => ({ ...acc, [cur.operator]: isString(cur.value) ? cur.value : cur.value[cur.operator] }),
    {},
  );
}

export function convertFilterModelToAdvancedSearch(filterModel: GridFilterModel) {
  let searchField = '';
  return filterModel.items.reduce((acc, cur) => {
    if (!cur.value || isString(cur.value)) {
      return { ...acc, [cur.operator]: cur.value };
    }
    searchField += `${searchField === '' ? '' : ','}${cur.operator}`;
    const keyword = cur.value.keyword;
    return { ...acc, searchField, keyword };
  }, {});
}
