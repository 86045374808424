import React, { useCallback, useEffect, useState } from 'react';
import { ButtonIcon, Image } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import usePopover from 'kikifrontend/hooks/usePopover';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'kikifrontend/components/Notification/atoms/notification.atom';
import { useAppSocket } from 'kikifrontend/layouts/dashboard/AppSocketProvider';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { RECEIVED_SOCKET_EVENTS } from 'kikifrontend/utils/constants';
import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';
import { localServices } from 'kikifrontend/_apis_';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import Badge from '@mui/material/Badge';

export interface DownloadingStatus {
  action:
    | 'browser.downloadingBrowser'
    | 'browser.extractingBrowser'
    | 'browser.browserExtracted'
    | 'browser.downloadError';
  percentage: number;
  browserType: BrowserType;
  browserVersion: string;
}

function Notification() {
  const { t } = useTranslation();
  const { browserEventData } = useAppSocket();
  const [showBadge, setShowBadge] = useState(false);

  const { anchorEl, openPopover, closePopover, open } = usePopover();
  const setNotificationEl = useSetRecoilState(notificationAtom);
  const { mutateAsync } = useMutation(localServices.downloadBrowser);

  const onDownloadBrowser = useCallback(
    (browserEventData: DownloadingStatus) => async () => {
      if (!browserEventData) return;
      try {
        await mutateAsync(browserEventData.browserType);
      } catch (err) {
        ErrorUtils.handleCommonError(err, t);
      }
    },
    [browserEventData],
  );

  useEffect(() => {
    if (browserEventData.gomu?.action || browserEventData.kifox?.action) {
      setShowBadge(true);
    }
  }, [browserEventData]);

  const onClickOpenPopover = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    openPopover(e);
    setShowBadge(false);
  }, []);

  const onClosePopover = useCallback(() => {
    closePopover();
    setShowBadge(false);
  }, []);

  const browserData = Object.values(browserEventData).filter((item) => !!item);

  return (
    <>
      <Badge
        color='error'
        badgeContent={showBadge ? 1 : 0}
        variant='dot'
        sx={{
          '.MuiBadge-badge': { width: 10, height: 10, borderRadius: '50%' },
        }}
      >
        <ButtonIcon
          buttonType='rectangle'
          sx={{ bgcolor: 'background.thirty', borderColor: 'background.thirty' }}
          onClick={onClickOpenPopover}
          ref={(ref) => setNotificationEl(ref)}
        >
          <Image src={Assets.icons.NotificationIcon} sx={{ width: 24 }} />
        </ButtonIcon>
      </Badge>
      <Popover
        id='notification-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiPaper-root': {
            width: 320,
          },
        }}
      >
        <Box sx={{ bgcolor: 'background.layout', p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {browserData.length > 0 ? (
            browserData.map((browserEventData, idx) => (
              <Box key={idx} sx={{ bgcolor: 'background.onLayout', px: 2, py: 1, borderRadius: 1 }}>
                <div className='flex gap-2 items-center'>
                  <Image
                    src={browserEventData.browserType === 'gomubrowser' ? Assets.icons.IcoGomu : Assets.icons.IcoKiFox}
                    sx={{
                      ...(browserEventData.action !== RECEIVED_SOCKET_EVENTS.browserBrowserExtracted && {
                        animation: 'nfLoaderSpin infinite 2000ms linear',
                        transformBox: 'fill-box',

                        '@keyframes nfLoaderSpin': {
                          from: {
                            transform: 'rotate(0deg)',
                          },
                          to: {
                            transform: 'rotate(360deg)',
                          },
                        },
                      }),
                    }}
                  />
                  <Typography variant='body2Bold' color='text.subColor2'>
                    {browserEventData.browserType === 'gomubrowser' ? 'Gomu Browser' : 'KiFox'} v
                    {browserEventData.browserVersion}
                  </Typography>
                </div>
                <div className='flex justify-between items-center mt-2 mb-4'>
                  <Typography variant='body2' color='text.subColor4'>
                    {browserEventData.action === RECEIVED_SOCKET_EVENTS.browserDownloadingBrowser &&
                      t('Đang tải xuống')}
                    {browserEventData.action === RECEIVED_SOCKET_EVENTS.browserExtractingBrowser && t('Đang giải nén')}
                    {browserEventData.action === RECEIVED_SOCKET_EVENTS.browserBrowserExtracted &&
                      t('Cài đặt thành công')}
                  </Typography>
                  {/*{browserEventData?.percentage && (*/}
                  <Typography variant='body2' color='text.subColor4'>
                    {browserEventData?.percentage ?? '0'}%
                  </Typography>
                  {/*)}*/}
                </div>
                {browserEventData.action === RECEIVED_SOCKET_EVENTS.browserDownloadError ? (
                  <div className='flex gap-3 items-center'>
                    <Typography variant='body2' color='error.main'>
                      {t('Có lỗi xảy ra')}
                    </Typography>
                    <Button onClick={onDownloadBrowser(browserEventData)}>{t('Thử lại')}</Button>
                  </div>
                ) : (
                  <LinearProgress
                    variant='determinate'
                    value={browserEventData?.percentage ?? 0}
                    color={
                      browserEventData.action !== RECEIVED_SOCKET_EVENTS.browserBrowserExtracted ? 'primary' : 'success'
                    }
                  />
                )}
              </Box>
            ))
          ) : (
            <Box
              sx={{ bgcolor: 'background.onLayout', px: 2, py: 1, borderRadius: 1 }}
              height={100}
              className='flex justify-center items-center'
            >
              <Typography variant='body1Bold'>{t('Chưa có cập nhật nào')}</Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default Notification;
