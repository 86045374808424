// Định nghĩa phương thức kiểm tra port hợp lệ
const isValidPort = (port: string) => {
  const portNumber = Number(port);
  return Number.isInteger(portNumber) && portNumber >= 0 && portNumber <= 65535;
};

// Định nghĩa phương thức kiểm tra chuỗi các port hợp lệ
export const validatePortsString = (portsString?: string) => {
  if (!portsString) return true;
  // Kiểm tra xem chuỗi có chứa dấu cách hay không
  if (portsString.includes(' ')) {
    return false;
  }

  // Kiểm tra xem chuỗi có đúng pattern không (các số cách nhau bởi dấu phẩy)
  const regex = /^(\d+,)*\d+$/;
  if (!regex.test(portsString)) {
    return false;
  }

  // Tách chuỗi thành mảng các port
  const portsArray = portsString.split(',');

  // Kiểm tra từng port trong mảng
  for (const port of portsArray) {
    if (!isValidPort(port)) {
      return false;
    }
  }

  // Nếu tất cả các port đều hợp lệ, trả về true
  return true;
};
