import React, { useEffect, useRef, useState } from 'react';
import { copyToClipboard } from 'kikifrontend/utils/copy';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { notifySuccess } from 'kikifrontend/utils/notification';
import TextField from '@mui/material/TextField';
import { generateToken } from 'kikifrontend/utils/crypto';
import Button from '@mui/material/Button';

const ModalTwoFA = ({ onClose }: ModalProps) => {
  const { t } = useTranslation();
  const [secret, setSecret] = useState('');
  const [authen, setAuthen] = useState('');
  const ref = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    const refresh = () => {
      if (secret) {
        const token = generateToken(secret);
        if (token) {
          setAuthen(token.token);
        }
      } else {
        setAuthen('');
      }
    };

    ref.current = setInterval(refresh, 1000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [secret]);

  const copyToken = () => {
    if (!authen) return;
    copyToClipboard(authen);
    notifySuccess(t('copySuccessfully'));
  };

  const onCancel = () => {
    setSecret('');
    setAuthen('');
    onClose();
  };

  const onChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value);
    if (event.target.value) {
      const token = generateToken(event.target.value);
      if (token) {
        setAuthen(token.token);
        if (ref.current) {
          clearInterval(ref.current);
        }
      }
    } else {
      setAuthen('');
    }
  };

  return (
    <Modal title={t('twoFactoryModal')} onClose={onClose} width={{ md: 500 }}>
      <div>
        <TextField label={t('secretKey')} value={secret} onChange={onChangeSecret} sx={{ width: 1 }} autoFocus>
          {t('secretKey')}
        </TextField>
      </div>
      <Box mt={2}>
        <Typography variant='textSm'>{t('authenCode')}</Typography>
        {/* <CustomInput className={"authen-code"} value={authen} readOnly />*/}
        <Stack
          direction='row'
          alignItems='center'
          p={2}
          sx={{ bgcolor: 'background.neutral', height: 48, borderRadius: '4px' }}
          mt={1}
        >
          <Typography variant='textSm'>{authen}</Typography>
        </Stack>
      </Box>
      <Stack direction='row' justifyContent='flex-end' spacing={1.25} mt={3}>
        <Button variant='contained' color='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' className={'btn-gen'} onClick={copyToken} disabled={!authen}>
          {t('copyCode')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ModalTwoFA;
