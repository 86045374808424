import React from 'react';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import { useModal } from '@kikisoftware/uikit';
import ChangePackageListPackagesModal from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageListPackagesModal';
import Typography from '@mui/material/Typography';

export function PremiumComponents({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();
  const isFree = useRecoilValue(isFreePackageSelector);

  if (!isFree) return <>{children}</>;

  return (
    <div className='flex items-center gap-2'>
      {children}
      <Chip
        label={t('Trả phí')}
        size='small'
        sx={{
          bgcolor: 'warning.lighter',
          color: 'warning.main',
          fontWeight: 700,
          fontSize: 10,
        }}
      />
    </div>
  );
}

export function PaidNotification() {
  const { t } = useTranslation();
  const isFree = useRecoilValue(isFreePackageSelector);
  const { openModal } = useModal();

  const openChangePackageModal = () => openModal(ChangePackageListPackagesModal);

  if (!isFree) return null;

  return (
    <div className='flex gap-2 items-center'>
      {/*<TipsAndUpdatesOutlinedIcon color='warning' />*/}
      <img
        src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1704860105528'
        alt='discount'
        width={65}
      />
      <Typography variant='body2' component='p' mt={0.5}>
        {t('Vui lòng')}{' '}
        <Typography
          component='span'
          variant='body2Bold'
          color='primary.main'
          sx={{ cursor: 'pointer', '&:hover': { color: 'primary.dark' } }}
          onClick={openChangePackageModal}
        >
          {t('nâng cấp gói cước')}
        </Typography>{' '}
        {t('để sử dụng tính năng này')}
      </Typography>
    </div>
  );
}
