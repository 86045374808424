import { StatisticProps } from 'kikifrontend/types/statistic.type';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';

export const getUserPackageInfo = (statistic?: StatisticProps) => {
  return statistic?.userInfo?.package?.packageDetail;
};

export default function useUserPackage() {
  const { statistic } = useRecoilValue(statisticAtom);

  return getUserPackageInfo(statistic);
}
