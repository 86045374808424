import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Popover } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { Image } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import track from 'kikifrontend/utils/analytics';
import useTrackParams from 'kikifrontend/hooks/useTrackParams';

interface MuiPopConfirmProps {
  onConfirm: (e: React.MouseEvent<HTMLElement>) => void;
  title?: string | React.ReactNode;
  showPop?: boolean;
  severity?: 'warning' | 'success' | 'info';
  eventName?: string;
  params?: Record<string, any>;
}

const MuiPopConfirm = ({
  children,
  onConfirm,
  title,
  showPop,
  severity = 'warning',
  eventName,
  params,
}: React.PropsWithChildren<MuiPopConfirmProps>) => {
  const { t } = useTranslation();
  const elementShowPopconfirm = useRef(null);
  const { params: trackParams } = useTrackParams(params);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(showPop ? elementShowPopconfirm.current : null);
  const handleClickConfirmDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };
  useEffect(() => {
    if (showPop) {
      setAnchorEl(elementShowPopconfirm.current);
    }
  }, [showPop]);

  return (
    <>
      <span aria-describedby='popoverConfirmDeleteUsers' ref={elementShowPopconfirm} onClick={handleClickConfirmDelete}>
        {children}
      </span>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='flex gap-2 items-center p-3 text-sm'>
          {severity === 'success' ? (
            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 14, mx: 0.5 }} />
          ) : severity === 'warning' ? (
            <Image src={Assets.icons.IcoWarning} />
          ) : severity === 'info' ? (
            <InfoOutlinedIcon color='info' sx={{ fontSize: 16, mx: 0.5 }} />
          ) : (
            <ErrorOutlineOutlinedIcon color='error' sx={{ fontSize: 16, mx: 0.5 }} />
          )}
          {title}
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 1, pb: 1 }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
            }}
            className='Mui-Popupconfirm-Cancel-button'
            sx={{ mr: 1.25 }}
            variant='contained'
            color='secondary'
            size='small'
          >
            {t('Hủy bỏ')}
          </Button>
          <Button
            variant='contained'
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
              if (eventName) {
                track.event(eventName, trackParams);
              }
              onConfirm(e);
            }}
            size='small'
            sx={{ mr: 2, minWidth: 'auto', color: 'white' }}
          >
            OK
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default MuiPopConfirm;
