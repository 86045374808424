import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Tooltip as LightTooltip,
} from '@mui/material';
import FilterStringSelectFields from './FilterStringSelectFields';
import { keyBy, truncate } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import { getInitialSearchStringValues } from '../utils';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { AsyncAutocomplete, ButtonIcon } from '@kikisoftware/uikit';
import CloseIcon from '@mui/icons-material/Close';
import { DataGridFilters, FiltersProps, KeyByProps, SearchModelProps, SearchSelectOptionsProps } from '../types';
import FilterDropdown, { ValueProps } from './components/FilterDropdown';
import FilterDateRanger from './components/FilterDateRanger';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

const TableInput2 = styled(InputBase)(({ theme }) => {
  return {
    // background: "rgb(239, 241, 244)",
    width: '100%',
    height: 40,
    border: '1px solid',
    borderColor: theme.palette.divider,
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: '4px',
    fontSize: 14,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
      background: 'white',
    },
    '&::placeholder': {
      fontSize: 14,
      // fontFamily: 'Roboto',
    },
  };
});

const groupByType = (items: DataGridFilters[]) => {
  return items.reduce((acc, curr) => {
    if (!acc[curr.type]) {
      acc[curr.type] = [];
    }
    acc[curr.type].push(curr);
    return acc;
  }, {} as any);
};

export const getFilterValues = (items: DataGridFilters) => {
  return Object.entries(items).reduce((acc, curr) => {
    const [type, cols] = curr;
    if (type === 'string') {
      return { ...acc, string: '' };
    } else if (type === 'select' || type === 'search-select' || type === 'date-range' || type === 'dropdown') {
      return {
        ...acc,
        ...cols.reduce(
          (sAcc: any, sCur: { field: string; value: any }) => ({
            ...sAcc,
            [sCur.field]: sCur.value ?? '',
          }),
          {},
        ),
      };
    }
    return acc;
  }, {} as any);
};

const injectValue = (
  items: DataGridFilters[],
  filter = {},
  stringFilters: { field: string; accept: boolean | undefined; label: string | undefined }[],
) => {
  const splitData = Object.entries(filter).reduce((acc, curr) => {
    const [key, value] = curr;
    return { ...acc, [key]: value };
  }, {} as any);

  const filterObj = keyBy(stringFilters, 'field');

  return items.map((col) => ({
    ...col,
    value: splitData[col.field] ?? (filterObj[col.field]?.accept ? splitData.string : ''),
    accept: filterObj[col.field]?.accept ?? false,
  }));
};

const Filters = React.memo(({ onFilterChange, title, locales, allowChangePaginationAfterFilter }: FiltersProps) => {
  const apiRef = useGridApiContext();
  const { items } = gridFilterModelSelector(apiRef) as { items: DataGridFilters[] };
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const keyByType = useMemo<KeyByProps>(() => groupByType(items), [items]);
  const [state, setState] = useState(getFilterValues(keyByType));
  const [stringFilters, setStringFilters] = useState(getInitialSearchStringValues(items) || []);

  // useEffect(() => {
  //   if (keyByType) {
  //     setState(getFilterValues(keyByType))
  //   }
  // }, [keyByType])

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    allowChangePaginationAfterFilter();
    timer.current = setTimeout(() => {
      const newFilterItems = injectValue(items, state, stringFilters);

      if (newFilterItems) {
        // filterCol.value = value;
        apiRef.current.setFilterModel({ items: newFilterItems });
        onFilterChange && onFilterChange(newFilterItems);
      }
    }, 500);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [state, stringFilters]);

  const onChangeFilters = (e: { target: { value: string } }) => {
    // setValue(e.target.value);
    setState((prevState: any) => ({ ...prevState, string: e.target.value }));
  };

  const onChangeSelect = (field: string) => (event: SelectChangeEvent) => {
    setState((prevState: any) => ({ ...prevState, [field]: event.target.value }));
  };

  const onChangeSearchSelect = (field: string) => (event: any, data: SearchSelectOptionsProps) => {
    setState((prevState: any) => ({ ...prevState, [field]: data?.value ?? '' }));
  };

  const onChangeAsyncSearchSelect = (field: string) => (value: string) => {
    setState((prevState: any) => ({ ...prevState, [field]: value ?? '' }));
  };

  const onChangeFilterString = (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentStringFilter = [...stringFilters];
    const index = currentStringFilter.findIndex((col) => col.field === fieldName);
    if (index === -1) {
      return;
    }
    currentStringFilter[index].accept = e.target.checked;
    setStringFilters(currentStringFilter);
  };

  const onDropdownChange = (fieldName: string) => (value: ValueProps) => {
    setState((prevState: any) => ({ ...prevState, [fieldName]: value.value ?? '' }));
  };

  const onDateRangerChange = (fieldName: string) => (value: DateRange<Dayjs>) => {
    if (value[0] && value[1]) {
      if (value[0].isValid() && value[1].isValid()) {
        setState((prevState: any) => ({
          ...prevState,
          [fieldName]: {
            startTime: value[0]?.startOf('day').toISOString(),
            endTime: value[1]?.add(2, 'day')?.toISOString(),
          },
        }));
      }
    }
  };

  const clearValue = (field: string) => () => {
    setState((prevState: any) => ({ ...prevState, [field]: '' }));
  };

  return (
    <>
      <Stack direction='row' spacing={2} alignItems='center' sx={{ flexGrow: 5 }}>
        {Object.entries(keyByType).map(([type, cols]: [string, SearchModelProps[]], idx) => {
          switch (type) {
            case 'string':
              return (
                <Stack
                  key={idx}
                  direction='row'
                  alignItems='center'
                  sx={{
                    width: '100%',
                    maxWidth: 300,
                    position: 'relative',
                    '&:hover': {
                      '& .search-icon': {
                        borderColor: 'primary.main',
                      },
                      '& .MuiInputBase-root': {
                        borderColor: 'primary.main',
                        borderRightColor: 'divider',
                      },
                      '& input': {
                        borderColor: 'primary.main',
                        borderRightColor: 'divider',
                      },
                      '& .button-filter': {
                        borderColor: 'primary.main',
                        borderLeft: 'none',
                      },
                    },
                  }}
                >
                  <Stack
                    justifyContent='center'
                    alignItems='flex-end'
                    sx={{
                      width: 34,
                      minWidth: 34,
                      minHeight: 36,
                      height: 40,
                      borderColor: 'divider',
                      color: 'text.primary',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderRight: 'none',
                    }}
                    className='search-icon'
                  >
                    <SearchIcon sx={{ color: '#9ca5ae', opacity: 0.8, fontSize: 22 }} />
                  </Stack>
                  <TableInput2
                    value={state.string}
                    placeholder={
                      title?.findBy +
                      stringFilters
                        .map((field) => (field.accept ? (locales ? locales(field.label) : field.label) : ''))
                        .filter((field) => !!field)
                        .join(', ')
                    }
                    sx={{
                      maxWidth: 300,
                      borderRadius: 0,
                      borderLeft: 'none',
                      // fontFamily: 'Roboto-Regular',
                      // '&::placeholder': {
                      //   fontFamily: 'Roboto-Regular',
                      // },
                    }}
                    onChange={onChangeFilters}
                    key={idx}
                    name='string'
                    endAdornment={
                      <IconButton
                        sx={{
                          visibility: state.string ? 'visible' : 'hidden',
                          width: 26,
                          height: 26,
                        }}
                        onClick={() => onChangeFilters({ target: { value: '' } })}
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    }
                  />

                  <FilterStringSelectFields
                    onChangeFilterString={onChangeFilterString}
                    initialStringFilters={stringFilters}
                    title={title}
                    locales={locales}
                  />
                </Stack>
              );

            case 'select':
              return cols.map((col: any) => {
                if (!col.options) {
                  col.options = [];
                }
                const index = col.options.findIndex((e: SearchSelectOptionsProps) => e.value === state[col.field]);
                const init = col.options[index];

                return (
                  <Select
                    key={col.id}
                    value={init.value}
                    onChange={onChangeSelect(col.field)}
                    fullWidth
                    sx={{
                      maxWidth: 240,
                      height: 40,
                      '& .MuiSelect-select': {
                        fontSize: 14,
                        color: '#757575',
                        borderColor: 'red',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px !important',
                        borderColor: '#e0e0e0',
                      },
                      '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                    displayEmpty
                    endAdornment={
                      <ButtonIcon
                        buttonType='circle'
                        sx={{ display: init.value ? '' : 'none', mr: 1 }}
                        onClick={clearValue(col.field)}
                      >
                        <ClearIcon sx={{ fontSize: 16 }} />
                      </ButtonIcon>
                    }
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          // bottom: 0,
                          mt: -1,
                          ml: init.value ? 3.5 : 0.9,
                          minWidth: 240,
                        },
                        style: { maxWidth: 240, width: '100%' },
                      },
                    }}
                  >
                    {col.options.map((option: SearchSelectOptionsProps) => (
                      <MenuItem key={option.value} value={option.value} sx={{ fontSize: 14 }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                );

                // return (
                //   <AutoComplete
                //     sx={{ maxWidth: 300, width: "100%" }}
                //     getOptionLabel={(option) => option.label}
                //     options={col.options}
                //     renderInput={(params) => (
                //       <Input {...params} label={col.label} name={col.field} />
                //     )}
                //     onChange={onChangeSelect(col.field)}
                //     value={init}
                //     key={col.id}
                //   />
                // );
              });

            case 'search-select':
              return cols.map((col: any, index) => {
                if (!col.options) {
                  col.options = [];
                }
                return (
                  <Autocomplete
                    // getOptionLabel={(option) => {
                    //   console.log(option)
                    //   return option.isExternalLabel ? locales(option.label) : option.label
                    // }}
                    key={index}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={col.options}
                    // @ts-ignore
                    name={col.field}
                    renderOption={(props, option) => {
                      const title = locales ? locales(option.label) : option.label;
                      return (
                        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.icon && <img loading='lazy' width='20' height='20' src={option.icon} alt='' />}
                          {title.length > 24 ? (
                            <Tooltip arrow placement='right' title={title}>
                              <span>
                                {truncate(title, {
                                  length: 24,
                                })}
                              </span>
                            </Tooltip>
                          ) : (
                            <span>
                              {truncate(title, {
                                length: 24,
                              })}
                            </span>
                          )}
                        </Box>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputLabelProps, InputProps, inputProps, ...rest } = params;
                      return (
                        // @ts-ignore
                        <TableInput2
                          // label={col.label as string}
                          placeholder={locales ? locales(col.label) : col.label}
                          {...InputLabelProps}
                          {...InputProps}
                          inputProps={{
                            ...inputProps,
                            value: locales && inputProps.value ? locales(inputProps.value) : inputProps.value,
                          }}
                          {...rest}
                        />
                      );
                    }}
                    onChange={onChangeSearchSelect(col.field)}
                    // value={state[col.field]}
                    value={
                      col.options.find((option: SearchSelectOptionsProps) => option.value === state[col.field]) ?? {
                        label: state[col.field],
                        value: state[col.field],
                      }
                    }
                    sx={{
                      width: '100%',
                      maxWidth: 240,
                      'input::placeholder': {
                        // fontFamily: 'Roboto-Regular',
                        color: '#757575',
                        opacity: 1,
                      },
                      '& .MuiInputBase-root': {
                        borderColor: '#e0e0e0',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                );
              });

            case 'dropdown':
              return cols.map((col: any) => {
                return (
                  <FilterDropdown
                    key={col.id}
                    locales={locales}
                    popover={col.popover}
                    onChange={onDropdownChange(col.field)}
                    initialValue={col.initialValue}
                    onClear={col.onClear}
                  />
                );
              });

            case 'date-range':
              return cols.map((col: any) => {
                return <FilterDateRanger key={col.id} locales={locales} onChange={onDateRangerChange(col.field)} />;
              });

            case 'async-select':
              return cols.map((col: any) => {
                return (
                  <AsyncAutocomplete
                    key={col.id}
                    labelField={col.labelField as never}
                    selectField={col.selectField as never}
                    fetchAPI={col.fetchAPI}
                    value={state[col.field]}
                    onChange={onChangeAsyncSearchSelect(col.field)}
                    placeholder={locales ? locales(col.placeholder) : col.placeholder}
                    InputComponent={TableInput2}
                    locales={locales}
                    sx={{
                      width: '100%',
                      maxWidth: 240,
                      'input::placeholder': {
                        // fontFamily: 'Roboto-Regular',
                        opacity: 1,
                        fontSize: 14,
                        color: '#9ca5ae',
                      },
                      '& .MuiInputBase-root': {
                        borderColor: '#e0e0e0',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                );
              });
            default:
              return <></>;
          }
        })}
      </Stack>
      <Divider />
    </>
  );
});

export default Filters;
