import React, { useEffect, useRef } from 'react';
import { getOperatingSystem } from '../../../utils/helpers';
interface Thing {
  width: number;
  height: number;
  x: number;
  y: number;
  speed: number;
  vY: number;
  vX: number;
  d: number;
  stepSize: number;
  step: number;
  angle: number;
  rad: number;
  opacity: number;
  _ratate: number;
}

const isMac = getOperatingSystem() === 'macos';

const SPEED = isMac ? 0.001 : 0.1;

const mouse = {
  x: -100,
  y: -100,
};
const minDist = 150;
function PeachBlossomFalling() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const things = useRef<Thing[]>([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1703647349319';

    const thingsCount = 20;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    for (let i = 0; i < thingsCount; i++) {
      const opacity = Math.random() + 0.4;
      const thingWidth = 10;
      // const thingWidth = (Math.floor(Math.random() * 20) + 20) * (opacity + 0.4);
      // const thingHeight = (image.height / image.width) * thingWidth;
      const thingHeight = 10;
      const speed = Math.random() * SPEED + 0.05;

      if (isMac) {
        if (things.current.length <= 4) {
          things.current.push({
            width: thingWidth,
            height: thingHeight,
            x: Math.random() * 0.02 * canvas.width,
            y: Math.random() * 0.02 * canvas.height - thingHeight,
            speed: speed,
            vY: speed,
            vX: 0,
            d: Math.random() * 1.2 - 0.6,
            stepSize: Math.random() / 200,
            step: 0,
            angle: Math.random() * 18 - 90,
            rad: Math.random(),
            opacity: opacity,
            _ratate: Math.random() * 0.1,
          });
        }
      } else {
        if (things.current.length <= 10) {
          things.current.push({
            width: thingWidth,
            height: thingHeight,
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height - thingHeight,
            speed: speed,
            vY: speed,
            vX: 0,
            d: Math.random() * 1.2 - 0.6,
            stepSize: Math.random() / 20,
            step: 0,
            angle: Math.random() * 180 - 90,
            rad: Math.random(),
            opacity: opacity,
            _ratate: Math.random(),
          });
        }
      }
    }

    const drawThings = () => {
      if (!ctx) return;
      things.current.forEach((thing) => {
        ctx.beginPath();
        thing.rad = (thing.angle * Math.PI) / 180;
        ctx.save();
        const cx = thing.x + thing.width / 2;
        const cy = thing.y + thing.height / 2;
        ctx.globalAlpha = thing.opacity;
        ctx.setTransform(
          Math.cos(thing.rad),
          Math.sin(thing.rad),
          -Math.sin(thing.rad),
          Math.cos(thing.rad),
          cx - cx * Math.cos(thing.rad) + cy * Math.sin(thing.rad),
          cy - cx * Math.sin(thing.rad) - cy * Math.cos(thing.rad),
        );
        ctx.drawImage(image, thing.x, thing.y, thing.width, thing.height);
        ctx.restore();
      });
    };

    const draw = () => {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawThings();
    };

    const update = () => {
      things.current.forEach((thing) => {
        const dist = Math.sqrt((thing.x - mouse.x) ** 2 + (thing.y - mouse.y) ** 2);

        if (dist < minDist) {
          const force = minDist / (dist * dist),
            xcomp = (mouse.x - thing.x) / dist,
            ycomp = (mouse.y - thing.y) / dist,
            deltaV = force * 2;

          thing.vX -= deltaV * xcomp;
          thing.vY -= deltaV * ycomp;

          if (thing.d * xcomp > 0) {
            thing.d = 0 - thing.d;
          }
        } else {
          thing.vX *= 0.98;

          if (thing.vY < thing.speed) {
            thing.vY = thing.speed;
          }

          thing.vX += Math.cos((thing.step += Math.random() * 0.05)) * thing.stepSize;
        }

        thing.y += thing.vY;
        thing.x += thing.vX + thing.d;

        const _angle = Math.random() + 0.2;

        if (thing._ratate === 0) {
          thing.angle += _angle;
        } else {
          thing.angle -= _angle;
        }

        if (thing.y > canvas.height) {
          reset(thing);
        }

        if (thing.x > canvas.width || thing.x < 0 - thing.width) {
          reset(thing);
        }
      });
    };

    const reset = (thing: Thing) => {
      thing.opacity = Math.random() + 0.4;
      thing.width = 10;
      thing.height = 10;
      thing.x = Math.floor(Math.random() * canvas.width);
      thing.y = 0 - thing.height;
      thing.speed = Math.random() * SPEED + 0.5;
      thing.vY = thing.speed;
      thing.vX = 0;
      thing._ratate = Math.random();
    };

    const handleMouseMove = (e: MouseEvent) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    };

    canvas.addEventListener('mousemove', handleMouseMove);

    const tick = () => {
      draw();
      update();
      requestAnimationFrame(tick);
    };

    tick();

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, []); // empty dependency array ensures useEffect runs only once

  return (
    <div className='fixed top-0 left-0 z-0'>
      <canvas ref={canvasRef} id='falling-canvas'></canvas>
    </div>
  );
}

export default React.memo(PeachBlossomFalling);
