import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { BaseButtonProps } from "./types";

const ButtonOutlined = ({
  title,
  children,
  onClick,
  loading,
  sx,
  disabled,
  isTableButton,
  ...props
}: BaseButtonProps) => {
  return (
    <LoadingButton
      {...props}
      disabled={disabled ?? loading}
      onClick={onClick}
      loading={loading}
      variant="outlined"
      sx={{
        // backgroundColor: "transparent",
        // border: "1px solid #456FD3",
        // color: "text.ocean",
        cursor: "pointer",
        textTransform: "none",
        minHeight: isTableButton ? 32 : 36,
        minWidth: isTableButton ? 90 : 80,
        whiteSpace: "nowrap",
        fontWeight: 500,
        ...sx,
      }}
    >
      {/* {loading && (*/}
      {/*  <CircularProgress style={{ width: 15, height: 15, color: "white", marginRight: 10 }} />*/}
      {/* }*/}
      {title ? <Typography>{title}</Typography> : children}
    </LoadingButton>
  );
};

export default ButtonOutlined;
