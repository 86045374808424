import { selector } from 'recoil';
import { semverCompareFull } from 'kikifrontend/utils/helpers';

const MINIMUM_SUPPORT_PLUGIN_VERSION = '1.8.3';

const getKikiLoginVersion = () => {
  const kikiIndex = window.navigator.userAgent.indexOf('KikiLogin');
  if (kikiIndex === -1) {
    return MINIMUM_SUPPORT_PLUGIN_VERSION;
  }
  return window.navigator.userAgent.substring(kikiIndex + 10, kikiIndex + 15);
};

export const isSupportPluginSupportSelector = selector({
  key: 'isSupportPluginSupportSelector',
  get: () => {
    return semverCompareFull(getKikiLoginVersion(), MINIMUM_SUPPORT_PLUGIN_VERSION) >= 0;
  },
});
