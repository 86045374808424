import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Flex } from '@kikisoftware/uikit';
import { dateTimeFormatter } from 'kikifrontend/utils/date-time';
import { useTranslation } from 'react-i18next';

interface CouponDetailProps {
  bgcolor: string;
  icon: React.ReactNode;
  description: string;
  code: string;
  endTime: string;
  onSelectClick: () => void;
  selectedButtonLabel: string;
}

function CouponDetail({
  bgcolor,
  icon,
  description,
  code,
  endTime,
  onSelectClick,
  selectedButtonLabel,
}: CouponDetailProps) {
  const { t } = useTranslation();

  return (
    <Stack
      direction='row'
      spacing={1.5}
      p={1.5}
      sx={{
        bgcolor,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'border.primary',
      }}
      mt={2}
      width={1}
      alignItems='center'
    >
      <Stack
        justifyContent='center'
        alignItems='center'
        width={60}
        height={60}
        sx={{
          bgcolor: 'rgba(32, 77, 194, 0.08)',
          borderRadius: '50%',
          flexShrink: 0,
        }}
      >
        {icon}
      </Stack>
      <Box width={1}>
        <Typography component='p' variant='body1Bold'>
          {description}
        </Typography>
        <Typography component='p' variant='body1Bold' color='text.subColor'>
          {code}
        </Typography>
        {/*<Typography variant='body2'>{couponDetail.}</Typography>*/}
        <Flex>
          <Typography component='p' variant='body2' color='text.subColor4' mt={1}>
            {t('HSD')}: {dateTimeFormatter(endTime)}
          </Typography>
          <Typography
            component='p'
            variant='textSm'
            color='primary.main'
            mt={1}
            fontWeight={600}
            sx={{ cursor: 'pointer' }}
            onClick={onSelectClick}
          >
            {selectedButtonLabel}
          </Typography>
        </Flex>
      </Box>
    </Stack>
  );
}

export default CouponDetail;
