import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { TrackButtonProps } from 'kikifrontend/components/Button/types';
import track from 'kikifrontend/utils/analytics';
import useTrackParams from 'kikifrontend/hooks/useTrackParams';

function TrackButton({ children, onClick, eventName, params, ...props }: TrackButtonProps) {
  const { params: trackParams } = useTrackParams(params);

  const trackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (eventName) {
      track.event(eventName, trackParams);
    }
    onClick?.(e);
  };

  return (
    <LoadingButton {...props} onClick={trackClick}>
      {children}
    </LoadingButton>
  );
}

export default TrackButton;
