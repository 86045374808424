import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import { PackageDetailOrPackage } from 'kikifrontend/types/user.types';
import { getStringFromLocalStorage } from 'kikifrontend/utils/localstorage';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TFunction } from 'i18next';
import i18n from 'kikifrontend/locales/i18n';

dayjs.extend(isToday);
dayjs.extend(utc);

export const dateTimeFormatter = (date?: string | number | Date | dayjs.Dayjs | null, format = 'DD/MM/YYYY HH:mm') => {
  return dayjs(date).format(format);
};

export const getDateTimeUTC = (date?: string | number | Date | dayjs.Dayjs | null) => {
  let convertDate = date;
  if (dayjs.isDayjs(date)) {
    convertDate = date.format('YYYY-MM-DD');
  }
  return dayjs.utc(convertDate);
};

export const subDate = (
  date1?: string | number | Date | dayjs.Dayjs | null,
  date2?: string | number | Date | dayjs.Dayjs | null,
  options?: { allowNegative: boolean; unit?: QUnitType | OpUnitType },
) => {
  const { allowNegative, unit = 'day' } = options || { allowNegative: true, unit: 'day' };
  const subDate = dayjs(date1).diff(dayjs(date2), unit);
  if (!allowNegative) return subDate < 0 ? 0 : subDate;
  return subDate;
};

export const isDateValidInRange = (time: string, dates: DateRange<Dayjs>) => {
  if (!dates[0] || !dates[1]) return true;
  const unix = dayjs(time).unix();
  return dayjs(dates[0]).startOf('day').unix() <= unix && unix <= dayjs(dates[1]).endOf('day').unix();
};

// To check if a free user has registered 3 days or not
const LIMIT_SHOW_COMMUNITY_DAY = 3;

export const isNeedShowCommunityModal = (packageInfo?: PackageDetailOrPackage) => {
  if (!packageInfo) return false;
  const lastShowedTime = getStringFromLocalStorage('communityShowTime');
  const isShowedTimeIsToday = lastShowedTime ? dayjs(lastShowedTime).isToday() : false;
  return (
    packageInfo.name.toLowerCase() === 'free' &&
    dayjs().diff(dayjs(packageInfo.buyAt), 'day') < LIMIT_SHOW_COMMUNITY_DAY &&
    !isShowedTimeIsToday
  );
};

const change2Digits = (time: number) => {
  return time >= 10 ? time : '0' + time;
};

// Convert time from seconds to format HH:mm:ss
export const formatTimeSeconds = (totalTime = 0) => {
  const hour = Math.floor(totalTime / 3600);
  const minute = Math.floor((totalTime - hour * 3600) / 60);
  const second = totalTime - hour * 3600 - minute * 60;
  return `${change2Digits(hour)}:${change2Digits(minute)}:${change2Digits(second)}`;
};

export const formatDuration = (totalTime = 0, { t } = i18n) => {
  const day = Math.floor(totalTime / 86400);
  const hour = Math.floor((totalTime - day * 86400) / 3600);
  const minute = Math.floor((totalTime - day * 86400 - hour * 3600) / 60);
  if (day > 0) {
    return `${change2Digits(day)} ${t('days')}`;
  }
  return `${change2Digits(hour)} ${t('hour')} ${change2Digits(minute)} ${t('minute')}`;
};

export const getTimeAgo = (time: string, t: TFunction<'translation', undefined, 'translation'>) => {
  const currentTime = dayjs();
  const inputTime = dayjs(time);
  const diff = currentTime.diff(inputTime, 'day');
  if (diff === 0) {
    return t('Hôm nay');
  }
  if (diff === 1) {
    return t('Hôm qua');
  }
  if (diff <= 10) {
    return `${diff} ${t('ngày trước')}`;
  }

  return inputTime.format('DD/MM/YYYY');
};

export const getTimeAgoFull = (time: string, t: TFunction<'translation', undefined, 'translation'>) => {
  const date = new Date(time || ''),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    dayDiff = Math.floor(diff / 86400);

  return (
    (dayDiff < 0 && t('justNow')) ||
    (dayDiff === 0 &&
      ((diff < 60 && t('justNow')) ||
        (diff < 120 && t('oneMinuteAgo')) ||
        (diff < 3600 && Math.floor(diff / 60) + t('minutesAgo')) ||
        (diff < 7200 && t('oneHourAgo')) ||
        (diff < 86400 && Math.floor(diff / 3600) + t('hoursAgo')))) ||
    (dayDiff === 1 && t('aDayAgo')) ||
    (dayDiff < 7 && t('daysAgo', { day: dayDiff })) ||
    (dayDiff < 31 &&
      new Intl.DateTimeFormat('en-GB', {
        timeStyle: 'short',
        dateStyle: 'short',
      }).format(new Date(time))) ||
    (dayDiff >= 31 &&
      new Intl.DateTimeFormat('en-GB', {
        timeStyle: 'short',
        dateStyle: 'short',
      }).format(new Date(time)))
  );
};
