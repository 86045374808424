export function getTotalPage(limit: number, total: number) {
  return Math.ceil(total / limit);
}

export function isLastPage(page: number, limit: number, total: number) {
  const totalPage = getTotalPage(limit, total);
  return page === totalPage;
}

export const getOperatingSystem = () => {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes('win')) {
    return 'windows';
  } else if (platform.includes('mac')) {
    return 'macos';
  } else if (platform.includes('linux')) {
    return 'linux';
  }

  return 'windows';
};
