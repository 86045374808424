import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { ProfileProxyConfig } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import { useForm, useWatch } from 'react-hook-form';
import {
  MAX_PROXY_CHANGE_IP_URL_LENGTH,
  PROXY_TYPE_OPTIONS,
} from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import {
  CreationAutocompleteOptions,
  PremiumComponents,
  PremiumInput,
  SelectFormField,
  TextFormField,
} from 'kikifrontend/components';
import { ProxyType } from 'kikifrontend/types/proxy.types';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { getProxyCheckedResults } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/getProxyCheckedResults';
import Skeleton from '@mui/material/Skeleton';
import { useMutation } from '@tanstack/react-query';
import { localServices } from 'kikifrontend/_apis_';
import { proxyValidation } from 'kikifrontend/features/profiles/profileEditor/utils/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useRecoilValue } from 'recoil';
import { isAppConnectedAtom } from 'kikifrontend/utils/recoil/atom';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Box from '@mui/material/Box';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import { checkProxyForm } from 'kikifrontend/utils/string';
// import useExternalTranslations from 'kikifrontend/hooks/useExternalTranslations';
import useLocales from 'kikifrontend/hooks/useLocales';
import GuideModal from 'kikifrontend/components/_ThemeEvents/Modal/GuideModal';

interface ProfileProxyConfigModal extends ModalProps {
  onSaveProxy: (values: ProfileProxyConfig) => void;
  initialValue?: ProfileProxyConfig;
  browserType: BrowserType;
}

const defaultProxyValues: ProfileProxyConfig = {
  isUsedProxy: false,
  proxy: '',
  proxyType: PROXY_TYPE_OPTIONS[0],
  proxyCountry: 'others',
  proxySource: 'others',
  selectType: PROXY_TYPE_OPTIONS[0],
  proxyTimezone: '',
  proxyChangeIpUrl: '',
  proxyBypassList: '',
};

export const isValidProxyPattern = (proxy?: string) => {
  if (!proxy || proxy.startsWith(':') || proxy.startsWith('/')) return false;
  if (proxy.startsWith('http://') || proxy.startsWith('socks5://')) return true;
  const splitProxy = proxy.split(':');
  if (splitProxy.length === 3 && splitProxy[1].includes('@')) {
    return true;
  }
  const [, port] = splitProxy;
  if (!port) return false;
  return !Number.isNaN(+port);
};

function ProfileProxyConfigModal({ onClose, onSaveProxy, initialValue }: ProfileProxyConfigModal) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValue
      ? { ...initialValue, proxyBypassList: initialValue.proxyBypassList?.split(';')?.join('\n') }
      : defaultProxyValues,
    resolver: yupResolver(proxyValidation),
  });
  const isAppConnected = useRecoilValue(isAppConnectedAtom);
  const { openModal } = useModal();
  // const { transcripts } = useExternalTranslations();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { currentLang } = useLocales();

  const { proxyType, proxy, selectType } = useWatch({
    control,
  });

  const [isAdvance, setIsAdvance] = useState(false);
  const isPackageFree = useRecoilValue(isFreePackageSelector);

  const isValidProxy = isValidProxyPattern(proxy);

  // const { data, isFetching, refetch, isSuccess, isFetched } = checkProxyQuery(
  //   {
  //     proxyType: proxyType as ProxyType | undefined,
  //     proxyString: proxy,
  //   },
  //   (data) => {
  //     setValue('proxyCountry', data.country.toLowerCase());
  //     setValue('proxyTimezone', data.timezone);
  //   },
  // );

  const { mutateAsync, isSuccess, data, isLoading, isError } = useMutation(localServices.checkProxy, {
    onSuccess: (data) => {
      setValue('proxyCountry', data.country.toLowerCase());
      setValue('proxyTimezone', data.timezone);
    },
    onError: () => {
      setValue('proxyCountry', defaultProxyValues.proxyCountry);
      setValue('proxyTimezone', defaultProxyValues.proxyTimezone);
    },
  });

  const proxyCheckedResults = getProxyCheckedResults(t, data);

  const watchChanged = useCallback(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'selectType') {
        if (value.selectType?.value === 'off') {
          setValue('isUsedProxy', false);
        } else {
          setValue('isUsedProxy', true);
          if (value.selectType?.label && value.selectType?.value) {
            setValue('proxyType', { label: value.selectType.label, value: value.selectType.value });
          }
        }
      }

      if (name === 'proxy' && value.proxy) {
        const { protocol } = checkProxyForm(value.proxy);
        if (protocol && ['http', 'socks5'].includes(protocol)) {
          setValue('proxyType', { value: protocol, label: protocol.toUpperCase() });
          setValue('selectType', { value: protocol, label: protocol.toUpperCase() });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(watchChanged, [watchChanged]);

  const onSubmit = (values: ProfileProxyConfig) => {
    const { proxyString } = checkProxyForm(values.proxy);
    const _values: ProfileProxyConfig = {
      ...values,
      proxyBypassList: values.proxyBypassList?.split('\n').join(';') || '',
      proxy: proxyString ?? values.proxy,
    };
    onSaveProxy(_values);
    onClose();
  };

  const notReadyToSave = isLoading || (selectType?.value !== 'off' && !proxy);

  const onClickCheckProxy = async () => {
    if (!proxy) return;
    const { proxyString } = checkProxyForm(proxy);
    await mutateAsync({
      proxyType: proxyType as ProxyType | undefined,
      proxyString: proxyString ?? proxy,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openHowToUseModal = () => {
    // if (!transcripts?.test_lark) return;
    openModal(
      GuideModal,
      {
        content: 'transcripts?.test_lark?.translations?.[currentLang.value]' ?? '',
        title: 'Hướng dẫn sử dụng proxy',
      },
      undefined,
      { isSecondModal: true },
    );
  };

  return (
    <Modal onClose={onClose} title={t('settingProxy')} width={520}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          <SelectFormField
            control={control}
            label={t('profile.proxyType')}
            options={PROXY_TYPE_OPTIONS}
            name='selectType'
            valueType='object'
            isOptionEqualToValue={(option, value) =>
              (option as CreationAutocompleteOptions).value === (value as CreationAutocompleteOptions).value
            }
          />

          {selectType?.value !== 'off' && (
            <>
              <div className='flex gap-3'>
                <TextFormField
                  name='proxy'
                  label='Proxy'
                  control={control}
                  className='grow'
                  placeholder={`'IP:PORT ${t('hoặc')} IP:PORT:USERNAME:PASSWORD'`}
                  error={!!proxy && !!proxy.split(':')[1] && !isValidProxy}
                  helperText={
                    !isAppConnected
                      ? t('Vui lòng mở ứng dụng KikiLogin để kiểm tra proxy')
                      : !!proxy && !!proxy.split(':')[1] && !isValidProxy
                      ? t('Proxy không hợp lệ')
                      : undefined
                  }
                />
                {isAppConnected && (
                  <LoadingButton
                    variant='contained'
                    color='secondary'
                    loading={isLoading}
                    onClick={onClickCheckProxy}
                    disabled={!proxyType || selectType?.value === 'off' || !proxy || !isValidProxy}
                  >
                    {t('checkProxy')}
                  </LoadingButton>
                )}
              </div>

              {isLoading && (
                <Typography variant='body2' fontStyle='italic' mt={-2}>
                  {t('autoChecking')}
                </Typography>
              )}
              {(isSuccess || isError) && (
                <div className='flex flex-col gap-2'>
                  <div className='flex gap-4'>
                    <Typography variant='body2' sx={{ width: 140 }}>
                      {t('Trạng thái')}
                    </Typography>

                    {isLoading ? (
                      <Skeleton animation='wave' width='100%' />
                    ) : (
                      <Typography variant='body2Bold' color={isSuccess ? 'success.main' : 'error.main'}>
                        {isSuccess ? t('proxyActive') : t('proxyDead')}
                      </Typography>
                    )}
                  </div>
                  {proxyCheckedResults.map(({ value, label }) => (
                    <div className='flex gap-4' key={label}>
                      <Typography variant='body2' sx={{ width: 140 }}>
                        {t(label)}
                      </Typography>
                      {isLoading ? (
                        <Skeleton animation='wave' width='100%' />
                      ) : (
                        <Typography variant='body2Bold'>{value}</Typography>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsAdvance(!isAdvance);
                  }}
                >
                  <Stack
                    direction={'row'}
                    sx={{
                      width: '100%',
                      cursor: 'pointer',
                      border: '1px solid',
                      borderColor: 'border.neutral',
                      borderRadius: 1,
                      height: 40,
                      px: 2,
                      py: 1,
                      ...(isAdvance && {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }),
                    }}
                    spacing={0.5}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Stack direction='row' spacing={1}>
                      <Typography variant='textSm' whiteSpace='nowrap'>
                        {t('profile.advance')}
                      </Typography>
                    </Stack>
                    <KeyboardArrowRightOutlinedIcon
                      sx={{ fontSize: 20, transform: isAdvance ? 'rotate(90deg)' : 'rotate(0)' }}
                    />
                  </Stack>
                </Box>
                <Collapse in={isAdvance} timeout='auto' unmountOnExit>
                  <Stack
                    sx={{
                      gap: 2,
                      py: 2.5,
                      px: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <TextFormField
                      name='proxyChangeIpUrl'
                      label='Proxy change IP URL'
                      control={control}
                      className='grow w-full'
                      placeholder='http(s)://abc.com'
                      error={!!errors.proxyChangeIpUrl}
                      helperText={
                        errors.proxyChangeIpUrl?.message &&
                        t(errors.proxyChangeIpUrl.message, { count: MAX_PROXY_CHANGE_IP_URL_LENGTH })
                      }
                    />
                    {isPackageFree ? (
                      <PremiumInput
                        label='Bypass list'
                        multiline
                        rows={6}
                        placeholder='*.google.com&#10;*foo.com&#10;127.0.0.1:8080'
                        sx={{ maxWidth: 500, mt: 2, width: 1 }}
                        helperText={t('Có thể nhập nhiều URL, mỗi URL trên 1 dòng')}
                      />
                    ) : (
                      <TextFormField
                        name='proxyBypassList'
                        control={control}
                        error={!!errors.proxyBypassList}
                        helperText={
                          errors.proxyBypassList?.message
                            ? t(errors.proxyBypassList.message)
                            : t('Có thể nhập nhiều URL, mỗi URL trên 1 dòng')
                        }
                        label={
                          <PremiumComponents>
                            <Typography variant='body2'>Bypass list</Typography>
                          </PremiumComponents>
                        }
                        multiline
                        rows={4}
                        placeholder='*.google.com&#10;*foo.com&#10;127.0.0.1:8080'
                        disabled={isPackageFree}
                      />
                    )}
                  </Stack>
                </Collapse>
              </div>
            </>
          )}
        </div>

        <div className='flex justify-between items-center gap-3 mt-6'>
          {/*<Typography sx={{ fontSize: 14, color: 'primary.main', cursor: 'pointer' }} onClick={openHowToUseModal}>*/}
          {/*  {t('affiliate.userManual')}*/}
          {/*</Typography>*/}
          <Link
            href='https://tailieu.kikilogin.com/tinh-nang-co-ban/2.-proxy/2.1-them-moi-va-kiem-tra-proxy'
            target='_blank'
            rel='noopener noreferer'
            sx={{ fontSize: 14 }}
          >
            {t('affiliate.userManual')}
          </Link>
          <Button variant='contained' type='submit' disabled={notReadyToSave || !isValidProxy}>
            {t('settings.saveChangeTextButton')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ProfileProxyConfigModal;
