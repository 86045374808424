import React from 'react';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import PremiumModal from 'kikifrontend/components/Premium/PremiumModal';
import { useModal } from '@kikisoftware/uikit';

function PremiumInput(props: TextFieldProps) {
  const isFree = useRecoilValue(isFreePackageSelector);
  const { openModal } = useModal();

  const premiumClick = () => {
    openModal(PremiumModal, {}, undefined, { isSecondModal: true });
  };

  if (isFree) {
    return (
      <div onClick={premiumClick} className='relative'>
        <TextField {...props} onKeyDown={undefined} disabled />
        <div className='absolute inset-0'></div>
      </div>
    );
  }

  return <TextField {...props} />;
}

export default PremiumInput;
