import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { pluginServices } from 'kikifrontend/_apis_';
import { QueryFilterData } from 'kikifrontend/types/api.types';

export const storePluginsQuery = (query: QueryFilterData) => {
  return useQuery([queryKeys.plugins.publicPluginFilter, query], () => pluginServices.getAllStorePlugins(query), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
