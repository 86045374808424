import React, { useRef, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MenuPopover } from '@kikisoftware/uikit';

interface FilterStringProps {
  initialStringFilters: {
    field: string;
    accept: boolean | undefined;
    label: string | undefined;
  }[];
  title?: any;
  locales?: any;
  onChangeFilterString: (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function FilterStringSelectFields({ initialStringFilters, onChangeFilterString, title, locales }: FilterStringProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [searchColumn, setSearchColumn] = useState("")

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangeSearchColumns = (e) => {
  //   setSearchColumn(e.target.value)
  // }

  return (
    <>
      <Button
        ref={anchorRef}
        size='small'
        onClick={handleOpen}
        variant='outlined'
        sx={{
          width: 34,
          minWidth: 34,
          minHeight: 36,
          height: 40,
          borderColor: 'divider',
          color: 'text.primary',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 'none',
          transition: 'none',
        }}
        className='button-filter'
      >
        <FilterListIcon sx={{ color: '#DBDBDB' }} />
      </Button>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 300, px: 2, py: 1 }}>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{title?.findBy}</Typography>
        <FormGroup>
          {initialStringFilters.map((field, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={field.accept}
                  onChange={onChangeFilterString(field.field)}
                  sx={{ cursor: 'pointer' }}
                  size='small'
                />
              }
              label={locales ? locales(field.label) : field.label}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.08)',
                },
                m: 0,
                '& .MuiTypography-root': {
                  fontSize: 14,
                },
              }}
            />
          ))}
        </FormGroup>
      </MenuPopover>
    </>
  );
}

export default FilterStringSelectFields;
