import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { groupServices } from 'kikifrontend/_apis_';
import { QueryFilterData, ResponseData } from 'kikifrontend/types/api.types';
import { GroupProps } from 'kikifrontend/types/group.types';

export const groupQuery = (query: QueryFilterData, onSuccess?: (data: ResponseData<GroupProps[]>) => void) => {
  return useQuery({
    queryKey: [queryKeys.profiles.infiniteMyGroups, 'profileEditor', query],
    queryFn: () => groupServices.getAllUserGroups(query),
    onSuccess,
    keepPreviousData: false,
    cacheTime: 10000,
  });
};
