import { forwardRef } from 'react';
// MUI
import { Avatar, useTheme } from '@mui/material';

const MAvatar = forwardRef<any, any>(({ color = 'default', sx, children, ...other }, ref) => {
  const theme = useTheme();
  const isCustomColor = color.startsWith('rgb') || color.startsWith('#');

  if (color === 'default') {
    return (
      <Avatar ref={ref} sx={sx} {...other}>
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: isCustomColor
          ? 'white'
          : // @ts-ignore
            theme.palette[color as keyof typeof theme.palette].contrastText,
        backgroundColor: isCustomColor
          ? color
          : // @ts-ignore
            theme.palette[color as keyof typeof theme.palette].main,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Avatar>
  );
});

export default MAvatar;
