import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { InputProps } from './types';

const Input = forwardRef<HTMLInputElement, Omit<InputProps, 'variant'>>(
  ({ value, onChange, name, label, sx, mode = 'decimals', onKeyPress, inputRef, ...props }, ref) => {
    return (
      <TextField
        {...props}
        ref={ref}
        inputRef={inputRef}
        value={value}
        onChange={onChange}
        name={name}
        margin='dense'
        label={label}
        fullWidth
        variant='outlined'
        size='small'
        sx={{
          // mt: 1,
          marginBottom: 0,
          '& .MuiInputBase-input': {
            paddingTop: '6.5px',
            paddingBottom: '6.5px',
          },
          ...sx,
        }}
        InputProps={{
          style: {},
          ...props.InputProps,
        }}
        InputLabelProps={{
          style: {
            // color: "white",
          },
          sx: {
            lineHeight: 1.25,
          },
        }}
        onKeyDown={(event) => {
          if (mode === 'integer') {
            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
              event.preventDefault();
            }
          }

          onKeyPress && onKeyPress(event);
        }}
      />
    );
  },
);

export default Input;
