import { DefaultSystemProps } from 'kikifrontend/types/statistic.type';
import { atom } from 'recoil';
import { getFromLocalStorage } from 'kikifrontend/utils/localstorage';
import { SystemInformation, WebConfigProps } from 'kikifrontend/types/common.types';
import { MeProps } from 'kikifrontend/types/user.types';

export const defaultSystemConfigs: DefaultSystemProps = {
  statistic: undefined,
  isAuthenticated: false,
  isInitialize: false,
};

export const statisticAtom = atom({
  key: 'statisticAtom',
  default: defaultSystemConfigs,
});

export const ipConfigAtom = atom<any>({
  key: 'ipConfigAtom',
  default: undefined,
});

export const webConfigAtom = atom<WebConfigProps>({
  key: 'webConfigAtom',
  default: getFromLocalStorage('webConfig'),
});

export const isAppConnectedAtom = atom<boolean>({
  key: 'isAppConnectedAtom',
  default: false,
});

export const systemInformationAtom = atom<SystemInformation | undefined>({
  key: 'systemInformationAtom',
  default: undefined,
});

export const expandSaleSupportAtom = atom({
  key: 'expandSaleSupportAtom',
  default: false,
});

export const tableFilterAtom = atom<Record<string, any>>({
  key: 'tableFilterAtom',
  default: {},
});

export const meAtom = atom<MeProps | undefined>({
  key: 'meAtom',
  default: undefined,
});

export const showLivaChatAtom = atom({
  key: 'showLivaChatAtom',
  default: false,
});
