import { isBoolean } from 'lodash';
import { DataGridColumnDef, DataGridColumnSettings, DataGridFilters } from './types';

export const getVisibilityFields = (columns: DataGridColumnDef[]) => {
  return (columns || []).map((col) => col.field).reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
};

export const getSearchValues = (items: DataGridFilters[] = []) => {
  return items.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.field]: isBoolean(curr.value) ? curr.value : curr.value || undefined,
    }),
    {},
  );
};

export const getInitialSearchStringValues = (items: DataGridFilters[]) => {
  const d = items
    .filter((item) => item.type === 'string')
    .map((item) => ({
      field: item.field,
      accept: item.accept,
      label: item.filterLabel,
    }));
  return d;
};

export const getFilters = (items: DataGridFilters[] = []) => {
  const stringFilters = items.filter((item) => item.type === 'string' && item.accept === true);
  const stringKeyWord = stringFilters[0]?.value;
  const stringKeyWordField = stringFilters.map((items) => items.field).join(',');
  const othersFilters = items.filter((item) => item.type !== 'string');
  const filters = {
    keyword: stringKeyWord ?? undefined,
    searchField: stringKeyWordField,
    ...getSearchValues(othersFilters),
  };

  return { filters, stringKeyWordField, stringKeyWord, othersFilters };
};

// export function getDifference(array1, array2, compareField) {
//   return array1.filter((object1) => {
//     return !array2.some((object2) => {
//       return object1[compareField] === object2[compareField];
//     });
//   });
// }

export const getVisibilityColumns = (columns: DataGridColumnDef[]) => {
  return columns.reduce((acc, curr) => ({ ...acc, [curr.field]: curr.visibility ?? false }), {});
};

export const mergeColumns = (arr: DataGridColumnDef[], arr2: DataGridColumnDef[] = [], ratio = 1) => {
  // return arr.map((item, idx) => ({
  //   ...item,
  //   ...arr2[idx],
  //   width: arr2[idx].width * ratio,
  // }))
  if (arr2.length === 0) {
    return arr;
  }

  const mergeArr = arr2.map((item) => ({
    ...arr.find((e) => e.field === item.field),
    ...item,
    width: (item.width as number) * ratio,
  }));

  const pinnedArr = mergeArr.filter((item) => item.pinned === true);
  const unpinnedArr = mergeArr.filter((item) => item.pinned === false);
  return [...pinnedArr, ...unpinnedArr];
};
// export const swapArrayElements = (arr, indexA, indexB) => {
//   const newArr = [...arr];
//   console.log({ newArr, arr, indexA, indexB });
//   const temp = newArr[indexA];
//   newArr[indexA] = newArr[indexB];
//   newArr[indexB] = temp;
//   return newArr;
// };

export const sortColumnsByPinned = (a: { pinned: any }, b: { pinned: any }) => {
  if (a.pinned < b.pinned) {
    return 1;
  }
  if (a.pinned > b.pinned) {
    return -1;
  }

  return 0;
};
