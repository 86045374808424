import { ProfileEditorFormValue } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import { COUNTRY_CODE, LOCALES_COUNTRIES } from 'kikifrontend/locales/constants/countries';
import { TIME_ZONE } from 'kikifrontend/locales/constants/timezone';
import Assets from 'kikifrontend/assets';
import { getOperatingSystem } from 'kikifrontend/utils/helpers';

export const MAX_PROXY_CHANGE_IP_URL_LENGTH = 250;

export const PROFILE_EDITOR_DEFAULT_SYSTEM = getOperatingSystem();
export const PROFILE_EDITOR_DEFAULT_BROWSER_TYPE = 'kikibrowser';

export const PROFILE_EDITOR_BROWSERS = [
  { value: 'gomubrowser', label: 'Gomu browser', icon: Assets.icons.IcoGomu },
  // { value: 'kikibrowser', label: 'KikiBrowser', icon: Assets.icons.IcoChromium },
  { value: 'kifox', label: 'KiFox', icon: Assets.icons.IcoKiFox },
];

export const PROFILE_EDITOR_MAPPING_OS_LABEL = {
  macos: 'MacOS',
  windows: 'Windows',
  linux: 'Linux',
};

export const PROFILE_EDITOR_LOCALES = LOCALES_COUNTRIES.map((c, index) => ({
  value: `${c}-${COUNTRY_CODE[index]}`,
  label: `${c}-${COUNTRY_CODE[index]}`,
}));

export const PROFILE_EDITOR_TIMEZONE = TIME_ZONE.map((tz) => ({
  label: tz.timeZoneName,
  value: tz.timeZoneName,
}));

export const WEB_RTC_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replacement' },
  { value: 'real', label: 'Real' },
  { value: 'disable', label: 'Disable' },
];

export const WEB_RTC_GOMU_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replacement' },
  { value: 'forward', label: 'Forward' },
  { value: 'real', label: 'Real' },
  { value: 'disable', label: 'Disable' },
];

export const WEB_GL_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replacement' },
  { value: 'real', label: 'Real' },
  { value: 'disable', label: 'Disable' },
];

export const WEB_GPU_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replace' },
  { value: 'real', label: 'Real' },
  { value: 'disable', label: 'Disable' },
];

export const SPEECH_VOICES_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replacement' },
  { value: 'real', label: 'Real' },
  { value: 'disable', label: 'Disable' },
];

export const COMMON_MODE_OPTIONS = [
  { value: 'replacement', label: 'Replace' },
  { value: 'real', label: 'Real' },
];

export const WEB_GL_IMAGE_MODE_OPTIONS = [
  { value: 'noise', label: 'Noise' },
  { value: 'real', label: 'Real' },
];

export const HARDWARE_CORE_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 4, label: '4' },
  { value: 6, label: '6' },
  { value: 8, label: '8' },
  { value: 12, label: '12' },
  { value: 16, label: '16' },
  { value: 24, label: '24' },
  { value: 32, label: '32' },
];

export const MEDIA_FINGERPRINT_OPTIONS = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
];

export const DEVICES_TYPE = {
  videoInputs: 'videoinput',
  audioInputs: 'audioinput',
  audioOutputs: 'audiooutput',
};

export const SYSTEM_OPTIONS = [
  { value: 'windows', label: 'Windows' },
  { value: 'macos', label: 'MacOS' },
  { value: 'linux', label: 'Linux' },
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
];

export const VIDEO_INPUTS_EXAMPLE = ['n/a', 'Integrated Camera', 'FaceTime HD Camera', ''];
export const AUDIO_INPUTS_EXAMPLE = ['n/a', 'default - (Built-in Microphone)', ''];
export const AUDIO_OUTPUTS_EXAMPLE = ['n/a', 'Built-in Microphone', ''];
export const DEVICE_EXAMPLE = ['communications', 'default', 'random'];

export const CANVAS_PROTECT_MODE = [
  { label: 'Noise mode a', value: 'noise-mode-a' },
  { label: 'Noise mode b', value: 'noise-mode-b' },
  // { label: "Noise mode c", value: "noise-mode-c" },
  { label: 'Block', value: 'block' },
];

export const WEBGL_VENDOR = [
  { value: 'Google Inc. (NVIDIA)', label: 'Google Inc. (NVIDIA)' },
  { value: 'Google Inc.', label: 'Google Inc.' },
  { value: 'Microsoft', label: 'Microsoft' },
  { value: 'Apple Inc.', label: 'Apple Inc.' },
  { value: 'ARM', label: 'ARM' },
  { value: 'Intel Inc.', label: 'Intel Inc.' },
  { value: 'Qualcomm', label: 'Qualcomm' },
];

export const PROTECT_FONT_FINGERPRINT_MODE = [
  { label: 'Random', value: 'random' },
  { label: 'Block', value: 'block' },
];

export const WEBGL_RENDERER = [
  {
    value: 'ANGLE (Intel, Intel(R) Iris(R) Xe Graphics Direct3D11 vs_5_0 ps_5_0, D3D11)',
    label: 'ANGLE (Intel, Intel(R) Iris(R) Xe Graphics Direct3D11 vs_5_0 ps_5_0, D3D11)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics 520 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics 520 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics 5300 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics 5300 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics 620 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics 620 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics 620 Direct3D9Ex vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics 620 Direct3D9Ex vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics Direct3D11 vs_4_1 ps_4_1)',
    label: 'ANGLE (Intel(R) HD Graphics Direct3D11 vs_4_1 ps_4_1)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 1050 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 1050 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 1050 Ti Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 1050 Ti Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 1660 Ti Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 1660 Ti Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics Family Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics Family Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) UHD Graphics 620 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) UHD Graphics 620 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel(R) HD Graphics 4400 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (Intel(R) HD Graphics 4400 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'Intel(R) HD Graphics 4600',
    label: 'Intel(R) HD Graphics 4600',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 750 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 750 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro K600 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro K600 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (AMD Radeon (TM) R9 370 Series Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (AMD Radeon (TM) R9 370 Series Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (AMD Radeon HD 7700 Series Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (AMD Radeon HD 7700 Series Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'Apple GPU',
    label: 'Apple GPU',
  },
  {
    value: 'Intel(R) UHD Graphics 620',
    label: 'Intel(R) UHD Graphics 620',
  },
  {
    value: 'Mali-G72',
    label: 'Mali-G72',
  },
  {
    value: 'Mali-G72 MP3',
    label: 'Mali-G72 MP3',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 750  Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 750  Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 750 Ti Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 750 Ti Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 760 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 760 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 760 Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 760 Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 770 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 770 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 780 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 780 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 850M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 850M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 850M Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 850M Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 860M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 860M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 950 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 950 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 950 Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 950 Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 950M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 950M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 950M Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 950M Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 960 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 960 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 960M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 960M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 960M Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 960M Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 970 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 970 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 970 Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 970 Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 980 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 980 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 980 Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 980 Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 980 Ti Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 980 Ti Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce GTX 980M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce GTX 980M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce MX130 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce MX130 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce MX150 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce MX150 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce MX230 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce MX230 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce MX250 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce MX250 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2060 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2060 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2060 Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2060 Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2060 SUPER Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2060 SUPER Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2070 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2070 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2070 SUPER Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2070 SUPER Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2080 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2080 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA GeForce RTX 2080 Ti Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA GeForce RTX 2080 Ti Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro 2000 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro 2000 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro 2000D Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA Quadro 2000D Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro 2000M Direct3D9Ex vs_3_0 ps_3_0)',
    label: 'ANGLE (NVIDIA Quadro 2000M Direct3D9Ex vs_3_0 ps_3_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro FX 380 Direct3D11 vs_4_0 ps_4_0)',
    label: 'ANGLE (NVIDIA Quadro FX 380 Direct3D11 vs_4_0 ps_4_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro K2000 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro K2000 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro K2200 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro K2200 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro M1000M Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro M1000M Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro NVS 295 Direct3D11 vs_4_0 ps_4_0)',
    label: 'ANGLE (NVIDIA Quadro NVS 295 Direct3D11 vs_4_0 ps_4_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P1000 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P1000 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P2000 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P2000 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P400 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P400 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P4000 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P4000 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P600 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P600 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (NVIDIA Quadro P620 Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (NVIDIA Quadro P620 Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (ATI Mobility Radeon HD 4330 Direct3D11 vs_4_1 ps_4_1)',
    label: 'ANGLE (ATI Mobility Radeon HD 4330 Direct3D11 vs_4_1 ps_4_1)',
  },
  {
    value: 'ANGLE (ATI Mobility Radeon HD 4500 Series Direct3D11 vs_4_1 ps_4_1)',
    label: 'ANGLE (ATI Mobility Radeon HD 4500 Series Direct3D11 vs_4_1 ps_4_1)',
  },
  {
    value: 'ANGLE (ATI Mobility Radeon HD 5000 Series Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (ATI Mobility Radeon HD 5000 Series Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (ATI Mobility Radeon HD 5400 Series Direct3D11 vs_5_0 ps_5_0)',
    label: 'ANGLE (ATI Mobility Radeon HD 5400 Series Direct3D11 vs_5_0 ps_5_0)',
  },
  {
    value: 'ANGLE (Intel, Intel(R) UHD Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8935)',
    label: 'ANGLE (Intel, Intel(R) UHD Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8935)',
  },
  {
    value: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 1070 Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.6079)',
    label: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 1070 Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.6079)',
  },
  {
    value: 'ANGLE (Intel, Intel(R) UHD Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-26.20.100.7870)',
    label: 'ANGLE (Intel, Intel(R) UHD Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-26.20.100.7870)',
  },
  {
    value: 'ANGLE (AMD, Radeon (TM) RX 470 Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.1034.6)',
    label: 'ANGLE (AMD, Radeon (TM) RX 470 Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.1034.6)',
  },
  {
    value: 'ANGLE (Intel, Intel(R) UHD Graphics 620 Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8681)',
    label: 'ANGLE (Intel, Intel(R) UHD Graphics 620 Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8681)',
  },
  {
    value: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0, D3D11-10.18.13.6881)',
    label: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0, D3D11-10.18.13.6881)',
  },
  {
    value: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 970 Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.5671)',
    label: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 970 Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.5671)',
  },
  {
    value: 'ANGLE (AMD, AMD Radeon(TM) Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.14028.11002)',
    label: 'ANGLE (AMD, AMD Radeon(TM) Graphics Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.14028.11002)',
  },
  {
    value: 'ANGLE (Intel, Intel(R) HD Graphics 630 Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8681)',
    label: 'ANGLE (Intel, Intel(R) HD Graphics 630 Direct3D11 vs_5_0 ps_5_0, D3D11-27.20.100.8681)',
  },
  {
    value: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.5671)',
    label: 'ANGLE (NVIDIA, NVIDIA GeForce GTX 750 Ti Direct3D11 vs_5_0 ps_5_0, D3D11-27.21.14.5671)',
  },
];

export const PROXY_TYPE_OPTIONS = [
  { value: 'off', label: 'disableProxy' },
  { value: 'http', label: 'HTTP' },
  // { value: 'https', label: 'HTTPS' },
  { value: 'socks5', label: 'SOCKS5' },
];

export const PROFILE_EDITOR_DEFAULT_FORM_VALUE: ProfileEditorFormValue = {
  proxy: {
    isUsedProxy: false,
    proxy: '',
    proxyType: { value: '', label: '' },
    proxyCountry: 'others',
    proxySource: 'others',
    proxyChangeIpUrl: '',
  },
  platform: 'Win32',
  tags: [],
  group: '',
  advancedFingerprint: {
    canvasHash: '',
    disableWebcam: false,
    customDNS: '',
    protectPortScan: true,
    protectPortScanAllowedPort: '',
    muteAudio: false,
    ignoreCertError: false,
    enableWebRTCStream: false,
    enableSpeechInput: false,
    protectWebgl: false,
    protectCanvas: false,
    canvasProtectMode: 'noise-mode-b',
    enableAudioFingerprint: false,
    webglVendor: WEBGL_VENDOR[0].value,
    webglRenderer: WEBGL_RENDERER[0].value,
    geolocation: [],
    protectFontFingerprint: false,
    fontFingerprintProtectionMode: 'random',
    doNotTrack: 0,
    disableImages: false,
    autoGeolocation: true,
    webGPU: {
      mode: 'replacement',
      vendor: '',
      architecture: '',
      device: '',
      driver: '',
      description: '',
      limits: {},
    },
    screenConfig: {
      mode: 'replacement',
      dpr: 1,
      depth: 1,
      isTouchScreen: false,
      maxTouchPoint: 0,
    },
    webgl: {
      mode: 'replacement',
      imageMode: 'noise',
      imageNoiseSeed: 5000,
      params: {},
      extensions: {
        webgl: [],
        webgl2: [],
      },
      unmaskedVendor: '',
      unmaskedRenderer: '',
    },
    clientHint: {
      platform: '',
      platformVersion: '',
      architecture: '',
      bitness: '',
      UAFullVersion: '',
      UAVersion: '',
      isMobile: 0,
    },
    speechSynthesis: {
      mode: 'replacement',
      voices: [],
    },
    clientRect: {
      mode: 'noise',
      noiseSeed: 4000,
    },
    audioContext: {
      mode: 'noise',
      noiseSeed: 4000,
    },
    plugin: {
      mode: 'real',
      plugins: [],
    },
    mimeTypes: {
      mode: 'real',
      types: [],
    },
    battery: {
      mode: 'real',
    },
    network: {
      mode: 'replacement',
    },
    font: {
      mode: 'real',
      fonts: [],
    },
  },
  mediaDeviceFingerprint: {
    isEnableProtection: false,
    videoInputs: [],
    audioInputs: [],
    audioOutputs: [],
  },
  timezone: PROFILE_EDITOR_TIMEZONE[0].value,
  autoTimezone: true,
  webRTC: {
    mode: 'replacement',
    isRandomWanIP: true,
    wanIp: '',
    localIp: '',
    webRTCIceServers: 'stun:stun.l.google.com:19302',
  },
  computerInfomation: {
    deviceName: '',
    hardwareConcurrency: 1,
    deviceMemory: 1,
    macAddress: '',
  },
  homepage: 'https://whoer.net/',
  name: '',
  customExtensions: '',
  system: { value: 'windows', label: 'Windows' },
  note: '',
  isAutoLanguage: true,
  userAgent: '',
  resolution: { value: '', label: '' },
  language: 'en-US',
  browserType: 'gomubrowser',
  savingOptions: {
    bookmark: false,
    history: false,
    password: false,
  },
  otherConfig: {
    disableImages: false,
  },
  workingPlatform: 'other',
  workingPlatformUsername: '',
  workingPlatformPassword: '',
  addFormField: {
    latitude: '',
    longitude: '',
    enabledMediaFingerprint: false,
    numberOfVideoInput: 0,
    numberOfAudioOutput: 0,
    numberOfAudioInput: 0,
    proxyTimezone: '',
  },
};

export const QUERY_MAPPING_SYSTEM = {
  windows: 'Windows',
  macos: 'macOS',
  linux: 'Linux',
  android: 'Android',
  ios: 'iOS',
};
