import React from 'react';
import { pluginsHeader } from 'kikifrontend/pages/dashboard/Plugins/PublicPlugins';
import Page from 'kikifrontend/components/Page';
import MyPluginsView from 'kikifrontend/features/plugins/ui/MyPluginsView';
import { useRecoilValue } from 'recoil';
import { isSupportPluginSupportSelector } from 'kikifrontend/features/plugins/selectors/pluginSuport.selector';
import PluginNoSupport from 'kikifrontend/features/plugins/components/PluginNoSupport';

function MyPlugins() {
  const isSupportPluginSupport = useRecoilValue(isSupportPluginSupportSelector);

  return (
    <Page title='Plugins' headerTitles={pluginsHeader}>
      {isSupportPluginSupport ? <MyPluginsView /> : <PluginNoSupport />}
    </Page>
  );
}

export default MyPlugins;
