import React from 'react';
import { GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import { keyframes, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonOutlined, useSettings } from '@kikisoftware/uikit';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
    display: flex;
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
    display: none;
  }
`;

function SelectionGridToolbar({
  selectionButtonGroup,
  locales,
}: {
  selectionButtonGroup?: (props: GridRowId[]) => React.ReactNode;
  locales?: any;
}) {
  const apiRef = useGridApiContext();
  const selectionModel = Array.from(apiRef.current.getSelectedRows().keys());
  const { themeMode } = useSettings();

  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='center'
      sx={{
        position: 'absolute',
        top: 65,
        left: 0,
        zIndex: 10,
        width: '95%',
        height: 50,
        // bgcolor: "white",
        // bgcolor: themeMode === 'light' ? '#fbfbfb' : '#141416',
        bgcolor: 'background.onLayout',
        ml: 7.5,
        display: selectionModel.length > 0 ? 'flex' : 'none',
        animation: `${selectionModel.length > 0 ? fadeIn : fadeOut} 0.15s ease`,
      }}
    >
      <>
        <Typography variant='text' sx={{ fontWeight: 500 }}>
          {locales ? locales('Selected') : 'Selected'} <strong>{selectionModel.length}</strong>
        </Typography>
        {selectionButtonGroup ? (
          selectionButtonGroup(selectionModel)
        ) : (
          <>
            {/* <Badge color="error" badgeContent={selectionModel.length}>*/}
            <ButtonOutlined startIcon={<DeleteIcon />} sx={{ height: 32, minHeight: 32 }}>
              Xóa
            </ButtonOutlined>
            {/* </Badge>*/}
            {/* <Badge color="error" badgeContent={selectionModel.length}>*/}
            <ButtonOutlined startIcon={<DeleteIcon />} color='warning' sx={{ height: 32, minHeight: 32 }}>
              Ước
            </ButtonOutlined>
            {/* </Badge>*/}
            {/* <Badge color="error" badgeContent={selectionModel.length}>*/}
            <ButtonOutlined startIcon={<DeleteIcon />} color='error' sx={{ height: 32, minHeight: 32 }}>
              Do something
            </ButtonOutlined>
            {/* </Badge>*/}
          </>
        )}
      </>
    </Stack>
  );
}

export default SelectionGridToolbar;
