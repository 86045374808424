import { styled } from "@mui/material/styles";

const TableSelect = styled("select")(({ theme }) => {
  return {
    // background: "rgb(239, 241, 244)",
    width: "100%",
    height: 34,
    border: "1px solid #EBECED",
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: "6px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      outline: "none",
      background: "white",
    },
  };
});

export default TableSelect;
