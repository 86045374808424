import React from 'react';
import Stack from '@mui/material/Stack';
import { ButtonIcon, Flex, Image, useModal } from '@kikisoftware/uikit';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import Button from '@mui/material/Button';
import { PluginInfo } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import { useTranslation } from 'react-i18next';
import PluginUseButton from 'kikifrontend/features/plugins/components/PluginUseButton';
import PluginDetailModal from 'kikifrontend/features/plugins/components/PluginDetailModal';
import useLocales from 'kikifrontend/hooks/useLocales';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import VideoModal from 'kikifrontend/components/Modals/VideoModal';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { formatDuration } from 'kikifrontend/utils/date-time';
import Assets from 'kikifrontend/assets';
import PluginBuyCardModal from 'kikifrontend/features/plugins/components/PluginBuyCardModal';

const SHOW_EXPIRES_TIME_WARNING = 3 * 86400;

function PublicPluginCard(plugin: PluginInfo) {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const { getI18nText } = useLocales();

  const openVideoGuide = () => {
    if (!plugin?.tutorialVideo) return;
    openModal(VideoModal, { videoSrc: plugin.tutorialVideo, title: 'Hướng dẫn sử dụng plugin' });
  };

  const openExtendPluginModal = () => openModal(PluginBuyCardModal, { plugin, type: 'renew' });

  const dayRemaining = plugin.expiredAt ? dayjs(plugin.expiredAt).diff(dayjs().toISOString(), 'second') : undefined;
  const openDetailPluginModal = () =>
    openModal(PluginDetailModal, { pluginId: plugin._id, dayRemaining, expiredAt: plugin.expiredAt });

  return (
    <Stack
      sx={{
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        height: 1,
        position: 'relative',
      }}
      p={2}
    >
      <Stack direction='row' gap={1}>
        <Image
          src={plugin.icon}
          alt='icon'
          asyncLoading={false}
          sx={{ width: 60, height: 60, flexBasis: 60, flexShrink: 0 }}
        />
        <Stack sx={{ width: 'calc(100% - 70px)', flexShrink: 0 }}>
          <Flex sx={{ flexShrink: 1, width: 1 }} spacing={3}>
            <Tooltip arrow title={plugin.name}>
              <Typography
                variant='body1Bold'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  width: 'fit-content',
                  lineHeight: 1.3,
                  color: 'text.subColor2',
                }}
              >
                {plugin.name}
              </Typography>
            </Tooltip>
            {plugin.price === 0 ? (
              <Typography variant='body2' lineHeight={1} whiteSpace='nowrap' color='text.modalTitle'>
                FREE
              </Typography>
            ) : (
              <Typography variant='body2Bold' color='text.modalTitle' whiteSpace='nowrap'>
                {currencyFormat(`${plugin.price}`, {
                  prefix: '$',
                  suffix:
                    plugin.pricePeriod !== -1
                      ? `/${plugin.pricePeriod === 1 ? '' : plugin.pricePeriod}${t('tháng')}`
                      : '',
                  rate: USDvsVND,
                })}
              </Typography>
            )}
          </Flex>
          <Stack direction='row' alignItems='center' sx={{ minWidth: 0, mt: 0.5 }}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'text.subColor4',
              }}
            >
              {t('Tác giả')}: {plugin.author}
            </Typography>
          </Stack>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text.subColor4',
            }}
          >
            Version: {plugin.version}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant='body1'
        color='text.subColor'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical',
          height: 96,
          mt: 3,
        }}
      >
        {getI18nText(plugin.shortDescription)}
      </Typography>
      <Divider sx={{ my: 1.5 }} />
      <div className='flex justify-between items-center'>
        {/*<Image src={AUTOMATION_TAGS[0].icon} sx={{ width: 34, height: 34 }} />*/}
        <div>
          {plugin.tutorialVideo && (
            <ButtonIcon buttonType='circle' title={t('scriptGuide')} onClick={openVideoGuide}>
              <OndemandVideoOutlinedIcon color='primary' />
            </ButtonIcon>
          )}
          {dayRemaining && (dayRemaining < SHOW_EXPIRES_TIME_WARNING || dayRemaining < 0) && (
            <Tooltip
              arrow
              title={
                dayRemaining < SHOW_EXPIRES_TIME_WARNING && dayRemaining > 0 ? (
                  <Typography variant='body2'>
                    {t('Hết hạn sau')}: {formatDuration(dayRemaining)}.{' '}
                    <Typography
                      component='span'
                      color='warning.main'
                      sx={{ '&:hover': { color: 'warning.light' } }}
                      variant='body2Bold'
                      className='cursor-pointer'
                      onClick={openExtendPluginModal}
                    >
                      {t('Gia hạn')}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant='body2'>
                    {t('Expire date')}: {dayjs(plugin.expiredAt).format('DD/MM/YYYY')}{' '}
                  </Typography>
                )
              }
            >
              <div>
                <Image src={Assets.icons.IcoWarning} />
              </div>
            </Tooltip>
          )}
        </div>

        <Stack direction='row' spacing={1.5} alignItems='center'>
          <Button variant='outlined' onClick={openDetailPluginModal}>
            {t('Xem chi tiết')}
          </Button>
          <PluginUseButton plugin={plugin} dayRemaining={dayRemaining} />
        </Stack>
      </div>
    </Stack>
  );
}

export default PublicPluginCard;
