import React, { useCallback } from 'react';
import { ButtonIcon, Modal, ModalProps } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isAppConnectedAtom, systemInformationAtom } from 'kikifrontend/utils/recoil/atom';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import { notificationAtom } from 'kikifrontend/components/Notification/atoms/notification.atom';
import { useMutation } from '@tanstack/react-query';
import { localServices } from 'kikifrontend/_apis_';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { useAppSocket } from 'kikifrontend/layouts/dashboard/AppSocketProvider';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from 'wagmi';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import MuiPopConfirm from 'kikifrontend/components/PopConfirm';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { notifySuccess } from 'kikifrontend/utils/notification';

const mappingPlatform = {
  win32: 'Windows',
  win64: 'Windows',
  linux: 'Linux',
  darwin: 'MacOS',
};

function SystemInformationModal({ onClose }: ModalProps) {
  const { t } = useTranslation();
  const systemInformation = useRecoilValue(systemInformationAtom);
  const buttonNotification = useRecoilValue(notificationAtom);
  const { mutate } = useMutation(localServices.reDownloadBrowser);
  const fixVcRedist = useMutation(localServices.fixVcRedist, {
    onSuccess: () => {
      notifySuccess(t('Sửa lỗi thành công'));
    },
    onError: (err) => {
      ErrorUtils.handleCommonError(err, t);
    },
  });
  const { pingClient } = useAppSocket();
  const isAppConnected = useRecoilValue(isAppConnectedAtom);
  const { data, refetch } = useQuery([queryKeys.pingAutomation, isAppConnected], localServices.pingAutomation);

  const onDownloadGomu = useCallback(
    (browserType: BrowserType) => async () => {
      try {
        mutate(browserType);
        onClose();
        if (buttonNotification) {
          buttonNotification.click();
        }
      } catch (err) {
        ErrorUtils.handleCommonError(err, t);
      }
    },
    [buttonNotification],
  );

  return (
    <Modal onClose={onClose} title={t('sidebar.monitor')} width={500}>
      <Stack divider={<Divider orientation='horizontal' flexItem />} spacing={1.5}>
        <div className='flex items-center'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            {t('kikiVersion')}
          </Typography>
          <div className='flex gap-2 items-center'>
            <div className='flex gap-2 items-center'>
              {!isAppConnected && <CloseIcon color='error' />}
              <Typography variant='body2'>{systemInformation?.currentVersion ?? 'Unknown'}</Typography>
            </div>
            <ButtonIcon buttonType='circle' size='small' onClick={pingClient}>
              <RefreshIcon fontSize='small' />
            </ButtonIcon>
          </div>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            {t('platform')}
          </Typography>
          <div className='flex gap-2 items-center'>
            {!isAppConnected && <CloseIcon color='error' />}
            <Typography variant='body2'>
              {mappingPlatform[systemInformation?.currentPlatform as keyof typeof mappingPlatform] ?? 'Unknown'}
            </Typography>
          </div>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            {t('Phiên bản hệ điều hành')}
          </Typography>
          <div className='flex gap-2 items-center'>
            {!isAppConnected && <CloseIcon color='error' />}
            <Typography variant='body2'>{systemInformation?.currentPlatformVersion ?? 'Unknown'}</Typography>
          </div>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            Storage
          </Typography>
          <Typography variant='body2'>Amazon</Typography>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            API Port
          </Typography>
          <Typography variant='body2'>8000</Typography>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            {t('browserVersion')}
          </Typography>
          <div className='flex gap-2 items-center'>
            {!isAppConnected && <CloseIcon color='error' />}
            <Typography variant='body2'>{systemInformation?.chromeVersion ?? 'Unknown'}</Typography>
          </div>
        </div>
        <div className='flex items-center'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            GomuBrowser version
          </Typography>
          <div className='flex items-center gap-2'>
            <div className='flex gap-2 items-center'>
              {!isAppConnected && <CloseIcon color='error' />}
              <Typography variant='body2'>
                {systemInformation?.gomuVersion ??
                  (systemInformation?.appDataGomuStatus ? t(systemInformation.appDataGomuStatus) : 'Unknown')}
              </Typography>
            </div>
            {isAppConnected && systemInformation && (
              <>
                {systemInformation.appDataGomuStatus === 'unknown' ? (
                  <ButtonIcon
                    buttonType='circle'
                    sx={{ p: 0.5 }}
                    onClick={onDownloadGomu('gomubrowser')}
                    title={t('downloadNow')}
                  >
                    <DownloadIcon fontSize='small' />
                  </ButtonIcon>
                ) : (
                  <MuiPopConfirm
                    title={t('Bạn có chắc chắn muốn tải và cài đặt lại trình duyệt không?', { browser: 'Gomu' })}
                    onConfirm={onDownloadGomu('gomubrowser')}
                  >
                    <ButtonIcon buttonType='circle' sx={{ p: 0.5 }} title={t('Tải và cài đặt lại')}>
                      <DownloadingOutlinedIcon fontSize='small' />
                    </ButtonIcon>
                  </MuiPopConfirm>
                )}
              </>
            )}
          </div>
        </div>
        <div className='flex items-center'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            Kifox version
          </Typography>
          <div className='flex items-center gap-2'>
            <div className='flex gap-2 items-center'>
              {!isAppConnected && <CloseIcon color='error' />}
              <Typography variant='body2'>
                {systemInformation?.kifoxVersion ??
                  (systemInformation?.appDataKiFoxStatus ? t(systemInformation.appDataKiFoxStatus) : 'Unknown')}
              </Typography>
            </div>
            {isAppConnected && systemInformation && (
              <>
                {systemInformation.appDataKiFoxStatus === 'unknown' ? (
                  <ButtonIcon
                    buttonType='circle'
                    sx={{ p: 0.5 }}
                    onClick={onDownloadGomu('kifox')}
                    title={t('downloadNow')}
                  >
                    <DownloadIcon fontSize='small' />
                  </ButtonIcon>
                ) : (
                  <div className='flex'>
                    <MuiPopConfirm
                      title={t('Bạn có chắc chắn muốn tải và cài đặt lại trình duyệt không?', { browser: 'Kifox' })}
                      onConfirm={onDownloadGomu('kifox')}
                    >
                      <ButtonIcon buttonType='circle' sx={{ p: 0.5 }} title={t('Tải và cài đặt lại')}>
                        <DownloadingOutlinedIcon fontSize='small' />
                      </ButtonIcon>
                    </MuiPopConfirm>
                    <ButtonIcon
                      buttonType='circle'
                      sx={{ p: 0.5 }}
                      title='Fix vc_redist'
                      onClick={() => fixVcRedist.mutateAsync()}
                      loading={fixVcRedist.isLoading}
                    >
                      <HandymanOutlinedIcon fontSize='small' />
                    </ButtonIcon>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/*<div className='flex'>*/}
        {/*  <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>*/}
        {/*    Kifox version*/}
        {/*  </Typography>*/}
        {/*  <div className='flex gap-2 items-center'>*/}
        {/*    {!isAppConnected && <CloseIcon color='error' />}*/}
        {/*    <Typography variant='body2'>{systemInformation?.kifoxVersion ?? 'Unknown'}</Typography>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='flex items-center'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            {t('automationVersion')}
          </Typography>
          <div className='flex gap-2 items-center'>
            {!isAppConnected && <CloseIcon color='error' />}
            <Typography variant='body2'>
              {isAppConnected && data?.currentVersion ? data?.currentVersion : 'Unknown'}
            </Typography>
            {isAppConnected && (
              <ButtonIcon buttonType='circle' size='small' onClick={() => refetch()}>
                <RefreshIcon fontSize='small' />
              </ButtonIcon>
            )}
          </div>
        </div>
        <div className='flex'>
          <Typography variant='body2Bold' sx={{ flexBasis: 240 }}>
            Iris Connector
          </Typography>
          <div className='flex gap-2 items-center'>
            {!isAppConnected && <CloseIcon color='error' />}
            <Typography variant='body2'>{systemInformation?.irisConnectorStatus ?? 'Unknown'}</Typography>
          </div>
        </div>
      </Stack>
    </Modal>
  );
}

export default SystemInformationModal;
