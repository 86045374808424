import React, { useMemo, useState } from 'react';
import { Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import SelectGroup from 'kikifrontend/features/automation/v3/AutomationTask/components/SelectGroup';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import { Controller, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { CreationAutocompleteOptions, SelectFormField, TextFormField } from 'kikifrontend/components';
import Button from '@mui/material/Button';
import { PROFILE_EDITOR_BROWSERS } from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import BrowserCard from 'kikifrontend/features/profiles/profileEditor/components/BrowserCard';
import { CreateFixedProfileFormValues } from 'kikifrontend/features/apolloScan/types/createProfile.types';
import ApolloCreateProfiles from 'kikifrontend/features/apolloScan/components/ApolloCreateProfiles';
import { groupQuery } from 'kikifrontend/features/groups/queries/group.query';
import FolderIcon from '@mui/icons-material/Folder';
import Skeleton from '@mui/material/Skeleton';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import track from 'kikifrontend/utils/analytics';
import { devices } from 'kikifrontend/features/profiles/profileEditor/ui/ProfileEditorBasic/ProfileEditorProxy';
import { ProfileProps } from 'kikifrontend/types/profile.types';

const options = [
  { value: 'create', label: 'Tạo profile được fix sẵn', icon: <AccountCircleIcon /> },
  { value: 'fixed', label: 'Fix profile có sẵn', disabled: true, icon: <AutoFixHighRoundedIcon /> },
];

const defaultValues: CreateFixedProfileFormValues = {
  profileName: '',
  count: '1',
  browserType: 'gomubrowser',
  system: { value: 'windows', label: 'Windows' },
  group: '',
};

const GroupField = ({ form }: { form: UseFormReturn<typeof defaultValues> }) => {
  const { t } = useTranslation();
  const { data, isLoading } = groupQuery({ page: 1, limit: 400 });

  const groupOptions = useMemo(
    () =>
      data?.results?.map((group) => ({
        label: group.name,
        value: group._id,
        icon: <FolderIcon sx={{ color: group.color }} />,
      })) ?? [],
    [data?.results],
  );

  return (
    <>
      {isLoading ? (
        <Skeleton animation='wave' height={44} />
      ) : (
        data && (
          <SelectFormField
            name='group'
            label={t('home.group')}
            control={form.control}
            options={groupOptions}
            disableEnterOnSearchValue={false}
          />
        )
      )}
    </>
  );
};

export const CreateMultiProfile = ({ cloneProfile }: { cloneProfile?: ProfileProps }) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...(cloneProfile && {
        browserType: cloneProfile.profileInfo.browserType,
      }),
    },
  });
  const { statistic } = useRecoilValue(statisticAtom);

  const { handleSubmit, control } = form;
  const browserType = useWatch({ control, name: 'browserType' });
  const system = useWatch({ control, name: 'system' });

  const onSubmit = (values: CreateFixedProfileFormValues) => {
    if (!cloneProfile) {
      track.event('profile_create_apollo_scan', {
        package_name: statistic?.package?.name,
        total_profile: values.count,
        browser_type: values.browserType,
        profile_os: values.system?.label,
      });
    }
    openModal(ApolloCreateProfiles, { values, cloneProfile });
  };

  return (
    <div className=''>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-4'>
          <TextFormField name='profileName' control={control} label={t('profile.name')} />
          <Controller
            name='browserType'
            control={control}
            render={({ field }) => {
              return (
                <div className='flex flex-wrap gap-2'>
                  {PROFILE_EDITOR_BROWSERS.map((browser) => (
                    <BrowserCard
                      key={browser.value}
                      browser={browser}
                      isSelected={field.value === browser.value}
                      onChange={field.onChange}
                      disabled={
                        !!cloneProfile ||
                        (browser.value === 'kifox' && (system?.value === 'android' || system?.value === 'ios'))
                      }
                    />
                  ))}
                </div>
              );
            }}
          />
          <GroupField form={form} />
          <div className='flex gap-3'>
            <SelectFormField
              name='system'
              label={t('Hệ điều hành')}
              control={control}
              options={browserType === 'kikibrowser' || browserType === 'kifox' ? devices.slice(0, 3) : devices}
              valueType='object'
              isOptionEqualToValue={(option, value) =>
                (option as CreationAutocompleteOptions).value === (value as CreationAutocompleteOptions).value
              }
            />
            <TextFormField type='number' name='count' control={control} label={t('count')} min={1} />
          </div>
        </div>
        <div className='flex gap-3 items-center justify-end mt-6'>
          <Button variant='contained' color='secondary' onClick={() => closeModal()}>
            {t('Hủy bỏ')}
          </Button>
          <Button variant='contained' type='submit'>
            {cloneProfile ? t('Xác nhận') : t('createProfile')}
          </Button>
        </div>
      </form>
    </div>
  );
};

function ApolloFixProfileModal({ onClose }: ModalProps) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(options[0].value);

  return (
    <Modal onClose={onClose} title={t('Tạo Profile đã được cấu hình')} width={700}>
      <SelectGroup options={options} value={selectedTab} onChange={setSelectedTab} />
      <div className='mt-6'>{selectedTab === options[0].value && <CreateMultiProfile />}</div>
    </Modal>
  );
}

export default ApolloFixProfileModal;
