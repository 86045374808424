import React, { useContext, useState } from 'react';

interface ModalWithClose {
  onClose: () => void;
  isSecondModal?: boolean;
}

type ModalProps<T = any> = T & ModalWithClose;

interface Modal {
  modalComponent?: React.ComponentType | null;
  modalNode?: React.ReactNode;
  modalProps?: any;
}

type ModalOpener = <M extends React.ComponentType<any>, P extends React.ComponentProps<M>>(
  modal?: M | null,
  modalProps?: Omit<P, 'onClose'>,
  modalNode?: React.ReactNode,
  options?: {
    isSecondModal?: boolean;
  },
) => void;

interface ModalContextProps {
  openModal: ModalOpener;
  closeModal: (props?: { closeAll?: boolean }) => void;
  isModalHasOpening: boolean;
}

const ModalContext = React.createContext<ModalContextProps>({
  openModal: () => {
    // do something
  },
  closeModal: () => {
    // do something
  },
  isModalHasOpening: false,
});

const ModalProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [ModalNode, setModalNode] = useState<Modal>();
  const [SecondModalNode, setSecondModalNode] = useState<Modal>();

  const onClose = ({ closeAll }: { closeAll?: boolean } | undefined = {}) => {
    if (closeAll) {
      setModalNode(undefined);
      setSecondModalNode(undefined);
    } else {
      if (SecondModalNode) {
        setSecondModalNode(undefined);
      } else {
        setModalNode(undefined);
      }
    }
  };

  return (
    <ModalContext.Provider
      value={{
        openModal: (modal, modalProps, modalNode, options) => {
          if (options?.isSecondModal) {
            setSecondModalNode({
              modalComponent: modal,
              modalProps: modalProps,
              modalNode: modalNode,
            });
          } else {
            setModalNode({
              modalComponent: modal,
              modalProps: modalProps,
              modalNode: modalNode,
            });
          }
        },
        closeModal: onClose,
        isModalHasOpening: !!ModalNode,
      }}
    >
      {ModalNode && (
        <>
          {ModalNode.modalComponent && (
            // @ts-ignore
            <ModalNode.modalComponent {...{ onClose, ...ModalNode.modalProps }} />
          )}
          {ModalNode.modalNode && ModalNode.modalNode}
        </>
      )}
      {SecondModalNode && (
        <>
          {SecondModalNode.modalComponent && (
            // @ts-ignore
            <SecondModalNode.modalComponent {...{ onClose, isSecondModal: true, ...SecondModalNode.modalProps }} />
          )}
          {SecondModalNode.modalNode && SecondModalNode.modalNode}
        </>
      )}
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextProps {
  return useContext(ModalContext);
}

export { ModalProvider, useModal };
export type { ModalProps, ModalOpener, Modal, ModalContextProps };
