import React from 'react';
import { Image } from '@kikisoftware/uikit';
import { Box } from '@mui/material';
import { CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';

function TokenIconWithNetwork({ token, iconSize = 32 }: { token: CryptoPaymentProps; iconSize?: number }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Image src={token.logo} asyncLoading={false} sx={{ width: iconSize, height: iconSize }} />
      <Image
        src={token.network.icon}
        asyncLoading={false}
        sx={{
          width: iconSize / 2,
          height: iconSize / 2,
          position: 'absolute',
          right: (-1 * iconSize) / 8,
          bottom: (-1 * iconSize) / 8 - 1,
        }}
      />
    </Box>
  );
}

export default TokenIconWithNetwork;
