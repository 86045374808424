import React, { useMemo } from 'react';
import { useAccount, useBalance, useDisconnect, useWaitForTransaction } from 'wagmi';
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { ButtonIcon, Flex, useModal } from '@kikisoftware/uikit';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import { useTranslation } from 'react-i18next';
import useTransferDepositToken from 'kikifrontend/hooks/useTransferDepositToken';
import useAllowance from 'kikifrontend/hooks/useAllowance';
import DepositSuccessfullyModal from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/DepositSuccessfullyModal';
import { currencyFormat, formatBalanceInCrypto, middleTruncate } from 'kikifrontend/utils/format';
import TokenWithAmount from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/TokenWithAmount';
import { getNetworkName } from 'kikifrontend/blockchains/configs/networks';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

interface ExternalWallet {
  method: string;
  crypto: CryptoPaymentProps;
  amount: string;
  flexibleFee: number;
  fee: number;
  fixedFee: number;
  originalAmount: string;
  onBack: () => void;
}

function PayWithExternalWallet({
  crypto,
  amount,
  flexibleFee,
  fee,
  fixedFee,
  method,
  originalAmount,
  onBack,
}: ExternalWallet) {
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();
  const { openModal } = useModal();
  const {
    data: balanceData,
    refetch,
    isLoading: isLoadingBalance,
  } = useBalance({
    address,
    token: crypto.contract,
    chainId: crypto.network.chainId,
  });

  const isEnoughBalance = useMemo(
    () => (balanceData ? Number(balanceData?.formatted) > Number(amount) : false),
    [balanceData, amount],
  );

  const {
    depositAmount,
    depositDataTx,
    chainInformation: { isEqualChain, isLoadingSwitchChain, selectPaymentChain },
    switchToPaymentChain,
    isLoadingContractWrite,
    refetchInitContract,
    resetContractWrite,
  } = useTransferDepositToken(crypto, amount);

  const { approve, isLoadingWriteContractApprove, allowance, isLoadingApprove, isLoadingAllowance } = useAllowance(
    crypto,
    amount,
    { refetchInitContract, resetContractWrite },
  );

  const { isLoading: isLoadingDeposit } = useWaitForTransaction({
    hash: depositDataTx?.hash,
    onSuccess: (data) => {
      if (data) {
        openModal(DepositSuccessfullyModal, {
          billSummary: {
            crypto,
            amount,
            flexibleFee,
            fee,
            fixedFee,
            method,
            originalAmount,
            tx: data,
          },
        });
      }
    },
    // onError: () => {},
  });
  const onDisconnect = () => {
    disconnect();
  };

  return (
    <Box px={6}>
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              width: 24,
              height: 24,
              color: 'white',
              bgcolor: 'primary.main',
              borderRadius: 12,
              flexShrink: 0,
            }}
          >
            {allowance.isAllowance ? <CheckOutlinedIcon sx={{ color: 'white', fontSize: 20 }} /> : '1'}
          </Stack>
          <Typography variant='body2'>{t('Phê duyệt')}</Typography>
        </Stack>
        <Box
          sx={{
            width: 140,
            height: '1px',
            bgcolor: allowance.isAllowance ? 'primary.main' : 'divider',
          }}
        />
        <Stack direction='row' spacing={1} alignItems='center'>
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              width: 24,
              height: 24,
              color: 'white',
              bgcolor: allowance.isAllowance ? 'primary.main' : '#E5E8EB',
              borderRadius: 12,
              flexShrink: 0,
            }}
          >
            2
          </Stack>
          <Typography variant='body2' color={allowance.isAllowance ? 'text.primary' : '#E5E8EB'}>
            {t('Xác nhận')}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        sx={{ border: '1px solid', borderColor: 'border.neutral', px: 2, py: 2, borderRadius: 1.25, mt: 2.5 }}
      >
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Phương thức thanh toán')}
          </Typography>
          <Typography variant='body2Bold'>{method}</Typography>
        </Flex>
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Địa chỉ ví')}
          </Typography>
          {isConnected ? (
            <Stack direction='row' spacing={1}>
              <Typography variant='body2Bold'>{address ? middleTruncate(address) : '--'}</Typography>

              <Typography
                variant='body2Bold'
                color='error.light'
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={onDisconnect}
              >
                {t('Ngắt kết nối')}
              </Typography>
            </Stack>
          ) : (
            <CircularProgress size='1rem' />
          )}
        </Flex>
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Số tiền nạp')}
          </Typography>
          <TokenWithAmount
            amount={amount}
            tokenName={crypto.name}
            tokenIcon={crypto.logo}
            iconSize={20}
            token={crypto}
          />
        </Flex>
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Network')}
          </Typography>
          <Typography variant='body2Bold'>{crypto.network.name}</Typography>
        </Flex>
        <Flex sx={{ alignItems: 'flex-start' }}>
          <Typography variant='body2' color='text.subColor4'>
            {t('Phí giao dịch')}
          </Typography>
          <Stack alignItems='flex-end'>
            <Typography variant='body2Bold'>
              {currencyFormat(fee)} {crypto.name}
            </Typography>
            <Typography variant='body2' color='text.subColor4' fontStyle='italic'>
              {t('Phí')}: {currencyFormat(fee)} ({fixedFee}$ + {flexibleFee}% {t('Số tiền thanh toán')})
            </Typography>
          </Stack>
        </Flex>
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Số dư ví')}
          </Typography>
          {isConnected ? (
            <Stack direction='row' alignItems='center' spacing={0.5}>
              <Typography variant='body2Bold' color={isEnoughBalance ? 'primary.main' : 'error.light'}>
                {balanceData ? formatBalanceInCrypto(balanceData.formatted) : '--'} {crypto.name}
              </Typography>{' '}
              <ButtonIcon buttonType='circle' onClick={() => refetch()}>
                <RefreshOutlinedIcon sx={{ fontSize: 20 }} />
              </ButtonIcon>
            </Stack>
          ) : (
            <CircularProgress size='1rem' />
          )}
        </Flex>
        <Divider />
        <Flex>
          <Typography variant='body2' color='text.subColor4'>
            {t('Tổng thanh toán')}
          </Typography>
          <TokenWithAmount
            amount={
              <Typography variant='h6'>
                {amount} {crypto.name}
              </Typography>
            }
            tokenName={crypto.name}
            tokenIcon={crypto.logo}
            token={crypto}
          />
        </Flex>
      </Stack>
      {isConnected && (
        <>
          {isLoadingBalance ? (
            <CircularProgress size='1rem' />
          ) : (
            <>
              {!isEnoughBalance ? (
                <>
                  <Typography
                    color='error.light'
                    textAlign='center'
                    component='p'
                    mt={2.5}
                    px={2}
                    fontSize={12}
                    fontWeight={700}
                  >
                    {t(
                      'Số dư không đủ, vui lòng nạp thêm tiền vào ví và thử lại hoặc liên hệ bộ phận CSKH qua livechat để được hỗ trợ.',
                    )}
                  </Typography>
                  <Button fullWidth sx={{ mt: 1 }} onClick={onBack}>
                    {t('back')}
                  </Button>
                </>
              ) : (
                <Typography fontSize={12} color='#8B9299' textAlign='center' component='p' mt={2.5} px={2}>
                  {t(
                    'Xác nhận đồng nghĩa với việc bạn đã đồng ý với tất các thông tin trên đã đúng và chấp nhận thanh toán',
                  )}
                </Typography>
              )}
            </>
          )}

          <Box mt={3} sx={{ textAlign: 'center' }}>
            {isLoadingSwitchChain ? (
              <Typography variant='body2Bold' color='info.main'>
                Đang đổi chain
              </Typography>
            ) : isEqualChain ? (
              isEnoughBalance &&
              !isLoadingAllowance && (
                <>
                  {allowance.isAllowance ? (
                    <LoadingButton
                      variant='contained'
                      onClick={() => depositAmount()}
                      loading={isLoadingDeposit || isLoadingContractWrite}
                      fullWidth
                    >
                      {t('Thanh toán')}
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      variant='contained'
                      onClick={() => approve?.()}
                      loading={isLoadingApprove || isLoadingWriteContractApprove}
                      fullWidth
                    >
                      {t('Phê duyệt')}
                    </LoadingButton>
                  )}
                  <Button fullWidth sx={{ mt: 1 }} onClick={onBack}>
                    {t('back')}
                  </Button>
                </>
              )
            ) : (
              <>
                <Typography variant='body2Bold' color='warning.main'>
                  {t('Network không đúng. Vui lòng đổi network sang')}: {getNetworkName(selectPaymentChain.name)}
                </Typography>
                <Button variant='contained' onClick={switchToPaymentChain} sx={{ mt: 2 }} fullWidth>
                  {t('Đổi network')}
                </Button>
              </>
            )}
          </Box>
        </>
      )}
      {/*{isDevelopment && (*/}
      {/*  <LoadingButton*/}
      {/*    variant='contained'*/}
      {/*    onClick={() => approve?.()}*/}
      {/*    loading={isLoadingApprove || isLoadingWriteContractApprove}*/}
      {/*  >*/}
      {/*    {t('Reset approve')}*/}
      {/*  </LoadingButton>*/}
      {/*)}*/}
    </Box>
  );
}

export default PayWithExternalWallet;
