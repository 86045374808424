import React from 'react';
import {
  CreationAutocompleteOptions,
  CreationAutocompleteProps,
} from 'kikifrontend/components/FreeSoloCreationAutocomplete/types';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { alpha } from '@mui/material/styles';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';

const filter = createFilterOptions<CreationAutocompleteOptions>();

function FreeSoloCreationAutocomplete({
  options,
  label,
  sx,
  disabled,
  value,
  onChange,
  onCreateNewOption,
  disabledCreation,
  renderInput,
  size = 'small',
  disableClearable,
  isOptionEqualToValue,
  disableEnterOnSearchValue,
  ...props
}: CreationAutocompleteProps) {
  const { t } = useTranslation();
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          onCreateNewOption?.(newValue);
          onChange?.(newValue);
        } else if (newValue && newValue.inputValue) {
          onCreateNewOption?.(newValue.inputValue);
          onChange?.(newValue.inputValue);
        } else {
          onChange?.(newValue?.value ?? '');
        }
      }}
      filterOptions={(options, params) => {
        if (disableEnterOnSearchValue) {
          return options;
        }
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (!disabledCreation && inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `+ ${t('Tạo')} "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (!disabledCreation && option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return t(option.label);
      }}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img, & > svg': { mr: 1, flexShrink: 0 } }} {...props}>
          {isString(option.icon) ? (
            <img loading='lazy' width='20' height='20' src={option.icon} alt='' className='rounded-full' />
          ) : (
            option.icon
          )}
          {t(option.label)}
        </Box>
      )}
      sx={{ width: 1, ...sx }}
      freeSolo
      renderInput={(params) => {
        const option = options.find((opt) => opt.label === params.inputProps.value);
        return renderInput ? (
          renderInput(params)
        ) : (
          <TextField
            {...params}
            label={label}
            size={size}
            InputProps={{
              ...params.InputProps,
              ...(option?.icon && {
                startAdornment: (
                  <InputAdornment position='start' sx={{ mr: 0, ml: 1 }}>
                    {isString(option.icon) ? (
                      <img loading='lazy' width='20' height='20' src={option.icon} alt='' className='rounded-full' />
                    ) : (
                      option.icon
                    )}
                  </InputAdornment>
                ),
              }),
            }}
            sx={{
              input: {
                pl: 0,
              },
            }}
          />
        );
      }}
      disabled={disabled}
      forcePopupIcon
      popupIcon={<KeyboardArrowDownOutlinedIcon sx={{ fontSize: 18 }} />}
      ListboxProps={{
        sx: {
          '&::-webkit-scrollbar': {
            width: 6,
            backgroundColor: 'background.onLayout',
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: 3,
            backgroundColor: alpha('#000000', 0.5),
          },
        },
      }}
      disableClearable={disableClearable}
      isOptionEqualToValue={isOptionEqualToValue}
      {...props}
    />
  );
}

export default FreeSoloCreationAutocomplete;
