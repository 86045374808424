import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const ErrorButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: 'rgba(239, 70, 111, 0.05)',
  color: theme.palette.error.light,
  '&:hover': { backgroundColor: 'rgba(239, 70, 111, 0.1)' },
}));

export default ErrorButton;
