import { useMutation, useQueryClient } from '@tanstack/react-query';
import { pluginServices } from 'kikifrontend/_apis_';
import { notifySuccess } from 'kikifrontend/utils/notification';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'kikifrontend/hooks/useAuth';

export const buyPluginMutation = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getSystemStatistic } = useAuth();

  return useMutation({
    mutationFn: pluginServices.buyPlugin,
    onSuccess: async () => {
      notifySuccess(t('Cài đặt thành công'));
      await queryClient.refetchQueries([queryKeys.plugins.personal.list]);
      onSuccess?.();
      await getSystemStatistic();
    },
    onError: (err) => {
      ErrorUtils.handleCommonError(err, t);
    },
  });
};

export const extendPluginMutation = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getSystemStatistic } = useAuth();

  return useMutation({
    mutationFn: pluginServices.extendPlugin,
    onSuccess: async () => {
      notifySuccess(t('Gia hạn thành công'));
      await queryClient.refetchQueries([queryKeys.plugins.personal.list]);
      onSuccess?.();
      await getSystemStatistic();
    },
    onError: (err) => {
      ErrorUtils.handleCommonError(err, t);
    },
  });
};
