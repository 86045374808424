import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { defaultChangePackageApplyCoupon } from 'kikifrontend/features/paymentCenter/utils/constants';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import UserPackageBanner from 'kikifrontend/features/paymentCenter/components/UserPackageBanner';
import PackageTimeOptions from 'kikifrontend/features/paymentCenter/components/PackageTimeOptions';
import PackageCoupon from 'kikifrontend/features/paymentCenter/components/PackageCoupon';
import { PackageChangeProps } from 'kikifrontend/features/paymentCenter/types/package.types';
import { changePackageAtom } from 'kikifrontend/features/paymentCenter/atom/changePackage.atom';
import { loadingCalculate } from 'kikifrontend/features/paymentCenter/atom/loading.atom';
import { changePackageSelectedCouponAtom } from 'kikifrontend/features/paymentCenter/atom/coupon.atom';
import ChangePackageSummary from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageSummary';
import { getRemainingBalanceQuery } from 'kikifrontend/features/paymentCenter/queries/remainingBalance.query';
import { calculateChangePackagePrice } from 'kikifrontend/features/paymentCenter/selectors/changePackage.selector';
import { currencyFormat } from 'kikifrontend/utils/format';

interface ChangePackageModalProps extends ModalProps {
  pack: PackageChangeProps;
}

function ChangePackageModal({ onClose, pack }: ChangePackageModalProps) {
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);
  const [changeState, setChangeState] = useRecoilState(changePackageAtom);
  const setLoading = useSetRecoilState(loadingCalculate);
  const [selectedCoupon, setSelectedCoupon] = useRecoilState(changePackageSelectedCouponAtom);
  const { data } = getRemainingBalanceQuery();
  const resultPrice = useRecoilValue(calculateChangePackagePrice({ pkg: pack, remainingData: data?.data }));

  const trackParams = useMemo(
    () => ({
      selected_package_name: pack.name,
      period: resultPrice?.packageMonths ?? '-',
      is_use_coupon: !!resultPrice?.coupon,
      sub_total: currencyFormat(resultPrice?.packagePriceInUSD, { prefix: '$' }),
      total: currencyFormat(resultPrice?.applyCouponPackagePriceInUSD, { prefix: '$' }),
    }),
    [pack, resultPrice],
  );

  const coupon =
    statistic && changeState.selectedTime
      ? // @ts-ignore
        defaultChangePackageApplyCoupon[pack.name][changeState.selectedTime]
      : undefined;

  const selectTimeOptions = (isYear: boolean) => () => {
    setChangeState((prevState) => ({ ...prevState, isYear }));
  };

  const onSelectTime = (time: string) => () => {
    if (changeState.selectedTime !== time) {
      setLoading(true);
    }
    setChangeState((prevState) => ({ ...prevState, selectedTime: time }));
  };

  return (
    <Modal
      onClose={onClose}
      title={`${t('Thanh toán cho gói')}: ${pack.name}`}
      width={525}
      sx={{ '.MuiDialog-container': { py: 0 }, '.MuiPaper-root': { m: 2, maxHeight: '100%' } }}
    >
      <UserPackageBanner />
      {pack ? (
        <PackageTimeOptions
          isYear={changeState.isYear}
          selectedTime={changeState.selectedTime}
          currentPackagePrice={pack}
          onSelectTime={onSelectTime}
          selectTimeOptions={selectTimeOptions}
        />
      ) : (
        <></>
      )}
      <PackageCoupon
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        defaultApplyCoupon={coupon}
        selectedTime={changeState.selectedTime}
        currentPackagePrice={pack}
        trackParams={trackParams}
        eventName='payment_center_change_package_apply_coupon'
      />
      <ChangePackageSummary pack={pack} resultPrice={resultPrice} />
    </Modal>
  );
}

export default ChangePackageModal;
