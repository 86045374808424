import React, { useCallback, useEffect, useState } from 'react';
import { Modal, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import { ApolloCreateProfilesProps } from 'kikifrontend/features/apolloScan/types/createProfile.types';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { nanoid } from 'nanoid';
import Typography from '@mui/material/Typography';
import SimpleBar from 'simplebar-react';
import { profileApis } from 'kikifrontend/_apis_/profiles';
import { generateDefaultValueFromFingerprintConfig } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/generateDefaultValueFromFingerprintConfig';
import { sleep } from 'kikifrontend/utils/helpers';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { groupServices } from 'kikifrontend/_apis_';
import Button from '@mui/material/Button';
import { Dot } from 'kikifrontend/components';
import { useAuth } from 'kikifrontend/hooks/useAuth';
import useCheckUserIp from 'kikifrontend/hooks/useCheckUserIp';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { ProxyTypeOrSystemOrResolution } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import { useQueryClient } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';

const generateProfile = (profileName: string, count: number) => {
  const arr = [];
  for (let i = 0; i < count; i++) {
    arr.push({
      id: nanoid(8),
      name: `${profileName} [${i + 1}]`,
      status: 'initial',
      error: false,
    });
  }

  return arr;
};

function ApolloCreateProfiles({ values, cloneProfile }: ApolloCreateProfilesProps) {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [profiles, setProfiles] = useState(generateProfile(values.profileName, Number(values.count)));
  const { getSystemStatistic } = useAuth();
  const { data: userIp } = useCheckUserIp();
  const { statistic } = useRecoilValue(statisticAtom);
  const queryClient = useQueryClient();

  const updateProfileStatus = (id: string, status: string, isError = false) => {
    const _profiles = [...profiles];
    const findIndex = _profiles.findIndex((profile) => profile.id === id);
    if (findIndex === -1) return;
    _profiles[findIndex].status = status;
    _profiles[findIndex].error = isError;
    setProfiles(_profiles);
  };

  const createProfile = useCallback(async () => {
    if (!statistic || !userIp) return;
    const groups = await groupServices.getAllUserGroups({ page: 1, limit: 1 });
    for (let i = 0, count = profiles.length; i < count; i++) {
      const profile = profiles[i];
      updateProfileStatus(profile.id, 'getConfig');
      try {
        const config = await profileApis.createProfileConfigV2(
          values.system.value ?? 'Windows',
          values.browserType ?? 'gomubrowser',
        );
        const newProfile = generateDefaultValueFromFingerprintConfig({
          fingerprintConfig: config,
          name: profiles[i].name,
          system: values.system.value ?? 'windows',
          browserType: values.browserType ?? 'gomubrowser',
          userIp,
          statistic,
        });

        await sleep(1000);
        updateProfileStatus(profile.id, 'creating');
        const fn = cloneProfile ? profileApis.cloneCookieAccountProfile : profileApis.mutateAccountProfile;
        await fn({
          config: {
            ...newProfile,
            group: values.group || groups.results[0]?._id || '',
            advancedFingerprint: {
              ...newProfile.advancedFingerprint,
              doNotTrack: Number(newProfile.advancedFingerprint.doNotTrack),
              geolocation: [newProfile.addFormField.latitude, newProfile.addFormField.longitude],
            },
            system: values.system as ProxyTypeOrSystemOrResolution,
            ...(cloneProfile && {
              proxy: cloneProfile.profileInfo.proxy,
            }),
          },
          ...(cloneProfile && {
            cookieProfile: cloneProfile.profileInfo._id,
          }),
        });
        await sleep(1500);
        updateProfileStatus(profile.id, 'success');
      } catch (err) {
        ErrorUtils.handleError(err, {
          isErrorInstanceFn: (error) => {
            updateProfileStatus(profile.id, error.data.reason, true);
          },
        });
      }
    }
  }, [userIp, statistic]);

  useEffect(() => {
    void createProfile();
  }, [createProfile]);

  const onClose = async () => {
    closeModal();
    void queryClient.refetchQueries([queryKeys.profiles.listProfile]);
    void getSystemStatistic();
  };
  return (
    <Modal title={t('Đang tạo profile')} width={500} onClose={onClose} disableCloseWhenClickOutside>
      <SimpleBar style={{ maxHeight: 500 }}>
        <Stack spacing={2} divider={<Divider orientation='horizontal' flexItem />} pr={2}>
          {profiles.map((profile) => (
            <div key={profile.id} className='flex items-center justify-between gap-3'>
              <Typography variant='body2' className='w-2/3 overflow-hidden whitespace-nowrap text-ellipsis'>
                {profile.name}
              </Typography>
              <div className='flex gap-2 items-center'>
                <Dot
                  size={10}
                  color={profile.error ? 'error.main' : profile.status === 'success' ? 'success.main' : 'info.main'}
                />
                <Typography variant='body2' className='whitespace-nowrap'>
                  {t(profile.status)}
                </Typography>
              </div>
            </div>
          ))}
        </Stack>
      </SimpleBar>
      {profiles.every((profile) => profile.status === 'success') && (
        <Button variant='contained' fullWidth sx={{ mt: 5 }} onClick={onClose}>
          OK
        </Button>
      )}
    </Modal>
  );
}

export default ApolloCreateProfiles;
