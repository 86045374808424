export const LOCALES_COUNTRIES = [
  'aa',
  'aa',
  'aa',
  'af',
  'am',
  'an',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'ar',
  'as',
  'ast',
  'az',
  'be',
  'ber',
  'ber',
  'bg',
  'bn',
  'bn',
  'bo',
  'bo',
  'br',
  'bs',
  'byn',
  'ca',
  'ca',
  'ca',
  'ca',
  'crh',
  'cs',
  'csb',
  'cy',
  'da',
  'de',
  'de',
  'de',
  'de',
  'de',
  'de',
  'dv',
  'dz',
  'el',
  'el',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'es',
  'et',
  'eu',
  'eu',
  'fa',
  'fi',
  'fil',
  'fo',
  'fr',
  'fr',
  'fr',
  'fr',
  'fr',
  'fur',
  'fy',
  'fy',
  'ga',
  'gd',
  'gez',
  'gez',
  'gl',
  'gu',
  'gv',
  'ha',
  'he',
  'hi',
  'hne',
  'hr',
  'hsb',
  'ht',
  'hu',
  'hy',
  'id',
  'ig',
  'ik',
  'is',
  'it',
  'it',
  'iu',
  'iw',
  'ja',
  'ka',
  'kk',
  'kl',
  'km',
  'kn',
  'ko',
  'ks',
  'ku',
  'kw',
  'ky',
  'lg',
  'li',
  'li',
  'lo',
  'lt',
  'lv',
  'mai',
  'mg',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms',
  'mt',
  'my',
  'nb',
  'nds',
  'nds',
  'ne',
  'nl',
  'nl',
  'nl',
  'nn',
  'no',
  'nr',
  'nso',
  'oc',
  'om',
  'om',
  'or',
  'pa',
  'pa',
  'pap',
  'pl',
  'ps',
  'pt',
  'pt',
  'ro',
  'ru',
  'ru',
  'rw',
  'sa',
  'sc',
  'sd',
  'se',
  'shs',
  'si',
  'sid',
  'sk',
  'sl',
  'so',
  'so',
  'so',
  'so',
  'sq',
  'sr',
  'sr',
  'sr',
  'sr',
  'ss',
  'st',
  'sv',
  'sv',
  'ta',
  'te',
  'tg',
  'th',
  'ti',
  'ti',
  'tig',
  'tk',
  'tl',
  'tn',
  'tr',
  'tr',
  'ts',
  'tt',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'wa',
  'wo',
  'xh',
  'yi',
  'yo',
  'zh',
  'zh',
  'zh',
  'zh',
  'zu',
];

export const COUNTRY_CODE = [
  'DJ',
  'ER',
  'ET',
  'ZA',
  'ET',
  'ES',
  'AE',
  'BH',
  'DZ',
  'EG',
  'IN',
  'IQ',
  'JO',
  'KW',
  'LB',
  'LY',
  'MA',
  'OM',
  'QA',
  'SA',
  'SD',
  'SY',
  'TN',
  'YE',
  'IN',
  'ES',
  'AZ',
  'BY',
  'DZ',
  'MA',
  'BG',
  'BD',
  'IN',
  'CN',
  'IN',
  'FR',
  'BA',
  'ER',
  'AD',
  'ES',
  'FR',
  'IT',
  'UA',
  'CZ',
  'PL',
  'GB',
  'DK',
  'AT',
  'BE',
  'CH',
  'DE',
  'LI',
  'LU',
  'MV',
  'BT',
  'CY',
  'GR',
  'AG',
  'AU',
  'BW',
  'CA',
  'DK',
  'GB',
  'HK',
  'IE',
  'IN',
  'NG',
  'NZ',
  'PH',
  'SG',
  'US',
  'ZA',
  'ZW',
  'AR',
  'BO',
  'CL',
  'CO',
  'CR',
  'DO',
  'EC',
  'ES',
  'GT',
  'HN',
  'MX',
  'NI',
  'PA',
  'PE',
  'PR',
  'PY',
  'SV',
  'US',
  'UY',
  'VE',
  'EE',
  'ES',
  'FR',
  'IR',
  'FI',
  'PH',
  'FO',
  'BE',
  'CA',
  'CH',
  'FR',
  'LU',
  'IT',
  'DE',
  'NL',
  'IE',
  'GB',
  'ER',
  'ET',
  'ES',
  'IN',
  'GB',
  'NG',
  'IL',
  'IN',
  'IN',
  'HR',
  'DE',
  'HT',
  'HU',
  'AM',
  'ID',
  'NG',
  'CA',
  'IS',
  'CH',
  'IT',
  'CA',
  'IL',
  'JP',
  'GE',
  'KZ',
  'GL',
  'KH',
  'IN',
  'KR',
  'IN',
  'TR',
  'GB',
  'KG',
  'UG',
  'BE',
  'NL',
  'LA',
  'LT',
  'LV',
  'IN',
  'MG',
  'NZ',
  'MK',
  'IN',
  'MN',
  'IN',
  'MY',
  'MT',
  'MM',
  'NO',
  'DE',
  'NL',
  'NP',
  'AW',
  'BE',
  'NL',
  'NO',
  'NO',
  'ZA',
  'ZA',
  'FR',
  'ET',
  'KE',
  'IN',
  'IN',
  'PK',
  'AN',
  'PL',
  'AF',
  'BR',
  'PT',
  'RO',
  'RU',
  'UA',
  'RW',
  'IN',
  'IT',
  'IN',
  'NO',
  'CA',
  'LK',
  'ET',
  'SK',
  'SI',
  'DJ',
  'ET',
  'KE',
  'SO',
  'AL',
  'CS',
  'ME',
  'RS',
  'YU',
  'ZA',
  'ZA',
  'FI',
  'SE',
  'IN',
  'IN',
  'TJ',
  'TH',
  'ER',
  'ET',
  'ER',
  'TM',
  'PH',
  'ZA',
  'CY',
  'TR',
  'ZA',
  'RU',
  'CN',
  'UA',
  'PK',
  'UZ',
  'ZA',
  'VN',
  'BE',
  'SN',
  'ZA',
  'US',
  'NG',
  'CN',
  'HK',
  'SG',
  'TW',
  'ZA',
];
