import React, { useRef, useState } from 'react';
import { Autocomplete, Box } from '@mui/material';
import Input from '../Input/Input';
import { AsyncAutoCompleteProps } from './types';
import Tooltip from '@mui/material/Tooltip';
import { truncate } from 'lodash';

function AsyncAutocomplete<T extends Record<K, string>, K extends keyof T>({
  fetchAPI,
  labelField,
  value,
  onChange,
  selectField,
  label,
  placeholder,
  sx,
  InputComponent,
  locales,
}: AsyncAutoCompleteProps<T, K>) {
  // const [value, setValue] = React.useState<T | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly { label: T[K]; value: T[K] }[]>([]);
  const [loading, setLoading] = React.useState(false);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [isFocused, setIsFocused] = useState(false);

  React.useEffect(() => {
    if (!isFocused) {
      return;
    }
    let active = true;

    // if (inputValue === "") {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }

    if (timer.current) {
      clearTimeout(timer.current);
    }
    setLoading(true);

    timer.current = setTimeout(() => {
      fetchAPI(inputValue, (results?: readonly T[]) => {
        if (active) {
          let newOptions: { label: T[K]; value: T[K] }[] = [];

          if (value) {
            const currentValue = options.find((option) => option.value === value);
            if (currentValue) {
              // newOptions.push(currentValue);
            }
          }

          if (results) {
            const convertOptions = results.map((result) => ({
              value: result[selectField],
              label: result[labelField],
            }));
            newOptions = [...newOptions, ...convertOptions];
          }

          setOptions(newOptions);
          setLoading(false);
        }
      });
    }, 500);

    return () => {
      active = false;
      clearTimeout(timer.current);
    };
  }, [value, inputValue, fetch, isFocused]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => {
        return locales ? locales(option.label) : option.label;
      }}
      renderOption={(props, option) => {
        const title = locales ? locales(option.label) : option.label;
        return title.length < 26 ? (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {title}
          </Box>
        ) : (
          <Tooltip title={title} arrow placement='right'>
            <Box component='li' sx={{ whiteSpace: 'nowrap', '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {truncate(title, { length: 26 })}
            </Box>
          </Tooltip>
        );
      }}
      options={options}
      loading={loading}
      value={options.find((option) => option.value === value)}
      onChange={(event: any, newValue: { label: T[K]; value: T[K] } | null) => {
        onChange(newValue?.value);
      }}
      open={isFocused}
      onOpen={() => {
        setIsFocused(true);
        setInputValue('');
      }}
      onClose={() => setIsFocused(false)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        if (InputComponent) {
          const { InputLabelProps, InputProps, ...rest } = params;

          return (
            // @ts-ignore
            <InputComponent
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
              placeholder={placeholder}
              {...InputLabelProps}
              {...InputProps}
              {...rest}
            />
          );
        }

        return (
          <Input
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
            placeholder={placeholder}
          />
        );
      }}
      noOptionsText={loading ? 'Loading...' : 'Không có dữ liệu'}
      sx={{
        '& .MuiFormControl-root': {
          margin: 0,
        },
        ...sx,
      }}
    />
  );
}

export default AsyncAutocomplete;
