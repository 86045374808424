import React from 'react';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import useLiveChat from 'kikifrontend/hooks/useLiveChat';
import { useModal } from '@kikisoftware/uikit';
import ChangePackageListPackagesModal from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageListPackagesModal';
import { useTranslation } from 'react-i18next';

function PremiumCTAButtons() {
  const { t } = useTranslation();
  const { toggleLiveChat } = useLiveChat();
  const { openModal, closeModal } = useModal();

  const openChangePackageModal = () => {
    closeModal({ closeAll: true });
    openModal(ChangePackageListPackagesModal);
  };

  return (
    <div className='flex gap-3 items-center mt-4'>
      <Button
        variant='contained'
        color='secondary'
        startIcon={
          <img
            src='https://cdn4.iconfinder.com/data/icons/seo-and-internet-marketing-9/65/10-512.png'
            alt='upgrade'
            width={20}
          />
        }
        onClick={openChangePackageModal}
        sx={{ position: 'relative' }}
      >
        {t('Nâng cấp ngay')}
      </Button>
      <Button variant='contained' color='secondary' onClick={toggleLiveChat} startIcon={<ChatIcon fontSize='small' />}>
        Live chat
      </Button>
    </div>
  );
}

export default PremiumCTAButtons;
