import React from 'react';
import Box from '@mui/material/Box';
import { GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import { keyframes } from '@mui/material/styles';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
    display: flex;
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
    display: none;
  }
`;

function SelectionGroup({
  renderSelectionBar,
}: {
  renderSelectionBar?: (selectionRowIds: GridRowId[]) => React.ReactNode;
}) {
  const apiRef = useGridApiContext();
  const selectionModel = Array.from(apiRef.current.getSelectedRows().keys());

  if (!renderSelectionBar) return null;

  return (
    <Box
      sx={{
        bgcolor: 'background.onLayout',
        position: 'absolute',
        left: 0,
        top: 73,
        width: 'calc(100% - 60px)',
        height: 56,
        zIndex: 10,
        display: selectionModel.length > 0 ? 'flex' : 'none',
        animation: `${selectionModel.length > 0 ? fadeIn : fadeOut} 0.15s ease`,
        ml: 7.5,
      }}
    >
      {renderSelectionBar(selectionModel)}
    </Box>
  );
}

export default SelectionGroup;
