import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import useLocales from 'kikifrontend/hooks/useLocales';
import { saveToLocalStorage } from 'kikifrontend/utils/localstorage';
import { userServices } from 'kikifrontend/_apis_';
import { TABLE_SETTING_FIELD } from 'kikifrontend/utils/constants';
import { useModal, useSettings } from '@kikisoftware/uikit';
import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import SurveyModal from 'kikifrontend/features/surveyCustomers/SurveyModal';
import { isNeedShowCommunityModal } from 'kikifrontend/utils/date-time';
import CommunityModal from 'kikifrontend/features/surveyCustomers/CommunityModal';
import useUserPackage from 'kikifrontend/hooks/useUserPackage';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { middleWareError } from 'kikifrontend/utils/configs';
import { useAuth } from 'kikifrontend/hooks/useAuth';

function WithApplySettings({ children }: any) {
  const { isAuthenticated, statistic } = useRecoilValue(statisticAtom);
  const { openModal } = useModal();
  const packageDetail = useUserPackage();
  const { logout } = useAuth();

  const { currentLang: lang, onChangeLang } = useLocales();
  // const [loading, setLoading] = useState(true);
  const { onChangeMode } = useSettings();

  const { isLoading } = useQuery({
    queryKey: [queryKeys.systemSettings],
    queryFn: userServices.getUserSettings,
    enabled: isAuthenticated && !!statistic,
    onSuccess: async (data) => {
      if (data) {
        if (data.language) {
          if (data.language !== lang) {
            await onChangeLang(data.language, false);
          }
        } else {
          await userServices.updateUserLanguage({ language: lang });
        }

        if (data.tables) {
          saveToLocalStorage(TABLE_SETTING_FIELD, data.tables);
        } else {
          localStorage.removeItem(TABLE_SETTING_FIELD);
        }

        if (data.theme) {
          onChangeMode(data.theme);
        }

        if (statistic?.userInfo?.isRoot) {
          if (!data?.businessInfo?.platform || data?.businessInfo?.platform?.length === 0) {
            openModal(SurveyModal);
          } else if (isNeedShowCommunityModal(packageDetail)) {
            openModal(CommunityModal);
          } else {
            saveToLocalStorage('businessInfo', data?.businessInfo);
            // closeModal();
          }
        }
      }
    },
    onError: (err) => {
      ErrorUtils.handleError(err, {
        isErrorInstanceFn: async (error) => {
          if (error.data.reason === middleWareError) {
            await logout();
          }
        },
      });
    },
  });

  // React.useEffect(() => {
  //   const fetchLanguage = async () => {
  //     try {
  //       const data = await userServices.getUserSettings();
  //       if (data) {
  //         if (data.language) {
  //           if (data.language !== lang) {
  //             await onChangeLang(data.language, false);
  //           }
  //         } else {
  //           await userServices.updateUserLanguage({ language: lang });
  //         }
  //       }
  //       if (data.tables) {
  //         saveToLocalStorage(TABLE_SETTING_FIELD, data.tables);
  //       } else {
  //         localStorage.removeItem(TABLE_SETTING_FIELD);
  //       }
  //
  //       if (data.theme) {
  //         onChangeMode(data.theme);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   if (isAuthenticated) {
  //     fetchLanguage();
  //   }
  // }, [isAuthenticated]);

  if (isLoading) {
    return (
      <Stack direction='row' justifyContent='center' sx={{ height: '100vh', pt: 4 }}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <CircularProgress />
          <Typography sx={{ fontSize: 16 }}>Setting language...</Typography>
        </Stack>
      </Stack>
    );
  }

  return children;
}

export default WithApplySettings;
