import crypto from 'crypto-browserify';
import b32 from 'thirty-two';
import { Buffer } from 'buffer/';

function intToBytes(num: number) {
  const bytes = [];

  for (let i = 7; i >= 0; --i) {
    bytes[i] = num & 255;
    num = num >> 8;
  }

  return bytes;
}

function hexToBytes(hex: string) {
  const bytes = [];
  for (let c = 0, C = hex.length; c < C; c += 2) {
    bytes.push(parseInt(hex.substring(c, c + 2), 16));
  }
  return bytes;
}

function hotpGen(key: string, opt: any) {
  key = key || '';
  opt = opt || {};
  const counter = opt.counter || 0;

  // Create the byte array
  const b = new Buffer(intToBytes(counter));

  const hmac = crypto.createHmac('sha1', new Buffer(key));

  // Update the HMAC with the byte array
  const digest = hmac.update(b).digest('hex');

  // Get byte array
  const h = hexToBytes(digest);

  // Truncate
  const offset = h[19] & 0xf;
  let v: number | string =
    ((h[offset] & 0x7f) << 24) |
    ((h[offset + 1] & 0xff) << 16) |
    ((h[offset + 2] & 0xff) << 8) |
    (h[offset + 3] & 0xff);

  v = ((v % 1000000) + '') as string;

  return Array(7 - v.length).join('0') + v;
}

function gen(key: string, opt?: any) {
  opt = opt || {};
  const time = opt.time || 30;
  let _t = Date.now();

  // Time has been overwritten.
  if (opt._t) {
    if (process.env.NODE_ENV != 'test') {
      throw new Error('cannot overwrite time in non-test environment!');
    }
    _t = opt._t;
  }

  // Determine the value of the counter
  // This is the number of time steps in seconds since T0
  opt.counter = Math.floor(_t / 1000 / time);

  return hotpGen(key, opt);
}

export function generateToken(secret: string) {
  if (!secret || !secret.length) return null;
  const unformatted = secret.replace(/\W+/g, '').toUpperCase();
  const bin = b32.decode(unformatted);

  return { token: gen(bin) };
}
