import React from 'react';
// MUI
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { BaseModalProps } from './types';
import Divider from '@mui/material/Divider';

const Modal = ({
  id,
  onClose,
  title,
  children,
  // divider,
  DialogContentProps = {},
  DialogTitleProps = {},
  sx,
  paddingContent = 0,
  width = 400,
  disableCloseWhenClickOutside = false,
  options = {
    closeIcon: <CloseIcon />,
  },
  PaperRootProps,
  divider = <Divider />,
  hideHeader = false,
}: BaseModalProps) => {
  return (
    <Dialog
      id={id}
      open={true}
      onClose={disableCloseWhenClickOutside ? undefined : onClose}
      disableAutoFocus
      disableEnforceFocus
      sx={{
        // '& .MuiDialogContent-root': { pt: 3 },
        '& .MuiPaper-root': {
          maxWidth: '100%',
          width: width,
          bgcolor: 'background.fourthly',
          backgroundImage: 'none',
          ...PaperRootProps,
        },
        '& .MuiDialog-scrollPaper': {
          alignItems: 'flex-start',
          py: { xl: 5, fhd: 8 },
        },
        ...sx,
      }}
    >
      {!hideHeader && (
        <>
          <DialogTitle component='div' sx={{ px: 3, py: 1.5, ...DialogTitleProps.sx }}>
            {typeof title === 'string' ? (
              <Typography sx={{ color: 'text.modalTitle', fontSize: 18, fontWeight: 600 }}>{title}</Typography>
            ) : (
              title
            )}
            {onClose && options.closeIcon ? (
              <IconButton
                aria-label='close'
                onClick={onClose as any}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'text.subColor2',
                }}
              >
                {options.closeIcon as any}
              </IconButton>
            ) : null}
          </DialogTitle>
          {divider}
        </>
      )}

      <DialogContent
        sx={{
          p: 3,
          '&::-webkit-scrollbar': {
            width: 6,
            backgroundColor: 'transparent',
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: 3,
            backgroundColor: 'grey.400',
          },
          position: 'relative',
          zIndex: 2,
          ...DialogContentProps.sx,
        }}
      >
        <Box pt={paddingContent}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
