import React, { useEffect } from 'react';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import Community from 'kikifrontend/features/surveyCustomers/components/Community';
import { saveToLocalStorage } from 'kikifrontend/utils/localstorage';
import dayjs from 'dayjs';

function CommunityModal({ onClose }: ModalProps) {
  const { t } = useTranslation();

  useEffect(() => {
    saveToLocalStorage('communityShowTime', dayjs().toISOString());
  }, []);

  return (
    <Modal
      onClose={onClose}
      title={t('Tham gia cộng đồng & nhận tài liệu')}
      width={600}
      DialogContentProps={{
        sx: {
          overflow: 'visible',
        },
      }}
    >
      <Community onClose={onClose} />
    </Modal>
  );
}

export default CommunityModal;
