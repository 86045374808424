import { merge } from 'lodash';
import Button from './Button';
import Input from './Input';
import AutoComplete from './AutoComplete';
import Checkbox from './Checkbox';
import { Theme } from '@mui/material';

export default function ComponentsOverrides(theme: Theme) {
  return merge(Button(theme), Input(theme), AutoComplete(), Checkbox(theme));
}
