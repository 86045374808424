import { ErrorApi, ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { notifyError } from 'kikifrontend/utils/notification';
import { TFunction } from 'i18next';
import {
  browserImageUrl,
  flagImageURL,
  GOMU_NOT_INSTALLED_ERROR,
  KIFOX_BROWSER_NOT_INSTALLED,
} from 'kikifrontend/utils/constants';
import * as Sentry from '@sentry/react';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import { StatisticProps } from 'kikifrontend/types/statistic.type';
import { dateTimeFormatter } from 'kikifrontend/utils/date-time';
import { BlockedProps } from 'kikifrontend/types/user.types';

export function isExistInArray<T>(array: T[], fn: (item: T) => void) {
  const index = array.findIndex(fn);
  return {
    isExist: index !== -1,
    index,
  };
}

export function pushItemToArray<T>(array: T[], item: T, { index, isExist }: { isExist: boolean; index: number }) {
  const cloneArray = [...array];
  if (isExist) {
    cloneArray[index] = item;
    return cloneArray;
  }

  return [...cloneArray, item];
}

export function getValueFromObjectByField<T, K extends keyof T>(obj: T, field: string) {
  const fields = field.split('.');
  let value: any = { ...obj };
  for (const f of fields) {
    value = value[f as K];
  }
  return value;
}

export const handleCommonError = (err: unknown, t: TFunction<'translation', undefined, 'translation'>) => {
  ErrorUtils.handleError(err, {
    isErrorInstanceFn: (error) => {
      notifyError(t(error.data.reason));
    },
  });
};

export function getFlagIcon(flag: string) {
  return `${flagImageURL}${flag.toLowerCase()}.svg`;
}

export function getBrowserIcon(flag: string) {
  return `${browserImageUrl}${flag.toLowerCase()}/${flag.toLowerCase()}.png`;
}

export function isStringIncludes(rootString: string, pattern: string) {
  return rootString.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
}

export const appErrorHandler = (statistic?: StatisticProps) => (error: Error) => {
  if (
    error?.message &&
    (error.message.indexOf('Loading chunk') !== -1 ||
      error.message.indexOf('Failed to fetch dynamically imported module') !== -1)
  ) {
    window.location.reload();
  } else {
    Sentry.captureException(error, {
      user: {
        email: statistic?.userInfo?.email,
        phone: statistic?.userInfo?.phone,
        credit: currencyFormat(statistic?.credit, { rate: USDvsVND, prefix: '$' }),
        userId: statistic?.userInfo?._id,
        packageName: statistic?.package?.name,
        buyAt: dateTimeFormatter(statistic?.package?.buyAt),
        expireAt: dateTimeFormatter(statistic?.package?.expireAt),
      },
      tags: {
        appCrashed: true,
      },
    });
  }
};

export function semverCompare(version1?: string, version2?: string) {
  if (!version1 || !version2) return 0;
  // Tách các phần tử của phiên bản thành mảng các số nguyên
  const v1Arr = version1.split('.').map((x) => parseInt(x));
  const v2Arr = version2.split('.').map((x) => parseInt(x));

  // Lấy số lượng phần tử của phiên bản
  const maxLength = Math.max(v1Arr.length, v2Arr.length);

  // Thêm số 0 cho đủ phần tử để tiện so sánh
  while (v1Arr.length < maxLength) {
    v1Arr.push(0);
  }
  while (v2Arr.length < maxLength) {
    v2Arr.push(0);
  }

  if (v1Arr[0] >= v2Arr[0]) {
    return 0;
  }

  return -1;

  // // So sánh từng phần tử theo thứ tự từ trái sang phải
  // for (let i = 0; i < maxLength; i++) {
  //   if (v1Arr[i] > v2Arr[i]) {
  //     return 1;
  //   } else if (v1Arr[i] < v2Arr[i]) {
  //     return -1;
  //   }
  // }
  //
  // // Nếu đến đây vẫn chưa có kết quả, hai phiên bản bằng nhau
  // return 0;
}

export function semverCompareFull(version1?: string, version2?: string) {
  if (!version1 || !version2) return 0;
  // Tách các phần tử của phiên bản thành mảng các số nguyên
  const v1Arr = version1.split('.').map((x) => parseInt(x));
  const v2Arr = version2.split('.').map((x) => parseInt(x));

  // Lấy số lượng phần tử của phiên bản
  const maxLength = Math.max(v1Arr.length, v2Arr.length);

  // Thêm số 0 cho đủ phần tử để tiện so sánh
  while (v1Arr.length < maxLength) {
    v1Arr.push(0);
  }
  while (v2Arr.length < maxLength) {
    v2Arr.push(0);
  }

  // So sánh từng phần tử theo thứ tự từ trái sang phải
  for (let i = 0; i < maxLength; i++) {
    if (v1Arr[i] > v2Arr[i]) {
      return 1;
    } else if (v1Arr[i] < v2Arr[i]) {
      return -1;
    }
  }

  // Nếu đến đây vẫn chưa có kết quả, hai phiên bản bằng nhau
  return 0;
}

export async function sleep(n: number) {
  return new Promise((resolve) => setTimeout(resolve, n));
}

export function randomNumber(start: number, end: number) {
  if (start >= end) {
    throw new Error('Start value must be less than end value.');
  }

  const range = end - start + 1;
  return Math.floor(Math.random() * range) + start;
}

export function getRandomElement(array: any[]) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function randomChildrenStringArray(array: string[]) {
  const childrenArrayLength = randomNumber(3, array.length);
  const childArr: string[] = [];

  for (let i = 0; i < childrenArrayLength; i++) {
    const el = getRandomElement(array);
    if (!childArr.includes(el)) childArr.push(el);
  }

  return childArr;
}

export function removeSensitiveParams(url: string) {
  // short if no params are detected
  if (url.indexOf(`?`) == -1) {
    return url;
  }

  const searchParams = new URLSearchParams(url);
  searchParams.delete(`access_token`);
  searchParams.delete(`refresh_token`);
  searchParams.delete(`token`);
  searchParams.delete(`id_token`);
  searchParams.delete(`code`);
  searchParams.delete(`otl`);
  searchParams.delete(`sessionId`);
  return url.split(`?`)[0] + `?` + searchParams.toString();
}

export function handleBrowserTypeError(
  error: ErrorApi<string | BlockedProps>,
  {
    gomuFn,
    falseFn,
    blockFn,
    kifoxFn,
  }: { gomuFn: () => void; kifoxFn: () => void; falseFn: () => void; blockFn: () => void },
) {
  if (error.data.reason === GOMU_NOT_INSTALLED_ERROR) {
    gomuFn();
  } else if (error.data.reason === KIFOX_BROWSER_NOT_INSTALLED) {
    kifoxFn();
  } else if (typeof error.data.reason === 'string') {
    falseFn();
  } else {
    blockFn();
  }
}

export const getOperatingSystem = () => {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes('win')) {
    return 'windows';
  } else if (platform.includes('mac')) {
    return 'macos';
  } else if (platform.includes('linux')) {
    return 'linux';
  }

  return 'windows';
};
