import React from 'react';
import appRoutes from 'kikifrontend/routes/path';
import Page from 'kikifrontend/components/Page';
import PublicPluginsView from 'kikifrontend/features/plugins/ui/publicPlugins/PublicPluginsView';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PluginNoSupport from 'kikifrontend/features/plugins/components/PluginNoSupport';
import { isSupportPluginSupportSelector } from 'kikifrontend/features/plugins/selectors/pluginSuport.selector';
import { useRecoilValue } from 'recoil';

export const pluginsHeader = [
  {
    title: 'Mini Tools',
    icon: <BuildOutlinedIcon color='primary' sx={{ width: 20 }} />,
    // icon: Assets.icons.IcoExtension,
  },
  {
    title: 'Store',
    url: appRoutes.plugins.fullPath,
  },
  {
    title: 'My tools',
    url: appRoutes.plugins.personalPlugins.fullPath,
  },
];

function PublicPlugins() {
  const isSupportPluginSupport = useRecoilValue(isSupportPluginSupportSelector);

  return (
    <Page title='Plugins' headerTitles={pluginsHeader}>
      {isSupportPluginSupport ? <PublicPluginsView /> : <PluginNoSupport />}
    </Page>
  );
}

export default PublicPlugins;
