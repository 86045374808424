import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { locationServices } from 'kikifrontend/_apis_/locations';

export default function useCheckUserIp(ip?: string) {
  return useQuery({
    queryKey: [queryKeys.checkUserIp, ip],
    queryFn: () => locationServices.collectUserCurrentIpLocation(ip),
    retry: false,
    // onSuccess: (data) => console.log(data),
  });
}
