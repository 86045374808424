import React from 'react';
import { KIKI_THEME_EVENTS, useKikiThemeProvider } from '../../themes/KikiThemeProvider';
import PeachBlossomFalling from './PeachBlossomFalling';

function FallingEffect() {
  const { theme } = useKikiThemeProvider();

  if (theme === KIKI_THEME_EVENTS.default) {
    return null;
  }

  return <PeachBlossomFalling />;
}

export default FallingEffect;
