import React from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

function Link({ children, to, className }: { children: any; to: To; className?: string }) {
  return (
    <MuiLink component={RouterLink} to={to} underline='none' className={className}>
      {children}
    </MuiLink>
  );
}

export default Link;
