import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@kikisoftware/uikit';
import useLocales from 'kikifrontend/hooks/useLocales';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { acceptedPaymentMethod } from 'kikifrontend/features/paymentCenter/utils/constants';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import Box from '@mui/material/Box';

const quickSelectAmount = [50, 100, 250, 500, 750, 1000];

const Input = styled('input')(({ theme }) => ({
  color: theme.palette.text.subColor2,
  fontSize: 24,
  fontFamily: 'SVN-Gilroy-Medium',
}));

const AmountLabel = styled('div')({
  backgroundColor: '#F7F7F7',
  borderRadius: 5,
  width: 67,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: 14,
});

function AmountInput({
  amount,
  setAmount,
  goToSelectPaymentMethod,
}: {
  amount: string;
  setAmount: (value: string) => void;
  goToSelectPaymentMethod: () => void;
}) {
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);
  const { allLang, currentLang } = useLocales();

  const isVn = currentLang.value === allLang[1].value;
  const labelAmount = useMemo(() => (!isVn ? parseFloat(amount) : parseFloat(amount) / USDvsVND), [isVn, amount]);

  const onChangeAmount = (v: string) => {
    setAmount(v);
  };

  const onAmountLabelClick = (am: string) => {
    if (!isVn) {
      setAmount(am);
    } else {
      setAmount(`${parseInt(am) * USDvsVND}`);
    }
  };
  return (
    <>
      <div className='flex justify-between items-center'>
        <Typography variant='body1Bold' color='text.subColor4'>
          {t('Số tiền nạp')}
        </Typography>
        <Typography variant='body1Bold' color='text.subColor4'>
          {t('Số dư')}:{' '}
          <Typography component='span' color='text.subColor2' variant='body1Bold'>
            {currencyFormat(statistic?.credit, { rate: USDvsVND, suffix: '$' })}
          </Typography>
        </Typography>
      </div>
      <Box
        sx={{ border: '1px solid', borderColor: 'divider', bgcolor: 'background.onLayout2' }}
        className='p-4 flex flex-col gap-3 rounded-lg mt-3'
      >
        <NumericFormat
          customInput={Input}
          onValueChange={(v) => {
            onChangeAmount(`${v.value}`);
          }}
          value={amount}
          placeholder='0'
          autoFocus
          allowNegative={false}
          allowedDecimalSeparators={['.', ',']}
          inputMode='decimal'
          thousandSeparator=','
          valueIsNumericString
          decimalScale={2}
          prefix={!isVn ? '$' : 'đ'}
          className='bg-transparent font-bold outline-none w-full border-0'
        />

        <Typography variant='body2' color='#A3ADB7'>
          ≈{' '}
          {!isVn
            ? currencyFormat(parseFloat(amount), { suffix: 'đ', rate: 1 / USDvsVND })
            : currencyFormat(parseFloat(amount), { suffix: '$', rate: USDvsVND })}
        </Typography>

        <Stack direction='row' spacing={1.5}>
          {quickSelectAmount.map((a) => (
            <AmountLabel
              key={a}
              onClick={() => onAmountLabelClick(`${a}`)}
              sx={
                labelAmount === a
                  ? {
                      background: 'rgba(32, 77, 194, 0.08)',
                      border: '1px solid',
                      borderColor: 'primary.main',
                      color: 'primary.main',
                    }
                  : {
                      background: 'transparent',
                      border: '1px solid',
                      borderColor: 'divider',
                    }
              }
            >
              ${a}
            </AmountLabel>
          ))}
        </Stack>
      </Box>
      <Stack alignItems='center' spacing={2}></Stack>

      <Divider sx={{ my: 2.5 }} />

      <Grid container rowSpacing={3} columnSpacing={4} mt={1}>
        {acceptedPaymentMethod.map((method, idx) => (
          <Grid item key={idx} xs={3} md={2.4}>
            <Image src={method.url} asyncLoading={false} />
          </Grid>
        ))}
      </Grid>

      <Button
        variant='contained'
        disabled={!amount || amount === '0'}
        // onClick={pay}
        fullWidth
        sx={{ mt: 5 }}
        onClick={goToSelectPaymentMethod}
      >
        {t('Pay_payment_center')}
      </Button>
    </>
  );
}

export default AmountInput;
