import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import appABIs from 'kikifrontend/blockchains/abi';
import { BigNumberish, ethers } from 'ethers';
import { MAX_ALLOWANCE_UNIT } from 'kikifrontend/blockchains/configs/constants';
import { AllowanceInformation } from 'kikifrontend/blockchains/types/network.types';
import { CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';

const defaultAllowanceInformation: AllowanceInformation = {
  isAllowance: false,
  allowanceBalanceInBN: '0',
  allowanceBalance: '0',
};

const getAllowanceInformation = (
  allowance: unknown,
  amount: string,
  { isSuccessApproved }: { isSuccessApproved: boolean },
) => {
  const allowanceBalance = ethers.formatEther(allowance as BigNumberish);

  return {
    isAllowance: Number(allowanceBalance) > Number(amount) || isSuccessApproved,
    allowanceBalanceInBN: allowance,
    allowanceBalance,
  };
};

export default function useAllowance(
  crypto: CryptoPaymentProps,
  amount: string,
  { refetchInitContract, resetContractWrite }: { refetchInitContract?: () => void; resetContractWrite?: () => void },
) {
  const { address } = useAccount();
  const {
    data: allowanceData,
    refetch,
    isLoading: isLoadingAllowance,
  } = useContractRead({
    address: crypto.contract,
    abi: appABIs.erc20,
    functionName: 'allowance',
    args: [address, crypto.network.paymentContract],
    chainId: crypto.network.chainId,
  });

  const { config } = usePrepareContractWrite({
    address: crypto.contract,
    abi: appABIs.erc20,
    functionName: 'approve',
    args: [crypto.network.paymentContract, MAX_ALLOWANCE_UNIT],
    chainId: crypto.network.chainId,
  });
  const { data, write: approve, isLoading: isLoadingWriteContractApprove } = useContractWrite(config);
  const { isLoading: isLoadingApprove, isSuccess: isSuccessApproved } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: async () => {
      await refetch();
      await refetchInitContract?.();
      await resetContractWrite?.();
    },
  });

  return {
    allowance: allowanceData
      ? getAllowanceInformation(allowanceData, amount, { isSuccessApproved })
      : defaultAllowanceInformation,
    approve,
    data,
    isLoadingApprove,
    isSuccessApproved,
    isLoadingWriteContractApprove,
    isLoadingAllowance,
  };
}
