import React from 'react';
import { TrackButtonIconProps } from 'kikifrontend/components/Button/types';
import TrackButtonIcon from './TrackButtonIcon';
import { useRecoilValue } from 'recoil';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';
import { useModal } from '@kikisoftware/uikit';
import PremiumModal from 'kikifrontend/components/Premium/PremiumModal';

function PremiumButtonIcon({ children, onClick, ...props }: TrackButtonIconProps) {
  const isFree = useRecoilValue(isFreePackageSelector);
  const { openModal } = useModal();

  const onFreeClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openModal(PremiumModal);
    return;
  };

  const premiumButtonClick = isFree ? onFreeClick : onClick;

  return (
    <TrackButtonIcon {...props} onClick={premiumButtonClick}>
      {children}
    </TrackButtonIcon>
  );
}

export default PremiumButtonIcon;
