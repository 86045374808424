import { PluginInfo, PluginProps } from 'kikifrontend/features/plugins/interfaces/plugin.interface';

export function mergePluginStore(storePlugins?: PluginInfo[], myPlugins?: PluginProps[]): PluginInfo[] {
  if (!storePlugins || !myPlugins) return [];
  return storePlugins.map((plugin) => {
    const findPlugin = myPlugins.find((myPlugin) => myPlugin.pluginInfo.slug === plugin.slug);
    return {
      ...plugin,
      isUsed: !!findPlugin,
      ...(findPlugin && {
        expiredAt: findPlugin.expireAt,
      }),
    };
  });
}

export function mergePlugin(plugin?: PluginInfo, myPlugins?: PluginProps[]): PluginInfo | undefined {
  if (!plugin || !myPlugins) return;
  const findPlugin = myPlugins.find((myPlugin) => myPlugin.pluginInfo.slug === plugin.slug);
  return {
    ...plugin,
    isUsed: !!findPlugin,
  };
}
