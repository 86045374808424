import React from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import {
  CreationAutocompleteOptions,
  FreeSoloCreationAutocomplete,
  SelectFormFieldOutlinedProps,
} from 'kikifrontend/components';

function SelectFormField<T extends FieldValues>({
  name,
  control,
  label,
  options,
  sx,
  valueType = 'string',
  disabled,
  textFieldProps,
  renderInput,
  disableClearable = true,
  disabledCreation = true,
  disableEnterOnSearchValue = true,
  isOptionEqualToValue,
}: SelectFormFieldOutlinedProps<T>) {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => {
        const index = options.findIndex((item: CreationAutocompleteOptions) => item.value === field.value);
        const init = options[index];
        return (
          <FreeSoloCreationAutocomplete
            sx={{ width: 1, pointerEvents: disabled ? 'none' : undefined, ...sx }}
            label={label}
            options={options}
            onChange={(value) => {
              if (valueType === 'string') {
                // @ts-ignore
                field.onChange(value);
              } else {
                // @ts-ignore
                field.onChange(options.find((item) => item.value === value));
              }
            }}
            value={valueType === 'string' ? init : field.value}
            disabledCreation={disabledCreation}
            disabled={disabled}
            textFieldProps={textFieldProps}
            renderInput={renderInput}
            disableClearable={disableClearable}
            isOptionEqualToValue={isOptionEqualToValue}
            disableEnterOnSearchValue={disableEnterOnSearchValue}
          />
        );
      }}
    />
  );
}

export default SelectFormField;
