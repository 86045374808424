import { useTranslation } from 'react-i18next';
import { Modal } from '@kikisoftware/uikit';
import React from 'react';
import { PremiumCard } from 'kikifrontend/components';
import Typography from '@mui/material/Typography';
import { useAuth } from 'kikifrontend/hooks/useAuth';

const FreePackageMaxProfileModal = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <Modal hideHeader width={410}>
      <PremiumCard
        // image='https://cdn-icons-png.flaticon.com/512/13611/13611087.png?ga=GA1.1.969386378.1704703279&'
        // imageWidth={90}
        title={t('Tài khoản vượt quá số lượng profile')}
        content={
          <>
            <Typography component='span' variant='body2'>
              {t(
                'Bạn đã tạo quá số lượng profile tối đa của gói cước Free (9/8 profile), vui lòng nâng cấp lên gói cước trả phí để tiếp tục sử dụng hoặc liên hệ livechat để được hỗ trợ',
              )}
            </Typography>
          </>
        }
        contentClassName='mt-4'
        footer={
          <div
            className='flex justify-center mt-4 cursor-pointer text-sm text-primary-main hover:text-primary-dark w-full'
            onClick={() => logout()}
          >
            {t('Sử dụng tài khoản khác')}
          </div>
        }
      />
    </Modal>
  );
};

export default FreePackageMaxProfileModal;
