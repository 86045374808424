import React from 'react';
import track from 'kikifrontend/utils/analytics';
import { ButtonIcon } from '@kikisoftware/uikit';
import { TrackButtonIconProps } from 'kikifrontend/components/Button/types';
import useTrackParams from 'kikifrontend/hooks/useTrackParams';

function TrackButtonIcon({ children, eventName, params, onClick, ...props }: TrackButtonIconProps) {
  const { params: trackParams } = useTrackParams(params);

  const trackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (eventName) {
      track.event(eventName, trackParams);
    }
    onClick?.(e);
  };

  return (
    <ButtonIcon {...props} onClick={trackClick}>
      {children}
    </ButtonIcon>
  );
}

export default TrackButtonIcon;
