import React from 'react';
import { Svg, useModal } from '@kikisoftware/uikit';
import { useWatch } from 'react-hook-form';
import { useProfileEditorFormActions } from 'kikifrontend/features/profiles/profileEditor/hooks/useProfileEditorFormActions';
import ProfileProxyConfigModal from 'kikifrontend/features/profiles/profileEditor/components/ProfileProxyConfigModal';
import { PROXY_TYPE_OPTIONS } from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { SetupEditor } from 'kikifrontend/features/profiles/profileEditor/types/editor.types';
import { CreationAutocompleteOptions, SelectFormField } from 'kikifrontend/components';
import Button from '@mui/material/Button';
import Assets from 'kikifrontend/assets';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ProfileCloneCookieModal from 'kikifrontend/features/profiles/profileList/components/ProfileCloneCookieModal';
import appRoutes from 'kikifrontend/routes/path';

export const devices = [
  {
    value: 'windows',
    label: 'Windows',
    icon: (
      <Box
        component='img'
        src={Assets.icons.IcoWindowsOutline}
        alt='windows'
        width={16}
        sx={{
          // opacity: 0.5,
          filter: 'invert(83%) sepia(11%) saturate(268%) hue-rotate(189deg) brightness(88%) contrast(85%)',
        }}
      />
    ),
  },
  {
    value: 'macos',
    label: 'MacOS',
    icon: (
      <Box
        component='img'
        src={Assets.icons.IcoAppleOutline}
        alt='windows'
        width={16}
        sx={{
          // opacity: 0.5,
          filter: 'invert(83%) sepia(11%) saturate(268%) hue-rotate(189deg) brightness(88%) contrast(85%)',
        }}
      />
    ),
  },
  {
    value: 'linux',
    label: 'Linux',
    icon: (
      <Box
        component='img'
        src={Assets.icons.IcoLinuxOutline}
        alt='windows'
        width={16}
        sx={{
          opacity: 0.7,
          filter: 'invert(83%) sepia(11%) saturate(268%) hue-rotate(189deg) brightness(88%) contrast(85%)',
        }}
      />
    ),
  },
  {
    value: 'android',
    label: 'Android',
    icon: (
      <Box
        component='img'
        src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1694601261362'
        alt='windows'
        width={16}
        sx={{
          // opacity: 0.5,
          filter: 'invert(83%) sepia(11%) saturate(268%) hue-rotate(189deg) brightness(88%) contrast(85%)',
        }}
      />
    ),
  },
  {
    value: 'ios',
    label: 'iOS',
    icon: (
      <Box
        component='img'
        src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1702450756804'
        alt='windows'
        width={16}
        sx={{
          // opacity: 0.5,
          filter: 'invert(83%) sepia(11%) saturate(268%) hue-rotate(189deg) brightness(88%) contrast(85%)',
        }}
      />
    ),
  },
];
function ProfileEditorProxy({ form, profileDetail }: SetupEditor) {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const { proxy, browserType } = useWatch({
    control: form.control,
  });

  const { onSaveProxy } = useProfileEditorFormActions(form);

  const openProxyConfigModal = () =>
    openModal(ProfileProxyConfigModal, {
      onSaveProxy,
      initialValue: {
        ...proxy,
        selectType: proxy?.isUsedProxy ? proxy.proxyType : PROXY_TYPE_OPTIONS[0],
      },
      browserType,
    });

  const openCloneCookieModal = () =>
    openModal(ProfileCloneCookieModal, { profile: profileDetail, backUrl: appRoutes.profiles.fullPath });

  return (
    <>
      <div className='flex gap-3'>
        {profileDetail ? (
          <Tooltip
            arrow
            title={
              <Typography variant='body2'>
                Không thể đổi hệ điều hành. Để có thể tạo một profile mới với dữ liệu của profile này, hãy sử dụng{' '}
                <Typography
                  component='span'
                  color='warning.main'
                  sx={{ '&:hover': { color: 'warning.light' } }}
                  variant='body2Bold'
                  className='cursor-pointer'
                  onClick={openCloneCookieModal}
                >
                  {t('Nhân bản dữ liệu')}
                </Typography>
              </Typography>
            }
          >
            <div className='grow'>
              <SelectFormField
                name='system'
                label={t('Hệ điều hành')}
                control={form.control}
                options={browserType === 'kikibrowser' || browserType === 'kifox' ? devices.slice(0, 3) : devices}
                valueType='object'
                isOptionEqualToValue={(option, value) =>
                  (option as CreationAutocompleteOptions).value === (value as CreationAutocompleteOptions).value
                }
                disabled
              />
            </div>
          </Tooltip>
        ) : (
          <SelectFormField
            name='system'
            label={t('Hệ điều hành')}
            control={form.control}
            options={browserType === 'kikibrowser' || browserType === 'kifox' ? devices.slice(0, 3) : devices}
            valueType='object'
            isOptionEqualToValue={(option, value) =>
              (option as CreationAutocompleteOptions).value === (value as CreationAutocompleteOptions).value
            }
          />
        )}

        <Button
          variant='contained'
          color='secondary'
          className='whitespace-nowrap'
          startIcon={<Svg src={Assets.images.ImgProxyIcon} sx={{ width: 18, height: 18 }} />}
          sx={{ minWidth: 150 }}
          onClick={openProxyConfigModal}
        >
          {t('profile.settingProxyServer')}
        </Button>
      </div>
    </>
  );
}

export default ProfileEditorProxy;
