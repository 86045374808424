import { styled } from '@mui/material/styles';

const TableInput = styled('input')(({ theme }) => {
  return {
    background: 'transparent',
    color: theme.palette.text.primary,
    width: '100%',
    height: 36,
    border: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.border.neutral,
    borderRadius: 8,
    paddingLeft: 7,
    paddingRight: 7,
    '&:hover': {
      // @ts-ignore
      borderColor: theme.palette.border.neutral,
    },
    '&:focus': {
      // @ts-ignore
      borderColor: theme.palette.border.neutral,
      outline: 'none',
    },
    '&::placeholder': {
      fontSize: 14,
    },
  };
});

export default TableInput;
