import React, { useMemo } from 'react';
import { ConnectButton, darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { chains } from 'kikifrontend/blockchains/configs/wallet';
import { Flex, useSettings } from '@kikisoftware/uikit';
import { BankPaymentProps, CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import TokenWithAmount from './TokenWithAmount';
import { currencyFormat, formatBalanceInCrypto, USDvsVND } from 'kikifrontend/utils/format';
import Divider from '@mui/material/Divider';
import useLocales from 'kikifrontend/hooks/useLocales';
import { useAccount } from 'wagmi';
import PayWithExternalWallet from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/PayWithExternalWallet';
import Button from '@mui/material/Button';
import { TrackContainer } from 'kikifrontend/components';

function DepositWithExternalWalletView({
  amount,
  method,
  selectMethod,
}: {
  amount: string;
  method: CryptoPaymentProps;
  selectMethod: (method?: BankPaymentProps) => () => void;
}) {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const { currentLang } = useLocales();
  const { address } = useAccount();

  const _amount = currentLang.value === 'vi' ? `${Number(amount) / USDvsVND}` : Number(amount);

  const flexibleFee = method.config.flexibleFee;

  const fixedFee = method.config.flexibleFee;

  const fee = useMemo(() => Number(_amount) * (flexibleFee / 100) + fixedFee, [flexibleFee, _amount, fixedFee]);
  const totalAmount = useMemo(() => formatBalanceInCrypto(Number(_amount) + fee), [_amount, fee]);

  return (
    <RainbowKitProvider chains={chains} coolMode theme={themeMode === 'light' ? lightTheme() : darkTheme()}>
      {address ? (
        <PayWithExternalWallet
          amount={`${Number(_amount) + fee}`}
          originalAmount={amount}
          method='Crypto'
          crypto={method}
          flexibleFee={flexibleFee}
          fee={fee}
          fixedFee={fixedFee}
          onBack={selectMethod(undefined)}
        />
      ) : (
        <>
          <Stack
            spacing={1}
            sx={{ border: '1px solid', borderColor: 'border.neutral', px: 2, py: 2, borderRadius: 1.25 }}
          >
            <Flex>
              <Typography variant='body2' color='text.subColor4'>
                {t('Số tiền nạp')}
              </Typography>
              <TokenWithAmount
                amount={`${_amount}`}
                tokenName={method.name}
                tokenIcon={method.logo}
                iconSize={20}
                token={method}
              />
            </Flex>
            <Flex>
              <Typography variant='body2' color='text.subColor4'>
                {t('Network')}
              </Typography>
              <Typography variant='body2Bold'>{method.network.name}</Typography>
            </Flex>
            <Flex sx={{ alignItems: 'flex-start' }}>
              <Typography variant='body2' color='text.subColor4'>
                {t('Phí giao dịch')}
              </Typography>
              <Stack alignItems='flex-end'>
                <Typography variant='body2Bold'>
                  {currencyFormat(fee)} {method.name}
                </Typography>
                <Typography variant='body2' color='text.subColor4' fontStyle='italic'>
                  {t('Phí')}: {currencyFormat(fee)} ({fixedFee}$ + {flexibleFee}% {t('Số tiền thanh toán')})
                </Typography>
              </Stack>
            </Flex>
            <Divider />
            <Flex>
              <Typography variant='body2' color='text.subColor4'>
                {t('Tổng thanh toán')}
              </Typography>
              <TokenWithAmount
                amount={
                  <Typography variant='h6'>
                    {totalAmount} {method.name}
                  </Typography>
                }
                tokenName={method.name}
                tokenIcon={method.logo}
                token={method}
              />
            </Flex>
          </Stack>
          <div className='flex justify-between  mt-4'>
            <Button variant='contained' color='secondary' onClick={selectMethod(undefined)}>
              {t('back')}
            </Button>
            <TrackContainer
              eventName='payment_center_deposit_connect_wallet'
              params={{
                payment_method: 'crypto',
                amount,
              }}
            >
              <ConnectButton />
            </TrackContainer>
          </div>
        </>
      )}
    </RainbowKitProvider>
  );
}

export default DepositWithExternalWalletView;
