export const truncateAddress = (text?: string | number, len = 7) => {
  if (!text) return '';
  if (typeof text === 'number' || text.length < len) {
    return text;
  }
  return text?.slice(0, len) + '...';
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function isValidEmail(email: string) {
  const regex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/;
  return regex.test(email.trim());
}

export function checkProxyFormInUserPassHostPort(input: string) {
  const regex = /^([^:]+):([^@]+)@([^:]+):(\d+)$/;
  const protocolRegex = /^([^:]+):\/\//;

  const protocol = input.match(protocolRegex);
  let proxyString = input.replace(/^.*?:\/\//, '');
  if (proxyString.endsWith('/')) {
    proxyString = proxyString.slice(0, -1);
  }
  const match = proxyString.match(regex);

  if (match) {
    const user = match[1];
    const pass = match[2];
    const host = match[3];
    const port = match[4];

    return {
      protocol: protocol?.[1]?.toLowerCase(),
      proxyString: `${host}:${port}:${user}:${pass}`,
    };
  }

  return {
    protocol: protocol?.[1]?.toLowerCase(),
    proxyString: undefined,
  };
}

export function checkProxyFormInHostPortUserPassword(input: string) {
  const slashIndex = input.indexOf('://');
  if (slashIndex === -1) {
    return {
      protocol: undefined,
      proxyString: input,
    };
  }
  return {
    protocol: input.slice(0, slashIndex)?.toLowerCase(),
    proxyString: input.slice(slashIndex + 3),
  };
}

export function checkProxyForm(input: string) {
  if (input.includes('@')) {
    return checkProxyFormInUserPassHostPort(input);
  }

  return checkProxyFormInHostPortUserPassword(input);
}
