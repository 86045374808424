import { AppBar, Box, IconButton, styled, Toolbar } from '@mui/material';
import useCollapseDrawer from 'kikifrontend/hooks/useCollapseDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import MHidden from './MHidden';
import { useLayoutProvider } from './LayoutProvider';
import React, { useEffect, useMemo } from 'react';
import { Image, Svg, useSettings } from '@kikisoftware/uikit';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import HeaderTab from 'kikifrontend/components/HeaderTab';
import Assets from 'kikifrontend/assets';
import { useAuth } from 'kikifrontend/hooks/useAuth';
import MyAvatar from 'kikifrontend/components/MyAvatar';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import usePopover from 'kikifrontend/hooks/usePopover';
import MenuItem from '@mui/material/MenuItem';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, PremiumButtonIcon } from 'kikifrontend/components';
import appRoutes from 'kikifrontend/routes/path';
import { UserType } from 'kikifrontend/types/user.types';
import Notification from 'kikifrontend/components/Notification/Notification';
import { userServices } from 'kikifrontend/_apis_';

const DRAWER_WIDTH = 300;
const COLLAPSE_WIDTH = 72;

const APPBAR_MOBILE = 70;
const APPBAR_DESKTOP = 70;

interface HeaderProps {
  showHeader: boolean;
  onOpenSidebar?: () => void;
}

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.background.onLayout,
  color: theme.palette.text.primary,
  position: 'fixed',
  zIndex: 10,
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APPBAR_MOBILE,
  gap: 12,
  [theme.breakpoints.up('lg')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(1, 3),
  },
}));

const USER_TYPE: Record<UserType, string> = {
  normal: '',
  partner: 'Đối tác',
  officialPartner: 'Đối tác chính thức',
};

export default function DashboardNavbar({ showHeader, onOpenSidebar }: HeaderProps) {
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);
  const { isCollapse } = useCollapseDrawer();
  const { titles } = useLayoutProvider();
  const { logout } = useAuth();
  const { themeMode, onChangeMode } = useSettings();
  const { anchorEl, togglePopover, open, closePopover } = usePopover();

  useEffect(() => {
    if (statistic?.package?.name?.toLowerCase() !== 'free') {
      window.$crisp.push(['do', 'chat:hide']);
    }
  }, [statistic]);

  const switchThemeMode = () => {
    if (statistic?.package?.name?.toLowerCase() === 'free') return;
    if (themeMode === 'light') {
      onChangeMode('dark');
      void userServices.updateUserTheme('dark');
    } else {
      onChangeMode('light');
      void userServices.updateUserTheme('light');
    }
  };

  const headerTitles = useMemo(() => {
    if (statistic?.userInfo?.isRoot) return titles;
    return titles.filter((item) => !item.hideWithMember);
  }, [statistic?.userInfo?.isRoot, JSON.stringify(titles)]);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
        display: showHeader ? 'flex' : 'none',
      }}
    >
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <MenuIcon />
          </IconButton>
        </MHidden>
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem />}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          alignItems='center'
        >
          {headerTitles.map((tab, idx) => (
            <HeaderTab key={idx} {...tab} />
          ))}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Notification />
        <PremiumButtonIcon
          buttonType='rectangle'
          sx={{ bgcolor: 'background.thirty', borderColor: 'background.thirty' }}
          onClick={switchThemeMode}
        >
          <Image src={themeMode === 'light' ? Assets.icons.IcoDark : Assets.icons.IcoLight} sx={{ width: 24 }} />
        </PremiumButtonIcon>

        <Box
          maxWidth={224}
          sx={{
            bgcolor: { md: 'background.thirty' },
            px: 2,
            py: 0.5,
            borderRadius: '6px',
            height: 40,
            overflow: 'hidden',
          }}
          className='flex gap-2 items-center cursor-pointer'
          onClick={togglePopover}
        >
          <MyAvatar email={statistic?.userInfo?.email ?? ''} sx={{ width: 30, height: 30, fontSize: 18 }} />
          <Box className='flex-col items-start hidden md:flex' width={1}>
            <Typography
              variant='body2Bold'
              color='text.primary'
              lineHeight={1.2}
              className='text-ellipsis overflow-hidden whitespace-nowrap'
              sx={{ maxWidth: 130 }}
            >
              {statistic?.userInfo?.email}
            </Typography>
            {statistic?.userInfo?.userType && USER_TYPE[statistic?.userInfo?.userType] && (
              <div className='flex gap-1 items-center'>
                <Image src={Assets.icons.IcoPartner} sx={{ width: 10 }} />
                <Typography
                  variant='body2Bold'
                  color='success.main'
                  lineHeight={1.2}
                  className='whitespace-nowrap overflow-hidden text-ellipsis'
                >
                  {t(USER_TYPE[statistic?.userInfo?.userType])}
                </Typography>
              </div>
            )}
          </Box>
          <KeyboardArrowRightIcon
            fontSize='small'
            sx={{ color: 'text.subColor4', flexShrink: 1, display: { xs: 'none', md: 'initial' } }}
          />
        </Box>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '.MuiPaper-root': {
              width: 200,
              mt: 1,
            },
          }}
          disableAutoFocusItem
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <Link to={appRoutes.historyLog.fullPath}>
            <MenuItem onClick={closePopover}>
              <div className='flex items-center gap-2'>
                <Svg
                  src={`/static/sidebar-icons/history-log.svg`}
                  sx={{ width: 20, height: 20, bgcolor: 'text.subColor2' }}
                />
                <Typography variant='body2' color='text.primary'>
                  {t('sidebar.historyLog')}
                </Typography>
              </div>
            </MenuItem>
          </Link>
          <Link to={appRoutes.appSettings.fullPath}>
            <MenuItem onClick={closePopover}>
              <div className='flex items-center gap-2'>
                <SettingsOutlinedIcon fontSize='small' sx={{ color: 'text.subColor2' }} />
                <Typography variant='body2' color='text.primary'>
                  {t('settings.general')}
                </Typography>
              </div>
            </MenuItem>
          </Link>
          <MenuItem onClick={() => logout()}>
            <div className='flex items-center gap-2'>
              <LogoutIcon fontSize='small' sx={{ color: 'text.subColor2' }} />
              <Typography variant='body2'>{t('sidebar.logout')}</Typography>
            </div>
          </MenuItem>
        </Menu>
      </ToolbarStyle>
    </RootStyle>
  );
}
