export function toStringObject(obj: Record<string, any>): Record<string, any> {
  const normalizedObj: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'string') {
        normalizedObj[key] = value;
      } else if (typeof value === 'number' || typeof value === 'boolean') {
        normalizedObj[key] = value.toString();
      } else {
        normalizedObj[key] = value;
      }
    }
  }

  return normalizedObj;
}
