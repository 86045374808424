import React, { useEffect } from 'react';
import { setSession } from 'kikifrontend/utils/sessions';
import { useSetRecoilState } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { useAuth } from 'kikifrontend/hooks/useAuth';
import { getStringFromLocalStorage } from 'kikifrontend/utils/localstorage';
import { middleWareError } from 'kikifrontend/utils/configs';
import useWebConfig from 'kikifrontend/hooks/useWebConfig';
// import useExternalTranslations from 'kikifrontend/hooks/useExternalTranslations';
// import useCheckUserIp from 'kikifrontend/hooks/useCheckUserIp';

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { getSystemStatistic } = useAuth();
  // const [loading, setLoading] = useState(true);
  const setSystemConfigs = useSetRecoilState(statisticAtom);

  useWebConfig();
  // useExternalTranslations();
  // useCheckUserIp();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getStringFromLocalStorage('authenticated');

        if (accessToken) {
          setSession(accessToken);
          await getSystemStatistic();
        } else {
          setSystemConfigs({
            statistic: undefined,
            isAuthenticated: false,
            isInitialize: true,
          });
        }
      } catch (err) {
        setSystemConfigs({
          statistic: undefined,
          isAuthenticated: false,
          isInitialize: true,
        });
        if (err instanceof Error) {
          if (err.message === middleWareError) {
            window.location.replace('/login');
          }
        }
      }
    };

    initialize();
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
