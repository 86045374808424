import React from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { YesNoFormFieldProps } from 'kikifrontend/components';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

function YesNoFormField<T extends FieldValues>({ control, name, label, disabled }: YesNoFormFieldProps<T>) {
  return (
    <FormControl component='fieldset' variant='standard' fullWidth>
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field }) => {
          return (
            <>
              <FormControlLabel
                control={<Checkbox checked={field.value} onChange={field.onChange} name={name} />}
                label={<Typography variant='body2'>{label}</Typography>}
                disabled={disabled}
              />
            </>
          );
        }}
      />
    </FormControl>
  );
}

export default YesNoFormField;
