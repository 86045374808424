import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';

function UserPackageBanner() {
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);

  return (
    <Box
      sx={{ bgcolor: 'primary.main', minHeight: 94 }}
      className='px-5 py-4 rounded-lg text-white relative overflow-hidden'
    >
      <Image
        src={Assets.images.Logo}
        asyncLoading={false}
        sx={{ position: 'absolute', top: -60, right: -80, width: 200, opacity: 0.1 }}
      />
      <div className='flex justify-between items-center'>
        <div className='flex flex-col gap-2'>
          <Typography variant='body2'>{t('Gói của bạn')}</Typography>
          <Typography fontSize={28} fontWeight={700}>
            {statistic?.package?.name}
          </Typography>
        </div>
        <div className='p-2 border text-white border-solid border-white rounded-xl z-10'>
          <Typography variant='h6'>
            {t('Số dư')}: {currencyFormat(statistic?.credit, { rate: USDvsVND, prefix: '$' })}
          </Typography>
        </div>
      </div>
    </Box>
  );
}

export default UserPackageBanner;
