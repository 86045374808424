import React, { useState } from 'react';
import { ButtonIcon, Image, Modal, useModal } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import {
  SubmitSurveyData,
  SurveyModalProps,
  SurveyStateProps,
} from 'kikifrontend/features/surveyCustomers/utils/types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SelectWebsites from 'kikifrontend/features/surveyCustomers/components/SelectWebsites';
import DescribeBusiness from './components/DescribeBusiness';
import SizeOfTeam from 'kikifrontend/features/surveyCustomers/components/SizeOfTeam';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { notifyError } from 'kikifrontend/utils/notification';
import { surveyQueries } from 'kikifrontend/features/surveyCustomers/utils/queries';
import Assets from 'kikifrontend/assets';
import CommunityModal from 'kikifrontend/features/surveyCustomers/CommunityModal';

export const SURVEY_STEPS = {
  selectWebsite: 'selectWebsite',
  describeBusiness: 'describeBusiness',
  sizeOfTeam: 'sizeOfTeam',
};

const lowerCaseArr = (arr: string[] = []) => {
  return arr.map((item) => item.split(' ').join('-').toLowerCase());
};

const mappingTeamSize = {
  0: '1',
  25: '2-4',
  50: '5-8',
  75: '9-30',
  100: '30+',
};

function SurveyModal({ initialData }: SurveyModalProps) {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const [step, setStep] = useState(initialData?.step ?? SURVEY_STEPS.selectWebsite);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<SurveyStateProps>(
    initialData?.state ?? {
      selectWebsite: [],
      describeBusiness: [],
      sizeOfTeam: 0,
    },
  );
  const [otherData, setOtherData] = useState(
    initialData?.otherData ?? {
      selectWebsite: '',
      describeBusiness: '',
    },
  );
  const [skipData, setSkipData] = useState(
    initialData?.skipData ?? {
      describeBusiness: false,
      sizeOfTeam: false,
    },
  );

  const surveyMutation = surveyQueries.submitSurvey();

  const getDisabled = () => {
    if (step === SURVEY_STEPS.selectWebsite) {
      if (state.selectWebsite.includes('Other')) {
        return otherData.selectWebsite === '';
      }
      return state.selectWebsite.length === 0;
    }
    if (step === SURVEY_STEPS.describeBusiness) {
      if (state.describeBusiness.includes('Other')) {
        return otherData.describeBusiness === '';
      }
      return state.describeBusiness.length === 0;
    }
  };

  const onChangeOther = (prop: keyof typeof otherData) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherData((prevState) => ({
      ...prevState,
      [prop]: e.target.value,
    }));
  };

  const onBack = () => {
    if (step === SURVEY_STEPS.sizeOfTeam) {
      setStep(SURVEY_STEPS.describeBusiness);
    } else if (step === SURVEY_STEPS.describeBusiness) {
      setStep(SURVEY_STEPS.selectWebsite);
    }
  };

  const onNext = async ({ isSkip = false }) => {
    if (step === SURVEY_STEPS.selectWebsite) {
      setStep(SURVEY_STEPS.describeBusiness);
    } else if (step === SURVEY_STEPS.describeBusiness) {
      setStep(SURVEY_STEPS.sizeOfTeam);
      if (isSkip) {
        setSkipData((prevState) => ({
          ...prevState,
          describeBusiness: true,
        }));
      }
    } else {
      const submitData: SubmitSurveyData = {
        platform: otherData.selectWebsite
          ? lowerCaseArr([...state.selectWebsite, otherData.selectWebsite])
          : lowerCaseArr(state.selectWebsite),
        business: otherData.describeBusiness
          ? lowerCaseArr([...state.describeBusiness, otherData.describeBusiness])
          : lowerCaseArr(state.describeBusiness),
        teamSize: mappingTeamSize[state.sizeOfTeam as keyof typeof mappingTeamSize],
      };
      if (skipData.describeBusiness) {
        delete submitData.business;
      }
      if (isSkip) {
        delete submitData.teamSize;
      }
      try {
        setIsLoading(true);
        await surveyMutation.mutateAsync(submitData);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        openModal(CommunityModal);
      } catch (err) {
        ErrorUtils.handleError<string>(err, {
          isErrorInstanceFn: (error) => {
            notifyError(t(error.data.reason));
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSelectWebsite = (website: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setState((prevState) => ({
        ...prevState,
        selectWebsite: [...prevState.selectWebsite, website],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectWebsite: prevState.selectWebsite.filter((w) => w !== website),
      }));
    }
  };

  const onSelectBusiness = (business: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setState((prevState) => ({
        ...prevState,
        describeBusiness: [...prevState.describeBusiness, business],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        describeBusiness: prevState.describeBusiness.filter((b) => b !== business),
      }));
    }
  };

  const onChangeSizeOfTeam = (e: Event, value: number | number[]) => {
    setState((prevState) => ({
      ...prevState,
      sizeOfTeam: value as number,
    }));
  };

  return (
    <Modal title={t('survey_modal_title')} width={520}>
      {isLoading ? (
        <Stack alignItems='center' spacing={1.25}>
          <Stack spacing={1} alignItems='center'>
            <Image src={Assets.images.Logo} alt='' sx={{ width: 60 }} />
            <Typography variant='body2'>{t('survey_modal_pleaseHoldOn')}</Typography>
          </Stack>
          <Typography
            variant='body2'
            sx={{
              maxWidth: '50%',
              textAlign: 'center',
            }}
          >
            {t('survey_modal_pleaseHoldOn_description')}
          </Typography>
        </Stack>
      ) : (
        <>
          <Box sx={{ height: 500 }}>
            {step === SURVEY_STEPS.selectWebsite && (
              <SelectWebsites
                selectWebsiteData={state.selectWebsite}
                onSelectWebsite={onSelectWebsite}
                value={otherData.selectWebsite}
                onOtherChange={onChangeOther}
              />
            )}
            {step === SURVEY_STEPS.describeBusiness && (
              <DescribeBusiness
                describeBusinessData={state.describeBusiness}
                onSelectDes={onSelectBusiness}
                value={otherData.describeBusiness}
                onOtherChange={onChangeOther}
              />
            )}
            {step === SURVEY_STEPS.sizeOfTeam && <SizeOfTeam value={state.sizeOfTeam} onChange={onChangeSizeOfTeam} />}
          </Box>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' spacing={1} alignItems='center'>
              {step !== SURVEY_STEPS.selectWebsite && (
                <>
                  <ButtonIcon buttonType='circle' onClick={onBack}>
                    <KeyboardReturnOutlinedIcon />
                  </ButtonIcon>
                  <Typography variant='body2'>{t('back')}</Typography>
                </>
              )}
            </Stack>
            <Stack direction='row' spacing={2}>
              {step !== SURVEY_STEPS.selectWebsite && (
                <Button variant='outlined' onClick={() => onNext({ isSkip: true })}>
                  {t('survey_modal_buttonSkip')}
                </Button>
              )}
              <Button variant='contained' onClick={() => onNext({})} disabled={getDisabled()}>
                {t(step === SURVEY_STEPS.sizeOfTeam ? 'survey_modal_buttonSubmit' : 'survey_modal_buttonNext')}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Modal>
  );
}

export default SurveyModal;
