import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PageViewLayout = styled(Box)(({ theme }) => ({
  '& p': {
    marginBottom: 0,
  },
  backgroundColor: theme.palette.background.onLayout,
  borderRadius: 8,
}));

export default PageViewLayout;
