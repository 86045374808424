import { IP_GEOLOCATION_API_KEY, IP_GEOLOCATION_API_URL } from 'kikifrontend/utils/constants';
import axios from 'axios';
import { parseDataFromIpGeolocation } from 'kikifrontend/utils/ip-services';
import { UserIpGeolocationProps, UserIpProps } from 'kikifrontend/types/user.types';

const randomAPIKey = (arr: string[]) => {
  const randomIndex = (arr.length * Math.random()) | 0;
  return arr[randomIndex];
};

async function collectUserCurrentIpLocation(ip?: string): Promise<UserIpProps | undefined> {
  let arrKey = IP_GEOLOCATION_API_KEY;
  let result;
  const getUserIp = async () => {
    const randomKey = randomAPIKey(arrKey);
    arrKey = IP_GEOLOCATION_API_KEY.filter((key) => key !== randomKey);
    const res = ip
      ? await axios.get<UserIpGeolocationProps>(`${IP_GEOLOCATION_API_URL}${randomKey}&ip=${ip}`)
      : await axios.get<UserIpGeolocationProps>(`${IP_GEOLOCATION_API_URL}${randomKey}`);
    result = Promise.resolve(parseDataFromIpGeolocation(res.data));
  };
  try {
    await getUserIp();
  } catch (err) {
    try {
      await getUserIp();
    } catch (err2) {
      try {
        await getUserIp();
      } catch (err3) {
        try {
          await getUserIp();
        } catch (err4) {
          try {
            await getUserIp();
          } catch (err5) {
            result = {
              status: 'proxyDead',
              country: 'others',
              query: '',
              lat: '',
              lon: '',
              language: '',
              zip: '',
              timezone: '',
              city: '',
              countryCode: '',
              region: '',
              regionName: '',
            };
          }
        }
      }
    }
  }
  return result;
}

export const locationServices = {
  collectUserCurrentIpLocation,
};
