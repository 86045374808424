import { atom } from 'recoil';

export const defaultChangePackageOptions = {
  isYear: true,
  selectedTime: 'oneYear',
};

export const changePackageAtom = atom({
  key: 'changePackageAtom',
  default: defaultChangePackageOptions,
});
