import { selectorFamily } from 'recoil';
import { PluginBill, PluginInfo } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { pluginPeriodAtom } from 'kikifrontend/features/plugins/atoms/pluginPeriod.atom';
import { VNDToUSD } from 'kikifrontend/utils/format';
import dayjs from 'dayjs';
import { dateTimeFormatter } from 'kikifrontend/utils/date-time';
import { MAPPING_PERIOD } from 'kikifrontend/features/plugins/utils/constants';

export const pluginCalcSelector = selectorFamily<PluginBill, any>({
  key: 'pluginCalcSelector',
  get:
    ({ plugin, expireAt }: { plugin: PluginInfo; expireAt?: string }) =>
    ({ get }) => {
      const { statistic } = get(statisticAtom);
      const period = get(pluginPeriodAtom);

      const isExpires = plugin.pricePeriod !== -1;
      const priceInVNDPerMonth = isExpires ? plugin.price / plugin.pricePeriod : plugin.price;
      const priceInVNDByPeriod = isExpires
        ? priceInVNDPerMonth * MAPPING_PERIOD[period as keyof typeof MAPPING_PERIOD]
        : plugin.price;
      const priceInUSD = VNDToUSD(plugin.price);
      const priceInUSDPerMonth = VNDToUSD(priceInVNDPerMonth);
      const priceInUSDByPeriod = VNDToUSD(priceInVNDByPeriod);
      const amountNeedToDepositInVND = Math.max(priceInVNDByPeriod - (statistic?.credit ?? 0), 0);
      const amountNeedToDepositInUSD = VNDToUSD(amountNeedToDepositInVND);
      const balanceInUSD = VNDToUSD(statistic?.credit ?? 0);

      return {
        ...plugin,
        priceInUSD,
        isExpires: plugin.pricePeriod !== -1,
        priceInUSDPerMonth,
        priceInUSDByPeriod,
        priceInVNDByPeriod,
        currentExpireDate: expireAt ? dateTimeFormatter(expireAt) : '',
        nextExpireDate: dateTimeFormatter(
          dayjs(expireAt).add(MAPPING_PERIOD[period as keyof typeof MAPPING_PERIOD], 'month'),
        ),
        amountNeedToDepositInVND,
        amountNeedToDepositInUSD,
        balanceInUSD,
        isReadyPay: amountNeedToDepositInVND <= 0,
        period: isExpires ? MAPPING_PERIOD[period as keyof typeof MAPPING_PERIOD] : -1,
        isFree: plugin.price === 0,
      };
    },
});
