import { CheckProxyResult } from 'kikifrontend/types/proxy.types';
import { TFunction } from 'i18next';

export const getProxyCheckedResults = (
  t: TFunction<'translation', undefined, 'translation'>,
  data?: CheckProxyResult,
) => {
  return [
    {
      label: 'proxyIP',
      value: data?.ip ?? t('notFound'),
    },
    {
      label: 'proxyCountry',
      value: data?.country ?? t('notFound'),
    },
    {
      label: 'proxyTimezone',
      value: data?.timezone ?? t('notFound'),
    },
  ];
};
