import { useEffect } from 'react';
import axios from 'axios';

export function ChatWidget() {
  useEffect(() => {
    let tokenPromise = null;
    const fetchToken = () => {
      tokenPromise = axios
        .get(process.env.REACT_APP_REMOTE_SERVER + '/api/livechat')
        .then((resp) => {
          return resp.data.data;
        })
        .then((data) => {
          tokenPromise = null;
          return data;
        });

      return tokenPromise;
    };

    window.__lc = window.__lc || {};
    window.__lc.custom_identity_provider = () => ({
      getToken: () => fetchToken(),
      getFreshToken: () => Promise.resolve(),
      hasToken: () => Promise.resolve(false),
      invalidate: () => Promise.resolve(),
    });

    window.LiveChatWidget?.init();
    window.LiveChatWidget?.call('hide');

    return () => {
      window.LiveChatWidget?.call('destroy');
    };
  }, []);

  return (
    // <InjectScript script={scriptToInject} />        // <noscript />
    <></>
  );
}
