import React, { useState } from 'react';
// MUI
import { Box, CircularProgress, Stack } from '@mui/material';
import { ImageProps } from './types';

const Image = ({ src, sx, alt = '', asyncLoading = false, ...props }: ImageProps) => {
  const [loading, setLoading] = useState(true);

  const onImageLoaded = () => {
    setLoading(false);
  };

  if (!asyncLoading) {
    return (
      <Box
        {...props}
        component='img'
        src={src}
        alt={alt}
        sx={sx}
        onError={() => {
          // currentTarget.onerror = null;
          // currentTarget.src = "/assets/images/cryptos/no-image.png";
          setLoading(false);
        }}
      />
    );
  }

  return (
    <>
      <Stack
        justifyContent='center'
        alignItems='center'
        sx={{
          display: loading ? 'flex' : 'none',
          // @ts-ignore
          height: sx?.height ?? 20,
          // @ts-ignore
          width: sx?.width ?? '100%',
        }}
      >
        <CircularProgress size={20} />
      </Stack>

      <Box sx={{ display: loading ? 'none' : 'block' }}>
        <Box
          {...props}
          component='img'
          src={src}
          alt={alt}
          onLoad={onImageLoaded}
          sx={sx}
          onError={() => {
            // currentTarget.onerror = null;
            // currentTarget.src = "/assets/images/cryptos/no-image.png";
            setLoading(false);
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(Image);
