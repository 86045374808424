import { selectorFamily } from 'recoil';
import {
  PackageChangeProps,
  PackagePricingProps,
  RemainingBalanceProps,
} from 'kikifrontend/features/paymentCenter/types/package.types';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { packageOptions, timeLabelToNumberMonth } from 'kikifrontend/features/paymentCenter/utils/constants';
import { applyCoupon, getDiscountLabel, VNDToUSDFloat } from 'kikifrontend/features/paymentCenter/utils/helpers';
import { changePackageSelectedCouponAtom } from 'kikifrontend/features/paymentCenter/atom/coupon.atom';
import { changePackageAtom } from 'kikifrontend/features/paymentCenter/atom/changePackage.atom';
import dayjs from 'dayjs';

export const packagesCanChange = selectorFamily<PackageChangeProps[], any>({
  key: 'packagesCanChange',
  get:
    (packagesPrice: PackagePricingProps[]) =>
    ({ get }) => {
      const { statistic } = get(statisticAtom);
      if (!packagesPrice) return [];
      return packagesPrice
        .filter((pkg) => pkg.name.toLowerCase() !== statistic?.package.name.toLowerCase() && pkg.name !== 'Free')
        .map((pkg) => {
          const yearlyPricing = pkg.pricingConfig?.oneYear ? Math.round(pkg.pricingConfig.oneYear / 12) : -1;
          return {
            ...pkg,
            isBestSeller: pkg.name === 'Scale',
            ...packageOptions[pkg.name as keyof typeof packageOptions],
            monthlyPricing: pkg.pricingConfig?.oneMonth ? pkg.pricingConfig.oneMonth : -1,
            yearlyPricing,
            monthlyPricingInUSD: pkg.pricingConfig?.oneMonth ? VNDToUSDFloat(pkg.pricingConfig.oneMonth) : -1,
            yearlyPricingInUSD: VNDToUSDFloat(yearlyPricing),
          };
        });
    },
});

export const calculateChangePackagePrice = selectorFamily<any, any>({
  key: 'calculateChangePackagePrice',
  get:
    ({ pkg, remainingData }: { pkg: PackageChangeProps; remainingData?: RemainingBalanceProps }) =>
    ({ get }) => {
      const { statistic } = get(statisticAtom);
      const selectedCoupon = get(changePackageSelectedCouponAtom);
      const { selectedTime } = get(changePackageAtom);

      if (!statistic || !selectedTime || !remainingData) return;

      const packageMonths = timeLabelToNumberMonth[selectedTime as keyof typeof timeLabelToNumberMonth];
      const packagePrice = pkg.pricingConfig.oneMonth * packageMonths;

      const coupon = selectedCoupon
        ? {
            type: selectedCoupon.type,
            amount: selectedCoupon.amount,
            percent: selectedCoupon.amount,
            addMonths: selectedCoupon.amount,
            maxDiscount: selectedCoupon.maxDiscountAmount ?? selectedCoupon.amount,
            discountLabel: getDiscountLabel(selectedCoupon, packagePrice),
            code: selectedCoupon.code,
          }
        : undefined;
      const currentExpireDate = statistic.package.expireAt;
      const nextExpireDate = dayjs().add(packageMonths, 'month').toISOString();

      const { applyCouponNextExpireDate, applyCouponPackagePrice, applyCouponPackagePriceInUSD } = applyCoupon(
        { nextExpireDate, packagePrice },
        coupon,
      );

      const userTotalBalance = (remainingData.CURRENT_LICENSE_REMAINING_MONEY ?? 0) + remainingData.USER_CREDIT;

      const userBalanceAfterPay = userTotalBalance - applyCouponPackagePrice;

      const results = {
        package: pkg,
        packageName: pkg.name,
        packagePrice,
        packagePriceInUSD: VNDToUSDFloat(packagePrice),
        packageMonths,
        currentExpireDate,
        nextExpireDate,
        coupon,
        applyCouponNextExpireDate,
        applyCouponPackagePrice: applyCouponPackagePrice < 0 ? 0 : applyCouponPackagePrice,
        applyCouponPackagePriceInUSD: applyCouponPackagePriceInUSD < 0 ? 0 : applyCouponPackagePriceInUSD,
        selectedTime,
        isReadySubmitExtend: userTotalBalance >= applyCouponPackagePrice,
        amountNeedToDeposit: applyCouponPackagePrice - userTotalBalance,
        amountNeedToDepositInUSD: VNDToUSDFloat(applyCouponPackagePrice - userTotalBalance),
        remainingBalance: remainingData.CURRENT_LICENSE_REMAINING_MONEY,
        remainingBalanceInUSD: VNDToUSDFloat(remainingData.CURRENT_LICENSE_REMAINING_MONEY),
        userTotalBalance,
        userTotalBalanceInUSD: VNDToUSDFloat(userTotalBalance),
        userBalance: remainingData.USER_CREDIT,
        userBalanceInUSD: VNDToUSDFloat(remainingData.USER_CREDIT),
        userBalanceAfterPayment: userBalanceAfterPay,
        userBalanceAfterPaymentInISD: VNDToUSDFloat(userBalanceAfterPay),
      };

      return results;
    },
});
