import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'kikifrontend/components';
import { Image } from '@kikisoftware/uikit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import appRoutes from 'kikifrontend/routes/path';

function PluginNoSupport() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ bgcolor: 'background.onLayout', height: 'calc(100vh - 140px)' }}
      className='w-full h-full flex flex-col items-center justify-center gap-2 py-14 rounded-md'
    >
      <Image
        src='https://cdn-icons-png.flaticon.com/512/13611/13611087.png?ga=GA1.1.969386378.1704703279&'
        sx={{ width: 200 }}
      />
      <div className='flex flex-col items-center'>
        <Typography variant='h3' component='p' my={3} sx={{ fontFamily: 'SVN-Gilroy-Medium', color: 'primary.dark' }}>
          Phiên bản không được hỗ trợ
        </Typography>
        <Typography variant='body1' mt-5 component='p' color='primary.dark'>
          Tính năng này chỉ hỗ trợ từ <strong>phiên bản 1.8.3</strong> trở lên
        </Typography>
        <Typography variant='body1' mt-5 component='p' color='primary.dark'>
          Vui lòng cập nhật ứng dụng để sử dụng
        </Typography>
        <Link to={appRoutes.supportCenter.fullPath}>
          <Button variant='contained' sx={{ mt: 3 }} startIcon={<DownloadIcon />}>
            {t('Cập nhật ngay')}
          </Button>
        </Link>
      </div>
    </Box>
  );
}

export default PluginNoSupport;
