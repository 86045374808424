import React, { useId } from 'react';
import { SidebarItem } from 'kikifrontend/layouts/dashboard/SidebarConfig';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import {
  activeRootStyle,
  activeSubStyle,
  ListItemIconStyle,
  ListItemStyle,
} from 'kikifrontend/layouts/dashboard/sidebar/NavSection';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function NavItemCollapse({ item, isShow }: { item: SidebarItem; isShow: boolean; onExpand: () => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { title, path, icon, info } = item;
  const isActiveRoot = path ? pathname.indexOf(path) !== -1 : false;
  const id = useId();

  const showSubMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeSubMenu = () => {
    setAnchorEl(null);
  };

  const subMenusNodes = item.children?.map((subMenuItem) => {
    const { title, path } = subMenuItem;
    const isActiveSub = path ? pathname.indexOf(path) !== -1 : false;

    return (
      <MenuItem
        key={title}
        component={RouterLink as any}
        to={path}
        sx={{
          ...(isActiveSub && activeSubStyle),
          px: 2,
        }}
      >
        <ListItemText
          disableTypography
          primary={t(title)}
          sx={{ fontSize: 14, color: isActiveSub ? 'primary.main' : 'inherit' }}
        />
      </MenuItem>
    );
  });

  return (
    <>
      <ListItemStyle
        component={RouterLink as any}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
          position: 'relative',
          marginBottom: isShow ? 0 : 0.5,
          height: isShow ? 36 : 50,
        }}
        aria-owns={anchorEl ? id : undefined}
        aria-haspopup='true'
        onMouseLeave={closeSubMenu}
        onMouseEnter={(e) => {
          if (item.children) {
            showSubMenu(e);
            return;
          }
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText disableTypography primary={t(title)} sx={{ fontSize: 14 }} />
            {info && info}
          </>
        )}
      </ListItemStyle>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeSubMenu}
        PaperProps={{
          onMouseEnter: (e) => {
            showSubMenu(e);
          },
          onMouseLeave: () => {
            closeSubMenu();
          },
          sx: {
            left: '0px !important',
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{
          left: 71,
          '& .MuiBackdrop-root': {
            left: 71,
          },
        }}
      >
        {subMenusNodes}
      </Menu>
    </>
  );
}

export default NavItemCollapse;
