import React, { useMemo } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MenuPopover } from '@kikisoftware/uikit';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FilterByFieldProps } from './types';

function SelectFields({ title, onChangeFilterString, filterFields = [], translationFn }: FilterByFieldProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [innerFilterFields, setInnerFilterFields] = React.useState(filterFields);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // onChangeFilterString?.(innerFilterFields);
  };

  const changeFilterFieldAllowance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilterFields = [...innerFilterFields];
    const updatedFieldIndex = newFilterFields.findIndex((field) => field.columnField === e.target.name);
    if (updatedFieldIndex !== -1) {
      newFilterFields[updatedFieldIndex] = { ...newFilterFields[updatedFieldIndex], accept: e.target.checked };
    }
    setInnerFilterFields(newFilterFields);
    onChangeFilterString?.(newFilterFields);
  };

  const lastFilterField = useMemo(() => {
    const filter = innerFilterFields?.filter((field) => field.accept);
    return filter.length === 1 ? filter[0] : undefined;
  }, [innerFilterFields]);

  return (
    <>
      <Button
        size='small'
        onClick={handleOpen}
        variant='outlined'
        sx={{
          width: 34,
          minWidth: 34,
          minHeight: 40,
          height: 40,
          borderColor: 'divider',
          color: 'text.primary',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 'none',
          transition: 'none',
        }}
        className='button-filter'
      >
        <FilterListIcon sx={{ color: open ? '#DBDBDB' : 'divider' }} />
      </Button>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorEl} sx={{ width: 300, px: 2, py: 1 }}>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{title}</Typography>
        <FormGroup sx={{ mt: 1 }}>
          {innerFilterFields?.map((field, index) => (
            <FormControlLabel
              key={index}
              disabled={filterFields?.length <= 1 || lastFilterField?.columnField === field.columnField}
              control={
                <Checkbox
                  checked={field.accept}
                  onChange={changeFilterFieldAllowance}
                  sx={{ cursor: 'pointer' }}
                  size='small'
                />
              }
              label={
                <Typography variant='body2' color='text.subColor4'>
                  {translationFn ? translationFn(field.label) : field.label}
                </Typography>
              }
              name={field.columnField}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.08)',
                },
                m: 0,
                '& .MuiTypography-root': {
                  fontSize: 14,
                },
              }}
            />
          ))}
        </FormGroup>
      </MenuPopover>
    </>
  );
}

export default SelectFields;
