import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import useCollapseDrawer from 'kikifrontend/hooks/useCollapseDrawer';
import { useTheme } from '@mui/material/styles';
import DashboardNavbar from 'kikifrontend/layouts/dashboard/DashboardNavbar';
import { useLayoutProvider } from './LayoutProvider';
import { Outlet } from 'react-router-dom';
import WithSignIn from 'kikifrontend/layouts/hoc/WithSignIn';
import WithApplySettings from 'kikifrontend/layouts/hoc/WithApplySettings';
import { ChatWidget } from 'kikifrontend/components/ChatWidget';
import DashboardSidebar from 'kikifrontend/layouts/dashboard/sidebar/DashboardSidebar';
import useHelperChat from 'kikifrontend/hooks/useHelperChat';
import { BubbleHelper } from 'kikifrontend/components';
import { FallingEffect } from '@kikisoftware/uikit';
import BlockedModal from 'kikifrontend/components/Modals/BlockedModal';

const APP_BAR_MOBILE = 72;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled('div')({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')<{ showHeader: boolean }>(({ theme, showHeader }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: 0,
  paddingTop: showHeader ? APP_BAR_MOBILE + 24 : 0,
  paddingBottom: 0,
  background: theme.palette.background.layout,
  [theme.breakpoints.up('lg')]: {
    paddingTop: showHeader ? APP_BAR_DESKTOP + 40 : 0,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const NewLayout = () => {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { showHeader } = useLayoutProvider();

  useHelperChat();

  const openSidebar = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <WithSignIn>
      <WithApplySettings>
        <BlockedModal />
        <ChatWidget />
        <FallingEffect />
        <RootStyle>
          <DashboardNavbar showHeader={showHeader} onOpenSidebar={openSidebar} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

          <MainStyle
            className='container mx-auto px-6 pb-6'
            showHeader={showHeader}
            sx={{
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.complex,
              }),
              ...(collapseClick && {
                ml: '72px',
              }),
              // background: 'url("https://oasis.app/static/img/background/top_bg.png")',
              // backgroundSize: 'cover',
              // backgroundAttachment: 'fixed',
              // backgroundPosition: '70% 100%',
              // backgroundRepeat: 'no-repeat',
            }}
          >
            <Outlet />
          </MainStyle>
          <BubbleHelper />
        </RootStyle>
      </WithApplySettings>
    </WithSignIn>
  );
};

export default NewLayout;
