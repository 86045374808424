import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useWs from 'kikifrontend/hooks/useWs';
import { localSocketURL } from 'kikifrontend/utils/configs';
import { RECEIVED_SOCKET_EVENTS, SOCKET_EVENTS } from 'kikifrontend/utils/constants';
import { useSetRecoilState } from 'recoil';
import { isAppConnectedAtom, systemInformationAtom } from 'kikifrontend/utils/recoil/atom';
import { ApolloScanProps } from 'kikifrontend/features/apolloScan/types/scan.types';
import { DownloadingStatus } from 'kikifrontend/components/Notification/Notification';
import { getStringFromLocalStorage } from 'kikifrontend/utils/localstorage';
import { lastSyncProfileAtom } from 'kikifrontend/features/synchronizer/atoms/syncProfile.atom';
import { runningProfilesAtom } from 'kikifrontend/features/profiles/profileList/atoms/profileStatus.atom';

const DELAY_RECONNECT_TIME = 3000;

const socketContext = React.createContext<{
  ws?: WebSocket;
  pingClient: () => void;
  resetApolloEventData: () => void;
  apolloEventData: ApolloScanProps | undefined;
  browserEventData: {
    gomu?: DownloadingStatus;
    kifox?: DownloadingStatus;
  };
}>({
  ws: undefined,
  pingClient: () => {
    // Empty
  },
  apolloEventData: undefined,
  browserEventData: {
    kifox: undefined,
    gomu: undefined,
  },
  resetApolloEventData: () => {
    //Empty
  },
});

function AppSocketProvider({ children }: React.PropsWithChildren) {
  const { ws, retry } = useWs(localSocketURL);
  const setIsAppConnected = useSetRecoilState(isAppConnectedAtom);
  const setSystemInformation = useSetRecoilState(systemInformationAtom);
  const [apolloEventData, setApolloEventData] = useState<ApolloScanProps | undefined>();
  const [browserEventData, setBrowserEventData] = useState<{
    gomu?: DownloadingStatus;
    kifox?: DownloadingStatus;
  }>({
    kifox: undefined,
    gomu: undefined,
  });
  const setLastSyncProfile = useSetRecoilState(lastSyncProfileAtom);
  const setRunningProfiles = useSetRecoilState(runningProfilesAtom);

  useEffect(() => {
    if (!ws) return;

    ws.onopen = () => {
      console.log('socket connected');
    };

    ws.onmessage = (event) => {
      const data = event.data.startsWith('{') && event.data.endsWith('}') ? JSON.parse(event.data) : undefined;

      if (data && data?.action === RECEIVED_SOCKET_EVENTS.updateProfile) {
        if (data.status === 'idle') {
          setLastSyncProfile(data.profile);
        }
        setRunningProfiles((prev) => ({ ...prev, [data.profile]: data.status }));
      }

      if (data?.action === RECEIVED_SOCKET_EVENTS.pingClient) {
        setSystemInformation(data.pingResult);
      }

      if (data?.action === RECEIVED_SOCKET_EVENTS.connectSuccess) {
        sendMessage({
          action: SOCKET_EVENTS.pingClient,
          sessionId: getStringFromLocalStorage('accessToken'),
        });
        setIsAppConnected(true);
      }

      if (data?.action?.startsWith('apollo')) {
        setApolloEventData(data);
      }

      if (data?.action?.startsWith('browser')) {
        if (data.browserType === 'kifox') {
          setBrowserEventData((prev) => ({
            ...prev,
            kifox: data,
          }));
        } else {
          setBrowserEventData((prev) => ({
            ...prev,
            gomu: data,
          }));
        }
        if (data.action === RECEIVED_SOCKET_EVENTS.browserBrowserExtracted) {
          if (data.browserType === 'gomubrowser') {
            setSystemInformation((prev) => {
              if (!prev) return prev;
              return { ...prev, appDataGomuStatus: 'checked' };
            });
          }
        }
      }
    };

    ws.onclose = () => {
      setIsAppConnected(false);
      setSystemInformation(undefined);
      setTimeout(retry, DELAY_RECONNECT_TIME);
    };
  }, [ws]);

  const sendMessage = useCallback(
    (data: any) => {
      if (!ws) return;
      ws.send(JSON.stringify(data));
    },
    [ws],
  );

  const pingClient = useCallback(() => {
    sendMessage({
      action: SOCKET_EVENTS.pingClient,
    });
  }, [sendMessage]);

  const resetApolloEventData = useCallback(() => {
    setApolloEventData(undefined);
  }, []);

  const values = useMemo(
    () => ({
      ws,
      pingClient,
      apolloEventData,
      browserEventData,
      resetApolloEventData,
    }),
    [ws, apolloEventData, browserEventData],
  );

  return <socketContext.Provider value={values}>{children}</socketContext.Provider>;
}

export const useAppSocket = () => React.useContext(socketContext);

export default AppSocketProvider;
