import { atom } from 'recoil';
import { PROFILE_EDITOR_DEFAULT_SYSTEM } from 'kikifrontend/features/profiles/profileEditor/utils/constants';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
// import { getRandomElement } from 'kikifrontend/utils/helpers';

export const profileEditorFormAtom = atom<{ system: string; browserType: BrowserType }>({
  key: 'profileEditorFormAtom',
  default: {
    system: PROFILE_EDITOR_DEFAULT_SYSTEM,
    // browserType: getRandomElement(['kikibrowser', 'gomubrowser']),
    browserType: 'gomubrowser',
  },
});

export const loadingNewFingerprintAtom = atom<boolean>({
  key: 'loadingNewFingerprintAtom',
  default: false,
});
