import React from 'react';
import { Image, Modal } from '@kikisoftware/uikit';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authServices } from 'kikifrontend/_apis_';
import Assets from 'kikifrontend/assets';
import Button from '@mui/material/Button';

function ExtendSuccessfullyModal() {
  const { t } = useTranslation();

  const handleClose = async () => {
    const newData = {
      serviceCode: 'kikilogin',
      sessionId: localStorage.getItem('authenticated') ?? '',
    };
    await authServices.ssoLogin(newData);
    window.location.reload();
  };
  return (
    <Modal title={t('Gia hạn')} onClose={handleClose} width={622} hideHeader>
      <Box position='relative'>
        <Image src={Assets.icons.IcoActionSuccess} asyncLoading={false} alt='' sx={{ mx: 'auto', width: 1, ml: 2 }} />
        <Box sx={{ width: '100%', mt: 2 }}>
          <Typography component='p' textAlign='center' variant='h5'>
            {t('Cảm ơn')}
          </Typography>
          <Typography component='p' textAlign='center' variant='body2' mt={2.5}>
            {t('Thanh toán của bạn đã hoàn tất')}
          </Typography>
        </Box>
        <Button variant='contained' fullWidth onClick={handleClose} sx={{ mt: 8 }}>
          {t('Xác nhận')}
        </Button>
      </Box>
      {/*<Stack spacing={1} px={3} py={1.5} sx={{ border: '1px solid #F1F3F4', borderRadius: 1 }} mt={3}>*/}
      {/*  <Typography variant='body1Bold'>{t('Chi tiết giao dịch')}</Typography>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Tên gói cước')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold'>{billSummary.packageName}</Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Thời gian gia hạn')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold'>{t(billSummary.selectedTime)}</Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Ngày kết thúc')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold'>{dateTimeFormatter(billSummary.applyCouponNextExpireDate)}</Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Phí duy trì')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold'>{CurrencyFormat(billSummary.packagePriceInUSD, '$', '', 2)}</Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Khuyến mãi')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold' color='primary.main'>*/}
      {/*      {billSummary.coupon?.discountLabel ?? '--'}{' '}*/}
      {/*      {billSummary.coupon?.type === couponTypes.addMonth && t('tháng sử dụng')}*/}
      {/*    </Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Thời gian thanh toán')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold'>{dateTimeFormatter(billSummary.paymentDate)}</Typography>*/}
      {/*  </Flex>*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='textSm' color='#A3ADB7'>*/}
      {/*      {t('Trạng thái')}*/}
      {/*    </Typography>*/}
      {/*    <Typography variant='body2Bold' color='#28A745'>*/}
      {/*      {t('Hoàn thành')}*/}
      {/*    </Typography>*/}
      {/*  </Flex>*/}
      {/*  <Divider />*/}
      {/*  <Flex>*/}
      {/*    <Typography variant='body1Bold'>{t('Tổng đã thanh toán')}</Typography>*/}
      {/*    <Typography fontSize={20} fontWeight={600}>*/}
      {/*      {CurrencyFormat(billSummary.applyCouponPackagePriceInUSD, '$', '', 2)}*/}
      {/*    </Typography>*/}
      {/*  </Flex>*/}
      {/*</Stack>*/}
    </Modal>
  );
}

export default ExtendSuccessfullyModal;
