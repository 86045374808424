import React from 'react';
import useLocales from 'kikifrontend/hooks/useLocales';
import Typography from '@mui/material/Typography';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { getPaymentMethodsQuery } from 'kikifrontend/features/paymentCenter/queries/paymentMethod.query';
import { BankPaymentProps, CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import { ButtonIcon, Image } from '@kikisoftware/uikit';
import { currencyFormat } from 'kikifrontend/utils/format';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { TrackContainer } from 'kikifrontend/components';

function SelectPaymentMethodAmountConfirm({
  amount,
  selectMethod,
  backToInputAmount,
}: {
  amount: string;
  selectMethod: (method: CryptoPaymentProps | BankPaymentProps) => () => void;
  backToInputAmount: () => void;
}) {
  const { t } = useTranslation();
  const { currentLang, allLang } = useLocales();
  const { data } = getPaymentMethodsQuery();

  const isVN = currentLang.value === allLang[1].value;

  return (
    <div>
      <div className='flex items-center mb-6'>
        <ButtonIcon buttonType='circle' onClick={backToInputAmount}>
          <KeyboardArrowLeftIcon />
        </ButtonIcon>
        <Typography variant='body1Bold'>{t('Choose payment methods')}</Typography>
      </div>
      <SimpleBar style={{ height: window.innerHeight * 0.5, paddingRight: 15 }}>
        {data?.map((method) => {
          const isActive = method.status === 'ACTIVE';
          const bankData = method as BankPaymentProps;
          return (
            <TrackContainer
              key={method.name}
              height={65}
              className='flex justify-between items-center'
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                px: 2,
                borderRadius: 1,
                mb: 2,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'secondary.main',
                  borderColor: 'primary.main',
                  color: 'secondary.darker',
                },
                ...(!isActive && {
                  webkitFilter: 'grayscale(100%)' /* Safari 6.0 - 9.0 */,
                  filter: 'grayscale(100%)',
                  pointerEvents: 'none',
                }),
              }}
              onClick={selectMethod(method)}
              eventName='payment_center_deposit_select_method'
              params={{
                payment_method: method.type,
              }}
            >
              <div className='flex gap-2 items-center'>
                <Image src={bankData.logo} asyncLoading={false} sx={{ width: 32, height: 32 }} />
                <Typography fontSize={18} fontWeight={700}>
                  {bankData.name}
                </Typography>
              </div>
              <div className='flex flex-col gap-1 items-end'>
                <Typography fontSize={18} fontWeight={700}>
                  {currencyFormat(amount, { prefix: isVN ? 'đ' : '$' })}
                </Typography>
                <Typography variant='body2' color='success.main'>
                  {t('Free transaction fee')}
                </Typography>
              </div>
            </TrackContainer>
          );
        })}
      </SimpleBar>
    </div>
  );
}

export default SelectPaymentMethodAmountConfirm;
