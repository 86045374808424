import { createContext, ReactElement, SyntheticEvent, useState } from 'react';

const AccordionContext = createContext<{
  expanded: string | number | false;
  onChange: (panel: string | number) => (event: SyntheticEvent, newExpanded: boolean) => void;
  disableOnlyOneAccordionExpand?: boolean;
}>({
  expanded: '',
  onChange: () => () => {
    // Code here
  },
  disableOnlyOneAccordionExpand: false,
});

const AccordionProvider = ({
  defaultActive,
  disableOnlyOneAccordionExpand,
  children,
}: {
  defaultActive: string | number | false;
  disableOnlyOneAccordionExpand?: boolean;
  children?: JSX.Element | ReactElement;
}) => {
  const [expanded, setExpanded] = useState<string | number | false>(defaultActive);

  const onChange = (panel: string | number) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <AccordionContext.Provider value={{ expanded, onChange, disableOnlyOneAccordionExpand }}>
      {children}
    </AccordionContext.Provider>
  );
};

const AccordionConsumer = AccordionContext.Consumer;

export { AccordionProvider, AccordionContext, AccordionConsumer };
