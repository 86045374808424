import { FeedbackForm } from 'kikifrontend/features/feedback/types/feedback.type';

export const FEEDBACK_TYPES = {
  featureRequest: 'featureRequest',
  bugReport: 'bugReport',
  complain: 'complain',
  other: 'other',
};

export const FEEDBACK_TYPE_OPTIONS = Object.values(FEEDBACK_TYPES).map((type) => ({
  value: type,
  label: type,
}));

export const FEEDBACK_DEFAULT_FORM_VALUES: FeedbackForm = {
  type: FEEDBACK_TYPES.featureRequest,
  content: '',
  attachments: {},
  attachDebugLogs: true,
  receiveNotification: true,
};
