import { CheckProxyResult, ProxyType } from 'kikifrontend/types/proxy.types';
import http from 'kikifrontend/utils/api-services/http';
import { OpenBrowserConfig } from 'kikifrontend/types/profile.types';
import { ResponseApi } from 'kikifrontend/types/api.types';
import { KikiSystemProps } from 'kikifrontend/types/common.types';
import { ExtensionStoreProps } from 'kikifrontend/types/extension.types';
import HttpUtils from 'kikifrontend/utils/api-services/httpUtils';
import { TaskFormValues, TaskProps } from 'kikifrontend/features/automation/v3/AutomationTask/types/task.types';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import { apiAutomationEndpoint } from 'kikifrontend/utils/configs';
import { FeedbackFormValues } from 'kikifrontend/features/feedback/types/feedback.type';

const WAITING_API_LOCAL_TIMEOUT = 5000;
const CHECK_PROXY_TIME_OUT = 20000;

const checkProxy = async (data: { proxyString?: string; proxyType?: ProxyType }) => {
  return http.local
    .post<ResponseApi<CheckProxyResult>>('/network/check-proxy', data, { timeout: CHECK_PROXY_TIME_OUT })
    .then(HttpUtils.getDataFromHttpResponse);
};

const changeChangeIpUrl = async (url: string) => {
  return http.local.post('/api/local-api/proxy/refresh-url', { url });
};

const openBrowser = async ({ profileID, config }: { profileID: string; config: OpenBrowserConfig }) => {
  return http.local.post(`/${profileID}`, config);
};

const closeBrowser = async (profileID: string) => {
  return http.local.post(`/s?sid=${profileID}`);
};

// const byPassMomoIPV6 = async (data: { profileId: string; proxyString: string }) => {
//   return http.local.post(`${apiAutomationLocalEndpoint}/api/local-api/disable-proxy-on-popup`, data);
// };

const bringtoFront = (profileID: string) => {
  const route = `/bring-to-front?sid=${profileID}`;
  return http.local.get(route);
};

const runScript = ({
  scriptId,
  profileID,
  ipAddress,
  options = {},
}: {
  scriptId: string;
  profileID: string;
  ipAddress?: string;
  options: any;
}) => {
  const { isFarm } = options;
  const protocol = `http://${ipAddress}:8000`;
  const route = ipAddress
    ? `${protocol}/run-automation?profile=${profileID}&app=${scriptId}`
    : `/run-automation?profile=${profileID}&app=${scriptId}`;
  if (isFarm) {
    return http.local.post(route);
  }
  return http.local.post(route, options.payload);
};

const ping = () => {
  const route = `/ping`;
  return http.local
    .get<ResponseApi<KikiSystemProps>>(route, undefined, { timeout: WAITING_API_LOCAL_TIMEOUT })
    .then((res) => res.data);
};

const checkProxyLiveOrDead = ({ proxyType, proxyString }: { proxyType: string; proxyString: string }) => {
  const route = `/network/check-proxy`;
  return http.local.post(route, { proxyType, proxyString });
};

const showHistory = ({ profileId, scriptId }: { profileId: string; scriptId: string }) => {
  const route = `/run-automation/logs?profile=${profileId}&script=${scriptId}`;
  return http.local.get(route, undefined, { timeout: WAITING_API_LOCAL_TIMEOUT });
};

const pingAutomationServer = () => {
  const route = `/api/local-api/ping`;
  return http.local.get(route, undefined, { timeout: WAITING_API_LOCAL_TIMEOUT });
};

const generateLiveView = (profileId: string) => {
  const route = `/api/local-api/profile/${profileId}/generate-liveview-url`;
  return http.local.get(route, undefined, { timeout: WAITING_API_LOCAL_TIMEOUT });
};

const checkExtensionURL = async (id?: string) => {
  return http.local
    .post<ResponseApi<ExtensionStoreProps>>(
      '/api/local-api/extension/get-chrome-extension-info',
      {
        lang: 'en-US',
        uuid: id,
      },
      {
        timeout: WAITING_API_LOCAL_TIMEOUT,
      },
    )
    .then((res) => res.data);
};

const mutateAutomationTask = async (data: TaskFormValues) => {
  return http.local.post('/api/task', data);
};

const getAllTask = async () => {
  return http.local.get<ResponseApi<TaskProps[]>>('/api/task').then(HttpUtils.getDataFromHttpResponse);
};

const deleteTask = async (data: { taskId: string }) => {
  return http.local.post('/api/task/delete', data);
};

const reRunTask = async (data: { taskId: string }) => {
  return http.local.post('/api/task/rerun', data);
};

const downloadBrowser = async (browserType: BrowserType) => {
  return http.local.post('/api/local-api/browser/download-browser', { browserType });
};

const reDownloadBrowser = async (browserType: BrowserType) => {
  return http.local.post('/api/local-api/browser/download-browser', { browserType, forceDownload: true });
};
const apolloCheckDomain = async (data: { profileId?: string; domain: string }) => {
  return http.local.post('/api/local-api/apollo-scan/check-domain', data);
};

const byPassMomoIPV6 = async (data: { profileId: string; proxyString: string }) => {
  return http.local.post(`${apiAutomationEndpoint}/api/local-api/disable-proxy-on-popup`, data);
};

const pingAutomation = () => {
  return http.automation
    .get<ResponseApi<{ currentVersion: string }>>('/api/local-api/ping')
    .then(HttpUtils.getDataFromHttpResponse);
};

const sendFeedback = (data: FeedbackFormValues) => {
  return http.local.post('/api/local-api/internal/send-feedback', data);
};

const fixVcRedist = () => {
  return http.local.post('/api/local-api/browser/fix-vc-redist');
};

export const localServices = {
  showHistory,
  openBrowser,
  closeBrowser,
  bringtoFront,
  runScript,
  ping,
  checkProxyLiveOrDead,
  generateLiveView,
  pingAutomationServer,
  checkProxy,
  changeChangeIpUrl,
  checkExtensionURL,
  mutateAutomationTask,
  getAllTask,
  deleteTask,
  downloadBrowser,
  apolloCheckDomain,
  byPassMomoIPV6,
  reRunTask,
  pingAutomation,
  reDownloadBrowser,
  sendFeedback,
  fixVcRedist,
};
