import { ProfileProps } from 'kikifrontend/types/profile.types';
import { ProfileEditorFormValue } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';

export const convertProfileInfoToFormValues = ({
  profileInfo: profile,
  tags,
}: ProfileProps): ProfileEditorFormValue => {
  return {
    name: profile.name,
    proxy: {
      ...profile.proxy,
      selectType: profile.proxy.proxyType,
    },
    addFormField: {
      enabledMediaFingerprint: profile.mediaDeviceFingerprint.isEnableProtection,
      latitude: profile.advancedFingerprint?.geolocation?.[0] ?? '',
      longitude: profile.advancedFingerprint?.geolocation?.[1] ?? '',
      numberOfAudioInput: profile.mediaDeviceFingerprint.audioInputs?.length ?? 0,
      numberOfAudioOutput: profile.mediaDeviceFingerprint.audioOutputs?.length ?? 0,
      numberOfVideoInput: profile.mediaDeviceFingerprint.videoInputs?.length ?? 0,
      proxyTimezone: '',
    },
    tags,
    advancedFingerprint: {
      ...profile.advancedFingerprint,
      protectPortScanAllowedPort: profile.advancedFingerprint.protectPortScanAllowedPort ?? '',
      canvasHash: profile.advancedFingerprint.canvasHash ?? '',
      webgl:
        profile.browserType === 'gomubrowser' || profile.browserType === 'kifox'
          ? profile.advancedFingerprint.webgl
          : {
              mode: profile.advancedFingerprint.protectWebgl ? 'replacement' : 'real',
              unmaskedVendor: profile.advancedFingerprint.webglVendor,
              unmaskedRenderer: profile.advancedFingerprint.webglRenderer,
              params: {},
              extensions: { webgl: [], webgl2: [] },
              imageMode: 'real',
              imageNoiseSeed: 0,
            },
    },
    autoTimezone: profile.autoTimezone,
    browserType: profile.browserType ?? 'kikibrowser',
    computerInfomation: profile.computerInfomation,
    customExtensions: profile.customExtensions,
    group: '',
    homepage: profile.homepage,
    isAutoLanguage: profile.isAutoLanguage,
    language: profile.language,
    mediaDeviceFingerprint: profile.mediaDeviceFingerprint,
    note: profile.note,
    otherConfig: profile.otherConfig,
    platform: profile.platform,
    resolution: profile.resolution,
    savingOptions: profile.savingOptions,
    system: profile.system,
    timezone: profile.timezone,
    userAgent: profile.userAgent,
    webRTC: profile.webRTC,
    workingPlatform: profile.workingPlatform ?? 'other',
    workingPlatformPassword: profile.workingPlatformUsername ?? '',
    workingPlatformUsername: profile.workingPlatformPassword ?? '',
  };
};
