import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import { PagePaginationProps } from 'kikifrontend/components/Table/types';

function PagePagination({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onChangeInputPage,
  rowsPerPageOptions,
}: PagePaginationProps) {
  const { t } = useTranslation();
  return (
    <Stack direction='row' alignItems='center' justifyContent='flex-end' sx={{ bgcolor: 'background.onLayout' }}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography variant='body2' sx={{ fontWeight: 500 }}>
          {t('Đi đến trang')}:
        </Typography>
        <NumericFormat
          customInput={TextField}
          onValueChange={(v) => {
            onChangeInputPage(v.value);
          }}
          // value={inputPage}
          sx={{
            width: 50,
            height: 28,
            '& .MuiInputBase-root': {
              height: 28,
            },
          }}
          defaultValue={page + 1}
          size='small'
          allowNegative={false}
          decimalScale={0}
        />
      </Stack>
      <TablePagination
        component='div'
        count={count ?? 0}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 20, 50]}
        showFirstButton
        showLastButton
        labelRowsPerPage={t('Số bản ghi mỗi trang')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} ${t('trong') ?? 'of'} ${count !== -1 ? count : `more than ${to}`}`
        }
        sx={{
          '.MuiTablePagination-selectLabel': {
            fontSize: 14,
            background: 'transparent',
          },
          '.MuiTablePagination-displayedRows': {
            fontSize: 14,
          },
          '.MuiInputBase-root': {
            minWidth: 55,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'border.neutral',
            borderRadius: 1,
            height: 30,
          },
          '.MuiTablePagination-select': {
            background: 'transparent',
            '&:focus': {
              background: 'transparent',
            },
          },
        }}
      />
    </Stack>
  );
}

export default PagePagination;
