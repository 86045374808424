import React from 'react';
import { AccordionConsumer, AccordionProvider } from 'kikifrontend/components/Accordion/AccordionContext';
import AccordionItem from 'kikifrontend/components/Accordion/AccordionItem';
import Stack from '@mui/material/Stack';

function Accordions({
  children,
  spacing = 0,
  disableOnlyOneAccordionExpand,
}: React.PropsWithChildren<{ spacing?: number; disableOnlyOneAccordionExpand?: boolean }>) {
  return (
    <AccordionProvider defaultActive={false} disableOnlyOneAccordionExpand={disableOnlyOneAccordionExpand}>
      <AccordionConsumer>{() => <Stack spacing={spacing}>{children}</Stack>}</AccordionConsumer>
    </AccordionProvider>
  );
}

Accordions.Accordion = AccordionItem;

export default Accordions;
