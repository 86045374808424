import { ChainMismatchError, useContractWrite, useNetwork, usePrepareContractWrite, useSwitchNetwork } from 'wagmi';
import appABIs from 'kikifrontend/blockchains/abi';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'utils/recoil/atom';
import { ethers } from 'ethers';
import { CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';

export default function useTransferDepositToken(crypto: CryptoPaymentProps, amount: string) {
  const { statistic } = useRecoilValue(statisticAtom);
  const { chain } = useNetwork();
  const { isLoading: isLoadingSwitchChain, switchNetwork } = useSwitchNetwork();
  const {
    config,
    isSuccess: isSuccessInitialDepositContract,
    refetch: refetchInitContract,
  } = usePrepareContractWrite({
    address: crypto.network.paymentContract,
    abi: appABIs.payment,
    functionName: 'purchaseByToken',
    args: [statistic?.userInfo.affCode, crypto.contract, ethers.parseUnits(amount, crypto.decimal)],
    enabled: !!statistic && !!amount,
    chainId: crypto.network.chainId,
    onError: (err) => {
      if (err instanceof ChainMismatchError) {
        switchToPaymentChain();
      }
    },
  });
  const {
    data: depositDataTx,
    write: deposit,
    isLoading: isLoadingContractWrite,
    reset: resetContractWrite,
  } = useContractWrite(config);

  const switchToPaymentChain = () => {
    if (switchNetwork) {
      switchNetwork(crypto.network.chainId);
    }
  };

  const depositAmount = async () => {
    try {
      if (!chain) {
        return;
      }
      if (chain.id !== crypto.network.chainId) {
        switchToPaymentChain();
      } else {
        deposit?.();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      }
    }
  };

  return {
    deposit,
    depositDataTx,
    isSuccessInitialDepositContract,
    depositAmount,
    chainInformation: {
      currentChain: chain,
      selectPaymentChain: crypto.network,
      isEqualChain: chain?.id === crypto.network.chainId,
      isLoadingSwitchChain: isLoadingSwitchChain,
    },
    switchToPaymentChain,
    isLoadingSwitchChain,
    isLoadingContractWrite,
    refetchInitContract,
    resetContractWrite,
  };
}
