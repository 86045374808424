import React from 'react';
import TextField from '@mui/material/TextField';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { FormTextFieldOutlinedProps } from 'kikifrontend/components/Form/types';
import { InputPassword } from 'kikifrontend/components';
import Typography from '@mui/material/Typography';

function TextFormField<T extends FieldValues>({
  control,
  name,
  type,
  required,
  label,
  min,
  max,
  ...props
}: Omit<FormTextFieldOutlinedProps<T>, 'variant'>) {
  const numberProps = {
    InputProps: { inputProps: { min, max } },
    inputProps: { min, inputmode: 'numeric', pattern: '[0-9]*' },
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
        e.preventDefault();
      }
    },
  };

  if (type === 'password') {
    return (
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field }) => <InputPassword variant='outlined' size='small' {...field} {...props} />}
      />
    );
  }
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => (
        <TextField
          variant='outlined'
          size='small'
          type={type}
          {...field}
          {...props}
          label={
            required ? (
              <>
                {label}
                <Typography component='span' variant='body2' color='error.main'>
                  {' '}
                  *
                </Typography>
              </>
            ) : (
              label
            )
          }
          {...(type === 'number' && numberProps)}
        />
      )}
    />
  );
}

export default TextFormField;
