import { kikiSSOS3Url, kikiWebappS3Url, ssoClientUrl } from 'kikifrontend/utils/configs';

function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const DEVELOPMENT = '/development';
const ROOT_CONNECTED_ACCOUNTS = '/connected-accounts';

// ----------------------------------------------------------------------

export const PATH_ROOT = {
  root: ROOTS,
};

const isS3Url = window.location.origin === kikiWebappS3Url;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: {
    path: 'login',
    fullPath: `${isS3Url ? kikiSSOS3Url : ssoClientUrl}/auth/login?serviceCode=kikilogin&redirectUrl=${
      window.location.origin
    }`,
  },
  signUp: `${ssoClientUrl}/auth/sign-up`,
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  expired: path(ROOTS_AUTH, '/expired'),
};

export const PATH_DASHBOARD = {
  automation: {
    path: 'automation',
    fullPath: path(ROOTS_DASHBOARD, '/automation/v3'),
    v4: {
      path: 'v4',
      editor: {
        path: 'editor',
        fullPath: path(ROOTS_DASHBOARD, '/automation/v4/editor'),
      },
    },
    v3: {
      path: 'v3',
      fullPath: path(ROOTS_DASHBOARD, '/automation/v3'),
      editor: {
        path: 'editor',
        fullPath: path(ROOTS_DASHBOARD, '/automation/v3/editor'),
      },
      editorPrevRelease: {
        path: 'editor',
        fullPath: path(ROOTS, 'automation/v3/editor'),
      },
      myAutomation: {
        path: 'me',
        fullPath: path(ROOTS_DASHBOARD, '/automation/v3/me'),
      },
      detailAutomation: {
        path: 'view',
        fullPath: path(ROOTS_DASHBOARD, '/automation/v3/view'),
      },
      myTask: {
        path: 'tasks',
        fullPath: path(ROOTS_DASHBOARD, '/automation/v3/tasks'),
      },
    },
  },
  affiliate: {
    path: 'affiliate',
    fullPath: path(ROOTS_DASHBOARD, '/affiliate'),
    createdLink: {
      path: 'created-link',
      fullPath: path(ROOTS_DASHBOARD, '/affiliate/created-link'),
    },
    statistic: {
      path: 'statistic',
      fullPath: path(ROOTS_DASHBOARD, '/affiliate/statistic'),
    },
  },
  historyLog: {
    path: 'history-log',
    fullPath: path(ROOTS_DASHBOARD, '/history-log'),
    login: {
      path: 'login',
      fullPath: path(ROOTS_DASHBOARD, '/history-log/login'),
    },
    profile: {
      path: 'profile',
      fullPath: path(ROOTS_DASHBOARD, '/history-log/profile'),
    },
    sessions: {
      path: 'sessions',
      fullPath: path(ROOTS_DASHBOARD, '/history-log/sessions'),
    },
  },
  adsReport: {
    path: 'ads-report',
    fullPath: path(ROOTS_DASHBOARD, '/ads-report'),
    facebook: {
      path: 'facebook',
      fullPath: path(ROOTS_DASHBOARD, '/ads-report/facebook'),
      accountReport: {
        path: 'account-report',
        fullPath: path(ROOTS_DASHBOARD, '/ads-report/facebook/account-report'),
      },
      campaignReport: {
        path: 'campaign-report',
        fullPath: path(ROOTS_DASHBOARD, '/ads-report/facebook/campaign-report'),
      },
      adsChange: {
        path: 'ads-change',
        fullPath: path(ROOTS_DASHBOARD, '/ads-report/facebook/ads-change'),
      },
    },
    adsTags: {
      path: 'ads-tags',
      fullPath: path(ROOTS_DASHBOARD, '/ads-tags'),
    },
    createAdsTag: {
      path: 'create',
      fullPath: path(ROOTS_DASHBOARD, '/ads-tags/create'),
    },
    editAdsTag: {
      path: 'edit/:id',
      fullPath: path(ROOTS_DASHBOARD, '/ads-tags/edit'),
    },
  },
  connectAccounts: {
    validateGoogleOAuthCode: {
      path: 'validate-oauth-code',
      fullPath: path(ROOT_CONNECTED_ACCOUNTS, '/validate-oauth-code'),
    },
  },
  members: {
    path: 'members',
    fullPath: path(ROOTS_DASHBOARD, '/members'),
  },
  appSettings: {
    path: 'settings',
    fullPath: path(ROOTS_DASHBOARD, '/settings'),
    user: {
      path: 'user',
      fullPath: path(ROOTS_DASHBOARD, '/settings/user'),
    },
  },
  profiles: {
    path: 'profiles',
    fullPath: path(ROOTS_DASHBOARD, '/profiles'),
    profileDeleted: {
      path: 'deleted',
      fullPath: path(ROOTS_DASHBOARD, '/profiles/deleted'),
    },
    profileShared: {
      path: 'shared',
      fullPath: path(ROOTS_DASHBOARD, '/profiles/shared'),
    },
    profileTeam: {
      path: 'my-team',
      fullPath: path(ROOTS_DASHBOARD, '/profiles/my-team'),
    },
    profileCreate: {
      path: 'create',
      fullPath: path(ROOTS_DASHBOARD, '/profiles/create'),
    },
    profileUpdate: {
      path: 'edit/:id',
      fullPath: path(ROOTS_DASHBOARD, '/profiles/edit'),
    },
  },
  supportCenter: {
    path: 'support-center',
    fullPath: path(ROOTS_DASHBOARD, '/support-center'),
  },
  externalServices: {
    path: 'external-services',
    fullPath: path(ROOTS_DASHBOARD, '/external-services'),
  },
  paymentCenter: {
    path: 'payment-center',
    fullPath: path(ROOTS_DASHBOARD, '/payment-center'),
  },
  apolloScan: {
    path: 'apollo-scan',
    fullPath: path(ROOTS_DASHBOARD, '/apollo-scan'),
  },
  synchronization: {
    path: 'synchronization',
    fullPath: path(ROOTS_DASHBOARD, '/synchronization'),
  },
  extensions: {
    path: 'extensions',
    fullPath: path(ROOTS_DASHBOARD, '/extensions'),
    personalExtensions: {
      path: 'me',
      fullPath: path(ROOTS_DASHBOARD, '/extensions/me'),
    },
    detailExtension: {
      path: 'view',
      fullPath: path(ROOTS_DASHBOARD, '/extensions/view'),
    },
  },
  plugins: {
    path: 'plugins',
    fullPath: path(ROOTS_DASHBOARD, '/plugins'),
    personalPlugins: {
      path: 'me',
      fullPath: path(ROOTS_DASHBOARD, '/plugins/me'),
    },
  },
};

export const PATH_DEVELOPMENT = {
  components: {
    path: 'components',
    fullPath: path(DEVELOPMENT, '/components'),
    color: {
      path: 'color',
      fullPath: path(DEVELOPMENT, '/components/color'),
    },
    typography: {
      path: 'typography',
      fullPath: path(DEVELOPMENT, '/components/typography'),
    },
    buttons: {
      path: 'buttons',
      fullPath: path(DEVELOPMENT, '/components/buttons'),
    },
    checkbox: {
      path: 'checkbox',
      fullPath: path(DEVELOPMENT, '/components/checkbox'),
    },
    input: {
      path: 'textfield',
      fullPath: path(DEVELOPMENT, '/components/textfield'),
    },
  },
  hooks: {
    path: 'hooks',
    fullPath: path(DEVELOPMENT, '/hooks'),
    debouncer: path(DEVELOPMENT, '/hooks/debouncer'),
  },
};
const appRoutes = {
  ...PATH_ROOT,
  ...PATH_AUTH,
  ...PATH_DASHBOARD,
  ...PATH_DEVELOPMENT,
};

export default appRoutes;
