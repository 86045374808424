import React from 'react';
import { PackageChangeProps } from 'kikifrontend/features/paymentCenter/types/package.types';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import { useTranslation } from 'react-i18next';
import { loadingCalculate } from 'kikifrontend/features/paymentCenter/atom/loading.atom';
import Stack from '@mui/material/Stack';
import { Flex, Svg, useModal } from '@kikisoftware/uikit';
import SimpleBar from 'simplebar-react';
import Typography from '@mui/material/Typography';
import { dateTimeFormatter, subDate } from 'kikifrontend/utils/date-time';
import Skeleton from '@mui/material/Skeleton';
import { currencyFormat } from 'kikifrontend/utils/format';
import Divider from '@mui/material/Divider';
import { couponTypes } from 'kikifrontend/features/paymentCenter/utils/constants';
import { useMutation } from '@tanstack/react-query';
import { paymentServices } from 'kikifrontend/_apis_';
import Box from '@mui/material/Box';
import DepositAmountModal from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/DepositAmountModal';
import useLocales from 'kikifrontend/hooks/useLocales';
import ExtendSuccessfullyModal from 'kikifrontend/features/paymentCenter/components/ExtendPackage/ExtendSuccessfullyModal';
import Assets from 'kikifrontend/assets';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { TrackButton } from 'kikifrontend/components';
import { isFreePackageSelector } from 'kikifrontend/utils/recoil/selectors';

function ChangePackageSummary({ pack, resultPrice }: { pack: PackageChangeProps; resultPrice: any }) {
  const { statistic } = useRecoilValue(statisticAtom);
  const { t } = useTranslation();
  const loading = useRecoilValue(loadingCalculate);
  const { mutateAsync, isLoading } = useMutation(paymentServices.changePackage);
  const { openModal } = useModal();
  const { allLang, currentLang } = useLocales();
  const isFree = useRecoilValue(isFreePackageSelector);

  const submitChangePackage = async () => {
    if (!resultPrice) return;
    try {
      await mutateAsync({
        quantity: resultPrice.selectedTime,
        coupon: resultPrice.coupon?.code,
        package: pack._id,
      });
      openModal(ExtendSuccessfullyModal);
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    }
  };

  const depositAmount = () => {
    const isVn = currentLang.value === allLang[1].value;
    openModal(DepositAmountModal, {
      initialAmount: isVn ? resultPrice?.amountNeedToDeposit : resultPrice?.amountNeedToDepositInUSD,
    });
  };

  return (
    <>
      <SimpleBar style={{ height: window.innerHeight * 0.19 }}>
        <Stack spacing={1} pr={2}>
          <Flex>
            <Typography variant='body1'>{t('Gói cũ')}</Typography>
            <Typography variant='body1Semibold' color='text.subColor2'>
              {statistic?.package?.name ?? '--'}
            </Typography>
          </Flex>
          {!isFree && (
            <>
              <Flex>
                <Typography variant='body1'>{t('Số ngày còn lại')}</Typography>
                <Typography variant='body1Semibold' color='text.subColor2'>
                  {subDate(statistic?.package?.expireAt) ?? '--'}
                </Typography>
              </Flex>
              <Flex>
                <Typography variant='body1'>{t('Ngày kết thúc gói hiện tại')}</Typography>
                {loading ? (
                  <Skeleton animation='wave' width={120} height={14} />
                ) : (
                  <Typography variant='body1Semibold' color='text.subColor2'>
                    {resultPrice ? `${dateTimeFormatter(resultPrice.currentExpireDate)}` : '--'}
                  </Typography>
                )}
              </Flex>
            </>
          )}
          <Flex>
            <Typography variant='body1'>{t('Số tiền quy đổi của số ngày còn lại')}</Typography>
            {loading ? (
              <Skeleton width={120} height={14} />
            ) : (
              <Typography variant='body1Semibold' color='primary.main'>
                {currencyFormat(resultPrice?.remainingBalanceInUSD, { prefix: '$' })}
              </Typography>
            )}
          </Flex>
          <Divider />
          <Flex>
            <Typography variant='body1'>{t('Gói đổi')}</Typography>
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <Typography variant='body1Semibold' color='#777E90'>
                {statistic?.package?.name ?? '--'}
              </Typography>
              <Svg
                src={Assets.icons.IcoArrowRight}
                sx={{ bgcolor: 'text.primary', display: 'block', width: 10, height: 10 }}
              />
              <Typography variant='body1Semibold' color='text.subColor2'>
                {resultPrice?.packageName ?? '--'}
              </Typography>
            </Stack>
          </Flex>
          <Flex>
            <Typography variant='body1'>{t('Thành viên')}</Typography>
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <Typography variant='body1Semibold' color='#777E90'>
                {statistic?.package?.memberLimit ?? '--'}
              </Typography>
              <Svg
                src={Assets.icons.IcoArrowRight}
                sx={{
                  bgcolor:
                    (statistic?.package?.memberLimit ?? 0) < resultPrice?.package?.memberLimit
                      ? 'success.main'
                      : 'error.main',
                  display: 'block',
                  width: 10,
                  height: 10,
                }}
              />
              <Typography variant='body1Semibold' color='text.subColor2'>
                {resultPrice?.package?.memberLimit ?? '--'}
              </Typography>
            </Stack>
          </Flex>
          <Flex>
            <Typography variant='body1'>{t('Profile')}</Typography>
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <Typography variant='body1Semibold' color='#777E90'>
                {statistic?.package?.profileLimit ?? '--'}
              </Typography>
              <Svg
                src={Assets.icons.IcoArrowRight}
                sx={{
                  bgcolor:
                    (statistic?.package?.profileLimit ?? 0) < resultPrice?.package?.profileLimit
                      ? 'success.main'
                      : 'error.main',
                  display: 'block',
                  width: 10,
                  height: 10,
                }}
              />
              <Typography variant='body1Semibold' color='text.subColor2'>
                {resultPrice?.package?.profileLimit ?? '--'}
              </Typography>
            </Stack>
          </Flex>

          <Flex>
            <Typography variant='body1' color='text.subColor2'>
              {t('Ngày hết hạn')}
            </Typography>
            {loading ? (
              <Skeleton width={120} height={14} />
            ) : (
              <Typography variant='body1Semibold' color='text.subColor2'>
                {resultPrice ? `${dateTimeFormatter(resultPrice.applyCouponNextExpireDate)}` : '--'}
              </Typography>
            )}
          </Flex>

          <Flex>
            <Typography variant='body1'>{t('Giá gói/tháng')}</Typography>
            {loading ? (
              <Skeleton width={120} height={14} />
            ) : (
              <Typography variant='body1Semibold' color='text.subColor2'>
                {currencyFormat(resultPrice?.packagePriceInUSD / resultPrice?.packageMonths, { prefix: '$' })}
              </Typography>
            )}
          </Flex>
          <Flex>
            <Typography variant='body1'>{t('Giá gói cước')}</Typography>
            {loading ? (
              <Skeleton width={120} height={14} />
            ) : (
              <Typography variant='body1Semibold' color='text.subColor2'>
                {currencyFormat(resultPrice?.packagePriceInUSD, { prefix: '$' })}
              </Typography>
            )}
          </Flex>
          <Flex>
            <Typography variant='body1'>{t('Khuyến mãi')}</Typography>
            {loading ? (
              <Skeleton width={120} height={14} />
            ) : (
              <Typography variant='body1Semibold' color='success.main'>
                {resultPrice?.coupon?.discountLabel ?? '--'}{' '}
                {resultPrice?.coupon?.type === couponTypes.addMonth && t('tháng sử dụng')}
              </Typography>
            )}
          </Flex>
          {/* <Flex>*/}
          {/*  <Typography variant="body1" color="#A3ADB7">*/}
          {/*    {t("Số dư ví")}*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body1Semibold" color="primary.main">*/}
          {/*    {CurrencyFormat(resultPrice?.userBalanceInUSD, "$", "", 2)}*/}
          {/*  </Typography>*/}
          {/* </Flex>*/}
        </Stack>
      </SimpleBar>
      <Flex sx={{ mt: 1.5 }}>
        <Typography variant='h5' color='text.subColor2' fontFamily='SVN-Gilroy-Bold'>
          {t('Tổng thanh toán')}
        </Typography>
        {loading ? (
          <Skeleton width={120} height={14} />
        ) : (
          <Typography variant='h5' color='primary.main' fontFamily='SVN-Gilroy-Medium'>
            {currencyFormat(resultPrice?.applyCouponPackagePriceInUSD, { prefix: '$' })}
          </Typography>
        )}
      </Flex>
      <Flex>
        <div />
        {loading ? (
          <Skeleton width={120} height={14} />
        ) : (
          <Typography variant='body1' color='text.subColor'>
            ≈ {currencyFormat(resultPrice?.applyCouponPackagePrice, { prefix: 'đ' })}
          </Typography>
        )}
      </Flex>
      {!loading && !resultPrice?.isReadySubmitExtend && (
        <Box
          className='w-full text-center py-2 flex gap-2 justify-center'
          mt={2}
          sx={{ bgcolor: 'rgba(239, 70, 111,' + ' 0.1)' }}
        >
          <Typography variant='body1' color='error.light'>
            {t('Số dư không đủ. Vui lòng nạp thêm', {
              amount: currencyFormat(resultPrice?.amountNeedToDepositInUSD, { prefix: '$' }),
            })}
            .
          </Typography>
          <Typography
            variant='body1Bold'
            color='error.light'
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={depositAmount}
          >
            {t('Nạp ngay')}
          </Typography>
        </Box>
      )}
      <TrackButton
        variant='contained'
        color='success'
        sx={{ mt: 2, color: 'white' }}
        fullWidth
        onClick={submitChangePackage}
        loading={isLoading}
        disabled={!resultPrice?.isReadySubmitExtend || loading || isLoading}
        eventName='payment_center_change_package_success'
        params={{
          selected_package_name: pack.name,
          period: resultPrice?.packageMonths ?? '-',
          is_use_coupon: !!resultPrice?.coupon,
          sub_total: currencyFormat(resultPrice?.packagePriceInUSD, { prefix: '$' }),
          total: currencyFormat(resultPrice?.applyCouponPackagePriceInUSD, { prefix: '$' }),
        }}
      >
        {t('Thanh toán')}
      </TrackButton>
    </>
  );
}

export default ChangePackageSummary;
