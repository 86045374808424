import React, { useMemo } from 'react';
import { extendTimeOptions } from 'kikifrontend/features/paymentCenter/utils/constants';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { currencyFormat, USDvsVND } from 'kikifrontend/utils/format';
import { PackagePricingProps, PricingConfig } from 'kikifrontend/features/paymentCenter/types/package.types';
import { ButtonIcon } from '@kikisoftware/uikit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PackageTimeOptions({
  isYear,
  selectedTime,
  currentPackagePrice,
  onSelectTime,
  selectTimeOptions,
}: {
  isYear: boolean;
  selectedTime: string;
  currentPackagePrice: PackagePricingProps;
  onSelectTime: (time: string) => () => void;
  selectTimeOptions: (isYear: boolean) => () => void;
}) {
  const { t } = useTranslation();

  const options = useMemo(
    () => Object.entries(extendTimeOptions).filter((time) => (isYear ? Number(time[0]) >= 12 : Number(time[0]) < 12)),
    [isYear],
  );

  return (
    <div className='mt-4 relative'>
      {isYear ? (
        <ButtonIcon
          buttonType='circle'
          sx={{ position: 'absolute', top: 40, left: -21, zIndex: 2 }}
          onClick={selectTimeOptions(false)}
        >
          <ArrowBackIcon sx={{ color: 'text.subColor' }} />
        </ButtonIcon>
      ) : (
        <ButtonIcon
          buttonType='circle'
          sx={{ position: 'absolute', top: 40, right: -10, zIndex: 2, transform: 'rotate(180deg)' }}
          onClick={selectTimeOptions(true)}
        >
          <ArrowBackIcon sx={{ color: 'text.subColor' }} />
        </ButtonIcon>
      )}
      <div className='flex gap-3'>
        {options.map(([key, value]) => {
          const packagePrice = currentPackagePrice.pricingConfig.oneMonth * Number(key);
          const discount = parseInt(
            `${(1 - currentPackagePrice?.pricingConfig[value as keyof PricingConfig] / packagePrice) * 100}`,
          );
          return (
            <Stack
              alignItems='flex-start'
              key={key}
              width={1}
              justifyContent='center'
              sx={{
                border: '1px solid',
                borderRadius: '5px',
                cursor: 'pointer',
                borderColor: value === selectedTime ? 'primary.main' : 'divider',
                position: 'relative',
                px: 1.5,
                py: 2.5,
              }}
              spacing={0.6}
              onClick={onSelectTime(value)}
            >
              <div className='flex gap-2 items-center'>
                <Typography variant='body1Bold' color={value === selectedTime ? 'text.modalTitle' : 'text.primary'}>
                  {t(value)}
                </Typography>
                {discount > 0 && (
                  <Box
                    width={46}
                    height={20}
                    className='flex justify-center items-center'
                    sx={{ bgcolor: 'rgba(73, 239, 70, 0.15)' }}
                  >
                    <Typography variant='caption' color='success.main'>
                      -{discount}%
                    </Typography>
                  </Box>
                )}
              </div>
              <div className='flex flex-col'>
                <Typography component='p' variant='h6' color='text.subColor2'>
                  {currencyFormat(currentPackagePrice?.pricingConfig[value as keyof PricingConfig], {
                    prefix: '$',
                    rate: USDvsVND,
                  })}
                </Typography>
                {discount > 0 ? (
                  <Typography variant='body1' color='grey.400' sx={{ textDecoration: 'line-through' }}>
                    {currencyFormat(packagePrice, {
                      prefix: '$',
                      rate: USDvsVND,
                    })}
                  </Typography>
                ) : (
                  <div className='h-6' />
                )}
              </div>
            </Stack>
          );
        })}
      </div>
    </div>
  );
}

export default PackageTimeOptions;
