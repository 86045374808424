import { profileApis } from 'kikifrontend/_apis_/profiles';
import { generateDefaultValueFromFingerprintConfig } from 'kikifrontend/features/profiles/profileEditor/utils/helpers/generateDefaultValueFromFingerprintConfig';
import { ProfileEditorFormValue } from 'kikifrontend/features/profiles/profileEditor/types/formValue.type';
import { UseFormReturn } from 'react-hook-form';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';

export const updateNewFingerprint = async (
  { browserType, system }: { browserType: BrowserType; system: { value?: string; label?: string } },
  { setValue }: UseFormReturn<ProfileEditorFormValue>,
) => {
  if (!system.value || !system.label) return;
  const result =
    browserType === 'gomubrowser' || browserType === 'kifox'
      ? await profileApis.createProfileConfigV2(system.value, browserType)
      : await profileApis.createProfileConfig(system.value, browserType);

  const generateValues = generateDefaultValueFromFingerprintConfig({
    fingerprintConfig: result,
    browserType: browserType,
    system: system.value,
  });

  setValue('userAgent', result.userAgent);
  setValue('platform', result.platform);
  setValue('computerInfomation', generateValues.computerInfomation);
  setValue('advancedFingerprint', generateValues.advancedFingerprint);
  setValue('mediaDeviceFingerprint', generateValues.mediaDeviceFingerprint);
  setValue('resolution', generateValues.resolution);
  setValue('otherConfig', generateValues.otherConfig);
  setValue('webRTC', generateValues.webRTC);
  setValue('addFormField', generateValues.addFormField);
  setValue('timezone', generateValues.timezone);
  setValue('language', generateValues.language);
  if (browserType === 'kifox') {
    setValue('savingOptions', generateValues.savingOptions);
  }
};
