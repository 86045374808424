const breakpoints: { values: { [Key: string]: number } } = {
  values: {
    xs: 0,
    sm: 600,
    smm: 700,
    md: 900,
    lg: 1200,
    mac: 1440,
    apps: 1500,
    xl: 1536,
    fhd: 1920,
    qhd: 2560,
  },
};

export default breakpoints;
