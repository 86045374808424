import { atom, DefaultValue, selector } from 'recoil';
import {
  NodeGlobalVariables,
  NodeVariables,
} from 'kikifrontend/features/automation/v3/AutomationEditor/types/node.types';
import { differenceBy, uniq } from 'lodash';

export const automationVariablesAtom = atom<NodeVariables[]>({
  key: 'automationVariablesAtom',
  default: [
    { node: 'BROWSER_PROFILE_ID', variables: ['BROWSER_PROFILE_ID'], scope: 'default' },
    { node: 'BROWSER_PROFILE_NAME', variables: ['BROWSER_PROFILE_NAME'], scope: 'default' },
  ],
});

export const automationVariablesMappingValueAtom = atom<NodeGlobalVariables[]>({
  key: 'automationVariablesMappingValueAtom',
  default: [],
});

export const automationGlobalVariableSelector = selector<NodeGlobalVariables[]>({
  key: 'automationVariableSelector',
  get: ({ get }) => {
    return get(automationVariablesMappingValueAtom);
  },
  set: ({ set, get }, newValue) => {
    const allVariables = get(automationVariablesAtom);
    const currentMappingVariables = get(automationVariablesMappingValueAtom);
    const newVariables: NodeVariables[] = [];
    if (!(newValue instanceof DefaultValue)) {
      const removedVariables = differenceBy(currentMappingVariables, newValue, 'name').map((variable) => variable.name);

      const variables = uniq(newValue.map((v) => v.name));
      variables.forEach((variable) => {
        if (allVariables.findIndex((v) => v.node === variable) === -1) {
          newVariables.push({ node: variable, variables: [variable], scope: 'global' });
        }
      });
      set(
        automationVariablesAtom,
        allVariables
          .concat(newVariables)
          .filter((variable) => !removedVariables.includes(variable.node) && variable.node !== ''),
      );
      set(automationVariablesMappingValueAtom, newValue);
    } else {
      set(automationVariablesMappingValueAtom, newValue);
    }
  },
});
