import { getFromLocalStorage, saveToLocalStorage } from 'kikifrontend/utils/localstorage';
import { DataGridColDef } from '@kikisoftware/uikit-table-v2';
import { ColumnParameter } from 'kikifrontend/types/user.types';
import { TABLE_SETTING_FIELD } from 'kikifrontend/utils/constants';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import { sortColumnsByPinned } from '@kikisoftware/uikit-table';

export function saveTableSettings(newTableSettings: any) {
  const currentTableSettings = getFromLocalStorage(TABLE_SETTING_FIELD) ?? {};
  const updateTableSettings = { ...currentTableSettings, ...newTableSettings };
  saveToLocalStorage(TABLE_SETTING_FIELD, updateTableSettings);
}

export const isOpenWithApp = window.navigator.userAgent.indexOf('KikiLogin') !== -1;

export const DEFAULT_APP_WIDTH = 1485;
export const WEB_APP_RATIO = window.innerWidth / DEFAULT_APP_WIDTH;
export const isLessThanAppWidth = window.innerWidth < DEFAULT_APP_WIDTH;

export function convertColumnsToSaveData(
  columns: DataGridColDef[],
  options?: { field: string; replaceData: Record<string, any> },
): ColumnParameter[] {
  const { field, replaceData } = options || {};
  return columns.map((col) => {
    return {
      width: col.width ?? 120,
      visibility: col.visibility ?? true,
      pinned: col.pinned ?? false,
      field: col.field,
      ...(field === col.field && {
        ...replaceData,
      }),
    };
  });
}

export function replaceNewConfig(
  currentConfig: ColumnParameter[],
  options: { field: string; replaceData: Record<string, any> },
): ColumnParameter[] {
  const { field, replaceData } = options;
  return currentConfig.map((col) => {
    return {
      ...col,
      ...(field === col.field && {
        ...replaceData,
      }),
    };
  });
}

export function changeElementOrder(
  array: ColumnParameter[],
  { field, oldIndex, targetIndex }: { field: string; oldIndex: number; targetIndex: number },
): ColumnParameter[] {
  const newArray = [...array];
  const item = newArray.find((item) => item.field === field);

  if (!item || oldIndex >= newArray.length || targetIndex >= newArray.length) {
    return newArray;
  }

  newArray.splice(oldIndex, 1);

  newArray.splice(targetIndex, 0, item);

  return newArray;
}

export function updatePinnedConfig(columns: ColumnParameter[], pinnedColumns: string[]): ColumnParameter[] {
  return columns
    .reduce((acc, { field, visibility, pinned, width, ratio }) => {
      return field !== GRID_CHECKBOX_SELECTION_COL_DEF.field && field !== GRID_DETAIL_PANEL_TOGGLE_FIELD
        ? [...acc, { field, visibility, pinned, width, ratio }]
        : acc;
    }, [] as ColumnParameter[])
    .map((col) => ({ ...col, pinned: pinnedColumns.includes(col.field) }))
    .sort(sortColumnsByPinned);
}

export function getPinnedColumns(columns: ColumnParameter[]): string[] {
  return columns.reduce((acc, cur) => {
    return cur.pinned ? [...acc, cur.field] : acc;
  }, [] as string[]);
}

export function getVisibilityColumns(columns: ColumnParameter[]): Record<string, boolean> {
  return columns.reduce((acc, cur) => ({ ...acc, [cur.field]: cur.visibility }), {});
}

export function updateNewVisibilityConfig(
  currentConfig: ColumnParameter[],
  model: GridColumnVisibilityModel,
): ColumnParameter[] {
  return currentConfig.map((col) => {
    return {
      ...col,
      visibility: model[col.field],
    };
  });
}

export function convertColumnsToVisibilitySaveData(
  columns: DataGridColDef[],
  model: GridColumnVisibilityModel,
): ColumnParameter[] {
  return columns.map((col) => {
    return {
      width: col.width ?? 120,
      visibility: model[col.field] ?? col.visibility ?? true,
      pinned: col.pinned ?? true,
      field: col.field,
    };
  });
}

export function convertColDefToColumnParameter(columns: DataGridColDef[]): ColumnParameter[] {
  const _columns: ColumnParameter[] = [];
  columns.forEach((col) => {
    if (col.field !== GRID_CHECKBOX_SELECTION_COL_DEF.field && col.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD) {
      _columns.push({
        width: col.width ?? 120,
        visibility: col.visibility ?? true,
        pinned: col.pinned ?? false,
        field: col.field,
      });
    }
  });

  return _columns;
}
