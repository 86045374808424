import React, { useCallback } from 'react';
import { BrowserType } from 'kikifrontend/features/profiles/profileEditor/types/browserType.types';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';
import { localServices } from 'kikifrontend/_apis_';
import { ErrorUtils } from 'kikifrontend/utils/api-services/error';
import { useRecoilValue } from 'recoil';
import { notificationAtom } from 'kikifrontend/components/Notification/atoms/notification.atom';
import { isAppConnectedAtom, systemInformationAtom } from 'kikifrontend/utils/recoil/atom';
import InfoIcon from '@mui/icons-material/Info';

interface DownloadBrowserModalProps extends ModalProps {
  browserType: BrowserType;
}

function DownloadBrowserModal({ onClose, browserType }: DownloadBrowserModalProps) {
  const { t } = useTranslation();
  const { mutate } = useMutation(localServices.downloadBrowser);
  const buttonNotification = useRecoilValue(notificationAtom);
  const isAppConnected = useRecoilValue(isAppConnectedAtom);
  const systemInformation = useRecoilValue(systemInformationAtom);

  const onDownloadBrowser = useCallback(async () => {
    try {
      mutate(browserType);
      onClose();
      if (buttonNotification) {
        buttonNotification.click();
      }
    } catch (err) {
      ErrorUtils.handleCommonError(err, t);
    }
  }, [buttonNotification]);

  return (
    <Modal title={t('Tải xuống browser')} onClose={onClose} width={520}>
      <Typography variant='body2'>
        {t('download_browser_description', {
          browser:
            browserType === 'kikibrowser'
              ? 'Kiki'
              : browserType === 'kifox'
              ? `Kifox v${systemInformation?.kifoxVersion ?? ''}`
              : `Gomu v${systemInformation?.gomuVersion ?? ''}`,
        })}
      </Typography>
      {isAppConnected ? (
        <div className='flex justify-end items-center gap-3 mt-4'>
          <Button variant='contained' color='secondary' onClick={onClose}>
            {t('Hủy bỏ')}
          </Button>
          <Button variant='contained' onClick={onDownloadBrowser}>
            {t('common.download')}
          </Button>
        </div>
      ) : (
        <div className='flex justify-end gap-2 items-center mt-4'>
          <InfoIcon color='warning' fontSize='small' />
          <Typography variant='body2' color='warning.main'>
            {t('Vui lòng mở KikiLogin App để tiếp tục')}
          </Typography>
        </div>
      )}
    </Modal>
  );
}

export default DownloadBrowserModal;
