import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Image } from '@kikisoftware/uikit';
import { CryptoPaymentProps } from 'kikifrontend/features/paymentCenter/types/paymentMethod.types';
import { formatBalanceInCrypto } from 'kikifrontend/utils/format';
import TokenIconWithNetwork from 'kikifrontend/features/paymentCenter/components/DepositAmountModal/TokenIconWithNetwork';

function TokenWithAmount({
  amount,
  tokenName,
  tokenIcon,
  iconSize = 24,
  token,
}: {
  amount: string | ReactNode;
  tokenName: string;
  tokenIcon: string;
  iconSize?: number;
  token?: CryptoPaymentProps;
}) {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      {typeof amount === 'string' ? (
        <Typography variant='body2Bold'>
          {formatBalanceInCrypto(amount)} {tokenName}
        </Typography>
      ) : (
        amount
      )}
      {token ? (
        <TokenIconWithNetwork token={token} iconSize={iconSize} />
      ) : (
        <Image src={tokenIcon} sx={{ width: iconSize, height: iconSize }} />
      )}
    </Stack>
  );
}

export default TokenWithAmount;
