import React from 'react';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { PremiumCard } from 'kikifrontend/components/Premium/PremiumFeature';

function PremiumModal({ onClose, isSecondModal }: ModalProps) {
  return (
    <Modal onClose={onClose} hideHeader>
      <PremiumCard isInSecondModal={isSecondModal} />
    </Modal>
  );
}

export default PremiumModal;
