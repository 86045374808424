import React, { ForwardedRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

export interface NavLinkProps {
  className?: string;
  activeClassName?: string | ((props: { isActive: boolean; isPending: boolean }) => string | undefined);
  to: string;
}

const NavLink = React.forwardRef(
  (props: React.PropsWithChildren<NavLinkProps>, ref?: ForwardedRef<HTMLAnchorElement>) => (
    <RouterLink ref={ref} to={props.to} className={props.activeClassName}>
      {props.children}
    </RouterLink>
  ),
);

export default NavLink;
