import { keyBy } from 'lodash';
import { Network, NetworkProps } from 'kikifrontend/blockchains/types/network.types';

export const MAX_ALLOWANCE_UNIT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const networkConfig: Record<Network, NetworkProps> = {
  'binance-smart-chain': {
    name: 'Binance Smart Chain',
    shortName: 'BSC',
    scanLink: 'https://bscscan.com/tx',
    hex: '0x38',
    decimal: '56',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  'bsc-testnet': {
    name: 'Binance Smart Chain Testnet',
    shortName: 'BSC Testnet',
    scanLink: 'https://testnet.bscscan.com/tx',
    hex: '0x61',
    decimal: '97',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  'ethereum-ropsten': {
    name: 'Ropsten',
    shortName: 'Ropsten',
    scanLink: 'https://ropsten.etherscan.io/tx',
    nativeCurrency: {
      name: 'ETH',
    },
    hex: '0x3',
    decimal: '3',
  },
  'ethereum-rinkeby': {
    name: 'Rinkeby',
    shortName: 'Rinkeby',
    scanLink: 'https://rinkeby.etherscan.io/tx',
    nativeCurrency: {
      name: 'ETH',
    },
    hex: '0x4',
    decimal: '4',
  },
  ethereum: {
    name: 'Ethereum',
    shortName: 'Ethereum',
    scanLink: 'https://etherscan.io/tx',
    nativeCurrency: {
      name: 'ETH',
    },
    hex: '0x1',
    decimal: '1',
  },
  'polygon-mumbai': {
    name: 'Polygon Mumbai Testnet',
    shortName: 'Polygon Mumbai',
    scanLink: 'https://mumbai.polygonscan.com/tx',
    hex: '0x13881',
    decimal: '80001',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygontestapi.terminet.io/rpc'],
    blockExplorerUrls: ['https://explorer-mumbai.maticvigil.com/'],
  },
  polygon: {
    name: 'Polygon',
    shortName: 'Polygon',
    scanLink: 'https://polygonscan.com/tx',
    hex: '0x89',
    decimal: '137',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
};

export const networkByIds = keyBy(Object.values(networkConfig), 'decimal');
export const getNetworkName = <T extends keyof typeof networkConfig>(network: T) =>
  networkConfig[network]?.name ?? network;
export const getNetworkShortName = <T extends keyof typeof networkConfig>(network: T) =>
  networkConfig[network]?.shortName ?? network;
