import React from 'react';
import SelectGroup from 'kikifrontend/features/automation/v3/AutomationTask/components/SelectGroup';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { SelectFormFieldOutlinedProps } from 'kikifrontend/components';
import Typography from '@mui/material/Typography';
import { classNames } from 'kikifrontend/utils/string';

function SelectLabelFormField<T extends FieldValues>({
  control,
  name,
  options,
  label,
  disabled,
  className = '',
}: Pick<SelectFormFieldOutlinedProps<T>, 'options' | 'control' | 'name' | 'label' | 'disabled' | 'className'>) {
  return (
    <div className={classNames('flex gap-4 items-center justify-between w-full flex-wrap', className)}>
      {label && (
        <Typography variant='body2' whiteSpace='nowrap' className='grow' component='p'>
          {label}
        </Typography>
      )}

      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field }) => {
          const value = options.find(({ value }) => value === field.value);
          return (
            <SelectGroup
              options={options}
              value={(value?.value ?? options[0].value) as string}
              // @ts-ignore
              onChange={field.onChange}
              labelStyle={{
                flexGrow: 0,
              }}
              sx={{
                height: 32,
                ...(disabled && {
                  pointerEvents: 'none',
                  opacity: 0.6,
                }),
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default SelectLabelFormField;
