import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SelectWebsitesProps } from 'kikifrontend/features/surveyCustomers/utils/types';
import FormControlLabel from '@mui/material/FormControlLabel';

const listWebsites = ['Facebook', 'Google', 'Youtube', 'Telegram', 'Amazon', 'Ebay', 'Etsy', 'Crypto'];

const SelectWebsites = ({ selectWebsiteData, onSelectWebsite, value, onOtherChange }: SelectWebsitesProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Typography variant='body1Bold'>1. {t('survey_modal_selectWebsites')}</Typography>
      <Typography variant='body2'>{t('survey_modal_selectWebsites_description')}</Typography>
      <Grid container>
        {listWebsites.map((website) => (
          <Grid item key={website} md={6}>
            <FormControlLabel
              label={website}
              name={website}
              control={<Checkbox checked={selectWebsiteData.includes(website)} onChange={onSelectWebsite(website)} />}
            />
          </Grid>
        ))}
        <Grid item md={12}>
          <FormControlLabel
            label='Other'
            control={<Checkbox checked={selectWebsiteData.includes('Other')} onChange={onSelectWebsite('Other')} />}
          />
          {selectWebsiteData.includes('Other') && (
            <Box mt={2}>
              <TextField
                multiline
                rows={1}
                value={value}
                onChange={onOtherChange('selectWebsite')}
                sx={{ width: 1 }}
                placeholder={t('Nhập nền tảng')}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SelectWebsites;
