import React from 'react';
import Divider from '@mui/material/Divider';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useRecoilValue } from 'recoil';
import { statisticAtom } from 'kikifrontend/utils/recoil/atom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, Image, useModal } from '@kikisoftware/uikit';
import Assets from 'kikifrontend/assets';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { LanguagePopover, Link } from 'kikifrontend/components';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Box from '@mui/material/Box';
import appRoutes from 'kikifrontend/routes/path';
import SaleContact from 'kikifrontend/layouts/dashboard/sidebar/SaleContact';
import SystemInformationModal from 'kikifrontend/components/Modals/SystemInformationModal';
import FeedbackModal from 'kikifrontend/features/feedback/components/FeedbackModal';
import { isFreePackageSelector, isLeaderSelector } from 'kikifrontend/utils/recoil/selectors';
import ChangePackageListPackagesModal from 'kikifrontend/features/paymentCenter/components/ChangePackage/ChangePackageListPackagesModal';
import { classNames } from 'kikifrontend/utils/string';

function SidebarFooter() {
  const { t } = useTranslation();
  const { statistic } = useRecoilValue(statisticAtom);
  const isFree = useRecoilValue(isFreePackageSelector);
  const isLeader = useRecoilValue(isLeaderSelector);
  const { openModal } = useModal();

  const openSystemModal = () => openModal(SystemInformationModal);

  const openFeedbackModal = () => openModal(FeedbackModal);

  const onBoxClick = () => {
    if (isFree) {
      openModal(ChangePackageListPackagesModal);
    }
  };

  return (
    <div className='relative'>
      <SaleContact />
      <Divider />
      {statistic && (
        <div className='px-5 py-2 mt-3'>
          <Box
            sx={
              isFree
                ? // ? {
                  //     background:
                  //       'linear-gradient(90deg, rgba(37,43,205,1) 0%, rgba(77,53,213,1) 37%, rgba(143,29,200,1) 100%)',
                  //     p: 2,
                  //     borderRadius: 1,
                  //     color: 'white',
                  //     position: 'relative',
                  //     overflow: 'hidden',
                  //     cursor: 'pointer',
                  //   }
                  { bgcolor: 'background.secondary', p: 2, borderRadius: 1 }
                : { bgcolor: 'background.secondary', p: 2, borderRadius: 1 }
            }
            onClick={onBoxClick}
          >
            {isFree && (
              <Box
                sx={{
                  position: 'absolute',
                  left: 30,
                  top: 0,
                  height: 1,
                  width: 36,
                  bgcolor: 'white',
                  opacity: 0.2,
                  transform: 'skew(-30deg)',
                  animation: 'slide 3000ms linear infinite',
                  '@keyframes slide': {
                    '20%, 100%': {
                      opacity: 0,
                      left: '100%',
                    },
                    '0%': {
                      opacity: 0,
                    },
                    '5%': {
                      opacity: 0.2,
                    },
                  },
                }}
              />
            )}
            <div className='flex gap-2 items-center justify-between'>
              <Typography
                component='p'
                variant='body2'
                color={'text.modalTitle'}
                sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {t('Gói của bạn')}
              </Typography>
              <Typography component='p' variant='body1Bold' color={'text.modalTitle'}>
                {statistic.userInfo.package.packageDetail.name}
              </Typography>
            </div>

            <div className='flex gap-2 items-center justify-between mt-1'>
              <Typography
                component='p'
                variant='body2'
                color={'text.subColo2'}
                sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
                className='grow'
              >
                {t('Profiles')}
              </Typography>
              <Box sx={{ bgcolor: 'white', borderRadius: 9999 }}>
                <Image src={Assets.icons.IcoGomu} />
              </Box>
              <Typography component='p' variant='body1Bold' color={'text.modalTitle'}>
                {statistic.userInfo.usedProfile}/{statistic.package.profileLimit - (isFree ? 1 : 0)}
              </Typography>
            </div>
            <div className='flex gap-2 items-center justify-between mt-1'>
              <Typography
                component='p'
                variant='body2'
                color='text.subColo2'
                sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
                className='grow'
              >
                {t('Thành viên')}
              </Typography>
              <PeopleAltIcon fontSize='small' sx={{ color: 'primary.main' }} />
              <Typography component='p' variant='body1Bold' color={'text.modalTitle'}>
                {statistic.userInfo.usedMember}/{statistic.package.memberLimit}
              </Typography>
            </div>
          </Box>
          <Divider sx={{ my: 2 }} />

          <div className='flex gap-1 items-center'>
            <Link
              to={appRoutes.paymentCenter.fullPath}
              className={classNames('grow', isLeader ? '' : 'pointer-events-none')}
            >
              <Button
                disableElevation
                variant='contained'
                color='secondary'
                fullWidth
                size='small'
                startIcon={<Image src={Assets.icons.Upgrade} sx={{ width: 20 }} />}
              >
                {t('Nâng cấp')}
              </Button>
            </Link>
            <LanguagePopover showLabel={false} />
            <ButtonIcon buttonType='circle' onClick={openSystemModal} title={t('sidebar.monitor')}>
              <MonitorHeartIcon sx={{ color: 'grey.500' }} />
            </ButtonIcon>
            <ButtonIcon buttonType='circle' title={t('sidebar.feedBack')} onClick={openFeedbackModal}>
              <RateReviewIcon sx={{ color: 'grey.500', fontSize: 21 }} />
            </ButtonIcon>
          </div>
        </div>
      )}
    </div>
  );
}

export default SidebarFooter;
