import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePickerProps } from './types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonIcon } from '@kikisoftware/uikit';

function DatePicker<T>({ value, onChange, localText, sx, onClear }: DatePickerProps<T>) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localText}>
        <DateRangePicker
          value={value}
          onChange={onChange}
          slots={{ field: SingleInputDateRangeField }}
          slotProps={{
            textField: {
              size: 'small',
              InputProps: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarMonthIcon fontSize='small' sx={{ color: 'text.subColor', opacity: 0.7 }} />
                  </InputAdornment>
                ),
                ...(onClear && {
                  endAdornment: (
                    <ButtonIcon buttonType='circle' onClick={onClear} sx={{ width: 28, height: 28 }}>
                      <CloseIcon fontSize='small' />
                    </ButtonIcon>
                  ),
                }),
              },
              sx: {
                fieldset: {
                  borderColor: 'border.neutral',
                },
                ...sx,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}

export default DatePicker;
