import React from 'react';
import { PluginProps } from 'kikifrontend/features/plugins/interfaces/plugin.interface';
import { useTranslation } from 'react-i18next';
import { Flex, Image, useModal } from '@kikisoftware/uikit';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PluginUseButton from 'kikifrontend/features/plugins/components/PluginUseButton';
import PluginDetailModal from 'kikifrontend/features/plugins/components/PluginDetailModal';
import useLocales from 'kikifrontend/hooks/useLocales';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';

function MyPluginCard(plugin: PluginProps) {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { getI18nText } = useLocales();

  const dayRemaining = plugin.expireAt ? dayjs(plugin.expireAt).diff(dayjs(), 'second') : undefined;

  const openDetailPluginModal = () => openModal(PluginDetailModal, { pluginId: plugin.pluginInfo._id });

  return (
    <Stack
      sx={{
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        height: 1,
        position: 'relative',
      }}
      p={2}
    >
      <Stack direction='row' gap={1}>
        <Image
          src={plugin.pluginInfo.icon}
          alt='icon'
          asyncLoading={false}
          sx={{ width: 60, height: 60, flexBasis: 60, flexShrink: 0 }}
        />
        <Stack sx={{ width: 'calc(100% - 70px)', flexShrink: 0 }}>
          <Flex sx={{ flexShrink: 1, width: 1 }} spacing={3}>
            <Tooltip arrow title={plugin.pluginInfo.name}>
              <Typography
                variant='body1Bold'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  width: 'fit-content',
                  lineHeight: 1.3,
                  color: 'text.subColor2',
                }}
              >
                {plugin.pluginInfo.name}
              </Typography>
            </Tooltip>

            {/*<Typography variant='body2Bold' color='text.modalTitle' whiteSpace='nowrap'>*/}
            {/*  Còn 120 ngày*/}
            {/*</Typography>*/}
          </Flex>
          <Stack direction='row' alignItems='center' sx={{ minWidth: 0 }}>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'text.subColor4',
              }}
            >
              {t('Tác giả')}: {plugin.pluginInfo.author}
            </Typography>
          </Stack>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text.subColor4',
            }}
          >
            Version: {plugin.pluginInfo.version}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant='body1'
        color='text.subColor'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical',
          height: 96,
          mt: 3,
        }}
      >
        {getI18nText(plugin.pluginInfo.shortDescription)}
      </Typography>
      <Divider sx={{ my: 1.5 }} />
      <div className='flex justify-between items-center'>
        {/*<Image src={AUTOMATION_TAGS[0].icon} sx={{ width: 34, height: 34 }} />*/}
        <div />
        <Stack direction='row' spacing={1.5} alignItems='center'>
          <Button variant='outlined' onClick={openDetailPluginModal}>
            {t('Xem chi tiết')}
          </Button>
          <PluginUseButton plugin={{ ...plugin.pluginInfo, isUsed: true }} dayRemaining={dayRemaining} />
        </Stack>
      </div>
    </Stack>
  );
}

export default MyPluginCard;
