export const TIME_ZONE = [
  { timeZoneName: 'Africa/Abidjan' },
  { timeZoneName: 'Africa/Accra' },
  { timeZoneName: 'Africa/Addis_Ababa' },
  { timeZoneName: 'Africa/Algiers' },
  { timeZoneName: 'Africa/Asmara' },
  { timeZoneName: 'Africa/Bamako' },
  { timeZoneName: 'Africa/Bangui' },
  { timeZoneName: 'Africa/Banjul' },
  { timeZoneName: 'Africa/Bissau' },
  { timeZoneName: 'Africa/Blantyre' },
  { timeZoneName: 'Africa/Bujumbura' },
  { timeZoneName: 'Africa/Cairo' },
  { timeZoneName: 'Africa/Casablanca' },
  { timeZoneName: 'Africa/Ceuta' },
  { timeZoneName: 'Africa/Conakry' },
  { timeZoneName: 'Africa/Dakar' },
  { timeZoneName: 'Africa/Dar_es_Salaam' },
  { timeZoneName: 'Africa/Djibouti' },
  { timeZoneName: 'Africa/Douala' },
  { timeZoneName: 'Africa/El_Aaiun' },
  { timeZoneName: 'Africa/Freetown' },
  { timeZoneName: 'Africa/Gaborone' },
  { timeZoneName: 'Africa/Harare' },
  { timeZoneName: 'Africa/Johannesburg' },
  { timeZoneName: 'Africa/Juba' },
  { timeZoneName: 'Africa/Kampala' },
  { timeZoneName: 'Africa/Khartoum' },
  { timeZoneName: 'Africa/Kigali' },
  { timeZoneName: 'Africa/Kinshasa' },
  { timeZoneName: 'Africa/Lagos' },
  { timeZoneName: 'Africa/Libreville' },
  { timeZoneName: 'Africa/Luanda' },
  { timeZoneName: 'Africa/Lubumbashi' },
  { timeZoneName: 'Africa/Lusaka' },
  { timeZoneName: 'Africa/Malabo' },
  { timeZoneName: 'Africa/Maputo' },
  { timeZoneName: 'Africa/Maseru' },
  { timeZoneName: 'Africa/Mbabane' },
  { timeZoneName: 'Africa/Mogadishu' },
  { timeZoneName: 'Africa/Monrovia' },
  { timeZoneName: 'Africa/Nairobi' },
  { timeZoneName: 'Africa/Ndjamena' },
  { timeZoneName: 'Africa/Niamey' },
  { timeZoneName: 'Africa/Nouakchott' },
  { timeZoneName: 'Africa/Ouagadougou' },
  { timeZoneName: 'Africa/Porto-Novo' },
  { timeZoneName: 'Africa/Sao_Tome' },
  { timeZoneName: 'Africa/Tripoli' },
  { timeZoneName: 'Africa/Tunis' },
  { timeZoneName: 'Africa/Windhoek' },
  { timeZoneName: 'America/Adak' },
  { timeZoneName: 'America/Anchorage' },
  { timeZoneName: 'America/Anguilla' },
  { timeZoneName: 'America/Antigua' },
  { timeZoneName: 'America/Araguaina' },
  { timeZoneName: 'America/Argentina/Catamarca' },
  { timeZoneName: 'America/Argentina/Cordoba' },
  { timeZoneName: 'America/Argentina/Jujuy' },
  { timeZoneName: 'America/Argentina/La_Rioja' },
  { timeZoneName: 'America/Argentina/Mendoza' },
  { timeZoneName: 'America/Argentina/Rio_Gallegos' },
  { timeZoneName: 'America/Argentina/San_Juan' },
  { timeZoneName: 'America/Argentina/San_Luis' },
  { timeZoneName: 'America/Argentina/Tucuman' },
  { timeZoneName: 'America/Argentina/Ushuaia' },
  { timeZoneName: 'America/Aruba' },
  { timeZoneName: 'America/Asuncion' },
  { timeZoneName: 'America/Atikokan' },
  { timeZoneName: 'America/Bahia' },
  { timeZoneName: 'America/Bahia_Banderas' },
  { timeZoneName: 'America/Barbados' },
  { timeZoneName: 'America/Belem' },
  { timeZoneName: 'America/Belize' },
  { timeZoneName: 'America/Blanc-Sablon' },
  { timeZoneName: 'America/Boa_Vista' },
  { timeZoneName: 'America/Bogota' },
  { timeZoneName: 'America/Boise' },
  { timeZoneName: 'America/Buenos_Aires' },
  { timeZoneName: 'America/Cambridge_Bay' },
  { timeZoneName: 'America/Campo_Grande' },
  { timeZoneName: 'America/Cancun' },
  { timeZoneName: 'America/Caracas' },
  { timeZoneName: 'America/Cayenne' },
  { timeZoneName: 'America/Cayman' },
  { timeZoneName: 'America/Chicago' },
  { timeZoneName: 'America/Chihuahua' },
  { timeZoneName: 'America/Costa_Rica' },
  { timeZoneName: 'America/Creston' },
  { timeZoneName: 'America/Cuiaba' },
  { timeZoneName: 'America/Curacao' },
  { timeZoneName: 'America/Danmarkshavn' },
  { timeZoneName: 'America/Dawson' },
  { timeZoneName: 'America/Dawson_Creek' },
  { timeZoneName: 'America/Denver' },
  { timeZoneName: 'America/Detroit' },
  { timeZoneName: 'America/Dominica' },
  { timeZoneName: 'America/Edmonton' },
  { timeZoneName: 'America/Eirunepe' },
  { timeZoneName: 'America/El_Salvador' },
  { timeZoneName: 'America/Fortaleza' },
  { timeZoneName: 'America/Glace_Bay' },
  { timeZoneName: 'America/Godthab' },
  { timeZoneName: 'America/Goose_Bay' },
  { timeZoneName: 'America/Grand_Turk' },
  { timeZoneName: 'America/Grenada' },
  { timeZoneName: 'America/Guadeloupe' },
  { timeZoneName: 'America/Guatemala' },
  { timeZoneName: 'America/Guayaquil' },
  { timeZoneName: 'America/Guyana' },
  { timeZoneName: 'America/Halifax' },
  { timeZoneName: 'America/Havana' },
  { timeZoneName: 'America/Hermosillo' },
  { timeZoneName: 'America/Indiana/Knox' },
  { timeZoneName: 'America/Indiana/Marengo' },
  { timeZoneName: 'America/Indiana/Petersburg' },
  { timeZoneName: 'America/Indiana/Tell_City' },
  { timeZoneName: 'America/Indiana/Vevay' },
  { timeZoneName: 'America/Indiana/Vincennes' },
  { timeZoneName: 'America/Indiana/Winamac' },
  { timeZoneName: 'America/Indianapolis' },
  { timeZoneName: 'America/Inuvik' },
  { timeZoneName: 'America/Iqaluit' },
  { timeZoneName: 'America/Jamaica' },
  { timeZoneName: 'America/Juneau' },
  { timeZoneName: 'America/Kentucky/Monticello' },
  { timeZoneName: 'America/Kralendijk' },
  { timeZoneName: 'America/La_Paz' },
  { timeZoneName: 'America/Lima' },
  { timeZoneName: 'America/Los_Angeles' },
  { timeZoneName: 'America/Lower_Princes' },
  { timeZoneName: 'America/Maceio' },
  { timeZoneName: 'America/Managua' },
  { timeZoneName: 'America/Manaus' },
  { timeZoneName: 'America/Marigot' },
  { timeZoneName: 'America/Martinique' },
  { timeZoneName: 'America/Matamoros' },
  { timeZoneName: 'America/Mazatlan' },
  { timeZoneName: 'America/Menominee' },
  { timeZoneName: 'America/Merida' },
  { timeZoneName: 'America/Metlakatla' },
  { timeZoneName: 'America/Mexico_City' },
  { timeZoneName: 'America/Miquelon' },
  { timeZoneName: 'America/Moncton' },
  { timeZoneName: 'America/Monterrey' },
  { timeZoneName: 'America/Montevideo' },
  { timeZoneName: 'America/Montreal' },
  { timeZoneName: 'America/Montserrat' },
  { timeZoneName: 'America/Nassau' },
  { timeZoneName: 'America/New_York' },
  { timeZoneName: 'America/Nipigon' },
  { timeZoneName: 'America/Nome' },
  { timeZoneName: 'America/Noronha' },
  { timeZoneName: 'America/North_Dakota/Beulah' },
  { timeZoneName: 'America/North_Dakota/Center' },
  { timeZoneName: 'America/North_Dakota/New_Salem' },
  { timeZoneName: 'America/Ojinaga' },
  { timeZoneName: 'America/Panama' },
  { timeZoneName: 'America/Pangnirtung' },
  { timeZoneName: 'America/Paramaribo' },
  { timeZoneName: 'America/Phoenix' },
  { timeZoneName: 'America/Port-au-Prince' },
  { timeZoneName: 'America/Porto_Velho' },
  { timeZoneName: 'America/Puerto_Rico' },
  { timeZoneName: 'America/Punta_Arenas' },
  { timeZoneName: 'America/Rainy_River' },
  { timeZoneName: 'America/Rankin_Inlet' },
  { timeZoneName: 'America/Recife' },
  { timeZoneName: 'America/Regina' },
  { timeZoneName: 'America/Resolute' },
  { timeZoneName: 'America/Santa_Isabel' },
  { timeZoneName: 'America/Santarem' },
  { timeZoneName: 'America/Santiago' },
  { timeZoneName: 'America/Santo_Domingo' },
  { timeZoneName: 'America/Sao_Paulo' },
  { timeZoneName: 'America/Scoresbysund' },
  { timeZoneName: 'America/Shiprock' },
  { timeZoneName: 'America/Sitka' },
  { timeZoneName: 'America/St_Barthelemy' },
  { timeZoneName: 'America/St_Johns' },
  { timeZoneName: 'America/St_Kitts' },
  { timeZoneName: 'America/St_Lucia' },
  { timeZoneName: 'America/St_Thomas' },
  { timeZoneName: 'America/St_Vincent' },
  { timeZoneName: 'America/Swift_Current' },
  { timeZoneName: 'America/Tegucigalpa' },
  { timeZoneName: 'America/Thule' },
  { timeZoneName: 'America/Thunder_Bay' },
  { timeZoneName: 'America/Tijuana' },
  { timeZoneName: 'America/Toronto' },
  { timeZoneName: 'America/Tortola' },
  { timeZoneName: 'America/Vancouver' },
  { timeZoneName: 'America/Virgin' },
  { timeZoneName: 'America/Whitehorse' },
  { timeZoneName: 'America/Winnipeg' },
  { timeZoneName: 'America/Yakutat' },
  { timeZoneName: 'America/Yellowknife' },
  { timeZoneName: 'Antarctica/Casey' },
  { timeZoneName: 'Antarctica/Davis' },
  { timeZoneName: 'Antarctica/DumontDUrville' },
  { timeZoneName: 'Antarctica/Macquarie' },
  { timeZoneName: 'Antarctica/Mawson' },
  { timeZoneName: 'Antarctica/McMurdo' },
  { timeZoneName: 'Antarctica/Palmer' },
  { timeZoneName: 'Antarctica/Rothera' },
  { timeZoneName: 'Antarctica/South_Pole' },
  { timeZoneName: 'Antarctica/Syowa' },
  { timeZoneName: 'Antarctica/Vostok' },
  { timeZoneName: 'Arctic/Longyearbyen' },
  { timeZoneName: 'Asia/Aden' },
  { timeZoneName: 'Asia/Almaty' },
  { timeZoneName: 'Asia/Amman' },
  { timeZoneName: 'Asia/Anadyr' },
  { timeZoneName: 'Asia/Aqtau' },
  { timeZoneName: 'Asia/Aqtobe' },
  { timeZoneName: 'Asia/Ashgabat' },
  { timeZoneName: 'Asia/Baghdad' },
  { timeZoneName: 'Asia/Bahrain' },
  { timeZoneName: 'Asia/Baku' },
  { timeZoneName: 'Asia/Bangkok' },
  { timeZoneName: 'Asia/Beirut' },
  { timeZoneName: 'Asia/Bishkek' },
  { timeZoneName: 'Asia/Brunei' },
  { timeZoneName: 'Asia/Choibalsan' },
  { timeZoneName: 'Asia/Chongqing' },
  { timeZoneName: 'Asia/Colombo' },
  { timeZoneName: 'Asia/Damascus' },
  { timeZoneName: 'Asia/Dhaka' },
  { timeZoneName: 'Asia/Dili' },
  { timeZoneName: 'Asia/Dubai' },
  { timeZoneName: 'Asia/Dushanbe' },
  { timeZoneName: 'Asia/Gaza' },
  { timeZoneName: 'Asia/Harbin' },
  { timeZoneName: 'Asia/Hebron' },
  { timeZoneName: 'Asia/Ho_Chi_Minh' },
  { timeZoneName: 'Asia/Hong_Kong' },
  { timeZoneName: 'Asia/Hovd' },
  { timeZoneName: 'Asia/Irkutsk' },
  { timeZoneName: 'Asia/Jakarta' },
  { timeZoneName: 'Asia/Jayapura' },
  { timeZoneName: 'Asia/Jerusalem' },
  { timeZoneName: 'Asia/Kabul' },
  { timeZoneName: 'Asia/Kamchatka' },
  { timeZoneName: 'Asia/Karachi' },
  { timeZoneName: 'Asia/Kashgar' },
  { timeZoneName: 'Asia/Katmandu' },
  { timeZoneName: 'Asia/Kolkata' },
  { timeZoneName: 'Asia/Krasnoyarsk' },
  { timeZoneName: 'Asia/Kuala_Lumpur' },
  { timeZoneName: 'Asia/Kuching' },
  { timeZoneName: 'Asia/Kuwait' },
  { timeZoneName: 'Asia/Macau' },
  { timeZoneName: 'Asia/Magadan' },
  { timeZoneName: 'Asia/Makassar' },
  { timeZoneName: 'Asia/Manila' },
  { timeZoneName: 'Asia/Muscat' },
  { timeZoneName: 'Asia/Nicosia' },
  { timeZoneName: 'Asia/Novokuznetsk' },
  { timeZoneName: 'Asia/Novosibirsk' },
  { timeZoneName: 'Asia/Omsk' },
  { timeZoneName: 'Asia/Oral' },
  { timeZoneName: 'Asia/Phnom_Penh' },
  { timeZoneName: 'Asia/Pontianak' },
  { timeZoneName: 'Asia/Pyongyang' },
  { timeZoneName: 'Asia/Qatar' },
  { timeZoneName: 'Asia/Qyzylorda' },
  { timeZoneName: 'Asia/Rangoon' },
  { timeZoneName: 'Asia/Riyadh' },
  { timeZoneName: 'Asia/Saigon' },
  { timeZoneName: 'Asia/Sakhalin' },
  { timeZoneName: 'Asia/Samarkand' },
  { timeZoneName: 'Asia/Seoul' },
  { timeZoneName: 'Asia/Shanghai' },
  { timeZoneName: 'Asia/Singapore' },
  { timeZoneName: 'Asia/Taipei' },
  { timeZoneName: 'Asia/Tashkent' },
  { timeZoneName: 'Asia/Tbilisi' },
  { timeZoneName: 'Asia/Tehran' },
  { timeZoneName: 'Asia/Tokyo' },
  { timeZoneName: 'Asia/Ulaanbaatar' },
  { timeZoneName: 'Asia/Urumqi' },
  { timeZoneName: 'Asia/Vladivostok' },
  { timeZoneName: 'Asia/Yakutsk' },
  { timeZoneName: 'Asia/Yekaterinburg' },
  { timeZoneName: 'Asia/Yerevan' },
  { timeZoneName: 'Atlantic/Azores' },
  { timeZoneName: 'Atlantic/Bermuda' },
  { timeZoneName: 'Atlantic/Canary' },
  { timeZoneName: 'Atlantic/Cape_Verde' },
  { timeZoneName: 'Atlantic/Faroe' },
  { timeZoneName: 'Atlantic/Madeira' },
  { timeZoneName: 'Atlantic/Reykjavik' },
  { timeZoneName: 'Atlantic/South_Georgia' },
  { timeZoneName: 'Atlantic/St_Helena' },
  { timeZoneName: 'Atlantic/Stanley' },
  { timeZoneName: 'Australia/Adelaide' },
  { timeZoneName: 'Australia/Brisbane' },
  { timeZoneName: 'Australia/Broken_Hill' },
  { timeZoneName: 'Australia/Canberra' },
  { timeZoneName: 'Australia/Currie' },
  { timeZoneName: 'Australia/Darwin' },
  { timeZoneName: 'Australia/Eucla' },
  { timeZoneName: 'Australia/Hobart' },
  { timeZoneName: 'Australia/Lindeman' },
  { timeZoneName: 'Australia/Lord_Howe' },
  { timeZoneName: 'Australia/Melbourne' },
  { timeZoneName: 'Australia/Perth' },
  { timeZoneName: 'Australia/Sydney' },
  { timeZoneName: 'Europe/Amsterdam' },
  { timeZoneName: 'Europe/Andorra' },
  { timeZoneName: 'Europe/Athens' },
  { timeZoneName: 'Europe/Belgrade' },
  { timeZoneName: 'Europe/Berlin' },
  { timeZoneName: 'Europe/Bratislava' },
  { timeZoneName: 'Europe/Brussels' },
  { timeZoneName: 'Europe/Bucharest' },
  { timeZoneName: 'Europe/Budapest' },
  { timeZoneName: 'Europe/Chisinau' },
  { timeZoneName: 'Europe/Copenhagen' },
  { timeZoneName: 'Europe/Dublin' },
  { timeZoneName: 'Europe/Gibraltar' },
  { timeZoneName: 'Europe/Guernsey' },
  { timeZoneName: 'Europe/Helsinki' },
  { timeZoneName: 'Europe/Isle_of_Man' },
  { timeZoneName: 'Europe/Istanbul' },
  { timeZoneName: 'Europe/Jersey' },
  { timeZoneName: 'Europe/Kaliningrad' },
  { timeZoneName: 'Europe/Kiev' },
  { timeZoneName: 'Europe/Lisbon' },
  { timeZoneName: 'Europe/Ljubljana' },
  { timeZoneName: 'Europe/London' },
  { timeZoneName: 'Europe/Luxembourg' },
  { timeZoneName: 'Europe/Madrid' },
  { timeZoneName: 'Europe/Malta' },
  { timeZoneName: 'Europe/Mariehamn' },
  { timeZoneName: 'Europe/Minsk' },
  { timeZoneName: 'Europe/Monaco' },
  { timeZoneName: 'Europe/Moscow' },
  { timeZoneName: 'Europe/Oslo' },
  { timeZoneName: 'Europe/Paris' },
  { timeZoneName: 'Europe/Podgorica' },
  { timeZoneName: 'Europe/Prague' },
  { timeZoneName: 'Europe/Riga' },
  { timeZoneName: 'Europe/Rome' },
  { timeZoneName: 'Europe/Samara' },
  { timeZoneName: 'Europe/Sarajevo' },
  { timeZoneName: 'Europe/Skopje' },
  { timeZoneName: 'Europe/Sofia' },
  { timeZoneName: 'Europe/Stockholm' },
  { timeZoneName: 'Europe/Tallinn' },
  { timeZoneName: 'Europe/Tirane' },
  { timeZoneName: 'Europe/Vaduz' },
  { timeZoneName: 'Europe/Vatican' },
  { timeZoneName: 'Europe/Vienna' },
  { timeZoneName: 'Europe/Vilnius' },
  { timeZoneName: 'Europe/Warsaw' },
  { timeZoneName: 'Europe/Zagreb' },
  { timeZoneName: 'Europe/Zurich' },
  { timeZoneName: 'Indian/Antananarivo' },
  { timeZoneName: 'Indian/Chagos' },
  { timeZoneName: 'Indian/Christmas' },
  { timeZoneName: 'Indian/Cocos' },
  { timeZoneName: 'Indian/Comoro' },
  { timeZoneName: 'Indian/Kerguelen' },
  { timeZoneName: 'Indian/Mahe' },
  { timeZoneName: 'Indian/Maldives' },
  { timeZoneName: 'Indian/Mauritius' },
  { timeZoneName: 'Indian/Mayotte' },
  { timeZoneName: 'Indian/Reunion' },
  { timeZoneName: 'Pacific/Apia' },
  { timeZoneName: 'Pacific/Auckland' },
  { timeZoneName: 'Pacific/Chatham' },
  { timeZoneName: 'Pacific/Chuuk' },
  { timeZoneName: 'Pacific/Easter' },
  { timeZoneName: 'Pacific/Efate' },
  { timeZoneName: 'Pacific/Enderbury' },
  { timeZoneName: 'Pacific/Fakaofo' },
  { timeZoneName: 'Pacific/Fiji' },
  { timeZoneName: 'Pacific/Galapagos' },
  { timeZoneName: 'Pacific/Gambier' },
  { timeZoneName: 'Pacific/Guadalcanal' },
  { timeZoneName: 'Pacific/Guam' },
  { timeZoneName: 'Pacific/Honolulu' },
  { timeZoneName: 'Pacific/Kiritimati' },
  { timeZoneName: 'Pacific/Kosrae' },
  { timeZoneName: 'Pacific/Kwajalein' },
  { timeZoneName: 'Pacific/Majuro' },
  { timeZoneName: 'Pacific/Marquesas' },
  { timeZoneName: 'Pacific/Nauru' },
  { timeZoneName: 'Pacific/Niue' },
  { timeZoneName: 'Pacific/Norfolk' },
  { timeZoneName: 'Pacific/Noumea' },
  { timeZoneName: 'Pacific/Pago_Pago' },
  { timeZoneName: 'Pacific/Palau' },
  { timeZoneName: 'Pacific/Pitcairn' },
  { timeZoneName: 'Pacific/Pohnpei' },
  { timeZoneName: 'Pacific/Port_Moresby' },
  { timeZoneName: 'Pacific/Rarotonga' },
  { timeZoneName: 'Pacific/Saipan' },
  { timeZoneName: 'Pacific/Tahiti' },
  { timeZoneName: 'Pacific/Tarawa' },
  { timeZoneName: 'Pacific/Tongatapu' },
  { timeZoneName: 'Pacific/Wallis' },
];
