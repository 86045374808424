import http from 'kikifrontend/utils/api-services/http';
import { QueryFilterData, ResponseApi, ResponseData } from 'kikifrontend/types/api.types';
import { MeProps, UserInfoProps } from 'kikifrontend/types/user.types';
import { StatisticProps } from 'kikifrontend/types/statistic.type';
import { ChangePasswordFormValue } from 'kikifrontend/features/appSettings/userSettings/utils/userPasswordHandler';
import HttpUtils from 'kikifrontend/utils/api-services/httpUtils';
import { SessionProps } from 'kikifrontend/features/historyLog/sessions/types/session.types';

async function ssoLogin(data: { serviceCode: string; sessionId: string }) {
  return http.sso
    .post<ResponseApi<{ token?: string; userInfo?: UserInfoProps }>>(`/api/sessions/check`, data)
    .then((res) => res.data);
}

async function logoutSSO() {
  return http.sso.post('/api/user/logout');
}

async function getStatistic() {
  return http.client.get<ResponseApi<StatisticProps>>('/api/statistic').then((res) => res.data);
}

async function changePassword(payload: ChangePasswordFormValue) {
  return http.sso.post('/api/user/auth/change-password', payload);
}

async function me() {
  return http.sso.get<ResponseApi<MeProps>>('/api/user/me').then(HttpUtils.getDataFromHttpResponse);
}

async function fetchAllSessions(query: QueryFilterData) {
  return http.sso
    .post<ResponseApi<ResponseData<SessionProps[]>>>('/api/sessions/filter', query)
    .then(HttpUtils.getDataFromHttpResponse);
}

async function endAllSessions() {
  return http.sso.post('/api/user/logout-all');
}

async function shutdownSession(sessionDatabaseId: string) {
  return http.sso.post('/api/sessions/end-session', { sessionDatabaseId });
}

async function resetApiKey() {
  return http.client.post('/api/user/reset-api-key');
}

async function twoFactorConfirmPassword(password: string) {
  return http.sso.post('/api/authentication/validate-password', { password });
}

async function twoFactorGenerateKey(payload: { password: string; method: string }) {
  return http.sso
    .post<ResponseApi<{ keyUri: string; otpSecret: string }>>('/api/authentication/2fa/generate', payload)
    .then(HttpUtils.getDataFromHttpResponse);
}

async function enableTwoFactor(data: { password: string; method: string; otpCode: string }) {
  return http.sso.post('/api/authentication/2fa/enable', data);
}

async function disableTwoFactor(data: { password: string; otpCode: string }) {
  return http.sso.post('/api/authentication/2fa/disable', data);
}

async function resetPassword(email: string) {
  return http.sso.post('/api/user/auth/reset-password', { email });
}

async function getBackupCode(data: { password: string; otpCode: string }) {
  return http.sso
    .post<ResponseApi<string[]>>('/api/authentication/2fa/get-backup-code', data)
    .then(HttpUtils.getDataFromHttpResponse);
}

async function resetBackupCode(data: { password: string; otpCode: string }) {
  return http.sso
    .post<ResponseApi<string[]>>('/api/authentication/2fa/reset-backup-code', data)
    .then(HttpUtils.getDataFromHttpResponse);
}

export const authServices = {
  logoutSSO,
  getStatistic,
  ssoLogin,
  changePassword,
  me,
  fetchAllSessions,
  endAllSessions,
  shutdownSession,
  resetApiKey,
  twoFactorConfirmPassword,
  twoFactorGenerateKey,
  enableTwoFactor,
  disableTwoFactor,
  resetPassword,
  getBackupCode,
  resetBackupCode,
};
