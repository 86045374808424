export const saveToLocalStorage = (key: string, data: any) => {
  if (typeof data === 'string') {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export function getFromLocalStorage<T>(key: string): T | undefined {
  const data = localStorage.getItem(key);
  if (!data) return;
  return JSON.parse(data);
}

export const getStringFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};
