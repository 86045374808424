import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import Assets from 'kikifrontend/assets';
import { ButtonIcon, Image, Modal, ModalProps, useModal } from '@kikisoftware/uikit';
import QrCodeScannerIcon from '@mui/icons-material/QrCode';
import Link from '@mui/material/Link';
import QRCode from 'qrcode.react';
import { CommunityChannelProps } from 'kikifrontend/features/surveyCustomers/utils/types';
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CommunityModal from 'kikifrontend/features/surveyCustomers/CommunityModal';

const defaultIconStyle = { width: 24, height: 24, borderRadius: '50%' };
const defaultIconStyle2 = { width: 35, height: 35, borderRadius: '50%' };

export const COMMUNITY: CommunityChannelProps[] = [
  {
    title: 'Cộng đồng KikiLogin (Hỗ trợ, Chia sẻ)',
    icon: <Image src={Assets.icons.FacebookIcon} sx={defaultIconStyle} />,
    iconLarge: <Image src={Assets.icons.FacebookIcon} sx={defaultIconStyle2} />,
    url: 'https://www.facebook.com/groups/congdongkikichiase/',
  },
  {
    title: 'Cộng đồng KikiLogin Vietnam (Chat)',
    icon: <Image src={Assets.images.ZaloIcon} sx={defaultIconStyle} />,
    iconLarge: <Image src={Assets.images.ZaloIcon} sx={defaultIconStyle2} />,
    url: 'https://zalo.me/g/ixpptw784',
  },
  {
    title: 'Cộng đồng KikiLogin Golbal (chat)',
    icon: <Image src={Assets.icons.AffiliateTelegram} sx={{ ...defaultIconStyle, borderRadius: '50%' }} />,
    iconLarge: <Image src={Assets.icons.AffiliateTelegram} sx={{ ...defaultIconStyle2, borderRadius: '50%' }} />,
    url: 'https://t.me/+1W1r6HcTwqxlMTNl',
  },
];

const DOCUMENTATIONS = [
  {
    title: 'Kikilogin Việt Nam',
    icon: <Image src={Assets.icons.YoutubeIcon} sx={defaultIconStyle} />,
    url: 'https://www.youtube.com/channel/UCpj7PlLum1OKKtVs10o0E2w',
  },
  {
    title: 'KikiLogin Academy',
    icon: <Image src={Assets.icons.Global} sx={defaultIconStyle} />,
    url: 'https://kikilogin.com/vi/academy',
  },
];

const SectionLabel = ({
  title,
  titleColor = 'text.primary',
  isExpand,
  onClick,
  suffixIcon = (
    <KeyboardArrowDownOutlinedIcon
      sx={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.25s ease' }}
    />
  ),
}: {
  icon: React.ReactNode;
  title: string;
  isExpand?: boolean;
  onClick?: () => void;
  suffixIcon?: React.ReactNode;
  titleColor?: string;
}) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ cursor: 'pointer', py: 2 }}
      onClick={onClick}
    >
      <Stack direction='row' alignItems='center' spacing={2}>
        <Typography fontSize={14} fontWeight={700} color={titleColor}>
          {title}
        </Typography>
      </Stack>
      {suffixIcon}
    </Stack>
  );
};

const QRModal = ({ onClose, channel }: ModalProps) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const onBack = () => {
    openModal(CommunityModal);
  };

  return (
    <Modal
      onClose={onClose}
      title={
        <div className='flex justify-between items-center'>
          <ButtonIcon buttonType='circle' onClick={onBack}>
            <KeyboardArrowLeftIcon sx={{ color: 'text.subColor' }} />
          </ButtonIcon>
          <Typography variant='titleBold' color='text.modalTitle'>
            {t('Tham gia cộng đồng & nhận tài liệu')}
          </Typography>
          <ButtonIcon buttonType='circle' sx={{ visibility: 'hidden' }}>
            <KeyboardArrowLeftIcon sx={{ color: 'text.subColor' }} />
          </ButtonIcon>
        </div>
      }
      // PaperRootProps={{ width: '100%', maxWidth: 1000 }}
      width={600}
    >
      <div className='flex flex-col items-center gap-10'>
        <Typography variant='body1Bold'>{channel.title}</Typography>
        <QRCode
          size={256}
          value={channel.url}
          level='H'
          imageSettings={{
            src: Assets.images.Logo,
            excavate: true,
            width: 64,
            height: 64,
          }}
        />
      </div>

      <Button variant='contained' fullWidth onClick={onClose} sx={{ mt: 6 }}>
        OK
      </Button>
    </Modal>
  );
};

function Community({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const [showCommunity, setShowCommunity] = useState(true);
  const [showDocuments, setShowDocuments] = useState(true);
  const [qr, setQr] = useState<CommunityChannelProps | undefined>();
  const { openModal } = useModal();

  const toggleCommunity = () => setShowCommunity((prev) => !prev);
  const toggleDocument = () => setShowDocuments((prev) => !prev);

  const onQrClick = (channel: CommunityChannelProps) => {
    openModal(QRModal, { channel });
    // setQr(channel);
  };

  return (
    <Stack spacing={3} position='relative'>
      {qr && (
        <Stack
          p={3}
          alignItems='center'
          justifyContent='center'
          spacing={2}
          sx={{
            bgcolor: alpha('#000000', 0.6),
            width: 'calc(100% + 48px)',
            height: 'calc(100% + 104px)',
            top: -80,
            left: -24,
            zIndex: 10,
          }}
          position='absolute'
        >
          <Box sx={{ bgcolor: 'white', p: 4.5, pb: 4, borderRadius: 2, position: 'relative' }}>
            <ButtonIcon
              buttonType='circle'
              onClick={() => setQr(undefined)}
              sx={{ width: 32, height: 32, position: 'absolute', right: 7, top: 7 }}
            >
              <CloseIcon />
            </ButtonIcon>
            <QRCode
              size={256}
              value={qr.url}
              level='H'
              imageSettings={{
                src: Assets.images.Logo,
                excavate: true,
                width: 64,
                height: 64,
              }}
            />
          </Box>
        </Stack>
      )}
      <Box sx={{ border: '1px solid', borderColor: 'border.neutral', borderRadius: 1, px: 2 }}>
        <SectionLabel
          icon={<GroupIcon />}
          title={t('Cộng đồng người dùng KikiLogin')}
          isExpand={showCommunity}
          onClick={toggleCommunity}
        />
        <Collapse in={showCommunity}>
          <Stack spacing={1}>
            {COMMUNITY.map((channel) => (
              <Stack
                key={channel.title}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ cursor: 'pointer', py: 0.5, borderRadius: 2 }}
              >
                <Link href={channel.url} target='_blank' rel='noopener noreferrer' underline='hover'>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    {channel.icon}
                    <Typography fontSize={14} fontWeight={500}>
                      {t(channel.title)}
                    </Typography>
                  </Stack>
                </Link>
                <ButtonIcon buttonType='circle' onClick={() => onQrClick(channel)}>
                  <QrCodeScannerIcon sx={{ color: 'text.subColor4' }} />
                </ButtonIcon>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Box>
      <Box sx={{ border: '1px solid', borderColor: 'border.neutral', borderRadius: 1, px: 2, mt: 2 }}>
        <SectionLabel
          icon={<ArticleIcon />}
          title={t('Kênh video & khoá học')}
          isExpand={showDocuments}
          onClick={toggleDocument}
        />
        <Collapse in={showDocuments}>
          <Stack spacing={1}>
            {DOCUMENTATIONS.map((channel) => (
              <Stack
                key={channel.title}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ cursor: 'pointer', py: 0.5, borderRadius: 2 }}
              >
                <Link href={channel.url} target='_blank' rel='noopener noreferrer' underline='hover'>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    {channel.icon}
                    <Typography fontSize={14} fontWeight={500}>
                      {channel.title}
                    </Typography>
                  </Stack>
                </Link>
                <ButtonIcon buttonType='circle' onClick={() => onQrClick(channel)}>
                  <QrCodeScannerIcon sx={{ color: 'text.subColor4' }} />
                </ButtonIcon>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Box>
      <Button variant='contained' fullWidth onClick={onClose}>
        OK
      </Button>
    </Stack>
  );
}

export default Community;
