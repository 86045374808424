import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryKeys from 'kikifrontend/utils/queries/queryKeys';
import { authServices } from 'kikifrontend/_apis_';
import Box from '@mui/material/Box';
import { ButtonIcon, Image, useModal } from '@kikisoftware/uikit';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallIcon from '@mui/icons-material/Call';
import SaleQrModal from 'kikifrontend/layouts/dashboard/sidebar/SaleQRModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { expandSaleSupportAtom, meAtom, statisticAtom } from 'kikifrontend/utils/recoil/atom';

function SaleContact() {
  const { statistic } = useRecoilValue(statisticAtom);
  const { openModal } = useModal();
  const [expand, setExpand] = useRecoilState(expandSaleSupportAtom);
  const setMeAtom = useSetRecoilState(meAtom);
  const { data } = useQuery({
    queryKey: [queryKeys.users.me],
    queryFn: authServices.me,
    onSuccess: (meProps) => {
      setMeAtom(meProps);
    },
  });

  useEffect(() => {
    if (statistic?.package?.name?.toLowerCase() === 'free') {
      setExpand(true);
    }
  }, [statistic?.package?.name]);

  const toggleExpand = () => setExpand(!expand);

  const openQrModal = (type: 'zalo' | 'telegram') => () =>
    openModal(SaleQrModal, {
      sale: data?.saleContactInfo
        ? {
            avatar: data?.saleContactInfo?.avatar,
            name: data?.saleContactInfo?.name,
            ...(type === 'zalo' && {
              zalo: data?.saleContactInfo?.zalo,
            }),
            ...(type === 'telegram' && {
              zalo: data?.saleContactInfo?.telegram,
            }),
          }
        : {
            avatar:
              data?.saleContactInfo?.avatar ??
              'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688631419052',
            name: data?.saleContactInfo?.name ?? 'Nguyen Ngoc Ha',
            ...(type === 'zalo' && {
              zalo: 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688529861876',
            }),
            ...(type === 'telegram' && {
              zalo: 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688529798054',
            }),
          },
    });

  if (!data?.saleContactInfo) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: expand ? -80 : -45,
        left: 0,
        right: 0,
        border: expand ? '1px solid' : 'none',
        borderColor: 'border.neutral',
        width: '88%',
        mx: 'auto',
        borderRadius: 1.5,
        px: 1,
        py: 2,
        zIndex: 10,
        bgcolor: 'background.secondary',
        height: expand ? 64 : 40,
      }}
      className='flex items-center gap-4'
    >
      {expand ? (
        <>
          <Image
            src={
              data?.saleContactInfo?.avatar ??
              'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1688631419052'
            }
            sx={{ width: 40, height: 40, borderRadius: 20 }}
          />
          <div className='grow'>
            <Typography variant='body2Bold'>{data?.saleContactInfo?.name ?? '--'}</Typography>
            <div className='flex items-center gap-4'>
              <Typography
                variant='body2Bold'
                component='span'
                color='primary.main'
                sx={{ cursor: 'pointer', textDecoration: 'underline', '&:hover': { color: 'primary.dark' } }}
                onClick={openQrModal('zalo')}
              >
                Zalo
              </Typography>
              <Typography
                variant='body2Bold'
                component='span'
                color='primary.main'
                sx={{ cursor: 'pointer', textDecoration: 'underline', '&:hover': { color: 'primary.dark' } }}
                onClick={openQrModal('telegram')}
              >
                Telegram
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <CallIcon color='primary' />
          <div className='grow'>
            <Typography variant='body2Bold' color='primary.main' sx={{ textDecoration: 'underline' }}>
              {data?.saleContactInfo?.name}
            </Typography>
          </div>
        </>
      )}
      <ButtonIcon buttonType='circle' onClick={toggleExpand}>
        <ArrowDownwardIcon color='primary' sx={{ transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </ButtonIcon>
    </Box>
  );
}

export default SaleContact;
