import React from 'react';
import { Modal, ModalProps } from '@kikisoftware/uikit';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

function VideoModal({ onClose, videoSrc, title }: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal title={t(title)} width={850} onClose={onClose}>
      <div className='w-full flex justify-center'>
        <ReactPlayer url={videoSrc} playsinline playing controls width={(450 * 16) / 9} height={450} />
      </div>
    </Modal>
  );
}

export default VideoModal;
